var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: [
        "sidebar-separator sidebar-block max-w-sm mx-auto mb-8",
        !_vm.is_first ? "mt-12" : ""
      ]
    },
    [_c("hr", { staticClass: "block bg-brown-lighter border-0" })]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }