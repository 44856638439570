<template>
  <aside class="store-sidebar">
    <div>
      <store-selected-products></store-selected-products>
    </div>
    <hr class="block bg-brown-lighter mt-10 border-0" />
    <div class="mt-10">
      <div class="mb-4" v-for="(button, b) in $parent.buttons" :key="b">
        <base-button
          :url="button.url"
          :label="button.text"
          :color="button.color"
          :outline="button.outlined"
          size="smaller"
          class_names="w-full"
        ></base-button>
      </div>
    </div>
    <div v-if="$parent.sidebar_has_text" class="mt-6 rich-text text-links red-text-links">
      <slot></slot>
    </div>
  </aside>
</template>

<script>
export default {
  components: {
    BaseButton: () => import(/* webpackChunkName: "base-button" */ "../components/BaseButton.vue"),
    StoreSelectedProducts: () => import(/* webpackChunkName: "store-selected-products" */ "./StoreSelectedProducts.vue")
  }
};
</script>

<style scoped>
hr {
  width: 120px;
  height: 3px;
}
</style>
