var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "tr",
    { staticClass: "align-top", class: "accordion-" + _vm.index },
    [
      _c(
        "td",
        {
          on: {
            click: function($event) {
              return _vm.toggleAccordion(_vm.index)
            }
          }
        },
        [
          _vm.isAccordionOpen(_vm.index)
            ? _c("span", { staticClass: "accordion-arrow" }, [_vm._v("▼")])
            : _c("span", { staticClass: "accordion-arrow" }, [_vm._v("▶")])
        ]
      ),
      _vm._v(" "),
      _c(
        "td",
        {
          staticClass: "cursor-pointer",
          on: {
            click: function($event) {
              return _vm.toggleAccordion(_vm.index)
            }
          }
        },
        [
          _vm._v("\n    " + _vm._s(_vm.item.thing_name) + "\n    "),
          _c("br"),
          _vm._v(" "),
          _c("span", { staticClass: "text-lg italic text-grey-alt" }, [
            _vm._v(_vm._s(_vm.item.status.version))
          ])
        ]
      ),
      _vm._v(" "),
      _c("td", [
        _vm._v(
          _vm._s(_vm.roundToThreeDecimals(_vm.item.status.free)) +
            " GB of 50 GB"
        )
      ]),
      _vm._v(" "),
      _c("td", [_vm._v(_vm._s(_vm.item.status.voltage) + " V")]),
      _vm._v(" "),
      _c("td", [
        _vm._v(
          "\n    " + _vm._s(_vm.formatDate(_vm.item.last_status)) + "\n    "
        ),
        _c("br"),
        _vm._v(" "),
        _c("span", { staticClass: "text-lg italic text-grey-alt" }, [
          _vm._v(_vm._s(_vm.timeAgo(_vm.item.last_status)))
        ])
      ]),
      _vm._v(" "),
      _vm.item.authorized && _vm.item.is_connected == "t"
        ? _c("td", [
            _c(
              "a",
              {
                attrs: {
                  href:
                    "https://" +
                    _vm.item.server_ip +
                    ":" +
                    _vm.item.server_https,
                  target: "_blank"
                }
              },
              [
                _vm._v(
                  "\n      https://" +
                    _vm._s(_vm.item.server_ip) +
                    ":" +
                    _vm._s(_vm.item.server_https) +
                    "\n    "
                )
              ]
            )
          ])
        : _c("td", [
            _c("span", [
              _vm._v(
                "\n      https://" +
                  _vm._s(_vm.item.server_ip) +
                  ":" +
                  _vm._s(_vm.item.server_https) +
                  "/\n      "
              ),
              _c("br"),
              _vm._v(" "),
              _c("span", { staticClass: "text-lg italic text-grey-alt" }, [
                _vm._v("current IP not authorized")
              ])
            ])
          ]),
      _vm._v(" "),
      _c("td", [_vm._v(_vm._s(_vm.item.server_ssh))]),
      _vm._v(" "),
      _vm.item.operator_ip
        ? _c("td", [_vm._v(_vm._s(_vm.item.operator_ip))])
        : _c("td", [
            _c("span", { staticClass: "text-lg italic text-grey-alt" }, [
              _vm._v("none")
            ])
          ]),
      _vm._v(" "),
      _c("td", { staticClass: "flex" }, [
        _c(
          "button",
          {
            staticClass:
              "button inline-block tr-all rounded font-bold leading-tighter text-center cursor-pointer border-3 border-red hover:border-red-hover text-white bg-red hover:bg-red-hover p-2 mr-2",
            on: {
              click: function($event) {
                return _vm.openModal(_vm.index)
              }
            }
          },
          [_vm._v("\n      Change IP\n    ")]
        ),
        _vm._v(" "),
        _c(
          "form",
          { attrs: { method: "post" } },
          [
            _c("input", {
              attrs: { type: "hidden", name: _vm.$root.csrfTokenName },
              domProps: { value: _vm.$root.csrfTokenValue }
            }),
            _vm._v(" "),
            _c("input", {
              attrs: {
                type: "hidden",
                name: "action",
                value: "wildlife/iot/access"
              }
            }),
            _vm._v(" "),
            _c("input", {
              attrs: { type: "hidden", name: "sn" },
              domProps: { value: _vm.item.thing_sn }
            }),
            _vm._v(" "),
            _c("input", {
              attrs: { type: "hidden", name: "device_name" },
              domProps: { value: _vm.item.thing_name }
            }),
            _vm._v(" "),
            _c("input", {
              attrs: { type: "hidden", name: "ip" },
              domProps: { value: _vm.userIp }
            }),
            _vm._v(" "),
            _vm.item.operator_ip
              ? _c("base-button", {
                  attrs: {
                    type: "submit",
                    name: "revoke",
                    value: "1",
                    label: "Revoke",
                    size: "wide",
                    color: "red",
                    textsize: "text-base",
                    class_names: "mx-1"
                  }
                })
              : _c(
                  "button",
                  {
                    staticClass:
                      "button uppercase inline-block tr-all rounded font-bold leading-tighter text-center cursor-pointer text-base border-3 border-grey text-white bg-grey px-4 py-2 xl:px-5 xl:py-3 mx-1",
                    attrs: {
                      disabled: "",
                      type: "submit",
                      value: "1",
                      name: "revoke"
                    }
                  },
                  [_vm._v("\n        Revoke\n      ")]
                )
          ],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }