var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "base-cart container mx-auto px-5 py-8 lg:py-10 xl:py-12" },
    [
      _vm.$root.cart === null
        ? _c("div", [
            _c("div", { staticClass: "mx-auto text-center my-10" }, [
              _c(
                "div",
                [
                  _c(
                    "base-icon",
                    {
                      staticClass: "block mx-auto text-grey",
                      attrs: {
                        "icon-name": "loading",
                        width: "100",
                        height: "100",
                        viewbox: "0 0 32 32"
                      }
                    },
                    [_c("icon-loading")],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "uppercase text-xl text-grey mt-8" }, [
                _vm._v("\n        Loading Cart\n      ")
              ])
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.$root.cart !== null
        ? _c("div", [
            _vm.$root.totalCartQuantity === 0
              ? _c("div", [
                  _c(
                    "div",
                    {
                      staticClass:
                        "w-full rounded bg-grey-lighter text-center py-20"
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "text-3xl uppercase text-grey-dark" },
                        [_vm._v("\n          Your cart is empty\n        ")]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "mt-12" },
                        [
                          _c("base-button", {
                            attrs: {
                              url: "/shop",
                              label: "Return to Products",
                              size: "smaller",
                              color: "red"
                            }
                          })
                        ],
                        1
                      )
                    ]
                  )
                ])
              : _c(
                  "div",
                  { staticClass: "cart" },
                  [
                    _c("cart-buttons", {
                      attrs: {
                        class_names: "text-right",
                        is_disabled: _vm.isLoading
                      },
                      on: { proceed: _vm.proceedToCheckout }
                    }),
                    _vm._v(" "),
                    _vm._m(0),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "border-b border-brown mt-3 md:mt-0" },
                      _vm._l(_vm.$root.cart.items, function(item, code) {
                        return _c("cart-line-item", {
                          key: code,
                          attrs: {
                            item: item,
                            code: code,
                            is_disabled: _vm.isLoading
                          }
                        })
                      }),
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "mt-8" },
                      [
                        _c(
                          "expandable-categories",
                          {
                            attrs: {
                              page: "cart",
                              show_add_to_cart_buttons: true,
                              is_disabled: _vm.isLoading
                            }
                          },
                          [
                            _c(
                              "template",
                              { slot: "bulk_prices_text" },
                              [_vm._t("bulk_prices_text")],
                              2
                            )
                          ],
                          2
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "mt-10 md:flex" }, [
                      _c("div", { staticClass: "md:w-1/3 lg:w-1/2 xl:w-p60" }),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "md:w-2/3 lg:w-1/2 xl:w-p40" },
                        [_c("cart-totals")],
                        1
                      )
                    ]),
                    _vm._v(" "),
                    _c("cart-buttons", {
                      attrs: {
                        class_names: "text-right mt-10",
                        is_disabled: _vm.isLoading
                      },
                      on: { proceed: _vm.proceedToCheckout }
                    })
                  ],
                  1
                )
          ])
        : _vm._e()
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "cart-top-row mt-6 flex font-bold text-green text-base md:text-lg lg:text-xl uppercase pb-3 lg:pt-3 lg:pb-5"
      },
      [
        _c("div", { staticClass: "col-1" }, [_vm._v("Item")]),
        _vm._v(" "),
        _c("div", { staticClass: "col-2 text-right" }, [_vm._v("Price")]),
        _vm._v(" "),
        _c("div", { staticClass: "col-3 text-center" }, [
          _c("span", { staticClass: "md:hidden" }, [_vm._v("Qty")]),
          _vm._v(" "),
          _c("span", { staticClass: "hidden md:inline" }, [_vm._v("Quantity")])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-4 text-right" }, [_vm._v("Total")])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }