<template>
  <button
    @click="onclick"
    :class="[
      'side-filter-btn block w-full cursor-pointer relative text-left py-2 border-t border-brown pr-6 text-xl xl:text-2xl font-bold uppercase',
      has_submenu ? 'has-sub pl-12' : 'pl-6',
      active ? 'active' : ''
    ]"
  >
    {{ label }}
  </button>
</template>

<script>
export default {
  props: ["label", "has_submenu", "active"],
  methods: {
    onclick() {
      this.$emit("onclick");
    }
  }
};
</script>
