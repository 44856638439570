var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: ["customer-story-item", _vm.class_names] },
    [
      _c(
        "div",
        { staticClass: "text-center" },
        [
          _c("thumbnail-image-with-overlay", {
            attrs: {
              src: _vm.item.src
                ? _vm.item.src
                : "/img/customer-story-thumbnail-placeholder.png",
              retina_src: _vm.item.retinaSrc
                ? _vm.item.retinaSrc
                : "/img/customer-story-thumbnail-placeholder@2x.png",
              alt: _vm.item.alt,
              url: _vm.item.url,
              text: "Read Customer Story",
              class_names: [
                "mb-3 mx-auto text-center",
                !_vm.item.src ? "shadow" : ""
              ]
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "base-heading",
        { attrs: { size: "h4", color: "brown-lighter", uppercase: true } },
        [_vm._v(_vm._s(_vm.item.location))]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "italic mt-1 text-sm text-brown" }, [
        _vm._v(_vm._s(_vm.item.date))
      ]),
      _vm._v(" "),
      _c(
        "base-heading",
        {
          attrs: {
            size: "h2",
            color: "brown",
            hover_color: "red",
            class_names: "mt-1",
            url: _vm.item.url
          }
        },
        [_vm._v(_vm._s(_vm.item.title))]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }