<template>
  <section class="callout-handwritten block w-full py-12 sm:py-16 lg:py-20" :style="innerStyles">
    <div :class="include_container ? 'container mx-auto px-5-safe' : 'full-container'">
      <div class="xl:max-w-2xl mx-auto bg-white border-2 border-brown p-8 md:p-12 lg:p-16">
        <div class="sm:flex">
          <div class="sm:w-1/4 sm:pr-6 md:pr-8">
            <base-image
              v-if="data.icon"
              :src="data.icon.src"
              :retina_src="data.icon.retina"
              :srcset_webp="data.icon.webpSrcset"
              class_names="mx-auto"
            ></base-image>
          </div>
          <div class="mt-6 text-center sm:text-left sm:mt-0 sm:w-3/4 text-4xl md:text-5xl lg:text-6xl font-cursive leading-loose">
            {{ data.text }}
          </div>
        </div>
        <div v-if="data.button" class="mt-12 text-center">
          <base-button
            :url="data.button.url"
            :label="data.button.text"
            color="red"
            size="extrawide"
            :target="data.button.target"
          ></base-button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import HelpersMixin from "../mixins/Helpers";

// @group Blocks
export default {
  props: ["data", "include_container"],
  mixins: [HelpersMixin],
  components: {
    BaseButton: () => import(/* webpackChunkName: "base-button" */ "../components/BaseButton.vue"),
    BaseHeading: () => import(/* webpackChunkName: "base-heading" */ "../components/BaseHeading.vue"),
    BaseImage: () => import(/* webpackChunkName: "base-image" */ "../components/BaseImage.vue")
  },
  computed: {
    innerStyles() {
      return {
        ...this.backgroundPosition(this.data.background.focalPoint),
        "background-image": `url(${this.data.background.src})`
      };
    }
  }
};
</script>
