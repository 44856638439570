<template>
  <div
    :class="[
      'product-three-columns flex justify-between',
      !block.isFirst ? (block.previousBlock === 'heading' ? 'mt-3' : 'mt-6') : ''
    ]"
  >
    <div class="w-p30" v-for="(column, c) in columns" :key="c">
      <base-rich-text size="smaller" v-html="column"></base-rich-text>
    </div>
  </div>
</template>

<script>
export default {
  data: function() {
    return {
      columns: {
        1: this.block.column1,
        2: this.block.column2,
        3: this.block.column3
      }
    };
  },
  props: ["block"],
  components: {
    BaseRichText: () => import(/* webpackChunkName: "base-rich-text" */ "../../components/BaseRichText.vue")
  }
};
</script>
