<template>
  <div :class="['product-table flex flex-col', !block.isFirst ? 'mt-6' : '']">
    <div v-if="block.searchEnabled" class="pb-4">
      <label>Search</label>
      <input type="text" name="search_term" :placeholder="block.searchBarPlaceholderText" v-model="searchTerm" class="input-text" />
    </div>
    <table class="w-full text-lg lg:text-xl xl:text-2xl leading-normal">
      <thead v-show="showTableHead">
        <tr>
          <td
            v-for="(column, i) in block.columns"
            :width="column.width"
            :align="column.align"
            v-html="column.heading"
            class="italic"
            :key="i"
          ></td>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(row, j) in filteredRows" :key="j">
          <td
            v-for="(cell, k) in row"
            :key="k"
            :width="block.columns[k].width"
            :align="block.columns[k].align"
            :class="['py-2 border-b border-brown', block.firstColumnBold && k === 0 ? 'font-bold' : '']"
            v-html="cell"
          >
            {{ k }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      searchTerm: ""
    };
  },
  props: ["block"],
  computed: {
    showTableHead() {
      var show = false;
      this.block.columns.forEach(function(column) {
        if (column.heading !== "") {
          show = true;
        }
      });
      return show;
    },
    filteredRows() {
      if (!this.block.searchEnabled || !this.searchTerm || this.searchTerm.length === 0) {
        return this.block.rows;
      }

      return this.block.rows.filter(row => {
        const matchedColumn = row.filter(col => {
          const regex = new RegExp(this.searchTerm, "gi");
          const matched = `${col}`.match(regex);

          return matched && matched.length > 0;
        });

        return matchedColumn && matchedColumn.length > 0;
      });
    }
  }
};
</script>
