var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._l(_vm.items, function(item, i) {
      return _c(
        "div",
        {
          key: "download_" + _vm.$parent.selectedCategory + "_" + i,
          class: i > 0 ? "pt-4 border-t border-grey mt-4" : "",
          attrs: { id: "row-" + item.uid }
        },
        [
          _c("div", { staticClass: "software-row pr-14" }, [
            _c("div", { staticClass: "flex flex-wrap md:flex-no-wrap" }, [
              _c(
                "div",
                { staticClass: "c-1 flex-shrink-0 flex-grow-0" },
                [
                  _c(
                    "base-icon",
                    {
                      attrs: {
                        "icon-name": "checkmark",
                        width: "30",
                        height: "30",
                        viewbox: "0 0 31 31"
                      }
                    },
                    [_c("icon-download")],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "c-2 md:flex-grow pl-4 pr-8" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "font-bold text-2xl hover:underline cursor-pointer",
                    on: {
                      click: function($event) {
                        return _vm.click(item)
                      }
                    }
                  },
                  [
                    _vm._v(
                      "\n                        " +
                        _vm._s(item.title) +
                        "\n                    "
                    )
                  ]
                ),
                _vm._v(" "),
                item.availableFor
                  ? _c(
                      "div",
                      { staticClass: "text-base mt-1 text-grey-dark" },
                      [
                        _vm._v(
                          "\n                        Available for " +
                            _vm._s(item.availableFor) +
                            "\n                    "
                        )
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                item.summary
                  ? _c("div", {
                      staticClass: "block text-lg mt-1 leading-normal",
                      domProps: { innerHTML: _vm._s(item.summary) }
                    })
                  : _vm._e()
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "c-3 md:flex-shrink-0 md:flex-grow-0" },
                [
                  _vm.$parent.showPlatforms.indexOf(item.uid) === -1
                    ? _c(
                        "button",
                        {
                          staticClass:
                            "button block md:w-full uppercase tr-all rounded text-lg xl:text-xl font-bold leading-tighter text-center cursor-pointer text-white bg-red hover:bg-red-hover px-4 py-2 xl:px-5 xl:py-3",
                          on: {
                            click: function($event) {
                              return _vm.click(item)
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(item.pickPlatform ? "Pick OS" : "View") +
                              "\n                    "
                          )
                        ]
                      )
                    : _vm._e()
                ]
              )
            ])
          ]),
          _vm._v(" "),
          item.pickPlatform
            ? _c(
                "div",
                { staticClass: "pick-platform" },
                [
                  _c(
                    "slide-up-down",
                    {
                      attrs: {
                        active:
                          _vm.$parent.showPlatforms.indexOf(item.uid) >= 0,
                        duration: 350
                      }
                    },
                    [
                      _c("platforms-list", {
                        attrs: { items: item.downloads, uid: item.uid },
                        on: {
                          click: _vm.viewDownload,
                          close: _vm.hidePlatforms
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }