var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "site-search" }, [
    _c("div", { staticClass: "bg-brown" }, [
      _c(
        "div",
        {
          staticClass:
            "container mx-auto px-5-safe py-6 lg:py-8 xl:py-10 text-white"
        },
        [
          _c(
            "h1",
            {
              staticClass:
                "font-bold text-4xl lg:text-5xl xl:text-6xl uppercase"
            },
            [_vm._v(_vm._s(_vm.pageTitle))]
          )
        ]
      )
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "container px-5-safe mx-auto py-10",
        attrs: { id: "search-top" }
      },
      [
        _c("search-bar", {
          attrs: { keyword: _vm.keyword, placeholder: "Search our site" },
          on: { updateResults: _vm.setKeyword }
        }),
        _vm._v(" "),
        _vm.ajaxError
          ? _c("div", {
              staticClass: "mt-10 text-red text-lg",
              domProps: { innerHTML: _vm._s(_vm.ajaxError) }
            })
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          { class: [_vm.isLoading ? "opacity-25" : ""] },
          [
            _c("results-info", {
              attrs: {
                total: _vm.total,
                current_page: _vm.currentPage,
                limit: _vm.limit,
                keyword: _vm.keyword,
                class_names: "mt-10"
              }
            }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "mt-8 text-lg text-links" },
              _vm._l(_vm.items, function(item, i) {
                return _c("search-item", {
                  key: i,
                  attrs: {
                    item: item,
                    class_names: [
                      i > 0 ? "border-t border-brown mt-5 pt-5" : ""
                    ]
                  }
                })
              }),
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "mt-6" },
              [
                _c("base-pagination", {
                  attrs: {
                    max_visible_buttons:
                      _vm.numPages >= 3 ? 3 : parseInt(_vm.numPages),
                    per_page: parseInt(_vm.limit),
                    num_pages: parseInt(_vm.numPages),
                    current_page: _vm.currentPage,
                    total: parseInt(_vm.total),
                    show_prev_next_btns: true,
                    pagination_disabled: _vm.isLoading
                  },
                  on: { pagechanged: _vm.onPageChange }
                })
              ],
              1
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }