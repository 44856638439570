var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("aside", { staticClass: "faqs-filter side-filter text-brown" }, [
    _c(
      "div",
      { staticClass: "h-12" },
      [
        _c("base-heading", { attrs: { size: "h2", color: "brown" } }, [
          _vm._v("Filter by:")
        ])
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "ul",
      { staticClass: "border-b border-brown" },
      _vm._l(_vm.$parent.categories, function(category, c) {
        return _c(
          "li",
          { key: c },
          [
            _c("sidebar-filter-button", {
              attrs: {
                label: category.name,
                has_submenu: true,
                active: category.active
              },
              on: {
                onclick: function($event) {
                  category.active = !category.active
                }
              }
            }),
            _vm._v(" "),
            _c(
              "slide-up-down",
              { attrs: { active: category.active, duration: 350 } },
              [
                _c(
                  "ul",
                  {
                    staticClass:
                      "sub border-t border-brown py-1 pl-4 pr-4 xl:pl-12 xl:pr-10 text-lg xl:text-xl"
                  },
                  _vm._l(category.children, function(subCategory, s) {
                    return _c(
                      "li",
                      { key: s, staticClass: "pt-1 pb-2" },
                      [
                        _c("form-checkbox", {
                          attrs: {
                            name: "subCategory.slug",
                            checked: subCategory.active,
                            label: subCategory.name,
                            class_names: "text-lg xl:text-xl"
                          },
                          model: {
                            value: subCategory.active,
                            callback: function($$v) {
                              _vm.$set(subCategory, "active", $$v)
                            },
                            expression: "subCategory.active"
                          }
                        })
                      ],
                      1
                    )
                  }),
                  0
                )
              ]
            )
          ],
          1
        )
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }