var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    {
      class: [
        "grant-program-rich-text",
        !_vm.block.isFirst
          ? _vm.block.previousBlock === "heading" ||
            _vm.block.previousBlock === "alignedImage"
            ? "mt-3"
            : "mt-10"
          : ""
      ]
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.block.text !== "",
              expression: "block.text !== ''"
            }
          ],
          staticClass: "text-brown"
        },
        [
          _c("base-rich-text", {
            attrs: { size: _vm.block.textSize === "footnote" ? "tiny" : "" },
            domProps: { innerHTML: _vm._s(_vm.block.text) }
          })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }