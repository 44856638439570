import { render, staticRenderFns } from "./GlossaryTerm.vue?vue&type=template&id=1915c1c9&"
import script from "./GlossaryTerm.vue?vue&type=script&lang=js&"
export * from "./GlossaryTerm.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/ian/wildlife-website.master/craftcms/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('1915c1c9')) {
      api.createRecord('1915c1c9', component.options)
    } else {
      api.reload('1915c1c9', component.options)
    }
    module.hot.accept("./GlossaryTerm.vue?vue&type=template&id=1915c1c9&", function () {
      api.rerender('1915c1c9', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/vue/glossary/GlossaryTerm.vue"
export default component.exports