<template>
  <div class="event-row">
    <div :class="['md:flex', !isFirst ? 'pt-7 mt-7 border-t border-brown' : '']">
      <div v-if="item.thumbnailSrc" class="col-1 mb-6 md:mb-0">
        <base-image
          :src="item.thumbnailSrc"
          :retina_src="item.thumbnailSrcRetina"
          :alt="item.thumbnailAlt"
          class_names="block m-0"
          :url="item.moreInfoUrl"
        ></base-image>
      </div>
      <div :class="['lg:flex', item.thumbnailSrc ? 'col-2' : 'w-full']">
        <div class="col-2-1 pl-8 lg:pr-8">
          <base-heading size="h4" color="brown-lighter" :uppercase="true">{{ item.date }}</base-heading>
          <base-heading size="h2" color="brown" hover_color="red" class_names="mt-1" :url="item.moreInfoUrl">{{
            item.title
          }}</base-heading>
          <div v-if="item.subheading" class="text-lg italic lg:text-xl mt-2" v-html="item.subheading"></div>
          <div v-if="item.location !== ''" class="text-xl lg:text-2xl mt-2" v-html="item.location"></div>
          <div v-if="item.signUpLink" class="text-base lg:text-lg text-red mt-3">
            {{ item.signUpText ? item.signUpText : "Sign up" }}
            {{ item.signUpDate }}
          </div>
        </div>
        <div class="col-2-2 pl-8 mt-4 lg:pl-0 lg:mt-0">
          <base-button
            :url="item.signUpLink"
            label="Sign Up"
            color="red"
            size="smaller"
            class_names="mr-3 lg:mr-0"
            style="width: 170px;"
            v-if="item.signUpLink"
          ></base-button>
          <base-button
            :url="item.moreInfoUrl"
            label="More Info"
            color="brown"
            size="smaller"
            style="width: 170px;"
            :class_names="item.signUpLink ? 'lg:mt-3' : ''"
            v-if="item.moreInfoUrl"
          ></base-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["item", "isFirst"],
  components: {
    BaseButton: () => import(/* webpackChunkName: "base-button" */ "../components/BaseButton.vue"),
    BaseHeading: () => import(/* webpackChunkName: "base-heading" */ "../components/BaseHeading.vue"),
    BaseImage: () => import(/* webpackChunkName: "base-image" */ "../components/BaseImage.vue")
  }
};
</script>

<style scoped>
@media (min-width: 768px) {
  .col-1 {
    width: 33%;
  }

  .col-2 {
    width: 67%;
  }
}
@media (min-width: 992px) {
  .col-2-1 {
    width: calc(100% - 170px);
  }

  .col-2-2 {
    width: 170px;
  }
}
</style>
