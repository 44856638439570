<template>
  <div :class="['form-radio-group', inline ? 'inline-options' : 'block', disabled ? 'disabled' : '']">
    <div :class="['radio-field', inline ? 'block lg:inline-block' : 'block mb-3']" v-for="(option, v) in options">
      <label :class="['styled-radio', disabled ? 'disabled' : '']"
        >{{ option }}
        <input type="radio" :name="name" :value="v" v-model="localValue" @change="change" />
        <span class="checkmark"><span class="bg-brown"></span></span>
      </label>
    </div>
  </div>
</template>

<script>
// @group Form

export default {
  data: function() {
    return {
      localValue: this.value
    };
  },
  props: ["name", "options", "inline", "value", "disabled"],
  methods: {
    change() {
      this.$emit("input", this.localValue);
    }
  }
};
</script>

<style scoped>
.form-radio-group {
  &.inline-options > div:not(:first-child) {
    margin-top: 0.75rem;
    @media (min-width: 992px) {
      margin-left: 2rem;
      margin-top: 0;
    }
  }
  label {
    display: block;
    position: relative;
    padding-left: 36px;
    height: auto;
    font-size: 18px;
    min-height: 26px;
    vertical-align: top;
    &:not(:last-child) {
      margin-bottom: 10px;
    }
    cursor: pointer;
    line-height: 26px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
    }
    .checkmark {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 26px;
      height: 26px;
      border-radius: 50%;
      background-color: #fff;
      border: 2px solid #000;
      transition: background-color 0.2s;
      span {
        display: block;
        width: 16px;
        height: 16px;
        transition: opacity 0.2s;
        border-radius: 50%;
        position: absolute;
        left: 50%;
        top: 50%;
        margin-left: -8px;
        margin-top: -8px;
        opacity: 0;
      }
    }
    input:checked ~ .checkmark span {
      opacity: 1;
    }

    &:not(.disabled) {
      &:hover input ~ .checkmark {
        background-color: #a1aec0;
      }
    }
    &.disabled {
      .checkmark {
        border-color: #b0b0b0;
        .bg-brown {
          background-color: #b0b0b0 !important;
        }
      }
    }
  }
}
</style>
