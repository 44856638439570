var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "add-to-cart-essential-products" }, [
    _c(
      "div",
      { staticClass: "products text-base leading-normal" },
      _vm._l(_vm.groupedEssentialProducts, function(group, g) {
        return _c(
          "div",
          { key: g },
          _vm._l(group, function(item, i) {
            return _c(
              "div",
              { key: i, staticClass: "py-2 px-5 flex border-b border-brown" },
              [
                _c("div", { staticClass: "c1" }, [
                  _c("div", [_vm._v(_vm._s(item.title))]),
                  _vm._v(" "),
                  _c("div", { staticClass: "text-xs mt-1" }, [
                    _vm._v("Product Code: " + _vm._s(item.code))
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "c2 text-right" }, [
                  _c("div", [
                    _c("div", { staticClass: "font-bold" }, [
                      _vm._v(_vm._s(_vm.$root.formattedPrice(item.price)))
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "mt-1 text-xs italic" }, [
                      _vm._v(_vm._s(_vm.$root.formattedCurrency()))
                    ]),
                    _vm._v(" "),
                    _c("div", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: item.stock !== "",
                          expression: "item.stock !== ''"
                        }
                      ],
                      staticClass: "text-xs uppercase mt-1 product-stock",
                      domProps: { innerHTML: _vm._s(item.stock) }
                    })
                  ])
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "c3 text-right" },
                  [
                    _c("input-product-quantity", {
                      attrs: {
                        value: _vm.groupedEssentialProducts[g][i].qty,
                        border_color: "brown",
                        bg_color: "grey-light",
                        is_disabled: _vm.isLoading,
                        class_names: "float-right ml-4 sm:float-none sm:mx-auto"
                      },
                      model: {
                        value: _vm.groupedEssentialProducts[g][i].qty,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.groupedEssentialProducts[g][i],
                            "qty",
                            $$v
                          )
                        },
                        expression: "groupedEssentialProducts[g][i].qty"
                      }
                    })
                  ],
                  1
                )
              ]
            )
          }),
          0
        )
      }),
      0
    ),
    _vm._v(" "),
    _c("div", { staticClass: "px-5 py-6" }, [
      _vm.ajaxError
        ? _c("div", {
            staticClass: "mb-6 text-red text-lg",
            domProps: { innerHTML: _vm._s(_vm.ajaxError) }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "flex justify-between" }, [
        _c(
          "div",
          { staticClass: "w-p48" },
          [
            _c("base-button", {
              attrs: {
                url: "/shop/cart",
                label: "View Cart",
                color: "brown",
                outline: "true",
                size: "smaller",
                class_names: "w-full"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "w-p48" }, [
          _c(
            "button",
            {
              class: [
                "button relative inline-block uppercase tr-all rounded text-xl font-bold leading-tight text-center border-3 cursor-pointer w-full py-2 px-5",
                _vm.isLoading || _vm.totalQuantity < 1
                  ? "text-grey bg-white"
                  : "bg-brown border-brown text-white hover:bg-brown-hover hover:border-brown-hover",
                _vm.isLoading ? "pl-8" : ""
              ],
              attrs: {
                type: "submit",
                disabled: _vm.isLoading || _vm.totalQuantity < 1
              },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.addToCart()
                }
              }
            },
            [
              _c(
                "base-icon",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isLoading,
                      expression: "isLoading"
                    }
                  ],
                  staticClass: "loading absolute ml-3 left-0 centered-y",
                  attrs: {
                    "icon-name": "loading",
                    width: "28",
                    height: "28",
                    viewbox: "0 0 32 32"
                  }
                },
                [_c("icon-loading")],
                1
              ),
              _vm._v(" "),
              _c("span", { staticClass: "inline" }, [_vm._v("Add to Order")])
            ],
            1
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }