<template>
  <section class="block images-grid heading-block white-block">
    <div :class="[
            include_container ? 'container mx-auto px-5-safe' : 'full-container',
            'pt-8 pb-4 lg:pt-10 lg:pb-4 xl:pt-12 xl:pb-6'
         ]">
      <div class="flex justify-between flex-wrap">
        <div
          v-for="(item, i) in images"
          v-if="item.src"
          :key="'image_' + i"
          :class="['image mb-4 lg:mb-6', parseInt(num_columns) === 2 ? 'w-p48' : 'sm:w-p48 md:w-p31']"
        >
          <base-image
            :src="item.src"
            :retina_src="item.retina"
            :srcset_webp="item.webpSrcset"
            class_names="mx-auto"
            :alt="item.alt"
          ></base-image>
          <div v-if="item.caption" class="text-sm lg:text-base leading-normal mt-4" v-html="item.caption"></div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
// @group Blocks
export default {
  props: {
    num_columns: {
      type: String,
      required: true
    },
    images: {
      type: Array,
      required: true
    },
    include_container: Boolean
  },
  components: {
    BaseImage: () => import(/* webpackChunkName: "base-image" */ "../components/BaseImage.vue")
  }
};
</script>

<style scoped>
@media (max-width: 575px) {
  .image {
    max-width: 390px;
    margin-left: auto;
    margin-right: auto;
  }
}
</style>
