<template>
  <div :class="['product-specifications text-2xl text-brown', !block.isFirst ? 'mt-6' : '']">
    <div v-for="(row, r) in block.rows" :class="['lg:flex', r > 0 ? 'border-t border-brown' : '']">
      <div class="lg:w-p40 font-bold pt-4 pb-3 lg:py-4">
        {{ row.heading }}
      </div>
      <div class="lg:w-p60 pb-4 lg:py-4">
        <div v-if="row.type === 'text'" v-html="row.text" class="rich-text leading-normal text-2xl"></div>
        <div v-if="row.type === 'columns'" class="xl:flex lg:justify-between">
          <div
            v-for="(column, c) in row.columns"
            :class="
              row.columns.length === 3 ? (c > 0 ? 'mt-3 xl:mt-0 ' : '') + 'xl:w-p31' : row.columns.length === 2 ? 'xl:w-p48' : ''
            "
            v-html="column"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["block"]
};
</script>
