<template>
  <footer class="site-footer print-hidden bg-cover" :style="footerStyles" v-lazy:background-image="background">
    <div class="container mx-auto px-5-safe pt-8 pb-8-safe lg:pt-10 lg:pb-10-safe xl:pt-14 xl:pb-14-safe text-white">
      <div class="md:flex md:justify-between">
        <div class="col-1">
          <a :href="logo_href" class="footer-logo">
            <base-image :src="logo_src"></base-image>
          </a>
          <div class="contact-details text-lg leading-loose mt-10 text-center md:text-left mb-8 md:mb-0">
            <slot name="contact-details"></slot>
          </div>
        </div>
        <div class="col-2">
          <site-footer-news-events></site-footer-news-events>
        </div>
        <div class="col-3 text-center md:text-left">
          <div class="flex flex-col-reverse md:flex-col">
            <div>
              <div class="text-left mx-auto max-w-xs md:max-w-full md:mx-0">
                <base-heading size="h3" :uppercase="true" color="white">
                  {{ footer_nav_heading }}
                </base-heading>
                <nav :class="['footer-nav text-lg leading-tight text-white', footer_nav_heading !== '' ? 'mt-6' : '']">
                  <ul>
                    <li class="my-3" v-for="(item, i) in nav_items" :key="i">
                      <a
                        :href="item.url"
                        :target="item.target"
                        class="inline-block relative tr-color hover:underline hover:text-brown-lightest pl-4"
                      >
                        <base-icon
                          icon-name="angle-right"
                          width="8"
                          height="14"
                          viewbox="0 0 9 17"
                          class="text-white absolute left-0 centered-y"
                        >
                          <icon-angle-right></icon-angle-right>
                        </base-icon>
                        {{ item.text }}</a
                      >
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
            <div class="mb-10 md:mb-0 md:mt-10">
              <social-icon
                v-for="(icon, i) in social_icons"
                :key="i"
                :url="icon.url"
                :type="icon.website_value"
                :target="icon.target"
                :class_names="['inline-block text-brown-lighter tr-color hover:text-brown-lightest', i > 0 ? 'ml-4' : '']"
                icon_size="40"
              ></social-icon>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import HelpersMixin from "../../mixins/Helpers";

export default {
  props: [
    "background",
    "background_focal_point",
    "logo_href",
    "logo_src",
    "footer_nav_heading",
    "middle_column_type",
    "middle_column_heading",
    "middle_column_items",
    "nav_items",
    "social_icons"
  ],
  mixins: [HelpersMixin],
  components: {
    BaseHeading: () => import(/* webpackChunkName: "base-heading" */ "../BaseHeading.vue"),
    BaseImage: () => import(/* webpackChunkName: "base-image" */ "../BaseImage.vue"),
    SocialIcon: () => import(/* webpackChunkName: "social-icon" */ "../SocialIcon.vue"),
    SiteFooterNewsEvents: () => import(/* webpackChunkName: "site-footer-news-events" */ "./SiteFooterNewsEvents.vue"),
    BaseIcon: () => import(/* webpackChunkName: "base-icon" */ "../BaseIcon.vue"),
    IconAngleRight: () => import(/* webpackChunkName: "icon-angle-right" */ "../../icons/IconAngleRight.vue")
  },
  computed: {
    footerStyles() {
      return this.backgroundPosition(this.background_focal_point);
    }
  }
};
</script>
<style>
.site-footer {
  .footer-logo {
    img {
      height: 64px;
      @apply mx-0 !important;
    }
  }
  .contact-details {
    a {
      transition: color 0.15s;
      &:hover {
        color: #ecd2ab;
        text-decoration: underline;
      }
    }
  }
  @media (min-width: 768px) {
    .footer-logo {
      width: 100%;
      /* height: auto; */
    }
    .col-1 {
      width: 60%;
    }
    .col-3 {
      width: 35%;
    }
  }

  @media only screen and (max-width: 768px) {
    .footer-logo {
      div {
        padding-left: 0;
      }
      img {
        @apply mx-auto !important;
      }
    }
  }

  @media (max-width: 991px) {
    .col-2 {
      display: none;
    }
  }
  @media (min-width: 992px) {
    .col-1 {
      width: 35.25%;
    }
    .col-2 {
      width: 27%;
    }
    .col-3 {
      width: 27.75%;
    }
  }
  @media (min-width: 1280px) {
    .footer-logo {
      div {
        padding-left: 40%;
      }
    }
    .contact-details {
      padding-left: 40%;
    }
  }
}
</style>
