var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "header",
      { class: ["site-header print-hidden", _vm.header_style + "-style"] },
      [
        _c(
          "div",
          {
            class: [
              "site-header__top hidden  lg:block",
              _vm.header_style === "light" ? "bg-white" : "bg-brown",
              _vm.text_color
            ]
          },
          [_c("utility-nav")],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "site-header__main" }, [
          _c(
            "div",
            {
              class: [
                "relative pt-5-safe pb-5",
                _vm.header_style === "dark" ? "lg:pt-5-safe" : "lg:pt-1-safe"
              ]
            },
            [
              _c("div", { staticClass: "bg-white" }, [
                _c("div", { staticClass: "relative z-40" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "relative container mx-auto px-5-safe flex justify-between items-center lg:items-end"
                    },
                    [
                      _c("div", [
                        _c("a", { attrs: { href: _vm.logo_url } }, [
                          _c("img", {
                            staticClass: "site-logo m-0 block h-12 lg:h-14",
                            attrs: {
                              src:
                                _vm.header_style === "light"
                                  ? _vm.logo_dark_src
                                  : _vm.logo_light_src,
                              alt: _vm.logo_alt,
                              width: _vm.logo_width
                            }
                          })
                        ])
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "-mr-2 -my-2 lg:hidden" }, [
                        _c(
                          "button",
                          {
                            staticClass:
                              "bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100",
                            attrs: { type: "button" },
                            on: {
                              click: function($event) {
                                $event.stopPropagation()
                                $event.preventDefault()
                                return _vm.toggleMobileMenu()
                              }
                            }
                          },
                          [
                            _c("span", { staticClass: "sr-only" }, [
                              _vm._v("Open menu")
                            ]),
                            _vm._v(" "),
                            _c(
                              "svg",
                              {
                                staticClass: "h-12 w-12",
                                attrs: {
                                  xmlns: "http://www.w3.org/2000/svg",
                                  fill: "none",
                                  viewBox: "0 0 24 24",
                                  stroke: "currentColor",
                                  "aria-hidden": "true"
                                }
                              },
                              [
                                _c("path", {
                                  attrs: {
                                    "stroke-linecap": "round",
                                    "stroke-linejoin": "round",
                                    "stroke-width": "2",
                                    d: "M4 6h16M4 12h16M4 18h16"
                                  }
                                })
                              ]
                            )
                          ]
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "hidden lg:flex md:items-center md:justify-between"
                        },
                        [
                          _c(
                            "nav",
                            {
                              staticClass:
                                "flex font-bold text-xl xl:text-hxl text-brown"
                            },
                            _vm._l(_vm.main_nav, function(item_l1) {
                              return _c(
                                "div",
                                { staticClass: "ml-7" },
                                [
                                  item_l1.depth === 1
                                    ? [
                                        _c(
                                          "a",
                                          {
                                            staticClass: "top-link",
                                            attrs: {
                                              href: item_l1.url,
                                              target: item_l1.target
                                            }
                                          },
                                          [
                                            _c(
                                              "span",
                                              { staticClass: "text relative" },
                                              [_vm._v(_vm._s(item_l1.text))]
                                            )
                                          ]
                                        )
                                      ]
                                    : [
                                        _c(
                                          "button",
                                          {
                                            staticClass: "top-link font-bold",
                                            attrs: { type: "button" },
                                            on: {
                                              click: function($event) {
                                                $event.stopPropagation()
                                                $event.preventDefault()
                                                return _vm.toggleDropdown(
                                                  item_l1.text
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _c(
                                              "span",
                                              { staticClass: "text relative" },
                                              [
                                                _vm._v(
                                                  "\n                          " +
                                                    _vm._s(item_l1.text) +
                                                    "\n                        "
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "base-icon",
                                              {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value:
                                                      item_l1.children.length >
                                                      0,
                                                    expression:
                                                      "item_l1.children.length > 0"
                                                  }
                                                ],
                                                class: [
                                                  "angle inline",
                                                  item_l1.text ===
                                                  _vm.activeMenu
                                                    ? "rotated"
                                                    : ""
                                                ],
                                                attrs: {
                                                  "icon-name": "angle-down",
                                                  width: "14",
                                                  height: "7",
                                                  viewbox: "0 0 17 9"
                                                }
                                              },
                                              [_c("icon-angle-down")],
                                              1
                                            )
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            directives: [
                                              {
                                                name: "on-clickaway",
                                                rawName: "v-on-clickaway",
                                                value: _vm.closeDropdown,
                                                expression: "closeDropdown"
                                              }
                                            ],
                                            class: [
                                              "absolute hidden z-10 text-white font-regular font-base mt-4 items-center justify-center ",
                                              item_l1.depth !== 2 &&
                                              item_l1.depth
                                                ? "w-full transform-center left-center"
                                                : "",
                                              item_l1.ctas.left ||
                                              item_l1.ctas.right
                                                ? "inset-x-0"
                                                : "",
                                              item_l1.text === _vm.activeMenu
                                                ? "md:flex"
                                                : ""
                                            ]
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "bg-red flex flex-col w-full px-6 pt-10"
                                              },
                                              [
                                                item_l1.ctas.left
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "w-p24 flex-none px-5-safe py-10 flex justify-end"
                                                      },
                                                      [
                                                        _c("header-cta", {
                                                          attrs: {
                                                            cta:
                                                              item_l1.ctas.left
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                item_l1.depth <= 3
                                                  ? [
                                                      _c(
                                                        "a",
                                                        {
                                                          staticClass:
                                                            "font-bold text-3xl hover:text-brown",
                                                          attrs: {
                                                            href: item_l1.url,
                                                            target:
                                                              item_l1.target
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                            " +
                                                              _vm._s(
                                                                item_l1.text
                                                              ) +
                                                              "\n\n                            "
                                                          ),
                                                          _c(
                                                            "base-icon",
                                                            {
                                                              staticClass:
                                                                "angle inline",
                                                              attrs: {
                                                                "icon-name":
                                                                  "angle-right",
                                                                width: "10",
                                                                height: "12",
                                                                viewbox:
                                                                  "0 0 9 17"
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "icon-angle-right"
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ]
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    class: [
                                                      "flex",
                                                      item_l1.depth >= 4
                                                        ? "flex-col pb-8"
                                                        : ""
                                                    ]
                                                  },
                                                  [
                                                    item_l1.depth >= 4
                                                      ? _vm._l(
                                                          item_l1.children,
                                                          function(item_l2) {
                                                            return _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "flex flex-wrap"
                                                              },
                                                              [
                                                                _c(
                                                                  "a",
                                                                  {
                                                                    staticClass:
                                                                      "w-full px-5-safe font-bold text-3xl hover:text-brown",
                                                                    class:
                                                                      item_l2.text ==
                                                                      "Accessories"
                                                                        ? "pt-2 pb-4"
                                                                        : "",
                                                                    attrs: {
                                                                      href:
                                                                        item_l2.url,
                                                                      target:
                                                                        item_l2.target
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n                                  " +
                                                                        _vm._s(
                                                                          item_l2.text
                                                                        ) +
                                                                        "\n\n                                  "
                                                                    ),
                                                                    _c(
                                                                      "base-icon",
                                                                      {
                                                                        staticClass:
                                                                          "angle inline",
                                                                        attrs: {
                                                                          "icon-name":
                                                                            "angle-right",
                                                                          width:
                                                                            "10",
                                                                          height:
                                                                            "12",
                                                                          viewbox:
                                                                            "0 0 9 17"
                                                                        }
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "icon-angle-right"
                                                                        )
                                                                      ],
                                                                      1
                                                                    )
                                                                  ],
                                                                  1
                                                                ),
                                                                _vm._v(" "),
                                                                _vm._l(
                                                                  item_l2.children,
                                                                  function(
                                                                    item_l3
                                                                  ) {
                                                                    return _c(
                                                                      "ul",
                                                                      {
                                                                        staticClass:
                                                                          "menu-list flex-grow px-5-safe py-4",
                                                                        class:
                                                                          item_l3.text ==
                                                                            "Reconyx Professional Cameras" ||
                                                                          item_l3.text ==
                                                                            "Reconyx Economy Cameras"
                                                                            ? "reconyx-level"
                                                                            : ""
                                                                      },
                                                                      [
                                                                        [
                                                                          _c(
                                                                            "li",
                                                                            {
                                                                              staticClass:
                                                                                "font-bold py-2"
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "\n                                        " +
                                                                                  _vm._s(
                                                                                    item_l3.text
                                                                                  ) +
                                                                                  "\n                                      "
                                                                              )
                                                                            ]
                                                                          ),
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _c(
                                                                            "div",
                                                                            [
                                                                              _vm._l(
                                                                                item_l3.children,
                                                                                function(
                                                                                  item_l4
                                                                                ) {
                                                                                  return [
                                                                                    _c(
                                                                                      "li",
                                                                                      {
                                                                                        staticClass:
                                                                                          "hover:bg-red-hover recon-l4"
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "a",
                                                                                          {
                                                                                            staticClass:
                                                                                              "flex w-full py-2",
                                                                                            attrs: {
                                                                                              href:
                                                                                                item_l4.url,
                                                                                              target:
                                                                                                item_l4.target
                                                                                            }
                                                                                          },
                                                                                          [
                                                                                            _vm._v(
                                                                                              "\n                                              " +
                                                                                                _vm._s(
                                                                                                  item_l4.text
                                                                                                ) +
                                                                                                "\n                                            "
                                                                                            )
                                                                                          ]
                                                                                        )
                                                                                      ]
                                                                                    ),
                                                                                    _vm._v(
                                                                                      " "
                                                                                    ),
                                                                                    _vm._l(
                                                                                      item_l4.children,
                                                                                      function(
                                                                                        item_l5
                                                                                      ) {
                                                                                        return [
                                                                                          _c(
                                                                                            "li",
                                                                                            {
                                                                                              staticClass:
                                                                                                "hover:bg-red-hover"
                                                                                            },
                                                                                            [
                                                                                              _c(
                                                                                                "a",
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "flex w-full pl-8 py-2",
                                                                                                  attrs: {
                                                                                                    href:
                                                                                                      item_l5.url,
                                                                                                    target:
                                                                                                      item_l5.target
                                                                                                  }
                                                                                                },
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    "\n                                                " +
                                                                                                      _vm._s(
                                                                                                        item_l5.text
                                                                                                      ) +
                                                                                                      "\n                                              "
                                                                                                  )
                                                                                                ]
                                                                                              )
                                                                                            ]
                                                                                          )
                                                                                        ]
                                                                                      }
                                                                                    )
                                                                                  ]
                                                                                }
                                                                              )
                                                                            ],
                                                                            2
                                                                          )
                                                                        ]
                                                                      ],
                                                                      2
                                                                    )
                                                                  }
                                                                )
                                                              ],
                                                              2
                                                            )
                                                          }
                                                        )
                                                      : _vm._e(),
                                                    _vm._v(" "),
                                                    item_l1.depth === 3
                                                      ? [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "flex flex-wrap"
                                                            },
                                                            _vm._l(
                                                              item_l1.children,
                                                              function(
                                                                item_l2
                                                              ) {
                                                                return _c(
                                                                  "ul",
                                                                  {
                                                                    staticClass:
                                                                      "menu-list px-5-safe py-4"
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "li",
                                                                      {
                                                                        staticClass:
                                                                          "font-bold py-2"
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "\n                                    " +
                                                                            _vm._s(
                                                                              item_l2.text
                                                                            ) +
                                                                            "\n                                  "
                                                                        )
                                                                      ]
                                                                    ),
                                                                    _vm._v(" "),
                                                                    _vm._l(
                                                                      item_l2.children,
                                                                      function(
                                                                        item_l3
                                                                      ) {
                                                                        return [
                                                                          _c(
                                                                            "li",
                                                                            {
                                                                              staticClass:
                                                                                "hover:bg-red-hover"
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "a",
                                                                                {
                                                                                  staticClass:
                                                                                    "flex w-full py-2",
                                                                                  attrs: {
                                                                                    href:
                                                                                      item_l3.url,
                                                                                    target:
                                                                                      item_l3.target
                                                                                  }
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "\n                                        " +
                                                                                      _vm._s(
                                                                                        item_l3.text
                                                                                      ) +
                                                                                      "\n                                      "
                                                                                  )
                                                                                ]
                                                                              )
                                                                            ]
                                                                          ),
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _vm._l(
                                                                            item_l3.children,
                                                                            function(
                                                                              item_l4
                                                                            ) {
                                                                              return _c(
                                                                                "li",
                                                                                {
                                                                                  staticClass:
                                                                                    "hover:bg-red-hover"
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "a",
                                                                                    {
                                                                                      staticClass:
                                                                                        "flex w-full pl-8 py-2",
                                                                                      attrs: {
                                                                                        href:
                                                                                          item_l4.url,
                                                                                        target:
                                                                                          item_l4.target
                                                                                      }
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        "\n                                        " +
                                                                                          _vm._s(
                                                                                            item_l4.text
                                                                                          ) +
                                                                                          "\n                                      "
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                ]
                                                                              )
                                                                            }
                                                                          )
                                                                        ]
                                                                      }
                                                                    )
                                                                  ],
                                                                  2
                                                                )
                                                              }
                                                            ),
                                                            0
                                                          )
                                                        ]
                                                      : _vm._e(),
                                                    _vm._v(" "),
                                                    item_l1.depth === 2
                                                      ? [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "flex w-full flex-wrap"
                                                            },
                                                            [
                                                              _c(
                                                                "ul",
                                                                {
                                                                  staticClass:
                                                                    "menu-list w-full py-4"
                                                                },
                                                                _vm._l(
                                                                  item_l1.children,
                                                                  function(
                                                                    item_l2
                                                                  ) {
                                                                    return _c(
                                                                      "li",
                                                                      {
                                                                        staticClass:
                                                                          "hover:bg-red-hover"
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "a",
                                                                          {
                                                                            staticClass:
                                                                              "flex w-full py-2",
                                                                            attrs: {
                                                                              href:
                                                                                item_l2.url,
                                                                              target:
                                                                                item_l2.target
                                                                            }
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                item_l2.text
                                                                              )
                                                                            )
                                                                          ]
                                                                        )
                                                                      ]
                                                                    )
                                                                  }
                                                                ),
                                                                0
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      : _vm._e()
                                                  ],
                                                  2
                                                ),
                                                _vm._v(" "),
                                                item_l1.ctas.right
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "hidden xl:block w-p24 flex-none px-10-safe py-10 bg-brown"
                                                      },
                                                      [
                                                        _c("header-cta", {
                                                          attrs: {
                                                            cta:
                                                              item_l1.ctas.right
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e()
                                              ],
                                              2
                                            )
                                          ]
                                        )
                                      ]
                                ],
                                2
                              )
                            }),
                            0
                          )
                        ]
                      )
                    ]
                  )
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "on-clickaway",
                        rawName: "v-on-clickaway",
                        value: _vm.closeMobileMenu,
                        expression: "closeMobileMenu"
                      }
                    ],
                    class: [
                      "absolute z-40 top-0 inset-x-0 p-2 transition transform origin-top-right lg:hidden",
                      _vm.mobileMenuActive ? "" : "hidden"
                    ]
                  },
                  [
                    _c("div", { staticClass: "shadow bg-white" }, [
                      _c("div", [
                        _c(
                          "div",
                          {
                            staticClass:
                              "flex items-center justify-end pt-5 pr-5"
                          },
                          [
                            _c("div", { staticClass: "-mr-2" }, [
                              _c(
                                "button",
                                {
                                  staticClass:
                                    "bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100",
                                  attrs: { type: "button" },
                                  on: {
                                    click: function($event) {
                                      $event.stopPropagation()
                                      $event.preventDefault()
                                      return _vm.toggleMobileMenu()
                                    }
                                  }
                                },
                                [
                                  _c("span", { staticClass: "sr-only" }, [
                                    _vm._v("Close menu")
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "svg",
                                    {
                                      staticClass: "h-12 w-12",
                                      attrs: {
                                        xmlns: "http://www.w3.org/2000/svg",
                                        fill: "none",
                                        viewBox: "0 0 24 24",
                                        stroke: "currentColor",
                                        "aria-hidden": "true"
                                      }
                                    },
                                    [
                                      _c("path", {
                                        attrs: {
                                          "stroke-linecap": "round",
                                          "stroke-linejoin": "round",
                                          "stroke-width": "2",
                                          d: "M6 18L18 6M6 6l12 12"
                                        }
                                      })
                                    ]
                                  )
                                ]
                              )
                            ])
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", [
                          _c("nav", [
                            _c(
                              "ul",
                              { staticClass: "mt-2" },
                              [
                                _vm._l(_vm.main_nav, function(item) {
                                  return [
                                    item.depth === 1
                                      ? [
                                          _c("li", [
                                            _c(
                                              "a",
                                              {
                                                staticClass:
                                                  "block leading-loosest py-2 px-8 text-lg font-bold",
                                                attrs: { href: item.url }
                                              },
                                              [_vm._v(_vm._s(item.text))]
                                            )
                                          ])
                                        ]
                                      : [
                                          _c("li", [
                                            _c(
                                              "button",
                                              {
                                                staticClass:
                                                  "block leading-loosest py-2 px-8 text-lg font-bold w-full text-left",
                                                attrs: { type: "button" },
                                                on: {
                                                  click: function($event) {
                                                    $event.stopPropagation()
                                                    $event.preventDefault()
                                                    return _vm.toggleMobileDropdown(
                                                      item.text
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  "\n                              " +
                                                    _vm._s(item.text) +
                                                    "\n\n                              "
                                                ),
                                                _c(
                                                  "base-icon",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "show",
                                                        rawName: "v-show",
                                                        value:
                                                          item.children.length >
                                                          0,
                                                        expression:
                                                          "item.children.length > 0"
                                                      }
                                                    ],
                                                    class: [
                                                      "angle inline",
                                                      item.text ===
                                                      _vm.activeMobileMenu
                                                        ? "rotated"
                                                        : ""
                                                    ],
                                                    attrs: {
                                                      "icon-name": "angle-down",
                                                      width: "14",
                                                      height: "7",
                                                      viewbox: "0 0 17 9"
                                                    }
                                                  },
                                                  [_c("icon-angle-down")],
                                                  1
                                                )
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "ul",
                                              {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value:
                                                      item.text ===
                                                      _vm.activeMobileMenu,
                                                    expression:
                                                      "item.text === activeMobileMenu"
                                                  }
                                                ]
                                              },
                                              [
                                                item.depth === 2
                                                  ? [
                                                      _c("li", [
                                                        _c(
                                                          "a",
                                                          {
                                                            staticClass:
                                                              "block leading-loosest py-2 px-8 text-lg",
                                                            attrs: {
                                                              href: item.url
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                                    " +
                                                                _vm._s(
                                                                  item.text
                                                                ) +
                                                                "\n                                    "
                                                            ),
                                                            _c(
                                                              "base-icon",
                                                              {
                                                                staticClass:
                                                                  "angle inline",
                                                                attrs: {
                                                                  "icon-name":
                                                                    "angle-right",
                                                                  width: "10",
                                                                  height: "12",
                                                                  viewbox:
                                                                    "0 0 9 17"
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "icon-angle-right"
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ])
                                                    ]
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                _vm._l(item.children, function(
                                                  item_l2
                                                ) {
                                                  return [
                                                    _c("li", [
                                                      _c(
                                                        "a",
                                                        {
                                                          staticClass:
                                                            "block leading-loosest py-2 px-8 text-lg",
                                                          attrs: {
                                                            href: item_l2.url
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              item_l2.text
                                                            ) +
                                                              "\n                                    "
                                                          ),
                                                          item.depth >= 3
                                                            ? _c(
                                                                "base-icon",
                                                                {
                                                                  staticClass:
                                                                    "angle inline",
                                                                  attrs: {
                                                                    "icon-name":
                                                                      "angle-right",
                                                                    width: "10",
                                                                    height:
                                                                      "12",
                                                                    viewbox:
                                                                      "0 0 9 17"
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "icon-angle-right"
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            : _vm._e()
                                                        ],
                                                        1
                                                      )
                                                    ]),
                                                    _vm._v(" "),
                                                    _vm._l(
                                                      item_l2.children,
                                                      function(item_l3) {
                                                        return [
                                                          _c("li", [
                                                            _c(
                                                              "a",
                                                              {
                                                                staticClass:
                                                                  "block leading-loosest py-2 px-8 text-lg",
                                                                attrs: {
                                                                  href:
                                                                    item_l3.url
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    item_l3.text
                                                                  )
                                                                )
                                                              ]
                                                            )
                                                          ]),
                                                          _vm._v(" "),
                                                          _vm._l(
                                                            item_l3.children,
                                                            function(item_l4) {
                                                              return [
                                                                _c("li", [
                                                                  _c(
                                                                    "a",
                                                                    {
                                                                      staticClass:
                                                                        "block leading-loosest py-2 px-16 text-lg",
                                                                      attrs: {
                                                                        href:
                                                                          item_l4.url
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          item_l4.text
                                                                        )
                                                                      )
                                                                    ]
                                                                  )
                                                                ])
                                                              ]
                                                            }
                                                          )
                                                        ]
                                                      }
                                                    )
                                                  ]
                                                })
                                              ],
                                              2
                                            )
                                          ])
                                        ]
                                  ]
                                }),
                                _vm._v(" "),
                                _vm._l(_vm.utility_nav, function(item) {
                                  return [
                                    _c(
                                      "li",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value:
                                              item.display.value !==
                                              "desktopOnly",
                                            expression:
                                              "item.display.value !== 'desktopOnly'"
                                          }
                                        ]
                                      },
                                      [
                                        _c(
                                          "a",
                                          {
                                            class: [
                                              "block leading-loosest py-2 px-8 text-lg",
                                              item.navHighlight
                                                ? "bg-red text-white"
                                                : ""
                                            ],
                                            attrs: { href: item.url }
                                          },
                                          [
                                            _vm._v(
                                              "\n                            " +
                                                _vm._s(
                                                  item.mobileText !== "" &&
                                                    item.mobileText !== null
                                                    ? item.mobileText
                                                    : item.text
                                                ) +
                                                "\n                          "
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  ]
                                })
                              ],
                              2
                            )
                          ])
                        ])
                      ])
                    ])
                  ]
                )
              ])
            ]
          )
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }