var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.input_type === "checkbox"
    ? _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.localValue,
            expression: "localValue"
          }
        ],
        class: ["input-text", _vm.disabled ? "disabled" : ""],
        attrs: {
          placeholder: _vm.placeholder,
          name: _vm.name,
          readonly: _vm.disabled,
          required: _vm.required,
          type: "checkbox"
        },
        domProps: {
          checked: Array.isArray(_vm.localValue)
            ? _vm._i(_vm.localValue, null) > -1
            : _vm.localValue
        },
        on: {
          change: function($event) {
            var $$a = _vm.localValue,
              $$el = $event.target,
              $$c = $$el.checked ? true : false
            if (Array.isArray($$a)) {
              var $$v = null,
                $$i = _vm._i($$a, $$v)
              if ($$el.checked) {
                $$i < 0 && (_vm.localValue = $$a.concat([$$v]))
              } else {
                $$i > -1 &&
                  (_vm.localValue = $$a
                    .slice(0, $$i)
                    .concat($$a.slice($$i + 1)))
              }
            } else {
              _vm.localValue = $$c
            }
          }
        }
      })
    : _vm.input_type === "radio"
    ? _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.localValue,
            expression: "localValue"
          }
        ],
        class: ["input-text", _vm.disabled ? "disabled" : ""],
        attrs: {
          placeholder: _vm.placeholder,
          name: _vm.name,
          readonly: _vm.disabled,
          required: _vm.required,
          type: "radio"
        },
        domProps: { checked: _vm._q(_vm.localValue, null) },
        on: {
          change: function($event) {
            _vm.localValue = null
          }
        }
      })
    : _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.localValue,
            expression: "localValue"
          }
        ],
        class: ["input-text", _vm.disabled ? "disabled" : ""],
        attrs: {
          placeholder: _vm.placeholder,
          name: _vm.name,
          readonly: _vm.disabled,
          required: _vm.required,
          type: _vm.input_type
        },
        domProps: { value: _vm.localValue },
        on: {
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.localValue = $event.target.value
          }
        }
      })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }