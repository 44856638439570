<template>
  <article class="blog-entry-item sm:w-p47 lg:w-p30 pb-10">
    <div class="text-center">
      <thumbnail-image-with-overlay
        :src="item.thumbnailSrc ? item.thumbnailSrc : '/img/blog-thumbnail-placeholder.png'"
        :retina_src="item.thumbnailSrcRetina ? item.thumbnailSrcRetina : '/img/blog-thumbnail-placeholder@2x.png'"
        :alt="item.alt"
        :url="item.url"
        text="Read Post"
        :class_names="['mb-5 mx-auto text-center', !item.thumbnailSrc ? 'shadow' : '']"
      ></thumbnail-image-with-overlay>
    </div>
    <blog-category-heading :name="item.category" :url="item.categoryUrl"></blog-category-heading>
    <div class="text-sm italic text-brown mt-1">
      {{ item.date }}
      <span v-if="item.author.firstName !== '' && item.author.lastName !== ''">
        / By {{ item.author.firstName }} {{ item.author.lastName }}
      </span>
    </div>
    <base-heading size="h2" color="brown" :url="item.url" class_names="mt-3 hover:underline">{{ item.title }}</base-heading>
    <div v-if="item.summary !== ''" class="text-xl xl:text-2xl leading-normal mt-2" v-html="item.summary"></div>
  </article>
</template>

<script>
// @group Blog
// Blog Entry
export default {
  props: {
    // Craft Blog Entry object
    item: {
      type: Object,
      required: true
    }
  },
  components: {
    BaseHeading: () => import(/* webpackChunkName: "base-heading" */ "../components/BaseHeading.vue"),
    BaseImage: () => import(/* webpackChunkName: "base-image" */ "../components/BaseImage.vue"),
    BlogCategoryHeading: () => import(/* webpackChunkName: "blog-category-heading" */ "./BlogCategoryHeading.vue"),
    ThumbnailImageWithOverlay: () =>
      import(/* webpackChunkName: "thumbnail-image-with-overlay" */ "../components/ThumbnailImageWithOverlay.vue")
  }
};
</script>
