var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container bg-grey-lighter p-6 mt-6 text-left relative" },
    [
      _c(
        "button",
        {
          staticClass:
            "text-grey-alt tr-color hover:text-grey-dark absolute top-0 right-0 mr-3 mt-3",
          on: { click: _vm.close }
        },
        [
          _c(
            "base-icon",
            {
              attrs: {
                "icon-name": "close",
                width: "18",
                height: "18",
                viewbox: "0 0 23 23"
              }
            },
            [_c("icon-x")],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm._l(_vm.items, function(item, i) {
        return item.platform.slug !== "firmware"
          ? _c(
              "div",
              {
                key: "platform_" + i,
                class: [
                  "platform-row pr-8",
                  i > 0 ? "pt-4 border-t border-grey mt-4" : ""
                ]
              },
              [
                _c("div", { staticClass: "flex flex-wrap md:flex-no-wrap" }, [
                  _c("div", { staticClass: "d-1 flex-shrink-0 flex-grow-0" }, [
                    _c(
                      "div",
                      {
                        staticClass: "cursor-pointer",
                        on: {
                          click: function($event) {
                            return _vm.click(item)
                          }
                        }
                      },
                      [
                        _c("base-image", {
                          attrs: {
                            src: item.platform.src,
                            retina_src: item.platform.retina,
                            alt: item.platform.alt,
                            class_names: "block m-0"
                          }
                        })
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "d-2 md:flex-grow pl-4 pr-8" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "font-bold text-2xl cursor-pointer hover:underline",
                        on: {
                          click: function($event) {
                            return _vm.click(item)
                          }
                        }
                      },
                      [
                        _vm._v(
                          "\n                    " +
                            _vm._s(item.platform.title) +
                            "\n                "
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "block text-base mt-1" }, [
                      _vm._v("\n                    Latest version: "),
                      _c("strong", [_vm._v(_vm._s(item.version))]),
                      _vm._v(
                        "\n                    " +
                          _vm._s(
                            item.hasOldVersions
                              ? " (" +
                                  item.oldVersions.length +
                                  " older version" +
                                  (item.oldVersions.length > 1 ? "s" : "") +
                                  " available)"
                              : ""
                          ) +
                          "\n                "
                      )
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "block text-lg mt-1 leading-normal" },
                      [
                        _vm._v(
                          "\n                    " +
                            _vm._s(item.note) +
                            "\n                "
                        )
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "d-3 md:flex-shrink-0 md:flex-grow-0" },
                    [
                      _c(
                        "button",
                        {
                          staticClass:
                            "button block w-full uppercase tr-all rounded text-lg xl:text-xl font-bold leading-tighter text-center cursor-pointer text-white bg-red hover:bg-red-hover px-4 py-2 xl:px-5 xl:py-3",
                          on: {
                            click: function($event) {
                              return _vm.click(item)
                            }
                          }
                        },
                        [_vm._v("\n                    View\n                ")]
                      )
                    ]
                  )
                ])
              ]
            )
          : _vm._e()
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }