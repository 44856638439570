var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "add-to-cart-box wrapper mx-auto" },
    [
      _c(
        "add-to-cart-box-price-tiers-flyout",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.hasPriceOverride,
              expression: "!hasPriceOverride"
            }
          ]
        },
        [
          _c(
            "template",
            { slot: "bulk_prices_text" },
            [_vm._t("bulk_prices_text")],
            2
          )
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          class: [
            "box bg-brown shadow-md text-white px-5 pt-5 pb-4 relative z-30",
            _vm.class_names
          ]
        },
        [
          _c("div", { staticClass: "text-left" }, [
            _c("div", { staticClass: "text-3xl lg:text-4xl font-bold" }, [
              _c("span", { domProps: { innerHTML: _vm._s(_vm.listPrice) } }),
              _vm._v(" "),
              _c("span", {
                staticClass: "text-xs lg:text-sm font-bold ml-1",
                domProps: { innerHTML: _vm._s(_vm.currency) }
              })
            ]),
            _vm._v(" "),
            _c("div", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value:
                    !_vm.hasPriceOverride && _vm.$parent.product.stock !== "",
                  expression:
                    "!hasPriceOverride && $parent.product.stock !== ''"
                }
              ],
              staticClass: "stock text-base lg:text-lg uppercase mt-1",
              domProps: { innerHTML: _vm._s(_vm.$parent.product.stock) }
            }),
            _vm._v(" "),
            _vm.ajaxError
              ? _c("div", {
                  staticClass: "mt-10 text-red text-lg",
                  domProps: { innerHTML: _vm._s(_vm.ajaxError) }
                })
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              {
                class: ["flex items-center", _vm.ajaxError ? "mt-4" : "mt-10"]
              },
              [
                _vm.showContactSales || _vm.hasPriceOverride
                  ? [_vm._m(0), _vm._v(" "), _vm._m(1)]
                  : [
                      _c(
                        "div",
                        { staticClass: "w-p40 flex items-center pr-4" },
                        [
                          _vm._m(2),
                          _vm._v(" "),
                          _c("div", { staticClass: "pr-4 flex-grow-1" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.quantity,
                                  expression: "quantity"
                                }
                              ],
                              staticClass:
                                "ml-4 px-2 w-full inline-block h-12 bg-white text-black text-xl font-bold rounded-lg text-center",
                              attrs: {
                                type: "number",
                                pattern: "[0-9]",
                                onkeypress:
                                  "return event.charCode >= 48 && event.charCode <= 57",
                                step: "1",
                                min: "0",
                                id: "quantity",
                                name: ["quantity_" + _vm.$parent.product_code],
                                value: "1"
                              },
                              domProps: { value: _vm.quantity },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.quantity = $event.target.value
                                }
                              }
                            })
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "w-p60" }, [
                        _c(
                          "button",
                          {
                            class: [
                              "relative loading-btn bg-brown-lighter text-xl inline-block font-bold rounded-lg text-center h-12 w-full uppercase",
                              _vm.isLoading || _vm.quantity < 1
                                ? "text-grey"
                                : "text-white tr-color tr-bg hover:text-white hover:bg-green",
                              _vm.isLoading ? "pl-8" : ""
                            ],
                            attrs: {
                              type: "submit",
                              disabled: _vm.isLoading || _vm.quantity < 1
                            },
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.addToCart()
                              }
                            }
                          },
                          [
                            _c(
                              "base-icon",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.isLoading,
                                    expression: "isLoading"
                                  }
                                ],
                                staticClass:
                                  "loading absolute ml-3 left-0 centered-y",
                                attrs: {
                                  "icon-name": "loading",
                                  width: "28",
                                  height: "28",
                                  viewbox: "0 0 32 32"
                                }
                              },
                              [_c("icon-loading")],
                              1
                            ),
                            _vm._v(" "),
                            _c("span", { staticClass: "inline" }, [
                              _vm._v("Add to Cart")
                            ])
                          ],
                          1
                        )
                      ])
                    ]
              ],
              2
            )
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.$parent.has_price_footnote,
                  expression: "$parent.has_price_footnote"
                }
              ],
              staticClass: "text-base mt-6 leading-normal"
            },
            [_vm._t("price_footnote")],
            2
          ),
          _vm._v(" "),
          _c("add-to-cart-box-overlay", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showOverlay,
                expression: "showOverlay"
              }
            ]
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "w-p60 pr-4" }, [
      _c("span", [_vm._v("For additional details, contact our Sales team.")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "w-p40" }, [
      _c(
        "a",
        {
          staticClass:
            "inline-flex items-center justify-center relative bg-brown-lighter text-xl inline-block font-bold rounded-lg text-center h-12 w-full uppercase text-white tr-color tr-bg cursor-pointer hover:text-white hover:bg-green",
          attrs: { href: "/sales" }
        },
        [_vm._v("\n              Contact Sales\n            ")]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "flex-shrink text-center pl-2" }, [
      _c(
        "label",
        { staticClass: "inline-block text-xl", attrs: { for: "quantity" } },
        [_vm._v("\n                Qty:\n              ")]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }