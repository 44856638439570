var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "g",
    { attrs: { transform: "translate(-875.000000, -2223.000000)" } },
    [
      _c("polygon", {
        attrs: {
          fill: "currentColor",
          points:
            "895.6,2245.6 875.4,2225.8 877.6,2223.6 895.6,2241.4 913.4,2223.4 915.6,2225.6"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }