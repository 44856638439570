var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isModalOpen
    ? _c("div", { staticClass: "modal-overlay" }, [
        _c("div", { staticClass: "modal rounded-lg min-w-64 p-0" }, [
          _c(
            "div",
            { staticClass: "modal-header bg-green text-white pt-5 pb-4" },
            [
              _c(
                "button",
                { staticClass: "modal-close", on: { click: _vm.closeModal } },
                [_vm._v("✖")]
              ),
              _vm._v(" "),
              _c(
                "h2",
                { staticClass: "text-center", staticStyle: { margin: "0" } },
                [_vm._v("Authorize IP Access")]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "text-brown-lightest text-center text-xl" },
                [_vm._v("System: " + _vm._s(_vm.item.thing_name))]
              )
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "modal-content flex" }, [
            _c(
              "div",
              {
                staticClass:
                  "modal-left w-1/2 pr-8 flex flex-col items-center justify-center"
              },
              [
                _c("form", { attrs: { method: "post" } }, [
                  _c("input", {
                    attrs: { type: "hidden", name: _vm.$root.csrfTokenName },
                    domProps: { value: _vm.$root.csrfTokenValue }
                  }),
                  _vm._v(" "),
                  _c("input", {
                    attrs: {
                      type: "hidden",
                      name: "action",
                      value: "wildlife/iot/access"
                    }
                  }),
                  _vm._v(" "),
                  _c("input", {
                    attrs: { type: "hidden", name: "sn" },
                    domProps: { value: _vm.item.thing_sn }
                  }),
                  _vm._v(" "),
                  _c("input", {
                    attrs: { type: "hidden", name: "device_name" },
                    domProps: { value: _vm.item.thing_name }
                  }),
                  _vm._v(" "),
                  _c("table", [
                    _c(
                      "tr",
                      {
                        staticClass: "flex flex-col items-center justify-center"
                      },
                      [
                        _c("h3", { staticClass: "text-center" }, [
                          _vm._v("Enter an IP Address")
                        ]),
                        _vm._v(" "),
                        _c(
                          "td",
                          {
                            staticClass: "w-full",
                            staticStyle: { padding: "0" }
                          },
                          [
                            !_vm.item.operator_ip
                              ? _c("input", {
                                  staticClass: "input-text smaller rounded-lg",
                                  attrs: { type: "text", name: "ip" },
                                  domProps: { value: _vm.userIp }
                                })
                              : _c("input", {
                                  staticClass: "input-text smaller rounded-lg",
                                  attrs: { type: "text", name: "ip" },
                                  domProps: { value: _vm.item.operator_ip }
                                })
                          ]
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c("tr", [
                      _c(
                        "td",
                        {
                          staticClass: "w-full flex justify-between",
                          staticStyle: { "padding-top": "0.75rem" }
                        },
                        [
                          _c("base-button", {
                            attrs: {
                              type: "submit",
                              name: "change",
                              value: "1",
                              label: "Authorize",
                              size: "wider",
                              color: "red",
                              textsize: "text-base",
                              class_names: "mx-1"
                            }
                          }),
                          _vm._v(" "),
                          _vm.item.operator_ip
                            ? _c("base-button", {
                                attrs: {
                                  type: "submit",
                                  name: "revoke",
                                  value: "1",
                                  label: "Revoke",
                                  size: "wider",
                                  color: "red",
                                  textsize: "text-base",
                                  class_names: "mx-1"
                                }
                              })
                            : _c(
                                "button",
                                {
                                  staticClass:
                                    "button uppercase inline-block tr-all rounded font-bold leading-tighter text-center cursor-pointer text-base border-3 border-grey text-white bg-grey px-4 py-2 xl:px-5 xl:py-3 mx-1",
                                  attrs: {
                                    disabled: "",
                                    type: "submit",
                                    value: "1",
                                    name: "revoke"
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                  Revoke\n                "
                                  )
                                ]
                              ),
                          _vm._v(" "),
                          _vm.item.is_connected === "f"
                            ? _c("base-button", {
                                attrs: {
                                  type: "submit",
                                  name: "delete",
                                  value: "1",
                                  label: "Delete",
                                  size: "wider",
                                  color: "red",
                                  textsize: "text-base",
                                  class_names: "mx-1"
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      )
                    ])
                  ])
                ])
              ]
            ),
            _vm._v(" "),
            _vm._m(0),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "modal-right w-1/2 pl-8 flex flex-col items-center justify-center pt-2 pb-4"
              },
              [
                _c(
                  "form",
                  { attrs: { method: "post" } },
                  [
                    _c("input", {
                      attrs: { type: "hidden", name: _vm.$root.csrfTokenName },
                      domProps: { value: _vm.$root.csrfTokenValue }
                    }),
                    _vm._v(" "),
                    _c("input", {
                      attrs: {
                        type: "hidden",
                        name: "action",
                        value: "wildlife/iot/access"
                      }
                    }),
                    _vm._v(" "),
                    _c("input", {
                      attrs: { type: "hidden", name: "sn" },
                      domProps: { value: _vm.item.thing_sn }
                    }),
                    _vm._v(" "),
                    _c("input", {
                      attrs: { type: "hidden", name: "device_name" },
                      domProps: { value: _vm.item.thing_name }
                    }),
                    _vm._v(" "),
                    _c("input", {
                      attrs: { type: "hidden", name: "ip" },
                      domProps: { value: _vm.userIp }
                    }),
                    _vm._v(" "),
                    _c("base-button", {
                      attrs: {
                        type: "submit",
                        name: "change",
                        value: "1",
                        label: "Authorize My IP Address",
                        size: "extrawide",
                        color: "red",
                        textsize: "text-lg",
                        class_names: "mb-4 mt-8"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c("h2", [_vm._v("Your IP: " + _vm._s(_vm.userIp))])
              ]
            )
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "modal-footer w-full flex items-center justify-center pb-8"
            },
            [
              _c(
                "button",
                {
                  staticClass:
                    "button inline-block tr-all w-full max-w-1/2 rounded font-bold leading-tighter text-center cursor-pointer border-3 border-red hover:border-red-hover text-white bg-red hover:bg-red-hover p-2",
                  on: { click: _vm.closeModal }
                },
                [_vm._v("\n        Cancel\n      ")]
              )
            ]
          )
        ])
      ])
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "flex flex-col items-center justify-center" },
      [
        _c(
          "div",
          { staticClass: "w-1 bg-black h-32 relative text-transparent" },
          [_vm._v(".")]
        ),
        _vm._v(" "),
        _c("span", { staticClass: "py-4 text-2xl" }, [_vm._v("or")]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "w-1 bg-black h-32 relative text-transparent" },
          [_vm._v(".")]
        )
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }