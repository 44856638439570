var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    {
      class: [
        "sidebar-person sidebar-block max-w-sm mx-auto text-links",
        !_vm.block.isFirst ? "mt-4" : ""
      ]
    },
    [
      _c(
        "div",
        { staticClass: "md:flex md:flex-wrap lg:block" },
        [
          _c("base-image", {
            attrs: {
              src: _vm.block.src,
              retina_src: _vm.block.retinaSrc,
              url: _vm.block.url
            }
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "p-5" },
            [
              _vm.block.products.length > 0
                ? _c(
                    "base-heading",
                    {
                      attrs: {
                        size: "h4",
                        color: "brown-lighter",
                        uppercase: true,
                        class_names: "mb-2"
                      }
                    },
                    _vm._l(_vm.block.products, function(product, p) {
                      return _c("span", {
                        key: p,
                        domProps: {
                          innerHTML: _vm._s((p > 0 ? ", " : "") + product.title)
                        }
                      })
                    }),
                    0
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("base-heading", { attrs: { size: "h2", color: "brown" } }, [
                _vm._v(_vm._s(_vm.block.name))
              ]),
              _vm._v(" "),
              _vm.block.description !== ""
                ? _c(
                    "div",
                    { staticClass: "mt-2" },
                    [
                      _c("base-rich-text", {
                        attrs: { size: "small" },
                        domProps: { innerHTML: _vm._s(_vm.block.description) }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.block.url && _vm.block.url !== ""
                ? _c("div", { staticClass: "mt-4 italic text-sm text-links" }, [
                    _c(
                      "a",
                      {
                        attrs: { href: _vm.block.url, target: _vm.block.target }
                      },
                      [_vm._v(_vm._s(_vm.block.linkText))]
                    )
                  ])
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }