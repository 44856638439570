<template>
  <div class="blog-index container mx-auto px-5-safe py-12" id="blog-entries">
    <div class="mb-12">
      <search-bar :keyword="keyword" @updateResults="keywordChanged" placeholder="Search Blog"></search-bar>
    </div>
    <div v-if="ajaxError" class="mb-10 text-red text-lg" v-html="ajaxError"></div>
    <div :class="['flex justify-between flex-wrap', isLoading ? 'opacity-25' : '']" v-if="localItems && localItems.length > 0">
      <blog-entry-item v-for="(item, i) in localItems" :item="item" :key="i"></blog-entry-item>
      <div v-if="localItems.length % 2 === 0" class="sm:w-p47 lg:w-p30 pb-10">&nbsp;</div>
    </div>
    <no-results :results="localItems" :keyword="keyword" @resetKeyword="resetKeyword"></no-results>
    <div class="mt-10">
      <base-pagination
        :max_visible_buttons="numPages >= 3 ? 3 : parseInt(numPages)"
        :per_page="parseInt(limit)"
        :num_pages="parseInt(numPages)"
        :current_page="currentPage"
        :total="parseInt(totalCount)"
        :show_prev_next_btns="true"
        :pagination_disabled="isLoading"
        @pagechanged="onPageChange"
      ></base-pagination>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import VueAxios from "../../js/modules/vue-axios";
import VueScrollTo from "vue-scrollto";
import HelpersMixin from "../mixins/Helpers";

Vue.use(VueScrollTo);
// Blog listing view
// @group Blog
export default {
  data: function() {
    return {
      localItems: null,
      currentPage: null,
      isLoading: false,
      keyword: "",
      numPages: this.num_pages,
      totalCount: this.total,
      ajaxError: ""
    };
  },
  props: ["total", "limit", "num_pages", "current_page", "items", "keyword_from_url"],
  mixins: [HelpersMixin],
  components: {
    BlogEntryItem: () => import(/* webpackChunkName: "blog-entry-item" */ "./BlogEntryItem.vue"),
    BasePagination: () => import(/* webpackChunkName: "base-pagination" */ "../components/BasePagination.vue"),
    SearchBar: () => import(/* webpackChunkName: "search-bar" */ "../components/SearchBar.vue"),
    NoResults: () => import(/* webpackChunkName: "no-results" */ "../components/NoResults.vue")
  },
  methods: {
    keywordChanged(keyword) {
      if (keyword !== undefined) {
        this.keyword = keyword;
        this.updateUrl();
      }
      self.currentPage = 1;
      this.updateEntries();
    },
    updateEntries() {
      let self = this,
        ajaxUrl = "/actions/site-module/blog/get-entries?limit=" + this.limit;
      self.isLoading = true;
      if (self.keyword !== "") {
        ajaxUrl += "&kw=" + encodeURIComponent(self.keyword);
      }
      ajaxUrl += "&page=" + this.currentPage;
      self.ajaxError = "";
      VueAxios.get(ajaxUrl)
        .then(response => {
          self.isLoading = false;
          let data = response.data || null;
          if (data.items !== undefined) {
            self.localItems = data.items.items;
            self.totalCount = data.items.totalCount;
            self.numPages = data.items.numPages;
            self.updateUrl();
            if (self.currentPage > 1) {
              self.scrollToTop();
            }
          } else {
            self.ajaxError = "An error occurred. Please try again.";
            self.scrollToTop();
          }
        })
        .catch(error => {
          self.ajaxError = "An error occurred. Please try again.";
          self.isLoading = false;
          self.scrollToTop();
        });
    },
    scrollToTop() {
      VueScrollTo.scrollTo("#blog-entries", 350, {
        offset: 20
      });
    },
    resetKeyword() {
      this.keyword = "";
      this.isLoading = true;
      this.updateEntries();
    },
    onPageChange(new_current_page) {
      this.currentPage = new_current_page;
      this.updateEntries();
    },
    updateUrl() {
      let url = "/blog";
      if (this.currentPage > 1) {
        url += "/p" + this.currentPage;
      }
      if (this.keyword !== "") {
        url += "?kw=" + encodeURIComponent(this.keyword);
      }
      this.setUrl("blog", document.title, url);
    }
  },
  mounted: function() {
    this.currentPage = parseInt(this.current_page);
    this.localItems = this.items;
    // if there are no results and the page is higher than 1, try resetting to page 1 and refresh
    if (this.localItems.length === 0 && this.currentPage > 1) {
      this.currentPage = 1;
      this.updateEntries();
    }
    if (this.keyword_from_url !== "") {
      this.keyword = this.keyword_from_url;
    }
  }
};
</script>
