var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.src
    ? _c(
        _vm.disable_lazy ? "span" : "lazy-component",
        {
          key: _vm.src,
          tag: "component",
          class: _vm.wrapper_class_names ? _vm.wrapper_class_names : null
        },
        [
          _c(
            _vm.url ? "a" : "span",
            {
              tag: "component",
              attrs: { href: _vm.url, target: _vm.target ? _vm.target : null }
            },
            [
              _c("picture", [
                _vm.srcset_webp
                  ? _c("source", {
                      attrs: { srcset: _vm.srcset_webp, type: "image/webp" }
                    })
                  : _vm._e(),
                _vm._v(" "),
                _c("img", {
                  class: [
                    _vm.image_align !== "left" ? "mx-auto" : "",
                    _vm.class_names
                  ],
                  attrs: {
                    src: _vm.src,
                    srcset: [
                      _vm.imageExtension !== "svg" &&
                      _vm.retina_src !== undefined &&
                      _vm.retina_src
                        ? _vm.src + " 1x, " + _vm.retina_src + " 2x"
                        : ""
                    ],
                    width: _vm.width,
                    height: _vm.height,
                    alt: _vm.alt || ""
                  }
                })
              ])
            ]
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }