var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._l(_vm.sessions, function(session, s) {
      return _c(
        "div",
        { key: "session_" + s, class: [s > 0 ? "mt-8" : ""] },
        [
          _c(
            "base-heading",
            {
              attrs: {
                size: "h4",
                color: "brown-lighter",
                uppercase: true,
                class_names: "mb-2"
              }
            },
            [_vm._v(_vm._s(session.subheading))]
          ),
          _vm._v(" "),
          _c("base-heading", { attrs: { size: "h2", color: "brown" } }, [
            _vm._v(_vm._s(session.heading))
          ]),
          _vm._v(" "),
          _c(
            "base-heading",
            { attrs: { size: "h3", color: "brown", class_names: "my-2" } },
            [_vm._v(_vm._s(session.date))]
          ),
          _vm._v(" "),
          session.time
            ? _c("div", { staticClass: "text-lg mt-4" }, [
                _c("strong", [_vm._v("Time:")]),
                _vm._v(" "),
                _c("span", {
                  staticClass: "italic",
                  domProps: { innerHTML: _vm._s(session.time) }
                })
              ])
            : _vm._e(),
          _vm._v(" "),
          session.description !== ""
            ? _c(
                "div",
                { staticClass: "mt-4" },
                [
                  _c("base-rich-text", {
                    domProps: { innerHTML: _vm._s(session.description) }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "text-center" },
            [
              session.buttonText !== "" && session.buttonUrl !== ""
                ? _c(
                    "div",
                    { staticClass: "mt-6 mb-4" },
                    [
                      _c("base-button", {
                        attrs: {
                          url: session.buttonUrl,
                          label: session.buttonText,
                          target: session.buttonTarget,
                          color: "red",
                          size: "smaller wider"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm._l(session.additionalButtons, function(button, b) {
                return _c(
                  "div",
                  { key: "button-" + b, staticClass: "mb-4" },
                  [
                    button.url
                      ? _c("base-button", {
                          attrs: {
                            url: button.url,
                            label: button.text,
                            target: button.target,
                            class_names: "button",
                            size: "smaller",
                            color: "red"
                          }
                        })
                      : _vm._e()
                  ],
                  1
                )
              })
            ],
            2
          )
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }