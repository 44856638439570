var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "footer",
    {
      directives: [
        {
          name: "lazy",
          rawName: "v-lazy:background-image",
          value: _vm.background,
          expression: "background",
          arg: "background-image"
        }
      ],
      staticClass: "site-footer print-hidden bg-cover",
      style: _vm.footerStyles
    },
    [
      _c(
        "div",
        {
          staticClass:
            "container mx-auto px-5-safe pt-8 pb-8-safe lg:pt-10 lg:pb-10-safe xl:pt-14 xl:pb-14-safe text-white"
        },
        [
          _c("div", { staticClass: "md:flex md:justify-between" }, [
            _c("div", { staticClass: "col-1" }, [
              _c(
                "a",
                { staticClass: "footer-logo", attrs: { href: _vm.logo_href } },
                [_c("base-image", { attrs: { src: _vm.logo_src } })],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "contact-details text-lg leading-loose mt-10 text-center md:text-left mb-8 md:mb-0"
                },
                [_vm._t("contact-details")],
                2
              )
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-2" },
              [_c("site-footer-news-events")],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "col-3 text-center md:text-left" }, [
              _c("div", { staticClass: "flex flex-col-reverse md:flex-col" }, [
                _c("div", [
                  _c(
                    "div",
                    {
                      staticClass:
                        "text-left mx-auto max-w-xs md:max-w-full md:mx-0"
                    },
                    [
                      _c(
                        "base-heading",
                        {
                          attrs: { size: "h3", uppercase: true, color: "white" }
                        },
                        [
                          _vm._v(
                            "\n                " +
                              _vm._s(_vm.footer_nav_heading) +
                              "\n              "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "nav",
                        {
                          class: [
                            "footer-nav text-lg leading-tight text-white",
                            _vm.footer_nav_heading !== "" ? "mt-6" : ""
                          ]
                        },
                        [
                          _c(
                            "ul",
                            _vm._l(_vm.nav_items, function(item, i) {
                              return _c("li", { key: i, staticClass: "my-3" }, [
                                _c(
                                  "a",
                                  {
                                    staticClass:
                                      "inline-block relative tr-color hover:underline hover:text-brown-lightest pl-4",
                                    attrs: {
                                      href: item.url,
                                      target: item.target
                                    }
                                  },
                                  [
                                    _c(
                                      "base-icon",
                                      {
                                        staticClass:
                                          "text-white absolute left-0 centered-y",
                                        attrs: {
                                          "icon-name": "angle-right",
                                          width: "8",
                                          height: "14",
                                          viewbox: "0 0 9 17"
                                        }
                                      },
                                      [_c("icon-angle-right")],
                                      1
                                    ),
                                    _vm._v(
                                      "\n                      " +
                                        _vm._s(item.text)
                                    )
                                  ],
                                  1
                                )
                              ])
                            }),
                            0
                          )
                        ]
                      )
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "mb-10 md:mb-0 md:mt-10" },
                  _vm._l(_vm.social_icons, function(icon, i) {
                    return _c("social-icon", {
                      key: i,
                      attrs: {
                        url: icon.url,
                        type: icon.website_value,
                        target: icon.target,
                        class_names: [
                          "inline-block text-brown-lighter tr-color hover:text-brown-lightest",
                          i > 0 ? "ml-4" : ""
                        ],
                        icon_size: "40"
                      }
                    })
                  }),
                  1
                )
              ])
            ])
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }