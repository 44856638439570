<template>
  <div class="customer-stories-filter">
    <div class="flex items-center">
      <div class="w-1/2">
        <base-heading size="h2" color="brown">Filter by:</base-heading>
      </div>
      <div class="w-1/2 text-right">
        <button
          type="button"
          @click="reset"
          v-if="showResetButton"
          class="button inline-block uppercase tr-all rounded text-lg xl:text-xl font-bold leading-tighter text-center cursor-pointer text-white bg-brown hover:bg-brown-hover px-4 py-2"
        >
          Reset
        </button>
      </div>
    </div>
    <div class="mt-4 flex flex-wrap justify-between">
      <div class="mb-4 w-full sm:w-p48 lg:w-p24" v-if="Object.keys($parent.customer_story_types).length > 0">
        <form-select
          @selectchanged="filterChangeStoryType"
          v-model="$parent.selectedCustomerStoryType"
          name="story_type"
          :value="$parent.selectedCustomerStoryType"
          :options="storyTypeOptions"
          :class_names="$parent.selectedCustomerStoryType !== '' ? 'bg-grey-lighter' : 'bg-white'"
        ></form-select>
      </div>
      <div class="mb-4 w-full sm:w-p48 lg:w-p24" v-if="Object.keys($parent.species).length > 0">
        <form-select
          @selectchanged="filterChangeSpecies"
          v-model="$parent.selectedSpecies"
          name="species"
          :value="$parent.selectedSpecies"
          :options="speciesOptions"
          :class_names="$parent.selectedSpecies !== '' ? 'bg-grey-lighter' : 'bg-white'"
        ></form-select>
      </div>
      <div class="mb-4 w-full sm:w-p48 lg:w-p24" v-if="Object.keys($parent.products).length > 0">
        <form-select
          @selectchanged="filterChangeProduct"
          v-model="$parent.selectedProduct"
          name="product"
          :value="$parent.selectedProduct"
          :options="productOptions"
          :class_names="$parent.selectedProduct !== '' ? 'bg-grey-lighter' : 'bg-white'"
        ></form-select>
      </div>
      <div class="mb-4 w-full sm:w-p48 lg:w-p24" v-if="Object.keys($parent.research_types).length > 0">
        <form-select
          @selectchanged="filterChangeResearchType"
          v-model="$parent.selectedResearchType"
          name="research_type"
          :value="$parent.selectedResearchType"
          :options="researchTypeOptions"
          :class_names="$parent.selectedResearchType !== '' ? 'bg-grey-lighter' : 'bg-white'"
        ></form-select>
      </div>
      <div class="mb-4 w-full sm:w-p48 lg:w-p24" v-if="Object.keys($parent.customer_story_types).length === 0">&nbsp;</div>
      <div class="mb-4 w-full sm:w-p48 lg:w-p24" v-if="Object.keys($parent.species).length === 0">&nbsp;</div>
      <div class="mb-4 w-full sm:w-p48 lg:w-p24" v-if="Object.keys($parent.products).length === 0">&nbsp;</div>
      <div class="mb-4 w-full sm:w-p48 lg:w-p24" v-if="Object.keys($parent.research_types).length === 0">&nbsp;</div>
    </div>
  </div>
</template>

<script>
export default {
  components: {
    BaseHeading: () => import(/* webpackChunkName: "base-heading" */ "../components/BaseHeading.vue"),
    FormSelect: () => import(/* webpackChunkName: "form-select" */ "../components/form/FormSelect.vue")
  },
  methods: {
    reset() {
      this.$parent.selectedCustomerStoryType = "";
      this.$parent.selectedSpecies = "";
      this.$parent.selectedProduct = "";
      this.$parent.selectedResearchType = "";
    },
    filterChangeStoryType(value) {
      this.$parent.selectedCustomerStoryType = value;
    },
    filterChangeSpecies(value) {
      this.$parent.selectedSpecies = value;
    },
    filterChangeProduct(value) {
      this.$parent.selectedProduct = value;
    },
    filterChangeResearchType(value) {
      this.$parent.selectedResearchType = value;
    },
    selectOptions(str, categories) {
      let options = {
        "": str
      };
      const array = Object.values(categories);
      array.forEach(function(item) {
        options[item.slug] = item.name;
      });
      return options;
    }
  },
  computed: {
    showResetButton() {
      return (
        this.$parent.selectedCustomerStoryType !== "" ||
        this.$parent.selectedSpecies !== "" ||
        this.$parent.selectedProduct !== "" ||
        this.$parent.selectedResearchType !== ""
      );
    },
    storyTypeOptions() {
      return this.selectOptions("Story type", this.$parent.customer_story_types);
    },
    speciesOptions() {
      return this.selectOptions("Species", this.$parent.species);
    },
    productOptions() {
      return this.selectOptions("Products", this.$parent.products);
    },
    researchTypeOptions() {
      return this.selectOptions("Research types", this.$parent.research_types);
    }
  }
};
</script>
