var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("path", {
    attrs: {
      fill: "currentColor",
      d:
        "M30.3,0H4.7C2.1,0,0,2.1,0,4.7v25.6C0,32.9,2.1,35,4.7,35h25.6c2.6,0,4.7-2.1,4.7-4.7V4.7C35,2.1,32.9,0,30.3,0z M10.8,30\n\tH5.4V11.2h5.4V30z M8.3,10c-1.4,0-2.5-1.1-2.5-2.5S6.9,5,8.3,5s2.5,1.1,2.5,2.5S9.6,10,8.3,10z M29.6,30h-5.4V18.4\n\tc0-1.4-0.4-2.3-2.1-2.3c-2.8,0-3.3,2.3-3.3,2.3V30h-5.4V11.2h5.4V13c0.8-0.6,2.7-1.8,5.4-1.8c1.7,0,5.4,1,5.4,7.3V30z"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }