var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { class: ["grant-program-heading", !_vm.block.isFirst ? "mt-10" : ""] },
    [
      _c("base-heading", { attrs: { size: "h1", color: "brown" } }, [
        _vm._v("\n    " + _vm._s(_vm.block.text) + "\n  ")
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }