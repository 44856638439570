<template>
  <div class="news-index container px-5-safe mx-auto py-12" id="news-top">
    <news-featured-story></news-featured-story>
    <div class="mb-8">
      <search-bar :keyword="keyword" @updateResults="keywordChanged" placeholder="Search news"></search-bar>
    </div>
    <div v-if="ajaxError" class="mb-10 text-red text-lg" v-html="ajaxError"></div>
    <results-info :total="total" :current_page="currentPage" :limit="limit" :keyword="keyword" class_names="mb-6"></results-info>
    <div :class="['md:flex md:justify-between md:flex-wrap', isLoading ? 'opacity-25' : '']" v-if="items && items.length > 0">
      <news-item v-for="(item, i) in items" :item="item" :isFirst="i === 0" :key="i"></news-item>
      <div v-if="items.length % 3 !== -1" class="w-full md:w-p48 lg:w-p31 mb-6">&nbsp;</div>
    </div>
    <no-results :results="items" :keyword="keyword" @resetKeyword="resetKeyword"></no-results>
    <div class="mt-10">
      <base-pagination
        :max_visible_buttons="numPages >= 3 ? 3 : parseInt(numPages)"
        :per_page="parseInt(limit)"
        :num_pages="parseInt(numPages)"
        :current_page="currentPage"
        :total="parseInt(total)"
        :show_prev_next_btns="true"
        :pagination_disabled="isLoading"
        @pagechanged="onPageChange"
      ></base-pagination>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import VueAxios from "../../js/modules/vue-axios";
import VueScrollTo from "vue-scrollto";
import HelpersMixin from "../mixins/Helpers";

Vue.use(VueScrollTo);

export default {
  data: function() {
    return {
      items: this.default_items,
      isLoading: false,
      keyword: "",
      numPages: this.default_num_pages,
      total: this.default_total,
      currentPage: 1,
      ajaxError: ""
    };
  },
  props: ["limit", "page_from_url", "keyword_from_url", "featured_data", "default_items", "default_num_pages", "default_total"],
  mixins: [HelpersMixin],
  components: {
    BasePagination: () => import(/* webpackChunkName: "base-pagination" */ "../components/BasePagination.vue"),
    NewsFeaturedStory: () => import(/* webpackChunkName: "news-featured-story" */ "./NewsFeaturedStory.vue"),
    NewsItem: () => import(/* webpackChunkName: "news-item" */ "./NewsItem.vue"),
    ResultsInfo: () => import(/* webpackChunkName: "results-info" */ "../components/ResultsInfo.vue"),
    SearchBar: () => import(/* webpackChunkName: "search-bar" */ "../components/SearchBar.vue"),
    NoResults: () => import(/* webpackChunkName: "no-results" */ "../components/NoResults.vue")
  },
  methods: {
    keywordChanged(keyword) {
      if (keyword !== undefined) {
        this.keyword = keyword;
        this.updateUrl();
      }
      self.currentPage = 1;
      this.updateEntries();
    },
    updateEntries() {
      let self = this,
        ajaxUrl = "/actions/site-module/news/get-entries?limit=" + self.limit;
      self.isLoading = true;
      ajaxUrl += "&page=" + self.currentPage;
      if (self.keyword !== "") {
        ajaxUrl += "&kw=" + encodeURIComponent(self.keyword);
      }
      self.ajaxError = "";
      VueAxios.get(ajaxUrl)
        .then(response => {
          self.isLoading = false;
          var data = response.data || null;
          if (data !== undefined) {
            self.items = data.items || null;
            self.numPages = data.numPages || null;
            self.total = data.totalCount || null;
            self.updateUrl();
            if (self.currentPage > 1) {
              self.scrollToTop();
            }
          } else {
            self.ajaxError = "An error occurred. Please try again.";
            self.scrollToTop();
          }
        })
        .catch(error => {
          self.ajaxError = "An error occurred. Please try again.";
          self.isLoading = false;
        });
    },
    scrollToTop() {
      VueScrollTo.scrollTo("#news-top", 350, {
        offset: 20
      });
    },
    updateUrl() {
      let url = "/resources/news";
      if (this.currentPage > 1) {
        url += "/p" + this.currentPage;
      }
      if (this.keyword !== "") {
        url += "?kw=" + encodeURIComponent(this.keyword);
      }
      this.setUrl("news", document.title, url);
    },
    resetKeyword() {
      this.keyword = "";
      this.isLoading = true;
      this.updateEntries();
    },
    onPageChange(newPageNum) {
      this.currentPage = newPageNum;
      this.updateEntries();
    }
  },
  mounted: function() {
    if (this.page_from_url !== "") {
      this.currentPage = parseInt(this.page_from_url);
    }
    if (this.keyword_from_url !== "") {
      this.keyword = this.keyword_from_url;
    }
  }
};
</script>
