var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "base-heading",
    {
      attrs: {
        size: _vm.textSize,
        color: _vm.block.color,
        class_names: !_vm.block.isFirst
          ? _vm.block.previousBlock === "heading"
            ? "mt-3"
            : "mt-6"
          : ""
      }
    },
    [_vm._v("\n  " + _vm._s(_vm.block.text) + "\n")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }