import { render, staticRenderFns } from "./BlockBoxedSideBySideImageAndText.vue?vue&type=template&id=338da8bc&scoped=true&"
import script from "./BlockBoxedSideBySideImageAndText.vue?vue&type=script&lang=js&"
export * from "./BlockBoxedSideBySideImageAndText.vue?vue&type=script&lang=js&"
import style0 from "./BlockBoxedSideBySideImageAndText.vue?vue&type=style&index=0&id=338da8bc&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "338da8bc",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/ian/wildlife-website.master/craftcms/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('338da8bc')) {
      api.createRecord('338da8bc', component.options)
    } else {
      api.reload('338da8bc', component.options)
    }
    module.hot.accept("./BlockBoxedSideBySideImageAndText.vue?vue&type=template&id=338da8bc&scoped=true&", function () {
      api.rerender('338da8bc', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/vue/blocks/BlockBoxedSideBySideImageAndText.vue"
export default component.exports