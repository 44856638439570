var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "downloads-page", attrs: { id: "download-top" } },
    [
      _c(
        "base-heading",
        { attrs: { size: "h2", color: "brown", class_names: "mb-6" } },
        [_vm._v("\n        " + _vm._s(_vm.pageHeading) + "\n    ")]
      ),
      _vm._v(" "),
      _vm.has_intro_text
        ? _c(
            "div",
            { staticClass: "text rich-text mb-4" },
            [_vm._t("intro")],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.selectedDownload
        ? _c("div", [
            !_vm.selectedCategory
              ? _c(
                  "div",
                  { staticClass: "product-categories" },
                  [
                    _c("products-list", {
                      attrs: { items: _vm.data.categories },
                      on: { select: _vm.selectCategory }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.selectedCategory
              ? _c("div", { staticClass: "mt-4" }, [
                  _c(
                    "button",
                    {
                      staticClass: "text-lg text-red underline",
                      on: {
                        click: function($event) {
                          _vm.selectedCategory = ""
                        }
                      }
                    },
                    [_vm._v("Back to all products")]
                  )
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.selectedCategory
              ? _c(
                  "div",
                  { staticClass: "mt-6" },
                  [
                    _c("software-list", {
                      attrs: { items: _vm.showSoftware },
                      on: { selectDownload: _vm.selectDownload }
                    })
                  ],
                  1
                )
              : _vm._e()
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.selectedDownload
        ? _c(
            "div",
            [
              _c("download-entry", {
                attrs: {
                  data: _vm.data,
                  version: _vm.selectedVersion,
                  item: _vm.selectedDownload
                },
                on: {
                  back: function($event) {
                    return _vm.setDownload(null)
                  }
                }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }