<template>
  <section :class="['sidebar-rich-text sidebar-block max-w-sm mx-auto', !is_first ? 'mt-4' : '']" v-if="block.text">
    <base-rich-text :size="size" link_style="red" v-html="block.text"></base-rich-text>
  </section>
</template>

<script>
export default {
  props: ["block", "is_first"],
  components: {
    BaseRichText: () => import(/* webpackChunkName: "base-rich-text" */ "../components/BaseRichText.vue")
  },
  computed: {
    size() {
      if (this.block.textSize === "small") {
        return "smaller";
      } else {
        return "small";
      }
    }
  }
};
</script>

<style>
.sidebar-rich-text p + ul {
  margin-top: -0.25em !important;
}
.sidebar-rich-text ul {
  margin-bottom: 0.75em !important;
}
</style>
