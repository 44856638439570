var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "glossary-index container px-5-safe mx-auto py-12" },
    [
      _c("nav", { staticClass: "text-left" }, [
        _vm.data.items !== undefined
          ? _c(
              "ul",
              {
                staticClass: "flex justify-center lg:justify-between flex-wrap"
              },
              [
                _c("li", [
                  _c(
                    "button",
                    {
                      class: [
                        "w-12 h-12 py-2 m-1 text-2xl font-bold bg-brown-tint hover:text-red",
                        _vm.activeLetter === null
                          ? "text-red"
                          : "text-green tr-color"
                      ],
                      attrs: { type: "button" },
                      on: {
                        click: function($event) {
                          return _vm.clearLetter()
                        }
                      }
                    },
                    [_vm._v("\n          All\n        ")]
                  )
                ]),
                _vm._v(" "),
                _vm._l(_vm.data.items, function(terms, letter) {
                  return _c(
                    "li",
                    { key: letter, staticClass: "inline-block" },
                    [
                      _c(
                        "button",
                        {
                          class: [
                            "h-12 w-12 m-1 text-2xl font-bold bg-brown-tint hover:text-red",
                            _vm.activeLetter === letter
                              ? "text-red"
                              : "text-green tr-color"
                          ],
                          attrs: { type: "button" },
                          on: {
                            click: function($event) {
                              return _vm.selectLetter(letter)
                            }
                          }
                        },
                        [_vm._v("\n          " + _vm._s(letter) + "\n        ")]
                      )
                    ]
                  )
                })
              ],
              2
            )
          : _vm._e()
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "mt-10 md:flex flex-wrap" },
        _vm._l(_vm.items, function(terms, letter) {
          return _c(
            "div",
            {
              key: letter,
              staticClass: "terms-group md:w-1/3 lg:w-1/4 xl:w-1/5 md:pr-4"
            },
            [
              _c("base-heading", { attrs: { size: "h2", color: "brown" } }, [
                _vm._v(_vm._s(letter))
              ]),
              _vm._v(" "),
              _c(
                "ul",
                _vm._l(terms, function(item) {
                  return _c("li", { staticClass: "my-2" }, [
                    _c(
                      "a",
                      {
                        staticClass: "underline text-xl",
                        attrs: { href: item.url }
                      },
                      [_vm._v(_vm._s(item.title))]
                    )
                  ])
                }),
                0
              )
            ],
            1
          )
        }),
        0
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }