var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("aside", { staticClass: "store-sidebar" }, [
    _c("div", [_c("store-selected-products")], 1),
    _vm._v(" "),
    _c("hr", { staticClass: "block bg-brown-lighter mt-10 border-0" }),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "mt-10" },
      _vm._l(_vm.$parent.buttons, function(button, b) {
        return _c(
          "div",
          { key: b, staticClass: "mb-4" },
          [
            _c("base-button", {
              attrs: {
                url: button.url,
                label: button.text,
                color: button.color,
                outline: button.outlined,
                size: "smaller",
                class_names: "w-full"
              }
            })
          ],
          1
        )
      }),
      0
    ),
    _vm._v(" "),
    _vm.$parent.sidebar_has_text
      ? _c(
          "div",
          { staticClass: "mt-6 rich-text text-links red-text-links" },
          [_vm._t("default")],
          2
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }