<template>
  <figure
    :class="[
      'grant-program-aligned-image aligned-image mb-6 md:mb-8',
      !block.isFirst ? (block.previousBlock === 'heading' ? 'mt-3' : 'mt-10') : '',
      'float-' + block.position,
      block.position === 'right' ? 'ml-6 md:ml-8' : 'mr-6 md:mr-8',
      block.hide.mobile ? 'hidden' + (!block.hide.tablet ? 'md:inline' : '') : '',
      block.hide.tablet ? 'md:hidden' + (!block.hide.desktop ? 'lg:inline' : '') : '',
      block.hide.desktop ? 'lg:hidden' : ''
    ]"
    :style="styles"
  >
    <base-image
      :src="block.src"
      :retina_src="block.retinaSrc"
      :alt="block.alt"
      class_names="block m-0"
      :url="block.url"
      :target="block.target"
    ></base-image>
  </figure>
</template>

<script>
export default {
  props: ["block"],
  components: {
    BaseImage: () => import(/* webpackChunkName: "base-image" */ "../components/BaseImage.vue")
  },
  computed: {
    styles() {
      if (this.block.width === "half") {
        return { width: "50%" };
      } else if (this.block.width === "third") {
        return { width: "33%" };
      } else if (this.block.width === "quarter") {
        return { width: "25%" };
      }
    }
  }
};
</script>
