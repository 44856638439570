<template>
  <section class="block products-list">
    <div :class="[
            include_container ? 'container mx-auto px-5-safe' : 'full-container',
            'py-12 lg:py-12 xl:py-14'
         ]">
      <div v-for="(product, p) in products" :key="p" :class="[p > 0 ? 'mt-16' : '']">
        <div class="md:flex">
          <div class="col-1 text-center xl:pl-12">
            <base-image
              :src="product.src"
              :retina_src="product.retinaSrc"
              :srcset_webp="product.webpSrcset"
              :alt="product.alt"
              :url="product.url"
            ></base-image>
            <div class="mt-5 text-center">
              <base-button :url="product.url" label="Learn More" color="red" size="smaller" :outline="true"></base-button>
            </div>
          </div>
          <div class="col-2 pl-8 mt-8 md:mt-0 xl:pl-12">
            <base-heading size="h2" :url="product.url">{{ product.heading }}</base-heading>
            <base-heading size="h4" color="green" class_names="mt-2">{{ product.subheading }}</base-heading>
            <hr align="left" class="my-4" />
            <base-rich-text v-html="product.text"></base-rich-text>
            <div class="text-center">
              <div
                v-if="product.icons.length > 0"
                class="flex flex-wrap justify-center lg:justify-start mt-6 -mb-4 text-xs leading-normal uppercase text-center"
              >
                <div
                  v-for="(icon, i) in product.icons"
                  :key="i"
                  :class="[
                    'feature inline-block align-top mb-4',
                    i > 0 ? 'pl-4 md:pl-5 lg:pl-6' : '',
                    i < product.icons.length ? 'pr-4 md:pr-5 lg:pr-6' : '',
                    i < product.icons.length - 1 ? 'border-r border-brown' : ''
                  ]"
                >
                  <div>
                    <base-image :src="icon.src" :retina_src="icon.retinaSrc" :alt="icon.alt"></base-image>
                  </div>
                  <div class="mt-3">
                    {{ icon.text }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
// @group Blocks

export default {
  props: ["block_id", "products", "include_container"],
  components: {
    BaseButton: () => import(/* webpackChunkName: "base-button" */ "../components/BaseButton.vue"),
    BaseHeading: () => import(/* webpackChunkName: "base-heading" */ "../components/BaseHeading.vue"),
    BaseImage: () => import(/* webpackChunkName: "base-image" */ "../components/BaseImage.vue"),
    BaseRichText: () => import(/* webpackChunkName: "base-rich-text" */ "../components/BaseRichText.vue")
  }
};
</script>

<style scoped>
section {
  background-color: #e5e5e3;
}
.feature {
  max-width: 120px;
}
@media (min-width: 768px) {
  .col-1 {
    width: 240px;
  }
  .col-2 {
    width: calc(100% - 240px);
  }
}
@media (min-width: 992px) {
  .col-1 {
    width: 320px;
  }
  .col-2 {
    width: calc(100% - 320px);
  }
}
@media (min-width: 1280px) {
  .col-1 {
    width: 400px;
  }
  .col-2 {
    width: calc(100% - 400px);
  }
  .feature {
    max-width: 150px;
  }
}
hr {
  display: block;
  width: 137px;
  height: 3px;
  background: #e1251b;
}
</style>
