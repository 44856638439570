<template>
  <div class="faqs-index container mx-auto px-5-safe pt-10 pb-14">
    <div class="lg:flex">
      <div class="lg:w-1/3 lg:pr-12">
        <faqs-filter></faqs-filter>
        <div class="mt-10 rich-text">
          <a :href="overview_url">
            See All FAQs
          </a>
        </div>
      </div>
      <div class="mt-10 lg:mt-0 lg:w-2/3 lg:pt-12">
        <faqs-results :faqs_type="faqs_type"></faqs-results>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: function() {
    return {
      categories: this.faq_categories
    };
  },
  props: ["faq_categories", "keyword", "faqs_type", "overview_url"],
  components: {
    FaqsFilter: () => import(/* webpackChunkName: "faqs-filter" */ "./FaqsFilter.vue"),
    FaqsResults: () => import(/* webpackChunkName: "faqs-results" */ "./FaqsResults.vue")
  }
};
</script>
