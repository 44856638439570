var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.show
    ? _c("div", { staticClass: "fixed top-0 left-0 w-full h-full wrapper" }, [
        _c("div", {
          staticClass:
            "overlay bg-black absolute top-0 left-0 w-full h-full opacity-50",
          on: { click: _vm.close }
        }),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "z-20 bg-white shadow-sm w-full centered address-verification-modal"
          },
          [
            _c(
              "div",
              {
                staticClass:
                  "bg-green text-white px-6 py-4 text-lg font-bold relative"
              },
              [
                _vm._v("\n      Help us verify your address\n\n      "),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass:
                      "text-white tr-opacity hover:opacity-50 float-right",
                    on: { click: _vm.close }
                  },
                  [
                    _c(
                      "base-icon",
                      {
                        attrs: {
                          "icon-name": "close",
                          width: "18",
                          height: "18",
                          viewbox: "0 0 23 23"
                        }
                      },
                      [_c("icon-x")],
                      1
                    )
                  ],
                  1
                )
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "px-6 pt-4 pb-6" }, [
              _vm._m(0),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "mt-5 flex flex-col md:flex-row md:justify-between"
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "p-2 flex-1 border-r-4 border-white flex flex-col bg-grey-lightest"
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "text-sm font-bold text-center pt-2" },
                        [_vm._v("Original Address")]
                      ),
                      _vm._v(" "),
                      _vm.original
                        ? _c("div", { staticClass: "flex flex-col pt-4" }, [
                            _c("div", { staticClass: "flex flex-row" }, [
                              _vm._m(1),
                              _vm._v(" "),
                              _c("div", { staticClass: "py-2 w-1/2" }, [
                                _c("span", { staticClass: "p-1" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.original.addr1
                                        ? _vm.original.addr1
                                        : ""
                                    )
                                  )
                                ])
                              ])
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "flex flex-row" }, [
                              _vm._m(2),
                              _vm._v(" "),
                              _c("div", { staticClass: "py-2 w-1/2" }, [
                                _c("span", { staticClass: "p-1" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.original.addr2
                                        ? _vm.original.addr2
                                        : ""
                                    )
                                  )
                                ])
                              ])
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "flex flex-row" }, [
                              _vm._m(3),
                              _vm._v(" "),
                              _c("div", { staticClass: "py-2 w-1/2" }, [
                                _c("span", { staticClass: "p-1" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.original.city ? _vm.original.city : ""
                                    )
                                  )
                                ])
                              ])
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "flex flex-row" }, [
                              _vm._m(4),
                              _vm._v(" "),
                              _c("div", { staticClass: "py-2 w-1/2" }, [
                                _c("span", { staticClass: "p-1" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.original.state
                                        ? _vm.original.state
                                        : ""
                                    )
                                  )
                                ])
                              ])
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "flex flex-row" }, [
                              _vm._m(5),
                              _vm._v(" "),
                              _c("div", { staticClass: "py-2 w-1/2" }, [
                                _c("span", { staticClass: "p-1" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.original.zip ? _vm.original.zip : ""
                                    )
                                  )
                                ])
                              ])
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "flex flex-row" }, [
                              _vm._m(6),
                              _vm._v(" "),
                              _c("div", { staticClass: "py-2 w-1/2" }, [
                                _c("span", { staticClass: "p-1" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.original.country
                                        ? _vm.original.country
                                        : ""
                                    )
                                  )
                                ])
                              ])
                            ])
                          ])
                        : _vm._e()
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "p-2 flex-1 border-r-4 border-white flex flex-col bg-grey-lightest"
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "text-sm font-bold text-center pt-2" },
                        [_vm._v("Suggested Address")]
                      ),
                      _vm._v(" "),
                      _vm.original
                        ? _c("div", { staticClass: "flex flex-col pt-4" }, [
                            _c("div", { staticClass: "flex flex-row" }, [
                              _vm._m(7),
                              _vm._v(" "),
                              _c("div", { staticClass: "py-2 w-1/2" }, [
                                _c(
                                  "span",
                                  {
                                    class:
                                      "p-1 " +
                                      (_vm.original.addr1 !==
                                      _vm.suggested.addr1
                                        ? "text-red"
                                        : "")
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.suggested.addr1
                                          ? _vm.suggested.addr1
                                          : ""
                                      )
                                    )
                                  ]
                                )
                              ])
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "flex flex-row" }, [
                              _vm._m(8),
                              _vm._v(" "),
                              _c("div", { staticClass: "py-2 w-1/2" }, [
                                _c(
                                  "span",
                                  {
                                    class:
                                      "p-1 " +
                                      (_vm.original.addr2 !==
                                      _vm.suggested.addr2
                                        ? "text-red"
                                        : "")
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.suggested.addr2
                                          ? _vm.suggested.addr2
                                          : ""
                                      )
                                    )
                                  ]
                                )
                              ])
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "flex flex-row" }, [
                              _vm._m(9),
                              _vm._v(" "),
                              _c("div", { staticClass: "py-2 w-1/2" }, [
                                _c(
                                  "span",
                                  {
                                    class:
                                      "p-1 " +
                                      (_vm.original.city !== _vm.suggested.city
                                        ? "text-red"
                                        : "")
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.suggested.city
                                          ? _vm.suggested.city
                                          : ""
                                      )
                                    )
                                  ]
                                )
                              ])
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "flex flex-row" }, [
                              _vm._m(10),
                              _vm._v(" "),
                              _c("div", { staticClass: "py-2 w-1/2" }, [
                                _c(
                                  "span",
                                  {
                                    class:
                                      "p-1 " +
                                      (_vm.original.state !==
                                      _vm.suggested.state
                                        ? "text-red"
                                        : "")
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.suggested.state
                                          ? _vm.suggested.state
                                          : ""
                                      )
                                    )
                                  ]
                                )
                              ])
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "flex flex-row" }, [
                              _vm._m(11),
                              _vm._v(" "),
                              _c("div", { staticClass: "py-2 w-1/2" }, [
                                _c(
                                  "span",
                                  {
                                    class:
                                      "p-1 " +
                                      (_vm.original.zip !== _vm.suggested.zip
                                        ? "text-red"
                                        : "")
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.suggested.zip
                                          ? _vm.suggested.zip
                                          : ""
                                      )
                                    )
                                  ]
                                )
                              ])
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "flex flex-row" }, [
                              _vm._m(12),
                              _vm._v(" "),
                              _c("div", { staticClass: "py-2 w-1/2" }, [
                                _c(
                                  "span",
                                  {
                                    class:
                                      "p-1 " +
                                      (_vm.original.country !==
                                      _vm.suggested.country
                                        ? "text-red"
                                        : "")
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.suggested.country
                                          ? _vm.suggested.country
                                          : ""
                                      )
                                    )
                                  ]
                                )
                              ])
                            ])
                          ])
                        : _vm._e()
                    ]
                  )
                ]
              ),
              _vm._v(" "),
              _c("div", [
                _c("div", { staticClass: "flex-1 flex flex-row" }, [
                  _c("div", { staticClass: "p-2 w-1/2" }, [
                    _c(
                      "button",
                      {
                        staticClass:
                          "relative loading-btn bg-brown-lighter text-base inline-block font-bold rounded-lg text-center h-12 w-full uppercase text-white tr-color tr-bg hover:text-white hover:bg-green",
                        on: {
                          click: function(e) {
                            return _vm.selectAddressHandler(e, "original")
                          }
                        }
                      },
                      [
                        _c("span", { staticClass: "inline" }, [
                          _vm._v("Use original address")
                        ])
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "p-2 w-1/2" }, [
                    _c(
                      "button",
                      {
                        staticClass:
                          "relative loading-btn bg-brown-lighter text-base inline-block font-bold rounded-lg text-center h-12 w-full uppercase text-white tr-color tr-bg hover:text-white hover:bg-green",
                        on: {
                          click: function(e) {
                            return _vm.selectAddressHandler(e, "suggested")
                          }
                        }
                      },
                      [
                        _c("span", { staticClass: "inline" }, [
                          _vm._v("Use suggested address")
                        ])
                      ]
                    )
                  ])
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "flex pt-4" }, [
                _c(
                  "button",
                  {
                    staticClass:
                      "relative loading-btn bg-white text-sm inline-block font-bold rounded-lg text-center h-12 w-full uppercase border border-brown text-brown tr-color tr-bg hover:text-white hover:bg-green",
                    on: { click: _vm.close }
                  },
                  [
                    _c("span", { staticClass: "inline" }, [
                      _vm._v("Go back to shipping form")
                    ])
                  ]
                )
              ])
            ])
          ]
        )
      ])
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "rich-text mb-4" }, [
      _c("p", [
        _vm._v("We noticed that the address you provided might not be accurate")
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "font-bold" }, [
        _vm._v("Please select an option to proceed")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "py-2 w-1/2" }, [
      _c("span", { staticClass: "p-1" }, [_vm._v("Address 1:")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "py-2 w-1/2" }, [
      _c("span", { staticClass: "p-1" }, [_vm._v("Address 2:")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "py-2 w-1/2" }, [
      _c("span", { staticClass: "p-1" }, [_vm._v("City:")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "py-2 w-1/2" }, [
      _c("span", { staticClass: "p-1" }, [_vm._v("State/Province:")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "py-2 w-1/2" }, [
      _c("span", { staticClass: "p-1" }, [_vm._v("Zip/Postal Code:")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "py-2 w-1/2" }, [
      _c("span", { staticClass: "p-1" }, [_vm._v("Country")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "py-2 w-1/2" }, [
      _c("span", { staticClass: "p-1" }, [_vm._v("Address 1:")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "py-2 w-1/2" }, [
      _c("span", { staticClass: "p-1" }, [_vm._v("Address 2:")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "py-2 w-1/2" }, [
      _c("span", { staticClass: "p-1" }, [_vm._v("City:")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "py-2 w-1/2" }, [
      _c("span", { staticClass: "p-1" }, [_vm._v("State/Province:")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "py-2 w-1/2" }, [
      _c("span", { staticClass: "p-1" }, [_vm._v("Zip/Postal Code:")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "py-2 w-1/2" }, [
      _c("span", { staticClass: "p-1" }, [_vm._v("Country")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }