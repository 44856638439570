var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "resellers-index" },
    [
      _c("base-heading", { attrs: { size: "h1", color: "brown" } }, [
        _vm._v(_vm._s(_vm.heading))
      ]),
      _vm._v(" "),
      _vm.has_text
        ? _c(
            "base-rich-text",
            { attrs: { class_names: "mt-2" } },
            [_vm._t("default")],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "lg:flex mt-10" }, [
        _c(
          "div",
          { staticClass: "lg:w-1/3 lg:pr-12" },
          [_c("resellers-filter", { on: { set: _vm.setActiveRegion } })],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "pt-10 lg:pt-0 lg:w-2/3 lg:pt-12" },
          [_c("resellers-results")],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }