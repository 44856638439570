var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "publications-results" },
    [
      _c("search-bar", {
        attrs: { keyword: _vm.keyword, placeholder: "Search Library" },
        on: { updateResults: _vm.updateResults }
      }),
      _vm._v(" "),
      _vm.ajaxError
        ? _c("div", {
            staticClass: "mt-10 text-red text-lg",
            domProps: { innerHTML: _vm._s(_vm.ajaxError) }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("div", { class: ["mt-10", _vm.isLoading ? "opacity-25" : ""] }, [
        _vm.items && _vm.items.length > 0
          ? _c(
              "div",
              { staticClass: "text-brown text-links" },
              _vm._l(_vm.items, function(item, i) {
                return _c(
                  "article",
                  { key: i, class: [i > 0 ? "mt-8" : ""] },
                  [
                    _c(
                      "base-heading",
                      {
                        attrs: {
                          size: "h4",
                          color: "brown-lighter",
                          uppercase: true
                        }
                      },
                      [_vm._v(_vm._s(item.publicationType))]
                    ),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        class: [
                          "block",
                          item.publicationType !== "" ? "mt-2" : ""
                        ],
                        attrs: { href: item.url, target: item.target }
                      },
                      [
                        _c("span", {
                          staticClass: "block text-2xl",
                          domProps: {
                            innerHTML: _vm._s(
                              item.overrideTitle
                                ? item.overrideTitle
                                : item.title
                            )
                          }
                        }),
                        _vm._v(" "),
                        item.authors !== ""
                          ? _c("span", { staticClass: "block text-lg mt-2" }, [
                              _vm._v(
                                "\n            " +
                                  _vm._s(item.authors) +
                                  "\n          "
                              )
                            ])
                          : _vm._e()
                      ]
                    )
                  ],
                  1
                )
              }),
              0
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.numPages > 1
          ? _c(
              "div",
              { staticClass: "mt-10" },
              [
                _c("base-pagination", {
                  attrs: {
                    max_visible_buttons:
                      _vm.numPages >= 3 ? 3 : parseInt(_vm.numPages),
                    per_page: parseInt(_vm.limit),
                    num_pages: parseInt(_vm.numPages),
                    current_page: _vm.currentPage,
                    total: parseInt(_vm.total),
                    show_prev_next_btns: true,
                    pagination_disabled: _vm.isLoading
                  },
                  on: { pagechanged: _vm.onPageChange }
                })
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        !_vm.items || _vm.items.length === 0
          ? _c("div", [
              _c(
                "div",
                {
                  staticClass:
                    "w-full rounded-lg bg-grey-lighter text-center py-20"
                },
                [
                  _c(
                    "div",
                    { staticClass: "text-3xl uppercase text-grey-dark" },
                    [_vm._v("\n          No publications found\n        ")]
                  ),
                  _vm._v(" "),
                  _vm.keyword !== ""
                    ? _c("div", [
                        _c(
                          "div",
                          {
                            staticClass:
                              "text-xl uppercase text-grey-dark mt-2 leading-normal"
                          },
                          [
                            _vm._v("\n            with keyword\n            "),
                            _c("span", { staticClass: "italic" }, [
                              _vm._v("`" + _vm._s(_vm.keyword) + "`")
                            ])
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "mt-12" }, [
                          _c(
                            "button",
                            {
                              staticClass:
                                "button inline-block uppercase tr-all rounded text-lg xl:text-xl font-bold leading-tighter text-center cursor-pointer text-white bg-red hover:bg-red-hover px-4 py-2",
                              on: {
                                click: function($event) {
                                  _vm.keyword = ""
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n              Reset Search\n            "
                              )
                            ]
                          )
                        ])
                      ])
                    : _vm._e()
                ]
              )
            ])
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }