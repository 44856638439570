var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { class: ["search-bar", _vm.class_names] }, [
    _c("div", { staticClass: "flex" }, [
      _c("div", { staticClass: "relative col-1" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.localKeyword,
              expression: "localKeyword"
            }
          ],
          staticClass:
            "border border-brown rounded-lg w-full text-xl xl:text-2xl italic py-3 px-4 pr-14",
          attrs: {
            type: "text",
            "aria-label": _vm.placeholder,
            placeholder: _vm.placeholder
          },
          domProps: { value: _vm.localKeyword },
          on: {
            keyup: function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              ) {
                return null
              }
              return _vm.updateResults($event)
            },
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.localKeyword = $event.target.value
            }
          }
        }),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass:
              "text-brown w-7 h-7 centered-y right-0 mr-4 tr-color hover:text-red",
            attrs: { type: "submit" },
            on: { click: _vm.updateResults }
          },
          [
            _c(
              "base-icon",
              {
                attrs: {
                  "icon-name": "search",
                  width: "24",
                  height: "24",
                  viewbox: "0 0 28 28"
                }
              },
              [_c("icon-search")],
              1
            )
          ],
          1
        )
      ]),
      _vm._v(" "),
      _vm.localKeyword !== ""
        ? _c("div", { staticClass: "col-2 pl-6" }, [
            _c(
              "button",
              {
                staticClass:
                  "button w-full h-full inline-block uppercase tr-bg tr-border rounded-lg text-lg xl:text-xl font-bold leading-tighter text-center cursor-pointer text-white bg-brown hover:bg-red px-4 py-2",
                on: { click: _vm.reset }
              },
              [_vm._v("\n        Reset\n      ")]
            )
          ])
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }