<template>
  <div class="training-courses-index container mx-auto px-5-safe py-12" id="blog-entries">
    <div v-if="ajaxError" class="mb-10 text-red text-lg" v-html="ajaxError"></div>
    <div :class="[isLoading ? 'opacity-25' : '']">
      <div class="md:flex md:flex-wrap md:justify-between">
        <training-course-item v-for="(item, i) in localItems" :item="item" :isFirst="i === 0" :key="i" class="mb-10 md:w-p48 lg:w-p30">
          {{ item.summary }}
        </training-course-item>
        <div class="mb-10 md:w-p48 lg:w-p30" v-if="localItems !== null && localItems !== undefined && localItems.length % 3 !== 0"></div>
      </div>
    </div>
    <div class="mt-6">
      <base-pagination
        :max_visible_buttons="num_pages >= 3 ? 3 : parseInt(num_pages)"
        :per_page="parseInt(limit)"
        :num_pages="parseInt(num_pages)"
        :current_page="currentPage"
        :total="parseInt(total)"
        :show_prev_next_btns="true"
        :pagination_disabled="isLoading"
        @pagechanged="onPageChange"
      ></base-pagination>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import VueAxios from "../../js/modules/vue-axios";
import VueScrollTo from "vue-scrollto";

Vue.use(VueScrollTo);

export default {
  data: function() {
    return {
      localItems: null,
      currentPage: null,
      isLoading: false,
      ajaxError: ""
    };
  },
  props: ["items", "total", "limit", "num_pages", "current_page"],
  components: {
    TrainingCourseItem: () => import(/* webpackChunkName: "training-course-item" */ "./TrainingCourseItem.vue"),
    BasePagination: () => import(/* webpackChunkName: "base-pagination" */ "../components/BasePagination.vue")
  },
  mounted: function() {
    this.currentPage = parseInt(this.current_page);
    this.localItems = this.items;
  },
  methods: {
    updateEntries() {
      let self = this;
      self.isLoading = true;
      self.ajaxError = "";
      VueAxios.get("/actions/site-module/training-courses/get-entries?limit=" + this.limit + "&page=" + this.currentPage)
        .then(response => {
          self.isLoading = false;
          var data = response.data || null;
          if (data.items !== undefined) {
            self.localItems = data.items.items;
            if (self.currentPage > 1) {
              self.scrollToTop();
            }
          } else {
            self.ajaxError = "An error occurred. Please try again.";
            self.scrollToTop();
          }
        })
        .catch(error => {
          self.ajaxError = "An error occurred. Please try again.";
          self.isLoading = false;
          self.scrollToTop();
        });
    },
    scrollToTop() {
      VueScrollTo.scrollTo("#training-courses-index", 350, {
        offset: 20
      });
    },
    onPageChange(new_current_page) {
      this.currentPage = new_current_page;
      this.updateEntries();
    }
  }
};
</script>
