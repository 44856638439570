var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    {
      class: [
        "sidebar-app-buttons sidebar-block max-w-sm mx-auto",
        !_vm.is_first ? "mt-5" : ""
      ]
    },
    [
      _vm.block.appStoreLink
        ? _c(
            "span",
            { staticClass: "inline-block" },
            [
              _c("base-image", {
                attrs: {
                  src: "/img/btn-app-store.png",
                  retina_src: "/img/btn-app-store@2x.png",
                  alt: "Download on the App Store",
                  url: _vm.block.appStoreLink,
                  image_align: "left",
                  target: "_blank"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.block.googlePlayLink
        ? _c(
            "span",
            { class: ["inline-block", _vm.block.appStoreLink ? "ml-3" : ""] },
            [
              _c("base-image", {
                attrs: {
                  src: "/img/btn-google-play.png",
                  retina_src: "/img/btn-google-play@2x.png",
                  alt: "Get it on Google Play",
                  url: _vm.block.googlePlayLink,
                  image_align: "left",
                  target: "_blank"
                }
              })
            ],
            1
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }