<template>
  <div class="share-icons">
    <div v-for="(item, i) in items" :key="'item_' + i" :class="['inline-block', i > 0 ? 'pl-2' : '']">
      <a :href="item.url" @click.prevent="openWindow(item.url)" class="text-brown-lighter tr-color hover:text-brown-light">
        <social-icon :type="item.site" :icon_size="icon_size"></social-icon>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  data: function() {
    return {
      items: [
        {
          site: "facebook",
          url: this.shareUrl("facebook")
        },
        {
          site: "linkedin",
          url: this.shareUrl("linkedin")
        },
        {
          site: "twitter",
          url: this.shareUrl("twitter")
        }
      ]
    };
  },
  props: {
    icon_size: String,
    url: String,
    title: String,
    share_site_name: String,
    share_twitter_handle: String
  },
  components: {
    SocialIcon: () => import(/* webpackChunkName: "social-icon" */ "../components/SocialIcon.vue")
  },
  methods: {
    openWindow(url) {
      window.open(url, "", "menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=300,width=600");
    },
    shareUrl(site) {
      let url = null;
      if (site === "facebook") {
        url = "https://www.facebook.com/sharer/sharer.php?u=" + encodeURIComponent(this.url);
      } else if (site === "linkedin") {
        url =
          "https://www.linkedin.com/shareArticle?mini=true&url=" +
          encodeURIComponent(this.url) +
          "&title=" +
          encodeURIComponent(this.title);
        if (this.share_site_name) {
          url += "&source=" + encodeURIComponent(this.share_site_name);
        }
      } else if (site === "twitter") {
        url = "https://twitter.com/intent/tweet?text=" + encodeURIComponent(this.title + " " + this.url);
        if (this.share_twitter_handle) {
          url += "&via=" + encodeURIComponent(this.share_twitter_handle);
        }
      }
      return url;
    }
  }
};
</script>
