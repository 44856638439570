<template>
  <section :class="['sidebar-products sidebar-block max-w-sm mx-auto', !is_first ? 'mt-6' : '']">
    <div v-show="block.products.length > 0" class="mt-3">
      <div v-for="(product, i) in block.products" :key="i">
        <h4 class="text-xl text-green font-bold uppercase">
          <component :is="product.url ? 'a' : 'span'" :href="product.url">
            {{ product.name }}
          </component>
        </h4>
        <div v-show="product.src" class="mt-3">
          <component :is="product.url ? 'a' : 'span'" :href="product.url">
            <base-image :src="product.src" :retina_src="product.srcRetina" :alt="product.alt"></base-image>
          </component>
        </div>
        <div v-show="product.text" class="mt-3 rich-text text-xl" v-html="product.text"></div>
        <div v-if="product.buttonText && product.url" class="mt-4">
          <base-button :url="product.url" :label="product.buttonText" color="red" :outline="true" class_names="w-full"></base-button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: ["block", "is_first"],
  components: {
    BaseHeading: () => import(/* webpackChunkName: "base-heading" */ "../components/BaseHeading.vue"),
    BaseButton: () => import(/* webpackChunkName: "base-button" */ "../components/BaseButton.vue"),
    BaseImage: () => import(/* webpackChunkName: "base-image" */ "../components/BaseImage.vue")
  }
};
</script>
