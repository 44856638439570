<template>
  <component v-if="src" :is="disable_lazy ? 'span' : 'lazy-component'" :key="src" :class="wrapper_class_names ? wrapper_class_names : null">
    <component :is="url ? 'a' : 'span'" :href="url" :target="target ? target : null">
      <picture>
        <source v-if="srcset_webp" :srcset="srcset_webp" type="image/webp" />
        <img
          :src="src"
          :srcset="[imageExtension !== 'svg' && retina_src !== undefined && retina_src ? `${src} 1x, ${retina_src} 2x` : '']"
          :width="width"
          :height="height"
          :alt="alt || ''"
          :class="[image_align !== 'left' ? 'mx-auto' : '', class_names]"
        />
      </picture>
    </component>
  </component>
</template>

<script>
export default {
  props: ["src", "retina_src", "srcset_webp", "class_names", "width", "height", "alt", "url", "image_align", "target", "disable_lazy", "wrapper_class_names"],
  computed: {
    imageExtension() {
      if (this.src === undefined) {
        return null;
      }
      return this.src.split(".").pop();
    }
  }
};
</script>
