var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: ["checkbox-field", _vm.class_names] }, [
    _c(
      "label",
      {
        class: [
          "styled-checkbox",
          _vm.uneditable || _vm.disabled ? "disabled" : ""
        ]
      },
      [
        _c(
          "span",
          {
            staticClass: "text text-links underlined-text-links leading-tight"
          },
          [_vm._v(_vm._s(_vm.label)), _vm._t("default")],
          2
        ),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.localValue,
              expression: "localValue"
            }
          ],
          attrs: { type: "checkbox", name: _vm.name, disabled: _vm.uneditable },
          domProps: {
            value: _vm.value || "on",
            checked: Array.isArray(_vm.localValue)
              ? _vm._i(_vm.localValue, _vm.value || "on") > -1
              : _vm.localValue
          },
          on: {
            change: [
              function($event) {
                var $$a = _vm.localValue,
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false
                if (Array.isArray($$a)) {
                  var $$v = _vm.value || "on",
                    $$i = _vm._i($$a, $$v)
                  if ($$el.checked) {
                    $$i < 0 && (_vm.localValue = $$a.concat([$$v]))
                  } else {
                    $$i > -1 &&
                      (_vm.localValue = $$a
                        .slice(0, $$i)
                        .concat($$a.slice($$i + 1)))
                  }
                } else {
                  _vm.localValue = $$c
                }
              },
              _vm.change
            ]
          }
        }),
        _vm._v(" "),
        _c(
          "span",
          { staticClass: "checkmark" },
          [
            _c(
              "base-icon",
              {
                staticClass: "sprite",
                attrs: {
                  "icon-name": "checkmark",
                  width: "16",
                  height: "16",
                  viewbox: "0 0 16 16"
                }
              },
              [_c("icon-checkmark")],
              1
            )
          ],
          1
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }