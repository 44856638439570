var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "click-outside",
          rawName: "v-click-outside",
          value: _vm.hideOverlay,
          expression: "hideOverlay"
        }
      ],
      staticClass:
        "add-to-cart-overlay overlay bg-grey-light shadow-md absolute text-brown"
    },
    [
      _c(
        "header",
        { staticClass: "block relative py-3 px-5 border-b border-brown" },
        [
          _c("div", { staticClass: "uppercase font-bold text-lg" }, [
            _vm._v("\n      Added to your cart\n    ")
          ]),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "text-brown absolute right-0 centered-y pr-5",
              on: { click: _vm.hideOverlay }
            },
            [
              _c(
                "base-icon",
                {
                  attrs: {
                    "icon-name": "x",
                    width: "16",
                    height: "16",
                    viewbox: "0 0 23 23"
                  }
                },
                [_c("icon-x")],
                1
              )
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "pt-4 pb-5 px-5" }, [
        _c("div", { staticClass: "flex" }, [
          _c(
            "div",
            { staticClass: "w-1/3 pr-4" },
            [
              _c("base-image", {
                attrs: {
                  src: _vm.$parent.$parent.product_thumbnail_src,
                  retina_src: _vm.$parent.$parent.product_thumbnail_retina_src
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "w-2/3 pt-1" }, [
            _c("div", {
              staticClass: "font-bold text-lg",
              domProps: { innerHTML: _vm._s(_vm.$parent.$parent.product_name) }
            }),
            _vm._v(" "),
            _c("div", { staticClass: "text-xs mt-1" }, [
              _vm._v(
                "Product Code: " + _vm._s(_vm.$parent.$parent.product_code)
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "mt-3 flex" }, [
              _c("div", { staticClass: "w-1/2 leading-normal" }, [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.$parent.addedToCartUnitPrice,
                        expression: "$parent.addedToCartUnitPrice"
                      }
                    ]
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.$root.formattedPrice(
                          _vm.$parent.addedToCartUnitPrice
                        )
                      )
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.$parent.addedToCartUnitPrice,
                        expression: "$parent.addedToCartUnitPrice"
                      }
                    ],
                    staticClass: "italic"
                  },
                  [_vm._v(_vm._s(_vm.$root.formattedCurrency()))]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.$parent.addedToCartQty,
                        expression: "$parent.addedToCartQty"
                      }
                    ]
                  },
                  [_vm._v("Qty: " + _vm._s(_vm.$parent.addedToCartQty))]
                )
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "w-1/2" },
                [
                  _c("base-button", {
                    attrs: {
                      url: "/shop/cart",
                      label: "View Cart",
                      color: "brown",
                      outline: "true",
                      size: "smaller"
                    }
                  })
                ],
                1
              )
            ])
          ])
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.products.length > 0,
                expression: "products.length > 0"
              }
            ]
          },
          [
            _vm.$parent.$parent.essential_products_heading !== "" ||
            _vm.$parent.$parent.essential_products_text !== ""
              ? _c(
                  "div",
                  { staticClass: "bg-red shadow-md text-white mt-3 p-3" },
                  [
                    _c("div", {
                      staticClass: "text-lg font-bold",
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.$parent.$parent.essential_products_heading
                        )
                      }
                    }),
                    _vm._v(" "),
                    _c("div", {
                      class: [
                        _vm.$parent.$parent.essential_products_heading !== ""
                          ? "mt-1"
                          : ""
                      ],
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.$parent.$parent.essential_products_text
                        )
                      }
                    })
                  ]
                )
              : _vm._e()
          ]
        )
      ]),
      _vm._v(" "),
      _vm.$parent.$parent.essential_products_heading !== "" ||
      _vm.$parent.$parent.essential_products_text !== ""
        ? _c("add-to-cart-box-essential-products")
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }