<template>
  <div class="faqs-results">
    <search-bar :keyword="keyword" @updateResults="updateResults" placeholder="Search FAQs"></search-bar>
    <div v-if="ajaxError" class="mt-6 text-red text-lg" v-html="ajaxError"></div>
    <div :class="['mt-6', isLoading ? 'opacity-25' : '']">
      <div v-if="items && items.length > 0">
        <div v-for="(item, i) in items" :key="i" :class="['question py-3 text-brown border-b border-brown', item.active ? 'active' : '']" :id="'faq-' + item.id">
          <button
            type="button"
            @click="selectQuestion(item)"
            :class="['block toggle text-xl xl:text-2xl pl-9 relative text-left', item.active ? 'font-bold active' : '']"
          >
            {{ item.question }}
          </button>
          <slide-up-down :active="item.active" :duration="350">
            <div class="pl-9 mt-3">
              <base-rich-text size="smaller" v-html="item.answer"></base-rich-text>
            </div>
          </slide-up-down>
        </div>
      </div>
      <div v-if="keyword !== '' && (!items || items.length === 0)">
        <div class="w-full rounded-lg bg-grey-lighter text-center py-20">
          <div class="text-3xl uppercase text-grey-dark">
            No questions found
          </div>
          <div v-if="keyword !== ''">
            <div class="text-xl uppercase text-grey-dark mt-2 leading-normal">
              with keyword
              <span class="italic">`{{ keyword }}`</span>
            </div>
            <div class="mt-12">
              <button
                @click="keyword = ''"
                class="button inline-block uppercase tr-all rounded text-lg xl:text-xl font-bold leading-tighter text-center cursor-pointer text-white bg-red hover:bg-red-hover px-4 py-2"
              >
                Reset Search
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import VueAxios from "../../js/modules/vue-axios";
import HelpersMixin from "../mixins/Helpers";
import VueScrollTo from "vue-scrollto";

Vue.use(VueScrollTo);

export default {
  data: function() {
    return {
      isLoading: false,
      keyword: "",
      items: null,
      ajaxError: "",
      hash: ""
    };
  },
  props: ["faqs_type"],
  components: {
    BaseRichText: () => import(/* webpackChunkName: "base-rich-text" */ "../components/BaseRichText.vue"),
    SearchBar: () => import(/* webpackChunkName: "search-bar" */ "../components/SearchBar.vue")
  },
  mixins: [HelpersMixin],
  computed: {
    subCategories() {
      // Create computed array of just sub-categories, so we can watch only those. Otherwise the `watch` below
      // will trigger a refresh of results each time a top-level category is expanded / collapsed.
      let array = [];
      this.$parent.categories.forEach(function(category) {
        category.children.forEach(function(subCategory) {
          array.push(subCategory);
        });
      });
      return array;
    },
    selectedCategories() {
      let selectedCategories = []
      this.subCategories.forEach(function(category) {
        if (category.active) {
          selectedCategories.push(category.slug);
        }
      });
      return selectedCategories;
    }
  },
  methods: {
    updateResults(keyword) {
      let self = this,
        ajaxUrl = "/actions/site-module/faqs/get-entries?type=" + this.faqs_type;
      if (keyword !== undefined) {
        self.keyword = keyword;
      }
      self.updateUrl();
      if (self.selectedCategories.length > 0) {
        ajaxUrl += "&c=" + self.selectedCategories.join(",");
      }
      if (self.keyword !== "") {
        ajaxUrl += "&kw=" + encodeURIComponent(self.keyword);
      }
      self.isLoading = true;
      self.ajaxError = "";
      VueAxios.get(ajaxUrl)
        .then(response => {
          let items = response.data.items || null;
          if (items) {
            self.items = items;
            self.isLoading = false;
            // open item from hash
            if (self.hash) {
              let lookForId = self.hash.replace('faq-', '');
              self.items.forEach(function(item) {
                if (parseInt(item.id) === parseInt(lookForId)) {
                  item.active = true;
                  // scroll to active question with hash
                  self.$nextTick(function() {
                    // setTimeout(function() {
                      VueScrollTo.scrollTo("#" + self.hash, 250, {
                        offset: 1
                      });
                    // }, 1000);
                  });
                }
              });
            }
          } else {
            self.ajaxError = "An error occurred. Please try again.";
          }
        })
        .catch(error => {
          self.ajaxError = "An error occurred. Please try again.";
          self.isLoading = false;
        });
    },
    selectQuestion(item) {
        item.active = !item.active;
        this.hash = "faq-" + item.id;
        this.updateUrl();
    },
    updateUrl() {
      let url = this.faqs_type === "training-courses" ? "/resources/training-courses/faqs" : "/resources/faqs";
      if (this.keyword) {
        url += "?kw=" + this.keyword;
      }
      if (this.selectedCategories.length > 0) {
        url += (this.keyword ? "&" : "?") + "c=" + this.selectedCategories.join(",");
      }
      if (this.hash) {
        url += "#" + this.hash;
      }
      this.setUrl("faqs", document.title, url);
    }
  },
  watch: {
    // Watch computed subcategories and update results via ajax if a checkbox is changed
    subCategories: {
      deep: true,
      handler() {
        // Update results
        this.hash = "";
        this.updateResults();
      }
    }
  },
  mounted() {
    if (this.$parent.keyword) {
      this.keyword = this.$parent.keyword;
    }
    if (window.location.hash) {
      this.hash = window.location.hash.slice(1);
    }
    this.updateResults();
  }
};
</script>

<style scoped>
.toggle {
  &:before {
    content: "";
    position: absolute;
    top: 50%;
    width: 0;
    height: 0;
    border-style: solid;
    pointer-events: none
  }
  &.active:before {
    border-width: 8px 8px 0 8px;
    border-color: #4b3d2a transparent transparent transparent;
    left: 0;
    top: 6px;
  }
  &:not(.active):before {
    border-width: 8px 0 8px 8px;
    border-color: transparent transparent transparent #4b3d2a;
    left: 6px;
    top: 1px;
  }
}
  .anchor-link {
    display: none;
  }
  .question.active button:hover .anchor-link {
    display: inline;
  }
</style>
