<template>
  <article :class="['flex', !last ? 'mb-5' : '']">
    <a :href="item.url" class="date tr-border">
      <div class="text-center flex items-center tr-color text-white hover:text-brown-lightest">
        <div class="text-center w-full">
          <div v-if="item.day" class="font-cursive text-2xl xl:text-4xl" v-html="item.day"></div>
          <div v-if="item.month" :class="['text-lg xl:text-hxl', item.day ? 'mt-2' : '']" v-html="item.month"></div>
          <div v-if="item.range" class="text-base xl:text-lg p-2" v-html="item.range"></div>
        </div>
      </div>
    </a>
    <div class="text pl-5">
      <div v-if="item.subheading" class="italic mb-1 text-base" v-html="item.subheading"></div>
      <h4 class="text-lg font-bold">
        <a :href="item.url" class="tr-color hover:underline hover:text-brown-lightest">{{ item.title }}</a>
      </h4>
      <div class="rich-text text-base mt-1" v-html="text"></div>
    </div>
  </article>
</template>

<script>
export default {
  props: ["item", "itemType"],
  data: function() {
    return {
      last: false
    };
  },
  methods: {
    truncateString(str, maxLen, separator = " ") {
      if (str.length <= maxLen) return str;
      return str.substr(0, str.lastIndexOf(separator, maxLen)) + "...";
    }
  },
  computed: {
    text() {
      if (this.itemType === "news") {
        return this.truncateString(this.item.summary, 50);
      } else {
        return this.item.location;
      }
    }
  },
  mounted() {
    this.last = this.$parent.$children[this.$parent.$children.length - 1] === this;
  }
};
</script>

<style>
article {
  .date {
    width: 66px;
    > div {
      height: 66px;
      border: 1px solid white;
    }
    &:hover {
      > div {
        border-color: #ecd2ab;
      }
    }
  }
  .text {
    width: calc(100% - 86px);
  }
  @media (min-width: 1280px) {
    .date {
      width: 86px;

      > div {
        height: 86px;
      }
    }
  }
}
</style>
