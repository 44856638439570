var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.name !== ""
    ? _c("div", { staticClass: "blog-category-heading" }, [
        _c(
          "a",
          {
            staticClass:
              "font-bold text-xl text-brown-lighter uppercase tr-color hover:text-brown-light hover:underline",
            attrs: { href: _vm.url }
          },
          [_vm._v("\n    " + _vm._s(_vm.name) + "\n  ")]
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }