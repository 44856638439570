<template>
  <div class="resellers-index">
    <base-heading size="h1" color="brown">{{ heading }}</base-heading>
    <base-rich-text v-if="has_text" class_names="mt-2">
      <slot></slot>
    </base-rich-text>
    <div class="lg:flex mt-10">
      <div class="lg:w-1/3 lg:pr-12">
        <resellers-filter @set="setActiveRegion"></resellers-filter>
      </div>
      <div class="pt-10 lg:pt-0 lg:w-2/3 lg:pt-12">
        <resellers-results></resellers-results>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: function() {
    return {
      activeRegion: this.active_region
    };
  },
  props: ["heading", "has_text", "reseller_data", "active_region"],
  components: {
    BaseHeading: () => import(/* webpackChunkName: "base-heading" */ "../components/BaseHeading.vue"),
    BaseRichText: () => import(/* webpackChunkName: "base-rich-text" */ "../components/BaseRichText.vue"),
    ResellersFilter: () => import(/* webpackChunkName: "resellers-filter" */ "./ResellersFilter.vue"),
    ResellersResults: () => import(/* webpackChunkName: "resellers-results" */ "./ResellersResults.vue")
  },
  methods: {
    setActiveRegion(region) {
      this.activeRegion = region;
    }
  }
};
</script>
