var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "animal-acoustics-page container px-5-safe mx-auto py-12" },
    [
      _c("div", { staticClass: "md:flex" }, [
        _c(
          "div",
          {
            class: [
              _vm.data.buttons.length > 0 ? "md:w-3/4 md:pr-12" : "md:w-full"
            ]
          },
          [
            _c("base-rich-text", {
              domProps: { innerHTML: _vm._s(_vm.data.text) }
            })
          ],
          1
        ),
        _vm._v(" "),
        _vm.data.buttons.length > 0
          ? _c(
              "div",
              {
                staticClass:
                  "mt-8 max-w-xs mx-auto md:mx-0 md:max-w-full md:mt-0 md:w-1/4"
              },
              _vm._l(_vm.data.buttons, function(button, b) {
                return _c(
                  "div",
                  { key: b, class: [b > 0 ? "mt-6" : ""] },
                  [
                    _c("base-button", {
                      attrs: {
                        url: button.url,
                        label: button.text,
                        color: button.color,
                        outline: button.outlined,
                        size: "smaller",
                        class_names: "w-full"
                      }
                    })
                  ],
                  1
                )
              }),
              0
            )
          : _vm._e()
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "mt-10 text-center md:text-left" },
        [
          _c("base-heading", { attrs: { size: "h1", color: "brown" } }, [
            _vm._v(_vm._s(_vm.data.heading))
          ])
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "mt-6" }, [
        _c(
          "div",
          {
            staticClass: "flex justify-between flex-row flex-wrap",
            staticStyle: { "margin-left": "-12px", "margin-right": "-12px" }
          },
          _vm._l(_vm.data.blocks, function(block, b) {
            return _c(
              "div",
              {
                key: b,
                staticClass: "block w-full md:w-p48 lg:w-p31 square p-3"
              },
              [
                block.type === "imageBlock"
                  ? _c(
                      block.url ? "a" : "div",
                      {
                        tag: "component",
                        staticClass: "bg-grey-lighter w-full h-full",
                        attrs: { href: block.url, target: block.target }
                      },
                      [
                        _c("base-image", {
                          attrs: {
                            src: block.src,
                            retina_src: block.retinaSrc,
                            alt: block.alt,
                            class_names: "w-full"
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                block.type === "iconBlock"
                  ? _c(
                      block.url ? "a" : "div",
                      {
                        tag: "component",
                        class: [
                          "flex items-center w-full h-full",
                          "bg-" + block.backgroundColor
                        ],
                        attrs: { href: block.url, target: block.target }
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "text-center w-full text-white p-4 lg:p-5 xl:p-6"
                          },
                          [
                            block.src !== ""
                              ? _c(
                                  "div",
                                  { staticClass: "mb-3" },
                                  [
                                    _c("base-image", {
                                      attrs: {
                                        src: block.src,
                                        retina_src: block.retinaSrc,
                                        alt: block.alt,
                                        class_names: "animal-acoustics-icon"
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "base-heading",
                              { attrs: { size: "h2", color: "white" } },
                              [_vm._v(_vm._s(block.heading))]
                            ),
                            _vm._v(" "),
                            block.text !== ""
                              ? _c(
                                  "div",
                                  { staticClass: "mt-2" },
                                  [
                                    _c("base-rich-text", {
                                      attrs: { size: "smallAlt" },
                                      domProps: {
                                        innerHTML: _vm._s(block.text)
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      ]
                    )
                  : _vm._e()
              ],
              1
            )
          }),
          0
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }