<template>
    <div>
        <div class="mb-6">
            <button @click="back()" class="text-lg underline text-red hover:text-red-hover">
                Back to list
            </button>
        </div>
        <base-heading size="h2" class_names="mb-3">{{ item.title }}</base-heading>
        <div class="mb-8">
            <div class="rich-text text-lg mb-4">
                {{ item.note }}
            </div>
            <div class="max-w-md text-lg">
                <div class="flex content-center">
                    <div class="w-1/3 md:w-1/4 py-2 border-b border-grey italic">
                        Latest Version
                    </div>
                    <div class="w-2/3 md:w-3/4 py-2 border-b border-grey">
                        <strong>{{ item.version }}</strong>
                    </div>
                </div>
                <div class="flex" v-if="item.hasOldVersions">
                    <div class="w-1/3 md:w-1/4 py-2 border-b border-grey italic">
                        Older versions
                    </div>
                    <div class="w-2/3 md:w-3/4 py-2 border-b border-grey">
                        {{ item.oldVersions.length }} older version{{ item.oldVersions.length > 1 ? "s" : "" }} available
                        <button @click="scrollToVersions" class="inline-block tr-bg bg-red hover:bg-red-hover rounded ml-3 align-middle px-3 py-1 text-base uppercase font-bold text-white no-underline">
                            View
                        </button>
                    </div>
                </div>
                <div class="flex" v-if="item.platform && item.platform.title !== undefined && item.platform.title !== 'Firmware'">
                    <div class="w-1/3 md:w-1/4 py-2 border-b border-grey italic">Platform</div>
                    <div class="w-2/3 md:w-3/4 py-2 border-b border-grey">{{ item.platform.title }}</div>
                </div>
                <div class="flex">
                    <div class="w-1/3 md:w-1/4 py-2 italic">Filename</div>
                    <div class="w-2/3 md:w-3/4 py-2">{{ item.filename }}</div>
                </div>
            </div>
        </div>
        <base-heading size="h3" class_names="mb-4" v-if="item.export">Download</base-heading>
        <div class="mb-4" v-if="item.hasOldVersions">
            <label class="form-label">Download Version</label>
            <div class="max-w-xs">
                <form-select
                        name="version"
                        title="Download Version"
                        :options="versionOptions"
                        v-model="selectedVersion"
                        :value="item.version"
                ></form-select>
            </div>
        </div>
        <div v-if="item.export" class="mb-6">
            <div :class="['rich-text text-lg', item.instructions || item.hasOldVersions ? 'mb-8' : '']">
                <base-heading size="h4" :uppercase="true" color="green" class_names="mb-2">
                    Export Certification for {{ item.title }}
                </base-heading>
                <p>
                    I understand that this software is subject to U.S. export controls and that the export or
                    reexport of this software may
                    require a license from the U.S. Government.
                </p>
                <p>
                    I certify that I am not located in, or a citizen or permanent resident of, Cuba, Iran, North
                    Korea, Syria or the Crimea
                    Region of Ukraine. I am not listed on the U.S. Consolidated List (see
                    <a href="https://www.export.gov/csl-search"
                       target="_blank">https://www.export.gov/csl-search</a>), I do not own 50% or
                    more of any entity listed on the Consolidated List. I will not ship, transfer, or transmit this
                    software (directly or
                    indirectly) to any of the above named regions, to citizens or permanent residents of the above
                    named regions, to any
                    persons or entities listed on the U.S. Consolidated list, or to any person or entity who owns
                    more than 50% or more of any
                    entity listed on the Consolidated List.
                </p>
                <form-checkbox
                        name="agree"
                        label="By checking this box, I am certifying the truthfulness of my response"
                        :checked="checkbox"
                        value="on"
                        v-model="checkbox"
                        class_names="text-lg xl:text-xl"
                ></form-checkbox>
            </div>
        </div>
        <div class="mb-8">
            <button
                    type="button"
                    @click.prevent="downloadFile()"
                    :disabled="item.export && !checkbox"
                    :class="[
                'button relative inline-block uppercase tr-all rounded text-xl font-bold leading-tight text-center border-3 cursor-pointer py-2 px-5',
                item.export && !checkbox
                  ? 'text-grey bg-white'
                  : 'bg-brown border-brown text-white hover:bg-brown-hover hover:border-brown-hover'
              ]"
            >
                Download
            </button>
        </div>
        <div v-if="item.instructions" :class="[item.hasOldVersions ? 'mb-8' : '']">
            <base-heading size="h3" class_names="mb-3">Instructions</base-heading>
            <div class="rich-text text-lg" v-html="item.instructions"></div>
        </div>
        <div v-if="item.hasOldVersions" id="versions">
            <div class="relative red-text-links">
                <base-heading size="h3" class_names="mb-4">Available Versions</base-heading>
                <a
                        v-if="item.releaseNotesUrl"
                        :href="item.releaseNotesUrl"
                        class="block mt-2 mb-4 sm:mt-1 sm:mb-0 sm:inline sm:absolute sm:top-0 sm:right-0 text-red text-lg tr-color hover:text-red-hover underline"
                >
                    View Release Notes
                </a>
            </div>
            <div class="mb-3 sm:hidden">
                <swipe-table-indicator></swipe-table-indicator>
            </div>
            <div class="rich-text">
                <div class="table-wrapper min-sm">
                    <table class="history w-full text-base leading-normal lined">
                        <thead>
                        <tr>
                            <td class="col-1">Version</td>
                            <td class="col-2">Filename</td>
                            <td class="col-3">Notes</td>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td class="col-1 align-top">{{ item.version }} (Latest)</td>
                            <td class="col-2 align-top">{{ item.filename }}</td>
                            <td class="col-3 align-top">{{ item.note }}</td>
                        </tr>
                        <tr v-for="row in item.oldVersions">
                            <td class="col-1 align-top">{{ row.version }}</td>
                            <td class="col-2 align-top">{{ row.filename }}</td>
                            <td class="col-3 align-top">{{ row.notes }}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div v-else-if="item.releaseNotesUrl" class="mt-8 red-text-links">
            <base-heading size="h3" class_names="mb-4">Release Notes</base-heading>
            <a :href="item.releaseNotesUrl" class="block mt-2 mb-4 text-red text-lg tr-color hover:text-red-hover underline">
                View Release Notes
            </a>
        </div>
    </div>
</template>

<script>
import Vue from "vue";
import VueScrollTo from "vue-scrollto";
Vue.use(VueScrollTo);

  export default {
    data: function() {
      return {
        checkbox: false,
        agreeCookieSet: false,
        selectedVersion: null
      }
    },
    props: ["data", "item", "version"],
    components: {
      BaseHeading: () => import(/* webpackChunkName: "base-heading" */ "../components/BaseHeading.vue"),
      FormCheckbox: () => import(/* webpackChunkName: "form-checkbox" */ "../components/form/FormCheckbox.vue"),
      FormSelect: () => import(/* webpackChunkName: "form-select" */ "../components/form/FormSelect.vue"),
      SwipeTableIndicator: () => import(/* webpackChunkName: "swipe-table-indicator" */ "../components/SwipeTableIndicator.vue")
    },
    methods: {
      back() {
        this.$emit("back");
      },
      scrollToVersions() {
        VueScrollTo.scrollTo("#versions", 350, {
          offset: -10
        });
      },
      downloadFile() {
        let self = this,
          filename = this.item.filename || null;
        if (self.selectedVersion !== self.item.version) {
          self.item.oldVersions.forEach(function(item) {
            if (item.version === self.selectedVersion) {
              filename = item.filename;
            }
          });
        }
        if (!filename) {
          this.$emit("back");
        } else {
          window.location.href = "/download-request/" + filename;
        }
      }
    },
    computed: {
      versionOptions() {
        if (!this.item || !this.item.hasOldVersions) {
          return [];
        }
        let options = {};
        options[this.item.version] = this.item.version + " (latest version)";
        this.item.oldVersions.forEach(function(item) {
          options[item.version] = item.version;
        });
        return options;
      }
    },
    mounted() {
      this.selectedVersion = this.version;
    }
  };
</script>
