var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "click-outside",
          rawName: "v-click-outside.capture",
          value: _vm.hideQuantityDiscountBox,
          expression: "hideQuantityDiscountBox",
          modifiers: { capture: true }
        }
      ],
      class: [
        "expandable-category-product flex flex-wrap pb-5 text-brown",
        !_vm.is_first ? "border-t border-brown pt-5" : "",
        "page-" + _vm.page
      ]
    },
    [
      _c(
        "div",
        { staticClass: "col-1 pb-4 md:pb-0 pr-4 md:px-4" },
        [
          _c("base-image", {
            attrs: {
              src:
                _vm.product.src !== ""
                  ? _vm.product.src
                  : "/img/add-on-small-thumbnail-placeholder.png",
              retina_src:
                _vm.product.retinaSrc !== ""
                  ? _vm.product.retinaSrc
                  : "/img/add-on-small-thumbnail-placeholder@2x.png",
              class_names: "mx-auto md:w-3/4",
              url: _vm.product.url
            }
          }),
          _vm._v(" "),
          _vm.product.zoomSrc && _vm.product.zoomExtraSrc
            ? _c("div", { staticClass: "text-center mt-2" }, [
                _c(
                  "a",
                  {
                    staticClass:
                      "mt-1 tr-color text-brown hover:text-red cursor-pointer",
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.openImageModal($event)
                      }
                    }
                  },
                  [
                    _c(
                      "base-icon",
                      {
                        attrs: {
                          "icon-name": "search",
                          width: "10",
                          height: "10",
                          viewbox: "0 0 28 28"
                        }
                      },
                      [_c("icon-search")],
                      1
                    ),
                    _vm._v("\n         Click here to zoom\n      ")
                  ],
                  1
                )
              ])
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col-2 pb-4 md:pb-0" },
        [
          _c(
            "base-heading",
            {
              attrs: {
                size: "h4",
                color: "brown",
                hover_color: "red",
                url: _vm.product.url
              }
            },
            [_vm._v("\n      " + _vm._s(_vm.product.title) + "\n    ")]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "text-sm sm:text-base mt-1 sm:mt-2" }, [
            _vm._v("Product Code: " + _vm._s(_vm.product.code))
          ]),
          _vm._v(" "),
          _vm.ajaxError
            ? _c("div", {
                staticClass: "mt-4 text-red text-lg",
                domProps: { innerHTML: _vm._s(_vm.ajaxError) }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.product.tooltip !== ""
            ? _c(
                "base-rich-text",
                { attrs: { size: "tiny", class_names: "mt-3" } },
                [
                  _c("span", {
                    staticClass: "mr-1",
                    domProps: { innerHTML: _vm._s(_vm.product.tooltip) }
                  })
                ]
              )
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "col-3 pr-6 md:pr-0 text-right" }, [
        _c("div", { staticClass: "text-xl font-bold" }, [
          _c("span", {
            domProps: {
              innerHTML: _vm._s(_vm.calculatedProductPrice(_vm.product))
            }
          })
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "text-xs mt-1 italic" }, [
          _vm._v(_vm._s(_vm.$root.formattedCurrency()))
        ]),
        _vm._v(" "),
        _c("div", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.hasPriceOverride,
              expression: "!hasPriceOverride"
            }
          ],
          staticClass: "text-sm uppercase mt-3 product-stock",
          domProps: { innerHTML: _vm._s(_vm.product.stock) }
        }),
        _vm._v(" "),
        !_vm.hasPriceOverride && _vm.product.price_tiers_display.length > 1
          ? _c(
              "div",
              { staticClass: "relative mt-4" },
              [
                _c(
                  "button",
                  {
                    staticClass: "italic text-red text-sm underline",
                    attrs: { type: "button" },
                    on: {
                      click: function($event) {
                        _vm.showQuantityDiscountBox = !_vm.showQuantityDiscountBox
                      }
                    }
                  },
                  [_vm._v("\n        Quantity Discount\n      ")]
                ),
                _vm._v(" "),
                _c(
                  "slide-up-down",
                  {
                    staticClass: "price-tiers-box absolute right-0 z-10",
                    attrs: { active: _vm.showQuantityDiscountBox, duration: 0 }
                  },
                  [
                    _c("div", { staticClass: "py-2 px-3 bg-red text-white" }, [
                      _c(
                        "table",
                        { staticClass: "mx-auto" },
                        _vm._l(_vm.product.price_tiers_display, function(
                          tier,
                          t
                        ) {
                          return _c("tr", { key: t }, [
                            _c("td", { staticClass: "text-right" }, [
                              _vm._v(_vm._s(tier.label) + ":")
                            ]),
                            _vm._v(" "),
                            _c("td", { staticClass: "pl-3" }, [
                              _vm._v(
                                _vm._s(_vm.formattedPrice(tier.price)) +
                                  " (" +
                                  _vm._s(_vm.$root.formattedCurrency()) +
                                  ")"
                              )
                            ])
                          ])
                        }),
                        0
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "text-left mt-2" },
                        [_vm._t("bulk_prices_text")],
                        2
                      )
                    ])
                  ]
                )
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.showContactSales || _vm.hasPriceOverride
          ? _c("div", { staticClass: "relative mt-4" }, [
              _c(
                "a",
                {
                  staticClass:
                    "button inline-block uppercase tr-all rounded font-bold leading-tighter text-center cursor-pointer text-sm border-2 border-brown hover:border-brown-hover text-brown bg-white hover:bg-grey-light px-2 py-1",
                  attrs: { href: "/sales" }
                },
                [_vm._v("\n        Contact sales\n      ")]
              )
            ])
          : _vm._e()
      ]),
      _vm._v(" "),
      !_vm.showContactSales && !_vm.hasPriceOverride
        ? _c(
            "div",
            { staticClass: "col-4 text-center" },
            [
              _c("input-product-quantity", {
                attrs: {
                  value: _vm.product.qty,
                  bg_color: "border-grey-lightest",
                  is_loading: _vm.isLoading,
                  is_disabled: _vm.is_disabled,
                  class_names: "mx-auto"
                },
                model: {
                  value: _vm.product.qty,
                  callback: function($$v) {
                    _vm.$set(_vm.product, "qty", $$v)
                  },
                  expression: "product.qty"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.page === "cart"
        ? [
            _vm.showContactSales || _vm.hasPriceOverride
              ? _c(
                  "div",
                  {
                    staticClass: "col-5 text-center pt-4 md:pt-0 md:text-center"
                  },
                  [
                    _c(
                      "a",
                      {
                        staticClass:
                          "button inline-block uppercase tr-all rounded font-bold leading-tighter text-center cursor-pointer text-sm border-2 border-brown hover:border-brown-hover text-brown bg-white hover:bg-grey-light px-4 py-2",
                        attrs: { href: "/sales" }
                      },
                      [_vm._v("\n        Contact sales\n      ")]
                    )
                  ]
                )
              : _c(
                  "div",
                  {
                    staticClass: "col-5 text-right pt-4 md:pt-0 md:text-center"
                  },
                  [
                    _c(
                      "button",
                      {
                        class: [
                          "text-white rounded uppercase py-2 px-5 font-bold text-lg xl:text-xl leading-tighter text-center tr-bg",
                          _vm.is_disabled ||
                          _vm.isLoading ||
                          _vm.product.qty === 0
                            ? "bg-grey"
                            : "bg-red hover:bg-red-hover"
                        ],
                        attrs: {
                          type: "button",
                          disabled:
                            _vm.is_disabled ||
                            _vm.isLoading ||
                            _vm.product.qty === 0
                        },
                        on: {
                          click: function($event) {
                            return _vm.addToCart()
                          }
                        }
                      },
                      [_vm._v("\n        Add\n      ")]
                    )
                  ]
                )
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }