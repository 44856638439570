var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "border-b border-brown" }, [
    _c(
      "header",
      {
        staticClass:
          "cursor-pointer font-bold pl-2 pr-12 py-2 relative select-none text-green text-xl xl:text-2xl uppercase w-full uppercase",
        class: { open: _vm.opened },
        on: { click: _vm.toggle }
      },
      [_vm._v(_vm._s(_vm.heading))]
    ),
    _vm._v(" "),
    _vm.opened
      ? _c("div", { staticClass: "px-2" }, [_vm._t("details")], 2)
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }