<template>
  <div v-if="show && src && srcZoom && src.length > 0 && srcZoom.length > 0" :class="classList">
    <div @click="close" class="overlay bg-black absolute top-0 left-0 w-full h-full opacity-50"></div>

    <div class="z-20 bg-white shadow border-grey-dark centered">
      <!-- Heading -->
      <div class="bg-green text-white px-6 py-4 text-lg font-bold relative">
        Click or hover over image to zoom

        <!-- Close button -->
        <button @click="$emit('close')" class="text-white tr-opacity hover:opacity-50 float-right">
          <base-icon icon-name="close" width="18" height="18" viewbox="0 0 23 23">
            <icon-x></icon-x>
          </base-icon>
        </button>
      </div>
      <!-- Content -->
      <div class="flex justify-center align-center">
        <zoomable-image :src="src" :srcZoom="srcZoom" :alt="alt"></zoomable-image>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["show", "src", "srcZoom", "alt"],
  components: {
    BaseIcon: () => import(/* webpackChunkName: "base-icon" */ "../components/BaseIcon.vue"),
    IconX: () => import(/* webpackChunkName: "icon-x" */ "../icons/IconX.vue"),
    ZoomableImage: () => import(/* webpackChunkName: "zoomable-image" */ "../_new/ZoomableImage.vue")
  },
  computed: {
    classList() {
      return {
        fixed: true,
        "inset-0": true,
        "overflow-y-auto": true,
        "h-full": true,
        "w-full": true,
        hidden: this.show ? false : true
      };
    }
  },
  methods: {
    close() {
      this.$emit("close");
    }
  }
};
</script>
