var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "article",
    { class: _vm.class_names },
    [
      _c(
        "base-heading",
        {
          attrs: {
            size: "h2",
            color: "brown",
            hover_color: "red",
            url: _vm.item.url,
            target: _vm.item.target
          }
        },
        [
          _vm._v(
            "\n    " +
              _vm._s(_vm.item.num) +
              ". " +
              _vm._s(_vm.item.title) +
              "\n  "
          )
        ]
      ),
      _vm._v(" "),
      _vm.item.section
        ? _c("div", { staticClass: "mt-2" }, [
            _vm._v("\n    (in\n    "),
            _vm.item.sectionUrl
              ? _c("span", [
                  _c("a", { attrs: { href: _vm.item.sectionUrl } }, [
                    _vm._v(_vm._s(_vm.item.section))
                  ])
                ])
              : _c("span", [_vm._v(_vm._s(_vm.item.section))]),
            _vm._v(")\n  ")
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "mt-3 break-all" }, [
        _c(
          "a",
          {
            staticClass: "underline",
            attrs: { href: _vm.item.url, target: _vm.item.target }
          },
          [_vm._v(_vm._s(_vm.item.url))]
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }