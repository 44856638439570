var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "g",
    {
      attrs: {
        stroke: "none",
        "stroke-width": "1",
        fill: "none",
        "fill-rule": "evenodd"
      }
    },
    [
      _c(
        "g",
        { attrs: { transform: "translate(-1177.000000, -6705.000000)" } },
        [
          _c(
            "g",
            { attrs: { transform: "translate(0.000000, 6464.000000)" } },
            [
              _c("path", {
                attrs: {
                  fill: "currentColor",
                  d:
                    "M1190,252.716495 C1190,252.582474 1189.94322,252.463918 1189.82966,252.360825 L1181.89178,245.154639 C1181.77822,245.051546 1181.64763,245 1181.5,245 C1181.35237,245 1181.22178,245.051546 1181.10822,245.154639 L1173.17034,252.360825 C1173.05678,252.463918 1173,252.582474 1173,252.716495 C1173,252.850516 1173.05678,252.969072 1173.17034,253.072165 L1174.02204,253.845361 C1174.13561,253.948454 1174.2662,254 1174.41383,254 C1174.56146,254 1174.69205,253.948454 1174.80561,253.845361 L1181.5,247.768041 L1188.19439,253.845361 C1188.30795,253.948454 1188.43854,254 1188.58617,254 C1188.7338,254 1188.86439,253.948454 1188.97796,253.845361 L1189.82966,253.072165",
                  transform:
                    "translate(1181.500000, 249.500000) scale(1, -1) rotate(-270.000000) translate(-1181.500000, -249.500000)"
                }
              })
            ]
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }