import { render, staticRenderFns } from "./SalesForm.vue?vue&type=template&id=bfde9438&"
import script from "./SalesForm.vue?vue&type=script&lang=js&"
export * from "./SalesForm.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/ian/wildlife-website.master/craftcms/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('bfde9438')) {
      api.createRecord('bfde9438', component.options)
    } else {
      api.reload('bfde9438', component.options)
    }
    module.hot.accept("./SalesForm.vue?vue&type=template&id=bfde9438&", function () {
      api.rerender('bfde9438', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/vue/_new/SalesForm.vue"
export default component.exports