<template>
  <div class="large-image-header">
    <!-- simple image with no text -->
    <header v-if="hide_text">
      <base-image :src="background" :srcset_webp="background_webp" class_names="w-full block m-0"> </base-image>
    </header>

    <!-- image background with text -->
    <header v-else class="large-image-header bg-cover" :style="headerStyles">
      <div class="container mx-auto px-5-safe relative">
        <span
          v-if="inset_image_src !== undefined && inset_image_src"
          :class="['hidden md:inline inset-image absolute top-0', inset_image_position === 'right' ? 'right-0 mr-5' : 'left-0 ml-5']"
        >
          <base-image :src="inset_image_src" :retina_src="inset_image_retina" image_align="left"></base-image>
        </span>
      </div>
      <div class="outer">
        <div class="inner text-white">
          <div class="container mx-auto px-5-safe pt-5 pb-4 lg:pt-7">
            <div class="lg:flex lg:items-center w-full">
              <div>
                <h1 class="uppercase font-heading font-bold text-4xl md:text-8xl lg:text-12xl">{{ heading }}</h1>
              </div>
              <div
                class="font-cursive mt-1 lg:mt-0 lg:text-right lg:flex-1 text-2xl md:text-3xl lg:text-4xl leading-loose"
                v-show="sub_heading !== ''"
              >
                {{ sub_heading }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  </div>
</template>

<script>
// @group Headers
import HelpersMixin from "../../mixins/Helpers";

export default {
  props: [
    "background",
    "background_webp",
    "background_focal_point",
    "dominant_color",
    "heading",
    "sub_heading",
    "inset_image_src",
    "inset_image_retina",
    "inset_image_width",
    "inset_image_position",
    "hide_text"
  ],
  mixins: [HelpersMixin],
  components: {
    BaseImage: () => import(/* webpackChunkName: "base-image" */ "../BaseImage.vue")
  },
  computed: {
    headerStyles() {
      return {
        ...this.backgroundPosition(this.background_focal_point),
        "background-image": `url(${this.background})`,
        "background-color": this.dominant_color
      };
    }
  }
};
</script>

<style scoped>
.outer {
  padding-top: 119px;
}

.inner {
  background-color: rgba(0, 0, 0, 0.75);
}

@media (min-width: 576px) {
  .outer {
    padding-top: 219px;
  }
}

@media (min-width: 768px) {
  .outer {
    padding-top: 275px;
  }

  .inset-image {
    width: 230px;
  }
}

@media (min-width: 992px) {

  .inset-image {
    width: 260px;
  }
}


</style>
