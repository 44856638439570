var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container mx-auto px-5-safe my-6 lg:my-8 xl:my-10" },
    _vm._l(_vm.term.blocks, function(block, b) {
      return _c(
        "div",
        { key: b, class: [b > 0 ? "mt-6" : ""] },
        [
          block.type === "text"
            ? _c("base-rich-text", {
                domProps: { innerHTML: _vm._s(block.text) }
              })
            : _vm._e(),
          _vm._v(" "),
          block.type === "image" && block.src !== ""
            ? _c(
                "div",
                { staticClass: "pb-5" },
                [
                  _c("base-image", {
                    attrs: {
                      src: block.src,
                      image_align: "left",
                      url: block.url,
                      width: block.width / 2
                    }
                  })
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }