<template>
  <div class="container mx-auto px-5-safe my-6 lg:my-8 xl:my-10">
    <div v-for="(block, b) in term.blocks" :key="b" :class="[b > 0 ? 'mt-6' : '']">
      <base-rich-text v-if="block.type === 'text'" v-html="block.text"></base-rich-text>
      <div v-if="block.type === 'image' && block.src !== ''" class="pb-5">
        <base-image :src="block.src" image_align="left" :url="block.url" :width="block.width / 2"></base-image>
      </div>
    </div>
  </div>
</template>

<script>
import HelpersMixin from "../mixins/Helpers";

export default {
  props: ["term"],
  components: {
    BaseImage: () => import(/* webpackChunkName: "base-image" */ "../components/BaseImage.vue"),
    BaseRichText: () => import(/* webpackChunkName: "base-rich-text" */ "../components/BaseRichText.vue")
  }
};
</script>
