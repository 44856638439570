var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: ["product-video", !_vm.block.isFirst ? "mt-6" : ""] },
    [
      _c("base-video", {
        attrs: { platform: _vm.block.platform, video_id: _vm.block.videoId }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }