<template>
  <div>
    <div v-for="(session, s) in sessions" :key="'session_' + s" :class="[s > 0 ? 'mt-8' : '']">
      <base-heading size="h4" color="brown-lighter" :uppercase="true" class_names="mb-2">{{ session.subheading }}</base-heading>
      <base-heading size="h2" color="brown">{{ session.heading }}</base-heading>
      <base-heading size="h3" color="brown" class_names="my-2">{{ session.date }}</base-heading>
      <div v-if="session.time" class="text-lg mt-4">
        <strong>Time:</strong> <span class="italic" v-html="session.time"></span>
      </div>
      <div v-if="session.description !== ''" class="mt-4">
        <base-rich-text v-html="session.description"></base-rich-text>
      </div>

      <div class="text-center">

        <!-- Registration button -->
        <div v-if="session.buttonText !== '' && session.buttonUrl !== ''" class="mt-6 mb-4">
          <base-button
            :url="session.buttonUrl"
            :label="session.buttonText"
            :target="session.buttonTarget"
            color="red"
            size="smaller wider"
          ></base-button>
        </div>

        <!-- Additional buttons -->
        <div v-for="(button, b) in session.additionalButtons"
             class="mb-4"
             :key="`button-${b}`">
          <base-button
            v-if="button.url"
            :url="button.url"
            :label="button.text"
            :target="button.target"
            class_names="button"
            size="smaller"
            color="red"
          ></base-button>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  props: ["sessions"],
  components: {
    BaseButton: () => import(/* webpackChunkName: "base-button" */ "../components/BaseButton.vue"),
    BaseHeading: () => import(/* webpackChunkName: "base-heading" */ "../components/BaseHeading.vue"),
    BaseRichText: () => import(/* webpackChunkName: "base-rich-text" */ "../components/BaseRichText.vue")
  }
};
</script>