var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("header", { staticClass: "text-white product-header bg-green" }, [
    _c("div", { staticClass: "container mx-auto px-5-safe" }, [
      _c(
        "div",
        { staticClass: "pt-6 pb-8 lg:pt-8 lg:pb-10 xl:pt-10 xl:pb-py-12" },
        [
          _c("div", { staticClass: "items-start lg:flex" }, [
            _c(
              "div",
              { staticClass: "mb-8 xl:pr-12 lg:mb-0 lg:w-1/2 xl:w-2/3" },
              [
                _c(
                  "base-heading",
                  { attrs: { size: "h1", uppercase: true, color: "white" } },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.product_name) +
                        "\n          "
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "base-rich-text",
                  { attrs: { size: "larger", class_names: "mt-2" } },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.product_cont) +
                        "\n          "
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "base-rich-text",
                  { attrs: { class_names: "mb-6 text-white" } },
                  [_vm._t("suggested_product")],
                  2
                ),
                _vm._v(" "),
                _c(
                  "base-heading",
                  {
                    attrs: {
                      size: "h2",
                      color: "brown-lighter",
                      class_names: "mt-3"
                    }
                  },
                  [
                    _vm._v(
                      "\n            " + _vm._s(_vm.subheading) + "\n          "
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "base-rich-text",
                  { attrs: { size: "larger", class_names: "mt-5" } },
                  [_vm._t("description")],
                  2
                ),
                _vm._v(" "),
                _vm.product_versions && _vm.product_versions.length
                  ? _c("product-version", {
                      attrs: {
                        product_versions: _vm.product_versions,
                        product_versions_heading: _vm.product_versions_heading
                      }
                    })
                  : _vm._e()
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "lg:w-1/2 xl:w-1/3 lg:pl-12 xl:pl-10" },
              [
                _vm.is_purchasable === "true"
                  ? _c(
                      "add-to-cart-box",
                      [
                        _c(
                          "template",
                          { slot: "price_footnote" },
                          [_vm._t("price_footnote")],
                          2
                        ),
                        _vm._v(" "),
                        _c(
                          "template",
                          { slot: "bulk_prices_text" },
                          [_vm._t("bulk_prices_text")],
                          2
                        )
                      ],
                      2
                    )
                  : _c(
                      "div",
                      {
                        staticClass:
                          "relative z-30 px-5 pt-5 pb-4 text-white shadow-md box bg-brown"
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "text-3xl font-bold lg:text-4xl" },
                          [
                            _vm._v(
                              "\n              This product is currently unavailable.\n            "
                            )
                          ]
                        )
                      ]
                    )
              ],
              1
            )
          ])
        ]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }