<template>
  <section
    class="block icon-columns md:py-8 lg:py-16 xl:py-32 bg-cover"
    :style="sectionStyles"
    v-lazy:background-image="backgroundSrc"
  >
    <div class="md:hidden text-white text-3xl font-bold text-center py-9 px-5-safe" v-html="heading"></div>
    <div :class="[
            include_container ? 'container mx-auto px-5-safe' : 'full-container',
            'relative'
         ]">
      <div class="absolute w-full h-full left-0 top-0 bg-brown-lighter bg z-10"></div>
      <div class="px-6 py-8 md:px-12 md:pt-8 md:pb-10 text-white relative z-20">
        <base-heading size="h1" class_names="hidden md:block" :centered="true">
          {{ heading }}
        </base-heading>
        <carousel
          :per-page="1"
          :mouse-drag="true"
          :perPageCustom="[[768, 3]]"
          paginationActiveColor="#fff"
          paginationColor="transparent"
          class="carousel md:mt-8"
        >
          <slide v-for="(column, i) in columns" :key="i" class="text-center px-5">
            <div v-show="column.src !== ''">
              <base-image
                class_names="mx-auto"
                :src="column.src"
                :retina_src="column.retinaSrc"
                :srcset_webp="column.webpSrcset"
                :alt="column.alt"
              ></base-image>
            </div>
            <base-heading size="h2" v-show="column.heading !== ''" :class_names="['text-brown', column.src !== '' ? 'mt-4' : '']">
              {{ column.heading }}
            </base-heading>
            <div
              v-show="column.text !== ''"
              :class="[
                'text-xl xl:text-2xl leading-compact lg:leading-normal',
                column.src !== '' || column.heading !== '' ? 'mt-2' : ''
              ]"
              v-html="column.text"
            ></div>
          </slide>
        </carousel>
      </div>
    </div>
  </section>
</template>

<script>
import { Carousel, Slide } from "vue-carousel";
import HelpersMixin from "../mixins/Helpers";
// @group Blocks
export default {
  props: ["background", "background_webp", "background_focal_point", "heading", "columns", "include_container"],
  mixins: [HelpersMixin],
  components: {
    BaseHeading: () => import(/* webpackChunkName: "base-heading" */ "../components/BaseHeading.vue"),
    BaseImage: () => import(/* webpackChunkName: "base-image" */ "../components/BaseImage.vue"),
    Carousel,
    Slide
  },
  computed: {
    sectionStyles() {
      return this.backgroundPosition(this.background_focal_point);
    },
    backgroundSrc() {
      if (this.canUseWebP() && this.background_webp) {
        return this.background_webp;
      }
      return this.background;
    }
  }
};
</script>

<style scoped>
.bg {
  opacity: 0.85;
}

@media (min-width: 768px) {
  .carousel {
    margin-left: -20px;
    margin-right: -20px;
  }
}
</style>
