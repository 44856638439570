<template>
  <section class="block logos-bar white-block logos-bar">
    <div :class="[
            include_container ? 'container mx-auto' : 'full-container',
            'relative pt-8 pb-4 xl:py-10'
         ]">
      <div v-show="heading !== ''" v-html="heading" class="text-brown font-bold text-2xl md:text-3xl lg:text-4xl text-center"></div>
      <div :class="['text-center px-4-safe', heading !== '' ? 'mt-5' : '']">
        <div
          v-for="(item, i) in logos"
          :class="['inline-block mb-4', i + 1 < logos.length ? 'mr-4 md:mr-6 lg:mr-8' : '', i > 0 ? 'ml-4 md:ml-6 lg:ml-8' : '']"
        >
          <div v-show="item.src !== ''">
            <a :href="item.url" :target="item.target">
              <base-image :src="item.src" :retina_src="item.retinaSrc" :srcset_webp="item.webpSrcset" :alt="item.alt"></base-image>
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
// @group Blocks

export default {
  props: ["heading", "logos", "include_container"],
  components: {
    BaseImage: () => import(/* webpackChunkName: "base-image" */ "../components/BaseImage.vue")
  }
};
</script>

<style scoped>
section img {
  height: 50px;
}
@media (min-width: 576px) {
  section img {
    height: 75px;
  }
}
@media (min-width: 768px) {
  section img {
    height: 100px;
  }
}
</style>
