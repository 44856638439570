<template>
  <div v-if="!results || results.length === 0">
    <div class="no-results w-full rounded-lg bg-grey-lighter text-center py-20">
      <div class="text-3xl uppercase text-grey-dark">
        No results found
      </div>
      <div class="mt-12" v-if="filters">
        <button
          @click="resetFilters"
          class="button inline-block uppercase tr-all rounded text-lg xl:text-xl font-bold leading-tighter text-center cursor-pointer text-white bg-red hover:bg-red-hover px-4 py-2"
        >
          Reset Filters
        </button>
      </div>
      <div v-if="keyword">
        <div class="text-xl uppercase text-grey-dark mt-2 leading-normal">
          with keyword
          <span class="italic">`{{ keyword }}`</span>
        </div>
        <div class="mt-12">
          <button
            @click="resetKeyword"
            class="button inline-block uppercase tr-all rounded text-lg xl:text-xl font-bold leading-tighter text-center cursor-pointer text-white bg-red hover:bg-red-hover px-4 py-2"
          >
            Reset Search
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["results", "keyword", "filters"],
  methods: {
    resetKeyword() {
      this.$emit("resetKeyword");
    },
    resetFilters() {
      this.$emit("resetFilters");
    }
  }
};
</script>
