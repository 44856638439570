<template>
  <div class="grant-program-content">
    <div v-for="(block, b) in $parent.content_blocks" :key="b">
      <component :is="'GrantProgramBlock' + capitalizeFirstLetter(block.type)" :block="block"></component>
    </div>
  </div>
</template>

<script>
import HelpersMixin from "../mixins/Helpers";

export default {
  mixins: [HelpersMixin],
  components: {
    GrantProgramBlockAlignedImage: () =>
      import(/* webpackChunkName: "grant-program-block-aligned-image" */ "./GrantProgramBlockAlignedImage.vue"),
    GrantProgramBlockApplyDeadlines: () =>
      import(/* webpackChunkName: "grant-program-block-apply-deadlines" */ "./GrantProgramBlockApplyDeadlines.vue"),
    GrantProgramBlockHeading: () => import(/* webpackChunkName: "grant-program-block-heading" */ "./GrantProgramBlockHeading.vue"),
    GrantProgramBlockHorizontalLine: () =>
      import(/* webpackChunkName: "grant-program-block-horizontal-line" */ "./GrantProgramBlockHorizontalLine.vue"),
    GrantProgramBlockRichText: () => import(/* webpackChunkName: "grant-program-block-rich-text" */ "./GrantProgramBlockRichText.vue")
  }
};
</script>
