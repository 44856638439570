var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "block posts white-block" }, [
    _c(
      "div",
      {
        class: [
          _vm.include_container
            ? "container mx-auto px-5-safe"
            : "full-container",
          "pt-10 pb-14"
        ]
      },
      [
        _vm.data.heading
          ? _c("base-heading", { attrs: { size: "h1", color: "brown" } }, [
              _vm._v("\n            " + _vm._s(_vm.data.heading) + "\n        ")
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.data.subheading
          ? _c("base-rich-text", {
              attrs: { class_names: "mt-2" },
              domProps: { innerHTML: _vm._s(_vm.data.subheading) }
            })
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          {
            class: [
              _vm.data.heading || _vm.data.subheading ? "mt-8" : "",
              "flex justify-between flex-wrap"
            ]
          },
          _vm._l(_vm.data.posts, function(post, p) {
            return _c(
              "div",
              {
                key: "post_" + p,
                staticClass: "mx-auto md:mx-0 md:w-p48 lg:w-p31 mb-8"
              },
              [
                _c("base-image", {
                  attrs: {
                    src: post.src,
                    retina_src: post.retina,
                    srcset_webp: post.webpSrcset,
                    class_names: "mx-auto",
                    alt: post.alt,
                    url: post.url
                  }
                }),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "mt-3" },
                  [
                    post.subheading
                      ? _c(
                          "base-heading",
                          {
                            attrs: {
                              size: "h4",
                              color: "brown-lighter",
                              uppercase: true
                            }
                          },
                          [
                            _vm._v(
                              "\n                        " +
                                _vm._s(post.subheading) +
                                "\n                    "
                            )
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "base-heading",
                      {
                        attrs: {
                          size: "h2",
                          color: "brown",
                          hover_color: "red",
                          class_names: post.subheading ? "mt-1" : "",
                          url: post.url
                        }
                      },
                      [
                        _vm._v(
                          "\n                        " +
                            _vm._s(post.title) +
                            "\n                    "
                        )
                      ]
                    )
                  ],
                  1
                )
              ],
              1
            )
          }),
          0
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }