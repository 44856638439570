var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "block logos-bar white-block logos-bar" },
    [
      _c(
        "div",
        {
          class: [
            _vm.include_container ? "container mx-auto" : "full-container",
            "relative pt-8 pb-4 xl:py-10"
          ]
        },
        [
          _c("div", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.heading !== "",
                expression: "heading !== ''"
              }
            ],
            staticClass:
              "text-brown font-bold text-2xl md:text-3xl lg:text-4xl text-center",
            domProps: { innerHTML: _vm._s(_vm.heading) }
          }),
          _vm._v(" "),
          _c(
            "div",
            {
              class: ["text-center px-4-safe", _vm.heading !== "" ? "mt-5" : ""]
            },
            _vm._l(_vm.logos, function(item, i) {
              return _c(
                "div",
                {
                  class: [
                    "inline-block mb-4",
                    i + 1 < _vm.logos.length ? "mr-4 md:mr-6 lg:mr-8" : "",
                    i > 0 ? "ml-4 md:ml-6 lg:ml-8" : ""
                  ]
                },
                [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: item.src !== "",
                          expression: "item.src !== ''"
                        }
                      ]
                    },
                    [
                      _c(
                        "a",
                        { attrs: { href: item.url, target: item.target } },
                        [
                          _c("base-image", {
                            attrs: {
                              src: item.src,
                              retina_src: item.retinaSrc,
                              srcset_webp: item.webpSrcset,
                              alt: item.alt
                            }
                          })
                        ],
                        1
                      )
                    ]
                  )
                ]
              )
            }),
            0
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }