var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "utility-nav container mx-auto px-5 text-right" },
    [
      _c(
        "nav",
        {
          class: [
            "font-bold text-lg xl:text-xl",
            "header-" + _vm.$parent.header_style
          ]
        },
        [
          _c(
            "ul",
            [
              _c(
                "li",
                {
                  class: [
                    "inline-block relative py-3",
                    _vm.$parent.header_style === "dark"
                      ? "pl-0 pr-9"
                      : "pl-4 pr-12"
                  ],
                  style: _vm.searchLiStyles
                },
                [
                  _c("form", { attrs: { method: "get", action: "/search" } }, [
                    _c("input", {
                      staticClass: "text-lg",
                      staticStyle: { width: "170px" },
                      style: _vm.searchInputStyles,
                      attrs: {
                        type: "text",
                        name: "keyword",
                        placeholder: "Search our site",
                        autocomplete: "off"
                      },
                      on: {
                        focus: function($event) {
                          _vm.searchFieldFocused = true
                        },
                        blur: function($event) {
                          _vm.searchFieldFocused = false
                        }
                      }
                    }),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        class: [
                          "tr-color",
                          _vm.$parent.header_style === "dark"
                            ? "text-white hover:text-brown-lighter"
                            : "text-brown hover:text-red"
                        ],
                        attrs: { type: "submit" }
                      },
                      [
                        _c(
                          "base-icon",
                          {
                            class: [
                              "centered-y right-0",
                              _vm.$parent.header_style === "dark"
                                ? "mr-0"
                                : "mr-3"
                            ],
                            attrs: {
                              "icon-name": "search",
                              width: "28",
                              height: "28",
                              viewbox: "0 0 28 28"
                            }
                          },
                          [_c("icon-search")],
                          1
                        )
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _vm.$parent.header_style === "dark"
                    ? _c("span", {
                        class: [
                          "search-underline absolute w-full bg-white left-0 bottom-0",
                          !_vm.searchFieldFocused ? "opacity-25" : ""
                        ]
                      })
                    : _vm._e()
                ]
              ),
              _vm._v(" "),
              _vm._l(_vm.utilityNavItems, function(item, i) {
                return _c(
                  "li",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: item.display.value !== "mobileOnly",
                        expression: "item.display.value !== 'mobileOnly'"
                      }
                    ],
                    key: i,
                    class: [
                      "inline-block py-3 px-5",
                      item.navHighlight ? "bg-red" : ""
                    ]
                  },
                  [
                    _c(
                      "a",
                      {
                        class: [
                          "u relative block uppercase",
                          _vm.$parent.text_color
                        ],
                        attrs: { href: item.url, target: item.target }
                      },
                      [
                        _c(
                          "span",
                          {
                            class: [
                              "text inline-block relative",
                              item.navHighlight ? "text-white" : ""
                            ]
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(item.text) +
                                "\n          "
                            )
                          ]
                        )
                      ]
                    )
                  ]
                )
              }),
              _vm._v(" "),
              _c(
                "li",
                {
                  staticClass: "cart-btn relative inline-block ml-6",
                  staticStyle: { height: "47px" }
                },
                [
                  _c(
                    "a",
                    { attrs: { href: "/shop/cart" } },
                    [
                      _c(
                        "base-icon",
                        {
                          attrs: {
                            "icon-name": "cart",
                            width: "28",
                            height: "28",
                            viewbox: "0 0 28 28"
                          }
                        },
                        [_c("icon-cart")],
                        1
                      ),
                      _vm._v(" "),
                      _c("span", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.$root.totalCartQuantity > 0,
                            expression: "$root.totalCartQuantity > 0"
                          }
                        ],
                        staticClass: "block circle bg-red"
                      })
                    ],
                    1
                  )
                ]
              )
            ],
            2
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }