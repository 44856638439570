<template>
    <section class="block posts white-block">
        <div :class="[
                include_container ? 'container mx-auto px-5-safe' : 'full-container',
                'pt-10 pb-14'
             ]">
            <base-heading v-if="data.heading" size="h1" color="brown">
                {{ data.heading }}
            </base-heading>
            <base-rich-text v-if="data.subheading" class_names="mt-2" v-html="data.subheading"></base-rich-text>
            <div :class="[
                data.heading || data.subheading ? 'mt-8' : '',
                'flex justify-between flex-wrap'
            ]">
                <div
                    v-for="(post, p) in data.posts"
                    :key="'post_' + p"
                    class="mx-auto md:mx-0 md:w-p48 lg:w-p31 mb-8"
                >
                    <base-image
                        :src="post.src"
                        :retina_src="post.retina"
                        :srcset_webp="post.webpSrcset"
                        class_names="mx-auto"
                        :alt="post.alt"
                        :url="post.url"
                    ></base-image>
                    <div class="mt-3">
                        <base-heading
                            v-if="post.subheading"
                            size="h4"
                            color="brown-lighter"
                            :uppercase="true">
                            {{ post.subheading }}
                        </base-heading>
                        <base-heading
                            size="h2"
                            color="brown"
                            hover_color="red"
                            :class_names="post.subheading ? 'mt-1' : ''"
                            :url="post.url">
                            {{ post.title }}
                        </base-heading>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
  // @group Blocks
  export default {
    props: ["data", "include_container"],
    components: {
      BaseRichText: () => import(/* webpackChunkName: "base-rich-text" */ "../components/BaseRichText.vue"),
      BaseHeading: () => import(/* webpackChunkName: "base-heading" */ "../components/BaseHeading.vue"),
      BaseImage: () => import(/* webpackChunkName: "base-image" */ "../components/BaseImage.vue")
    }
  };
</script>
