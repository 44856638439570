<template>
  <section class="block expandable-icon-blocks bg-cover" :style="sectionStyles" v-lazy:background-image="backgroundSrc">
    <div :class="include_container ? 'container mx-auto px-5-safe' : 'full-container'">
      <div class="lg:w-2/3">
        <div class="blocks text-brown">
          <div v-for="(item, i) in blocks">
            <button
              @click="isActive === i ? (isActive = null) : (isActive = i)"
              :class="[
                'block relative w-full cursor-pointer text-left px-5 md:pl-7 lg:pl-10 xl:pl-12 pr-24 py-4 lg:py-6 font-bold text-xl lg:text-2xl xl:text-3xl',
                headingColors[i],
                i === 3 ? 'text-brown' : 'text-white',
                isActive === i ? 'active' : ''
              ]"
            >
              {{ item.heading }}
              <base-icon
                icon-name="angle-down-alt"
                width="14"
                height="7"
                viewbox="0 0 41 23"
                class="absolute angle centered-y right-0 mr-5 md:mr-7 lg:mr-10 xl:mr-12"
              >
                <icon-angle-down-alt></icon-angle-down-alt>
              </base-icon>
            </button>
            <slide-up-down :active="isActive === i" :duration="350">
              <div class="px-5 md:px-7 lg:px-10 xl:px-12 py-4 lg:py-6 text-lg leading-normal lg:text-xl xl:text-2hxl leading-normal">
                <div :class="[item.src !== '' ? 'flex items-start' : '']">
                  <div class="w-1/3 pt-8" v-show="item.src !== ''">
                    <base-image :src="item.src" :retina_src="item.retinaSrc" :alt="item.alt"></base-image>
                  </div>
                  <div :class="[item.src !== '' ? 'w-2/3 pl-5' : '']" v-html="item.text"></div>
                </div>
              </div>
            </slide-up-down>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import HelpersMixin from "../mixins/Helpers";
// @group Blocks
export default {
  props: ["background", "background_webp", "background_focal_point", "blocks", "active_index", "include_container"],
  mixins: [HelpersMixin],
  data: function() {
    return {
      isActive: parseInt(this.active_index),
      headingColors: ["bg-brown", "bg-brown-light", "bg-brown-lighter", "bg-brown-lightest"]
    };
  },
  components: {
    BaseImage: () => import(/* webpackChunkName: "base-image" */ "../components/BaseImage.vue"),
    BaseIcon: () => import(/* webpackChunkName: "base-icon" */ "../components/BaseIcon.vue"),
    IconAngleDownAlt: () => import(/* webpackChunkName: "icon-angle-down-alt" */ "../icons/IconAngleDownAlt.vue")
  },
  computed: {
    sectionStyles() {
      return this.backgroundPosition(this.background_focal_point);
    },
    backgroundSrc() {
      if (this.canUseWebP() && this.background_webp) {
        return this.background_webp;
      }
      return this.background;
    }
  }
};
</script>

<style scoped>
.blocks {
  background: rgba(236, 210, 171, 0.95);
}
svg.angle {
  width: 25px;
  height: 15px;
  transform-origin: 19px 4px;
}
button.active {
  svg.angle {
    transform: scaleY(-1);
  }
}
@media (min-width: 768px) {
  svg.angle {
    transform-origin: 19px 6px;
    width: 38px;
    height: 18px;
  }
}
</style>
