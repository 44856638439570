<template>
  <section class="author-profile pt-6 pb-6 lg:pb-12">
    <div class="container px-5 mx-auto">
      <div class="sm:flex">
        <div class="sm:w-1/3 md:w-p40 polaroid lg:pl-6 xl:pl-12" v-if="photo_src !== ''">
          <div class="inline-block mx-auto">
            <base-image :src="photo_src" :retina_src="photo_retina_src" :srcset_webp="photo_webp_srcset" :alt="photo_alt"></base-image>
          </div>
        </div>
        <div class="sm:pl-6 lg:pl-0 sm:w-2/3 md:w-p60 lg:pr-6 xl:pr-12">
          <div class="hidden sm:block mb-1 md:mb-2 lg:mb-3">
            <img src="/img/author-profile-arrow.png" alt="" class="arrow" />
          </div>
          <div
            class="text-red font-cursive leading-loose text-center mt-6 sm:mt-0 sm:text-left text-xl lg:text-2xl lg:pl-10 xl:pl-0 xl:text-2xl"
          >
            That's me, {{ first_name }} {{ last_name }}
          </div>
          <div class="lg:pl-12 xl:pl-20 pt-4 lg:pt-6 xl:pt-10">
            <div v-for="(row, r) in details" :key="r" :class="['py-3', r > 0 ? 'border-t border-brown' : '']">
              <div
                class="text-base lg:text-lg xl:text-xl text-brown-lighter font-bold uppercase"
                v-if="row.heading !== ''"
                v-html="row.heading"
              ></div>
              <div
                :class="['text-lg lg:text-xl xl:text-2xl text-brown', row.heading !== '' ? 'mt-1' : '']"
                v-if="row.text !== ''"
                v-html="row.text"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: ["first_name", "last_name", "photo_src", "photo_retina_src", "photo_webp_srcset", "photo_alt", "details"],
  components: {
    BaseImage: () => import(/* webpackChunkName: "base-image" */ "../components/BaseImage.vue")
  }
};
</script>

<style scoped>
.polaroid div {
  margin-top: 60px;
  transform: rotate(-2deg);
  border-color: #e9ebf7;
  border-style: solid;
  border-width: 10px 10px 45px;
  box-shadow: 0 1px 4px 1px rgba(0, 0, 0, 0.4);
}
.arrow {
  width: 60px;
  height: 40px;
  margin-left: -20px;
}
@media (max-width: 575px) {
  .polaroid {
    max-width: 200px;
    margin-left: auto;
    margin-right: auto;
    div {
      margin-top: 0;
    }
  }
}
@media (min-width: 768px) {
  .polaroid div {
    margin-top: 90px;
    transform: rotate(-3deg);
    border-width: 16px 16px 90px;
  }
  .arrow {
    width: 100px;
    height: 70px;
    margin-left: -30px;
  }
}
@media (min-width: 1280px) {
  .polaroid div {
    margin-top: 110px;
    margin-left: 0;
    transform: rotate(-4deg);
    border-width: 16px 16px 90px;
  }
  .arrow {
    width: 135px;
    height: 94px;
    margin-left: -30px;
  }
}
</style>
