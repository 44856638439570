<template>
  <section
    :class="['sidebar-facebook-button sidebar-block text-xl max-w-sm mx-auto', !is_first ? 'mt-5' : '']"
    v-if="block.facebookLink"
  >
    <div class="inline-flex items-center">
      <div>
        <social-icon :url="block.facebookLink" type="facebook" class_names="inline-block" icon_size="40"></social-icon>
      </div>
      <div class="pl-3">
        <a :href="block.facebookLink">
          {{ block.linkText ? block.linkText : "Find us on Facebook" }}
        </a>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: ["block", "is_first"],
  components: {
    SocialIcon: () => import(/* webpackChunkName: "social-icon" */ "../components/SocialIcon.vue")
  }
};
</script>

<style scoped>
section,
section a {
  color: #1982f4;
}
</style>
