<template>
  <div :class="[
          include_container ? 'container mx-auto px-5-safe' : 'full-container',
          'block white-block image-block'
       ]">
    <span :class="['table w-auto mb-10', 'max-width-' + data.maximumWidth, 'position-' + data.position]">
      <base-image
        :src="data.src"
        :retina_src="data.retina"
        :alt="data.alt"
        :url="data.url"
        :target="data.target ? '_blank' : null"
      ></base-image>
      <span v-if="data.hasCaption" class="caption text-base mt-2 leading-normal"><slot></slot></span>
    </span>
  </div>
</template>

<script>
export default {
  props: ["data", "include_container"],
  components: {
    BaseImage: () => import(/* webpackChunkName: "base-image" */ "../components/BaseImage.vue")
  }
};
</script>

<style scoped>
.caption {
  display: table-caption;
  caption-side: bottom;
}
.max-width-full {
  margin-left: auto;
  margin-right: auto;
}
.max-width-half {
  @media (max-width: 575px) {
    margin-left: auto;
    margin-right: auto;
  }
  @media (min-width: 576px) {
    max-width: 50%;
    &.position-left {
      float: left;
      margin-right: 40px;
    }
    &.position-right {
      float: right;
      margin-left: 40px;
    }
    &.position-center {
      margin-left: auto;
      margin-right: auto;
    }
  }
}
.max-width-third {
  @media (max-width: 991px) {
    margin-left: auto;
    margin-right: auto;
  }
  @media (min-width: 992px) {
    max-width: 33%;
    &.position-left {
      float: left;
      margin-right: 40px;
    }
    &.position-right {
      float: right;
      margin-left: 40px;
    }
    &.position-center {
      margin-left: auto;
      margin-right: auto;
    }
  }
}
.max-width-quarter {
  @media (max-width: 767px) {
    margin-left: auto;
    margin-right: auto;
  }
  @media (min-width: 768px) {
    max-width: 25%;
    &.position-left {
      float: left;
      margin-right: 40px;
    }
    &.position-right {
      float: right;
      margin-left: 40px;
    }
    &.position-center {
      margin-left: auto;
      margin-right: auto;
    }
  }
}
</style>
