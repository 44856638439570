<template>
  <div class="cta-subscribe bg-green py-12 text-white">
    <div class="container px-5-safe mx-auto">
      <div class="text-center">
        <base-heading size="h2" color="white">
          {{ heading }}
        </base-heading>
        <div v-if="success" class="rich-text text-xl mt-8 text-center leading-normal text-links-on-brown">
          <div v-if="has_thank_you_text">
            <slot name="thanks"></slot>
          </div>
          <div v-else>
            <p>Thank you for signing up to our mailing list.</p>
          </div>
        </div>
      </div>
      <div v-if="!success">
        <div class="mt-4 rich-text text-lg lg:text-xl text-center" v-if="has_text">
          <slot name="text"></slot>
        </div>
        <div class="mt-10 max-w-lg mx-auto text-left text-lg">
          <div class="sm:flex sm:justify-between sm:flex-wrap">
            <div class="sm:w-p48 mb-5">
              <form-input input_type="text" placeholder="First Name" name="fname" v-model="fname" :disabled="isLoading"></form-input>
            </div>
            <div class="sm:w-p48 mb-5">
              <form-input input_type="text" placeholder="Last Name" name="lname" v-model="lname" :disabled="isLoading"></form-input>
            </div>
            <div class="w-full mb-5">
              <form-input input_type="text" placeholder="Email *" name="email" v-model="email" :disabled="isLoading"></form-input>
            </div>
            <div class="sm:w-p48 mb-5">
              <form-input
                input_type="text"
                placeholder="Organization name"
                name="orgname"
                v-model="orgname"
                :disabled="isLoading"
              ></form-input>
            </div>
            <div class="sm:w-p48 mb-5">
              <form-select
                name="orgtype"
                title="Organization type *"
                :options="org_types"
                v-model="orgtype"
                :disabled="isLoading"
              ></form-select>
            </div>
            <div class="sm:w-p48 mb-5">
              <div class="select-wrapper relative">
                <form-select name="country" title="Country" :options="countries" v-model="country" :disabled="isLoading"></form-select>
              </div>
            </div>
            <div class="sm:w-p48 mb-5">
              <div class="select-wrapper relative">
                <form-input
                  input_type="text"
                  placeholder="How did you hear about us?"
                  name="referred"
                  v-model="referred"
                  :disabled="isLoading"
                ></form-input>
              </div>
            </div>
          </div>
          <div class="mt-4 text-lg leading-normal" v-if="errors.length > 0 || message">
            <span v-for="error in errors"> {{ error }}<br /></span>
            <span v-if="message" v-html="message"></span>
          </div>
          <div class="mt-6 text-center">
            <button
              type="button"
              @click.prevent="submit()"
              :disabled="isLoading"
              :class="[
                'relative bg-white font-bold uppercase rounded border-4 text-xl py-3 tr-color tr-bg hover:bg-grey-lighter',
                isLoading ? 'pl-14 pr-10 text-grey border-grey' : 'px-10 text-brown border-brown hover:text-black hover:bg-grey-light'
              ]"
            >
              <base-icon
                icon-name="loading"
                width="28"
                height="28"
                viewbox="0 0 32 32"
                class="loading absolute ml-3 left-0 centered-y"
                v-show="isLoading"
              >
                <icon-loading></icon-loading>
              </base-icon>
              <span class="inline">Subscribe</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueAxios from "../../js/modules/vue-axios";

export default {
  data: function() {
    return {
      fname: "",
      lname: "",
      email: "",
      orgname: "",
      orgtype: "",
      country: "",
      referred: "",
      tsb_mini: false,
      tsb_sm4: false,
      tsb_emt2: false,
      tsb_kpro: false,
      errors: [],
      success: false,
      message: null,
      isLoading: false
    };
  },
  props: ["heading", "has_text", "has_thank_you_text", "countries", "org_types"],
  components: {
    BaseHeading: () => import(/* webpackChunkName: "base-heading" */ "./BaseHeading.vue"),
    FormInput: () => import(/* webpackChunkName: "form-input" */ "./form/FormInput.vue"),
    FormCheckbox: () => import(/* webpackChunkName: "form-input" */ "./form/FormCheckbox.vue"),
    FormRadioGroup: () => import(/* webpackChunkName: "form-radio-group" */ "./form/FormRadioGroup.vue"),
    FormSelect: () => import(/* webpackChunkName: "form-select" */ "./form/FormSelect.vue"),
    BaseIcon: () => import(/* webpackChunkName: "base-icon" */ "../components/BaseIcon.vue"),
    IconLoading: () => import(/* webpackChunkName: "icon-loading" */ "../icons/IconLoading.vue")
  },
  methods: {
    submit() {
      let self = this;
      self.errors = [];
      if (self.email === "") {
        self.errors.push("Please enter your Email Address.");
      }
      if (self.orgtype === "") {
        self.errors.push("Please specify an organization type.");
      }
      if (self.country === "") {
        self.errors.push("Please specify a country.");
      }
      if (self.errors.length === 0) {
        self.isLoading = true;
        let formData = new FormData();
        formData.append(self.$root.csrfTokenName, self.$root.csrfTokenValue);
        formData.append("fname", self.fname);
        formData.append("lname", self.lname);
        formData.append("email", self.email);
        formData.append("orgname", self.orgname);
        formData.append("orgtype", self.orgtype);
        formData.append("country", self.country);
        formData.append("referred", self.referred);
        formData.append("tsb_mini", self.tsb_mini);
        formData.append("tsb_kpro", self.tsb_kpro);
        formData.append("tsb_emt2", self.tsb_emt2);
        formData.append("tsb_sm4", self.tsb_sm4);
        formData.append("action", "wildlife/mail-list/signup");
        let options = {
          method: "POST",
          data: formData,
          url: "/"
        };
        VueAxios(options)
          .then(response => {
            let data = response.data || null;
            self.isLoading = false;
            if (data.success) {
              self.success = true;
            } else {
              self.message = data.message || null;
            }
          })
          .catch(error => {
            self.message = "An error occurred. Please try again.";
            self.isLoading = false;
          });
      }
    }
  }
};
</script>
