var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    {
      class: [
        "block image-columns",
        _vm.background_color === "brown-tint" ? "bg-brown-tint" : ""
      ]
    },
    [
      _c(
        "div",
        {
          class: [
            _vm.include_container
              ? "container mx-auto px-5-safe"
              : "full-container",
            "relative py-8 lg:py-10 xl:py-16"
          ]
        },
        [
          _c(
            "h1",
            {
              class: [
                "font-bold",
                "text-brown text-center",
                "text-xl md:text-4xl lg:text-6xl xl:text-8xl",
                _vm.heading_centered ? "" : "lg:text-left"
              ]
            },
            [_vm._v("\n      " + _vm._s(_vm.heading) + "\n    ")]
          ),
          _vm._v(" "),
          _vm.columns.length > 0
            ? _c(
                "div",
                {
                  class: [
                    "md:flex md:justify-around md:flex-wrap",
                    _vm.heading ? "mt-10" : "",
                    _vm.columns.length === 2 ? "max-w-3xl mx-auto" : ""
                  ]
                },
                _vm._l(_vm.columns, function(column, i) {
                  return _c(
                    "div",
                    {
                      key: i,
                      class: [
                        "max-w-sm mb-8 mx-auto relative md:max-w-full md:px-6",
                        _vm.max_columns_per_row === 2 ? "md:w-1/2" : "",
                        _vm.max_columns_per_row === 3
                          ? "md:w-1/2 lg:w-1/3"
                          : "",
                        _vm.max_columns_per_row === 4
                          ? "md:w-1/2 lg:w-1/4"
                          : "",
                        _vm.column_padding,
                        "bg-" + _vm.columns_background_color,
                        _vm.columns_text_centered ? "text-center" : "text-left"
                      ]
                    },
                    [
                      _c("div", { staticClass: "flex flex-col h-full" }, [
                        column.src
                          ? _c(
                              "div",
                              {
                                ref: ["block_" + _vm.block_id + "_image"],
                                refInFor: true,
                                staticClass: "matchheight"
                              },
                              [
                                _c("base-image", {
                                  attrs: {
                                    src: column.src,
                                    retina_src: column.retinaSrc,
                                    srcset_webp: column.webpSrcset,
                                    class_names: "mx-auto",
                                    alt: column.alt,
                                    width: column.width,
                                    height: column.height
                                  }
                                })
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        column.leading_heading || column.heading
                          ? _c(
                              "div",
                              {
                                ref: ["block_" + _vm.block_id + "_heading"],
                                refInFor: true,
                                class: [
                                  column.src ? "mt-6" : "",
                                  _vm.heading_centered ? "text-center" : ""
                                ]
                              },
                              [
                                column.leading_heading
                                  ? _c(
                                      "base-heading",
                                      {
                                        attrs: {
                                          size: "h4",
                                          uppercase: true,
                                          color: "green"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(column.leading_heading) +
                                            "\n            "
                                        )
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                column.heading
                                  ? _c(
                                      "base-heading",
                                      {
                                        attrs: {
                                          size: "h2",
                                          color: _vm.text_color,
                                          class_names:
                                            column.leading_heading !== ""
                                              ? "mt-1"
                                              : ""
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(column.heading) +
                                            "\n            "
                                        )
                                      ]
                                    )
                                  : _vm._e()
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        column.text
                          ? _c(
                              "div",
                              {
                                ref: ["block_" + _vm.block_id + "_text"],
                                refInFor: true,
                                class: ["mt-3", "text-" + _vm.text_color]
                              },
                              [
                                _c("base-rich-text", {
                                  attrs: {
                                    class_names:
                                      column.src || column.heading
                                        ? "mt-2"
                                        : "",
                                    size: "small"
                                  },
                                  domProps: { innerHTML: _vm._s(column.text) }
                                })
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        column.footnotes !== ""
                          ? _c(
                              "div",
                              { staticClass: "mt-4 red-text-links" },
                              [
                                _c("base-rich-text", {
                                  attrs: { size: "tiny" },
                                  domProps: {
                                    innerHTML: _vm._s(column.footnotes)
                                  }
                                })
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            class: [
                              "flex flex-col mt-6 w-full " + _vm.columns.length,
                              _vm.bottom_align_buttons
                                ? "flex-grow justify-end"
                                : "",
                              _vm.bottom_align_buttons &&
                              _vm.columns_background_color === "brown-lighter"
                                ? "px-6 pb-6"
                                : "",
                              _vm.buttons_alignment === "leftAligned"
                                ? "items-start"
                                : "items-center"
                            ]
                          },
                          [
                            _vm.columnButtons(column).length > 0
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "flex flex-wrap justify-center -mb-4 -mx-3"
                                  },
                                  _vm._l(_vm.columnButtons(column), function(
                                    button,
                                    b
                                  ) {
                                    return _c(
                                      "div",
                                      {
                                        key: "column-" + i + "-" + b,
                                        class: [
                                          "px-3 mb-4",
                                          _vm.columns.length > 2 ? "w-full" : ""
                                        ]
                                      },
                                      [
                                        _c("base-button", {
                                          attrs: {
                                            url: button.url,
                                            label: button.text,
                                            target: button.target,
                                            color: _vm.buttons_color,
                                            outline:
                                              _vm.buttons_color === "brown",
                                            size:
                                              _vm.columns.length < 2
                                                ? "extrawide"
                                                : "",
                                            class_names:
                                              "whitespace-no-wrap" +
                                              (_vm.columns.length > 2
                                                ? " w-full"
                                                : "")
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  }),
                                  0
                                )
                              : _vm._e()
                          ]
                        )
                      ])
                    ]
                  )
                }),
                0
              )
            : _vm._e()
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }