var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "block product-content white-block" }, [
    _c(
      "div",
      {
        staticClass: "container mx-auto px-5-safe pt-6 pb-10 lg:pt-10 lg:pb-14"
      },
      [
        _vm.detail_sections_show_expand_all_links
          ? _c("div", { staticClass: "mb-6 text-2xl" }, [
              _c(
                "button",
                {
                  staticClass: "underline tr-color text-brown hover:text-red",
                  attrs: { type: "button" },
                  on: {
                    click: function($event) {
                      return _vm.toggleAllSections(true)
                    }
                  }
                },
                [_vm._v("\n        Expand All\n      ")]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass:
                    "underline tr-color text-brown hover:text-red ml-4",
                  attrs: { type: "button" },
                  on: {
                    click: function($event) {
                      return _vm.toggleAllSections(false)
                    }
                  }
                },
                [_vm._v("\n        Collapse All\n      ")]
              )
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("div", { class: _vm.has_sidebar_blocks ? "lg:flex" : "" }, [
          _c(
            "div",
            {
              class: _vm.has_sidebar_blocks
                ? "mb-10 lg:mb-0 lg:w-3/4 lg:pr-12 xl:pr-16"
                : ""
            },
            [_c("product-detail-sections", { attrs: { active: false } })],
            1
          ),
          _vm._v(" "),
          _vm.has_sidebar_blocks
            ? _c(
                "div",
                { staticClass: "lg:w-1/4" },
                [
                  _c("sidebar-blocks", {
                    attrs: { blocks: _vm.sidebar_blocks }
                  })
                ],
                1
              )
            : _vm._e()
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }