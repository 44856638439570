<template>
  <section class="block comparison-chart white-block">
    <div class="container px-5 mx-auto pt-8 lg:pt-12 pb-12 lg:pb-16">
      <div :class="[
              'chart mx-auto leading-normal',
              data.products.length === 3 ? 'max-w-3xl text-sm sm:text-base lg:text-lg xl:text-xl' : 'max-w-2xl text-lg lg:text-xl xl:text-2xl',
              'num-products-' + data.products.length
           ]">
        <div class="top-row flex flex-wrap">
          <div class="c1 border-r border-brown">&nbsp;</div>
          <div
            :class="[
                'c2 sm:border-t border-brown',
                'bg-brown-lighter tr-bg hover:bg-brown-light',
                p < (data.products.length - 1) ? 'border-r' : 'sm:border-r'
            ]"
            v-for="(product, p) in data.products"
            v-if="product"
            :key="`product-${p}`"
          >
            <a :href="product.url"
               :class="[
                 'block p-4 bg-white',
                 data.products.length === 3 ? 'px-2 lg:px-4' : 'px-4 lg:px-6'
               ]">
              <base-image
                :src="product.src"
                :retina_src="product.retinaSrc"
                :srcset_webp="product.webpSrcset"
                :alt="product.name"
              ></base-image>
            </a>
            <a
              :href="product.url"
              class="block text-white w-full font-bold pad"
            >
              {{ product.name }}
            </a>
          </div>
        </div>

        <div v-if="hasAudio" class="flex flex-wrap">
          <div class="c1 text-center sm:text-left font-bold py-2 lg:py-3 px-5 sm:pl-0 sm:pr-5 sm:lg:pr-7 border-b sm:border-r border-brown">
            AUDIO EXAMPLE
          </div>
          <div
            v-for="(product, p) in data.products"
            :class="[
              'c2 border-b border-brown pad',
              p < (data.products.length - 1) ? 'border-r' : 'sm:border-r'
              ]">
            <audio controls v-if="product.audio" class="max-w-full">
              <source :src="product.audio" type="audio/mpeg" />
              Your browser does not support the audio tag.
            </audio>
          </div>
        </div>

        <div v-for="(row, r) in data.rows"
             :key="`row-${r}`"
             class="flex flex-wrap">
          <div
              class="c1 text-center sm:text-left font-bold py-2 lg:py-3 px-5 sm:pl-0 sm:pr-5 sm:lg:pr-7 border-b sm:border-r border-brown"
              v-html="row[0]"
          ></div>
          <div
              v-if="data.products[0]"
              class="c2 rich-text border-b border-r border-brown pad"
              v-html="row[1]"
          ></div>
          <div
              v-if="data.products[1]"
              :class="[
                  'c2 rich-text border-b border-brown pad',
                  data.products.length === 3 ? 'border-r' : 'sm:border-r'
              ]"
              v-html="row[2]"
          ></div>
          <div
              v-if="data.products[2]"
              class="c2 rich-text border-b sm:border-r border-brown pad"
              v-html="row[3]"
          ></div>
        </div>
        <div class="buttons-row flex flex-wrap mt-10">
          <div class="c1">&nbsp;</div>
          <div class="c2 px-5 lg:px-7 text-center" v-for="(product, p) in data.products" :key="p">
            <base-button :url="product.url" color="red" label="More Information"></base-button>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: ["data"],
  components: {
    BaseButton: () => import(/* webpackChunkName: "base-button" */ "./BaseButton.vue"),
    BaseImage: () => import(/* webpackChunkName: "base-image" */ "./BaseImage.vue")
  },
  computed: {
    hasAudio() {
      for(const product of this.data.products) {
        if(product.audio) {
          return true;
        }
      }

      return false;
    }
  }
};
</script>

<style scoped>
.chart {
  .c1 {
    width: 100%;
  }
  &.num-products-2 {
    .c2 {
      width: 50%;
    }
  }
  &.num-products-3 {
    .c2 {
      width: 33.333%;
    }
  }
}
.pad {
  @apply py-2 px-4;
}
.chart.num-products-3 .pad {
  @apply p-2;
}
@screen lg {
  .pad {
    @apply py-3 px-6;
  }
  .chart.num-products-3 .pad {
    @apply py-3 px-4;
  }
}
@media (max-width: 575px) {
  .top-row,
  .buttons-row {
    .c1 {
      display: none;
    }
  }
}
@media (min-width: 576px) {
  .chart {
    &.num-products-2 {
      .c1 {
        width: 30%;
      }
      .c2 {
        width: 35%;
      }
    }
    &.num-products-3 {
      .c1 {
        width: 25%;
      }
      .c2 {
        width: 25%;
      }
    }
  }
}
</style>
