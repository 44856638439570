<template>
  <div class="base-cart container mx-auto px-5 py-8 lg:py-10 xl:py-12">
    <div v-if="$root.cart === null">
      <div class="mx-auto text-center my-10">
        <div>
          <base-icon icon-name="loading" width="100" height="100" viewbox="0 0 32 32" class="block mx-auto text-grey">
            <icon-loading></icon-loading>
          </base-icon>
        </div>
        <div class="uppercase text-xl text-grey mt-8">
          Loading Cart
        </div>
      </div>
    </div>
    <div v-if="$root.cart !== null">
      <div v-if="$root.totalCartQuantity === 0">
        <div class="w-full rounded bg-grey-lighter text-center py-20">
          <div class="text-3xl uppercase text-grey-dark">
            Your cart is empty
          </div>
          <div class="mt-12">
            <base-button url="/shop" label="Return to Products" size="smaller" color="red"></base-button>
          </div>
        </div>
      </div>
      <div v-else class="cart">
        <cart-buttons class_names="text-right" @proceed="proceedToCheckout" :is_disabled="isLoading"></cart-buttons>
        <div class="cart-top-row mt-6 flex font-bold text-green text-base md:text-lg lg:text-xl uppercase pb-3 lg:pt-3 lg:pb-5">
          <div class="col-1">Item</div>
          <div class="col-2 text-right">Price</div>
          <div class="col-3 text-center">
            <span class="md:hidden">Qty</span>
            <span class="hidden md:inline">Quantity</span>
          </div>
          <div class="col-4 text-right">Total</div>
        </div>
        <div class="border-b border-brown mt-3 md:mt-0">
          <cart-line-item
            v-for="(item, code) in $root.cart.items"
            :item="item"
            :code="code"
            :key="code"
            :is_disabled="isLoading"
          ></cart-line-item>
        </div>
        <div class="mt-8">
          <expandable-categories page="cart" :show_add_to_cart_buttons="true" :is_disabled="isLoading">
            <template slot="bulk_prices_text">
              <slot name="bulk_prices_text"></slot>
            </template>
          </expandable-categories>
        </div>
        <div class="mt-10 md:flex">
          <div class="md:w-1/3 lg:w-1/2 xl:w-p60"></div>
          <div class="md:w-2/3 lg:w-1/2 xl:w-p40">
            <cart-totals></cart-totals>
          </div>
        </div>
        <cart-buttons class_names="text-right mt-10" @proceed="proceedToCheckout" :is_disabled="isLoading"></cart-buttons>
      </div>
    </div>
  </div>
</template>

<script>
import VueAxios from "../../js/modules/vue-axios";
import CartMixin from "../mixins/Cart";

export default {
  data: function() {
    return {
      isLoading: false
    };
  },
  props: ["message", "is_admin"],
  mixins: [CartMixin],
  components: {
    BaseButton: () => import(/* webpackChunkName: "base-button" */ "../components/BaseButton.vue"),
    CartButtons: () => import(/* webpackChunkName: "cart-buttons" */ "./CartButtons.vue"),
    CartLineItem: () => import(/* webpackChunkName: "cart-line-item" */ "./CartLineItem.vue"),
    CartTotals: () => import(/* webpackChunkName: "cart-totals" */ "./CartTotals.vue"),
    ExpandableCategories: () => import(/* webpackChunkName: "expandable-categories" */ "./ExpandableCategories.vue"),
    BaseIcon: () => import(/* webpackChunkName: "base-icon" */ "../components/BaseIcon.vue"),
    IconLoading: () => import(/* webpackChunkName: "icon-loading" */ "../icons/IconLoading.vue")
  },
  methods: {
    proceedToCheckout() {
      // update cart with new quantities (and prices if admin), then redirect to checkout
      let self = this,
        formData = new FormData();
      self.isLoading = true;
      formData.append(self.$root.csrfTokenName, self.$root.csrfTokenValue);
      formData.append("action", "wildlife/shop/update");
      formData.append("update", "");
      // loop through cart items to add form data
      Object.keys(self.$root.cart.items).forEach(productCode => {
        const code = productCode.replace(/\\/g, '\\\\');
        let lineItem = self.$root.cart.items[productCode];
        formData.append("quantity_" + code, lineItem.newQty);
        if (self.is_admin) {
          // if admin also add price
          formData.append("price_" + code, lineItem.unit);
        }
      });
      let options = {
        method: "POST",
        data: formData,
        url: "/"
      };
      VueAxios(options).then(response => {
        // redirect to cart
        window.location.href = "/shop/checkout/shipping";
      });
    }
  }
};
</script>

<style>
.cart {
  .col-1 {
    width: 100%;
  }

  .col-1-1 {
    width: 75px;
  }

  .col-1-2 {
    width: calc(100% - 75px);
  }

  .col-2 {
    width: 100%;
  }

  .col-3 {
    width: 100%;
  }

  .col-4 {
    width: 100%;
  }
}
@media (max-width: 575px) {
  .cart-top-row {
    display: none;
  }
}
@media (min-width: 576px) {
  .cart {
    .col-1 {
      width: 55%;
    }

    .col-1-1 {
      width: 75px;
    }

    .col-1-2 {
      width: calc(100% - 75px);
    }

    .col-2 {
      width: 15%;
    }

    .col-3 {
      width: 15%;
    }

    .col-4 {
      width: 15%;
    }
  }
}
@media (min-width: 768px) {
  .cart {
    .col-1-1 {
      width: 120px;
    }
    .col-1-2 {
      width: calc(100% - 120px);
    }
  }
}
@media (min-width: 992px) {
  .cart {
    .col-1-1 {
      width: 200px;
    }
    .col-1-2 {
      width: calc(100% - 200px);
    }
  }
}
</style>
