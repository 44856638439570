var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "figure",
    {
      class: [
        "grant-program-aligned-image aligned-image mb-6 md:mb-8",
        !_vm.block.isFirst
          ? _vm.block.previousBlock === "heading"
            ? "mt-3"
            : "mt-10"
          : "",
        "float-" + _vm.block.position,
        _vm.block.position === "right" ? "ml-6 md:ml-8" : "mr-6 md:mr-8",
        _vm.block.hide.mobile
          ? "hidden" + (!_vm.block.hide.tablet ? "md:inline" : "")
          : "",
        _vm.block.hide.tablet
          ? "md:hidden" + (!_vm.block.hide.desktop ? "lg:inline" : "")
          : "",
        _vm.block.hide.desktop ? "lg:hidden" : ""
      ],
      style: _vm.styles
    },
    [
      _c("base-image", {
        attrs: {
          src: _vm.block.src,
          retina_src: _vm.block.retinaSrc,
          alt: _vm.block.alt,
          class_names: "block m-0",
          url: _vm.block.url,
          target: _vm.block.target
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }