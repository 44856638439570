var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: ["product-table flex flex-col", !_vm.block.isFirst ? "mt-6" : ""]
    },
    [
      _vm.block.searchEnabled
        ? _c("div", { staticClass: "pb-4" }, [
            _c("label", [_vm._v("Search")]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.searchTerm,
                  expression: "searchTerm"
                }
              ],
              staticClass: "input-text",
              attrs: {
                type: "text",
                name: "search_term",
                placeholder: _vm.block.searchBarPlaceholderText
              },
              domProps: { value: _vm.searchTerm },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.searchTerm = $event.target.value
                }
              }
            })
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "table",
        { staticClass: "w-full text-lg lg:text-xl xl:text-2xl leading-normal" },
        [
          _c(
            "thead",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showTableHead,
                  expression: "showTableHead"
                }
              ]
            },
            [
              _c(
                "tr",
                _vm._l(_vm.block.columns, function(column, i) {
                  return _c("td", {
                    key: i,
                    staticClass: "italic",
                    attrs: { width: column.width, align: column.align },
                    domProps: { innerHTML: _vm._s(column.heading) }
                  })
                }),
                0
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "tbody",
            _vm._l(_vm.filteredRows, function(row, j) {
              return _c(
                "tr",
                { key: j },
                _vm._l(row, function(cell, k) {
                  return _c(
                    "td",
                    {
                      key: k,
                      class: [
                        "py-2 border-b border-brown",
                        _vm.block.firstColumnBold && k === 0 ? "font-bold" : ""
                      ],
                      attrs: {
                        width: _vm.block.columns[k].width,
                        align: _vm.block.columns[k].align
                      },
                      domProps: { innerHTML: _vm._s(cell) }
                    },
                    [_vm._v("\n          " + _vm._s(k) + "\n        ")]
                  )
                }),
                0
              )
            }),
            0
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }