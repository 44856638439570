<template>
  <div
    :class="[
      'expandable-category-product flex flex-wrap pb-5 text-brown',
      !is_first ? 'border-t border-brown pt-5' : '',
      'page-' + page
    ]"
    v-click-outside.capture="hideQuantityDiscountBox"
  >
    <div class="col-1 pb-4 md:pb-0 pr-4 md:px-4">
      <base-image
        :src="product.src !== '' ? product.src : '/img/add-on-small-thumbnail-placeholder.png'"
        :retina_src="product.retinaSrc !== '' ? product.retinaSrc : '/img/add-on-small-thumbnail-placeholder@2x.png'"
        class_names="mx-auto md:w-3/4"
        :url="product.url"
      ></base-image>
      <div v-if="product.zoomSrc && product.zoomExtraSrc" class="text-center mt-2">
        <a @click.prevent="openImageModal" class="mt-1 tr-color text-brown hover:text-red cursor-pointer">
          <base-icon icon-name="search" width="10" height="10" viewbox="0 0 28 28">
            <icon-search></icon-search>
          </base-icon>
          &nbsp;Click here to zoom
        </a>
      </div>
    </div>
    <div class="col-2 pb-4 md:pb-0">
      <base-heading size="h4" color="brown" hover_color="red" :url="product.url">
        {{ product.title }}
      </base-heading>
      <div class="text-sm sm:text-base mt-1 sm:mt-2">Product Code: {{ product.code }}</div>
      <div v-if="ajaxError" class="mt-4 text-red text-lg" v-html="ajaxError"></div>
      <base-rich-text v-if="product.tooltip !== ''" size="tiny" class_names="mt-3">
        <span v-html="product.tooltip" class="mr-1"></span>
      </base-rich-text>
    </div>
    <div class="col-3 pr-6 md:pr-0 text-right">
      <div class="text-xl font-bold">
        <span v-html="calculatedProductPrice(product)"></span>
      </div>
      <div class="text-xs mt-1 italic">{{ $root.formattedCurrency() }}</div>
      <div class="text-sm uppercase mt-3 product-stock" v-show="!hasPriceOverride" v-html="product.stock"></div>
      <div v-if="!hasPriceOverride && product.price_tiers_display.length > 1" class="relative mt-4">
        <button @click="showQuantityDiscountBox = !showQuantityDiscountBox" type="button" class="italic text-red text-sm underline">
          Quantity Discount
        </button>
        <slide-up-down :active="showQuantityDiscountBox" :duration="0" class="price-tiers-box absolute right-0 z-10">
          <div class="py-2 px-3 bg-red text-white">
            <table class="mx-auto">
              <tr v-for="(tier, t) in product.price_tiers_display" :key="t">
                <td class="text-right">{{ tier.label }}:</td>
                <td class="pl-3">{{ formattedPrice(tier.price) }} ({{ $root.formattedCurrency() }})</td>
              </tr>
            </table>

            <div class="text-left mt-2">
              <slot name="bulk_prices_text"></slot>
            </div>
          </div>
        </slide-up-down>
      </div>
      <div v-if="showContactSales || hasPriceOverride" class="relative mt-4">
        <a
          href="/sales"
          class="button inline-block uppercase tr-all rounded font-bold leading-tighter text-center cursor-pointer text-sm border-2 border-brown hover:border-brown-hover text-brown bg-white hover:bg-grey-light px-2 py-1"
        >
          Contact sales
        </a>
      </div>
    </div>
    <div v-if="!showContactSales && !hasPriceOverride" class="col-4 text-center">
      <input-product-quantity
        v-model="product.qty"
        :value="product.qty"
        bg_color="border-grey-lightest"
        :is_loading="isLoading"
        :is_disabled="is_disabled"
        class_names="mx-auto"
      ></input-product-quantity>
    </div>
    <template v-if="page === 'cart'">
      <div v-if="showContactSales || hasPriceOverride" class="col-5 text-center pt-4 md:pt-0 md:text-center">
        <a
          href="/sales"
          class="button inline-block uppercase tr-all rounded font-bold leading-tighter text-center cursor-pointer text-sm border-2 border-brown hover:border-brown-hover text-brown bg-white hover:bg-grey-light px-4 py-2"
        >
          Contact sales
        </a>
      </div>
      <div class="col-5 text-right pt-4 md:pt-0 md:text-center" v-else>
        <button
          type="button"
          :disabled="is_disabled || isLoading || product.qty === 0"
          @click="addToCart()"
          :class="[
            'text-white rounded uppercase py-2 px-5 font-bold text-lg xl:text-xl leading-tighter text-center tr-bg',
            is_disabled || isLoading || product.qty === 0 ? 'bg-grey' : 'bg-red hover:bg-red-hover'
          ]"
        >
          Add
        </button>
      </div>
    </template>
  </div>
</template>

<script>
import Vue from "vue";
import VueAxios from "../../js/modules/vue-axios";
import { gaAddToCart } from "../../js/modules/wa-analytics";
import ClickOutside from "vue-click-outside";
import CartMixin from "../mixins/Cart";
import VueScrollTo from "vue-scrollto";
Vue.use(VueScrollTo);

export default {
  data: function() {
    return {
      productCategories: window.productCategories,
      isLoading: false,
      page: this.$parent.$parent.page,
      showQuantityDiscountBox: false,
      ajaxError: ""
    };
  },
  props: ["product", "is_first", "add_to_cart_button", "is_disabled"],
  mixins: [CartMixin],
  components: {
    BaseButton: () => import(/* webpackChunkName: "base-button" */ "../components/BaseButton.vue"),
    BaseHeading: () => import(/* webpackChunkName: "base-heading" */ "../components/BaseHeading.vue"),
    BaseIcon: () => import(/* webpackChunkName: "base-icon" */ "../components/BaseIcon.vue"),
    BaseImage: () => import(/* webpackChunkName: "base-image" */ "../components/BaseImage.vue"),
    BaseRichText: () => import(/* webpackChunkName: "base-rich-text" */ "../components/BaseRichText.vue"),
    IconSearch: () => import(/* webpackChunkName: "icon-search" */ "../icons/IconSearch.vue"),
    InputProductQuantity: () => import(/* webpackChunkName: "input-product-quantity" */ "../components/InputProductQuantity.vue")
  },
  computed: {
    showContactSales() {
      return this.product.requires_sales_contact === true || this.product.requires_sales_contact === "true";
    },
    hasPriceOverride() {
      return this.product.price_override && this.product.price_override.length > 0;
    }
  },
  methods: {
    addToCart() {
      let self = this;
      self.isLoading = true;
      let formData = new FormData();
      const productCode = self.product.code.replace(/\\/g, '\\\\');

      formData.append(self.$root.csrfTokenName, self.$root.csrfTokenValue);
      formData.append("action", "wildlife/shop/update");
      formData.append("quantity_" + productCode, self.product.qty);
      formData.append("add_" + productCode, "");
      let options = {
        method: "POST",
        data: formData,
        url: "/"
      };
      self.ajaxError = "";
      VueAxios(options)
        .then(response => {
          let data = response.data || null;
          self.isLoading = false;
          if (data.success) {
            // update $root.cart
            let data = response.data || null,
              cart = data.cart || null;
            if (data.success && cart) {
              self.$root.cart = cart;

              // Send to Google Analytics
              gaAddToCart(
                [
                  {
                    name: self.product.title,
                    id: self.product.code,
                    price: self.product.price,
                    brand: self.$root.getSiteFullName(),
                    quantity: self.product.qty
                  }
                ],
                {
                  list: "Cart Add-Ons"
                },
                self.product.qty,
                self.$root.currencyCode(),
              );
            }
            // set qty box back to zero
            self.product.qty = 0;
            // scroll to line item
            self.$nextTick(function() {
              VueScrollTo.scrollTo("#cart-line-item-" + self.product.code, 350, {
                offset: -10
              });
            });
          } else {
            self.ajaxError = "An error occurred. Please try again.";
          }
        })
        .catch(error => {
          self.isLoading = false;
          self.ajaxError = "An error occurred. Please try again.";
        });
    },
    calculatedProductPrice(product) {
      return this.hasPriceOverride
        ? this.product.price_override
        : this.formattedPrice(this.getProductPriceByQuantity(product, product.qty));
    },
    hideQuantityDiscountBox() {
      this.showQuantityDiscountBox = false;
    },
    openImageModal() {
      this.$emit("zoom-image", {
        alt: this.product.alt ?? "",
        src: this.product.zoomSrc !== "" ? this.product.zoomSrc : "/img/add-on-small-thumbnail-placeholder.png",
        srcRetina: this.product.retinaSrc !== "" ? this.product.retinaSrc : "/img/add-on-small-thumbnail-placeholder@2x.png",
        srcWebp: this.product.webpSrcset ?? false,
        srcZoom: this.product.zoomExtraSrc !== "" ? this.product.zoomExtraSrc : "/img/add-on-small-thumbnail-placeholder@2x.png"
      });
    }
  },
  directives: {
    ClickOutside
  }
};
</script>

<style scoped>
.page-store {
  .col-1 {
    width: 25%;
  }
  .col-2 {
    width: 43%;
  }
  .col-3 {
    width: calc(100% - 48px);
  }
  .col-4 {
    width: 48px;
  }
  @media (min-width: 768px) {
    .col-3 {
      width: 22%;
    }
    .col-4 {
      width: 10%;
    }
  }
}
.page-cart {
  .col-1 {
    width: 25%;
  }
  .col-2 {
    width: 75%;
  }
  .col-3 {
    width: calc(100% - 48px);
  }
  .col-4 {
    width: 48px;
  }
  .col-5 {
    width: 100%;
  }
  @media (min-width: 768px) {
    .col-1 {
      width: 175px;
    }
    .col-2 {
      width: calc(60% - 175px);
    }
    .col-3 {
      width: 15%;
    }
    .col-4 {
      width: 13%;
    }
    .col-5 {
      width: 12%;
    }
  }
}
.price-tiers-box {
  top: 25px;
  background-color: "blue";
}
.product-stock >>> a {
  @apply underline text-red;
}
</style>
