<template>
  <section :class="['sidebar-app-buttons sidebar-block max-w-sm mx-auto', !is_first ? 'mt-5' : '']">
    <span v-if="block.appStoreLink" class="inline-block">
      <base-image
        src="/img/btn-app-store.png"
        retina_src="/img/btn-app-store@2x.png"
        alt="Download on the App Store"
        :url="block.appStoreLink"
        image_align="left"
        target="_blank"
      ></base-image>
    </span>
    <span v-if="block.googlePlayLink" :class="['inline-block', block.appStoreLink ? 'ml-3' : '']">
      <base-image
        src="/img/btn-google-play.png"
        retina_src="/img/btn-google-play@2x.png"
        alt="Get it on Google Play"
        :url="block.googlePlayLink"
        image_align="left"
        target="_blank"
      ></base-image>
    </span>
  </section>
</template>

<script>
export default {
  props: ["block", "is_first"],
  components: {
    BaseImage: () => import(/* webpackChunkName: "base-image" */ "../components/BaseImage.vue")
  }
};
</script>
