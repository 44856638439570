<template>
  <section :class="['sidebar-person sidebar-block max-w-sm mx-auto text-links', !block.isFirst ? 'mt-4' : '']">
    <div class="md:flex md:flex-wrap lg:block">
      <base-image :src="block.src" :retina_src="block.retinaSrc" :url="block.url"></base-image>
      <div class="p-5">
        <base-heading size="h4" color="brown-lighter" :uppercase="true" v-if="block.products.length > 0" class_names="mb-2">
          <span v-for="(product, p) in block.products" :key="p" v-html="(p > 0 ? ', ' : '') + product.title"></span>
        </base-heading>
        <base-heading size="h2" color="brown">{{ block.name }}</base-heading>
        <div v-if="block.description !== ''" class="mt-2">
          <base-rich-text size="small" v-html="block.description"></base-rich-text>
        </div>
        <div v-if="block.url && block.url !== ''" class="mt-4 italic text-sm text-links">
          <a :href="block.url" :target="block.target">{{ block.linkText }}</a>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: ["block"],
  components: {
    BaseHeading: () => import(/* webpackChunkName: "base-heading" */ "../components/BaseHeading.vue"),
    BaseImage: () => import(/* webpackChunkName: "base-image" */ "../components/BaseImage.vue"),
    BaseRichText: () => import(/* webpackChunkName: "base-rich-text" */ "../components/BaseRichText.vue")
  }
};
</script>

<style scoped>
section {
  background-color: #e8e8e6;
  max-width: 293px;
  @media (max-width: 991px) {
    margin-left: auto;
    margin-right: auto;
  }
}
</style>
