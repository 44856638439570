var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.text !== ""
    ? _c(
        _vm.type !== "label" ? _vm.size : "label",
        {
          tag: "component",
          class: [
            _vm.cursive ? "font-cursive" : "font-bold",
            _vm.uppercase ? "uppercase" : "",
            _vm.centered ? "text-center" : "",
            _vm.sizeClass,
            _vm.color ? "text-" + _vm.color : "text-brown",
            _vm.class_names
          ],
          attrs: { for: _vm.label_for }
        },
        [
          _vm.url !== undefined && _vm.url !== ""
            ? _c(
                "a",
                {
                  class: [
                    "tr-color",
                    _vm.color ? "text-" + _vm.color : "text-brown",
                    _vm.url
                      ? _vm.hover_color
                        ? "hover:text-" + _vm.hover_color
                        : "hover:text-" + _vm.color + "-hover"
                      : ""
                  ],
                  attrs: {
                    href: _vm.url,
                    target: _vm.target ? _vm.target : null
                  }
                },
                [_vm._t("default")],
                2
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.url === undefined || _vm.url === ""
            ? _c("span", [_vm._t("default")], 2)
            : _vm._e()
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }