<template>
  <section
    :class="[
      'grant-program-rich-text',
      !block.isFirst ? (block.previousBlock === 'heading' || block.previousBlock === 'alignedImage' ? 'mt-3' : 'mt-10') : ''
    ]"
  >
    <div v-show="block.text !== ''" class="text-brown">
      <base-rich-text :size="block.textSize === 'footnote' ? 'tiny' : ''" v-html="block.text"></base-rich-text>
    </div>
  </section>
</template>

<script>
export default {
  props: ["block"],
  components: {
    BaseRichText: () => import(/* webpackChunkName: "base-rich-text" */ "../components/BaseRichText.vue")
  }
};
</script>
