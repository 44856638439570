var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "block audio-block bg-white text-brown white-block" },
    [
      _c(
        "div",
        {
          class: [
            _vm.include_container
              ? "container mx-auto px-5-safe"
              : "full-container",
            "py-8 lg:py-8 xl:py-10"
          ]
        },
        [
          _vm.s.showAudioPlayer
            ? _c("audio", { staticClass: "w-full", attrs: { controls: "" } }, [
                _c("source", {
                  attrs: { src: _vm.s.file, type: "audio/mpeg" }
                }),
                _vm._v(
                  "\n      Your browser does not support the audio tag.\n    "
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.s.showDownloadLink
            ? _c("div", { class: _vm.s.showAudioPlayer ? "mt-4" : "" }, [
                _c(
                  "a",
                  {
                    staticClass:
                      "download-link block relative pl-10 text-lg text-brown tr-color hover:text-red",
                    attrs: { href: _vm.s.file, target: "_blank" }
                  },
                  [
                    _c(
                      "base-icon",
                      {
                        staticClass: "absolute left-0 top-0",
                        attrs: {
                          "icon-name": "checkmark",
                          width: "24",
                          height: "24",
                          viewbox: "0 0 31 31"
                        }
                      },
                      [_c("icon-download")],
                      1
                    ),
                    _vm._v(
                      "\n        " + _vm._s(_vm.s.downloadLinkText) + "\n      "
                    )
                  ],
                  1
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.s.description
            ? _c("div", {
                staticClass: "rich-text text-lg mt-4 leading-normal",
                domProps: { innerHTML: _vm._s(_vm.s.description) }
              })
            : _vm._e()
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }