<template>
  <section :class="['search-bar', class_names]">
    <div class="flex">
      <div class="relative col-1">
        <input
          type="text"
          :aria-label="placeholder"
          :placeholder="placeholder"
          v-model="localKeyword"
          class="border border-brown rounded-lg w-full text-xl xl:text-2xl italic py-3 px-4 pr-14"
          v-on:keyup.enter="updateResults"
        />
        <button type="submit" class="text-brown w-7 h-7 centered-y right-0 mr-4 tr-color hover:text-red" @click="updateResults">
          <base-icon icon-name="search" width="24" height="24" viewbox="0 0 28 28">
            <icon-search></icon-search>
          </base-icon>
        </button>
      </div>
      <div v-if="localKeyword !== ''" class="col-2 pl-6">
        <button
          @click="reset"
          class="button w-full h-full inline-block uppercase tr-bg tr-border rounded-lg text-lg xl:text-xl font-bold leading-tighter text-center cursor-pointer text-white bg-brown hover:bg-red px-4 py-2"
        >
          Reset
        </button>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data: function() {
    return {
      localKeyword: this.keyword,
      previousKeyword: ""
    };
  },
  props: ["keyword", "placeholder", "class_names"],
  components: {
    BaseIcon: () => import(/* webpackChunkName: "base-icon" */ "../components/BaseIcon.vue"),
    IconSearch: () => import(/* webpackChunkName: "icon-search" */ "../icons/IconSearch.vue")
  },
  methods: {
    reset() {
      this.previousKeyword = "";
      this.localKeyword = "";
      this.$emit("updateResults", this.localKeyword);
    },
    updateResults() {
      this.previousKeyword = this.localKeyword;
      this.$emit("updateResults", this.localKeyword);
    }
  },
  watch: {
    localKeyword: function() {
      // wait until user is done typing
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        if (this.localKeyword.length > 2 || (this.localKeyword.length === 0 && this.localKeyword !== this.previousKeyword)) {
          this.updateResults(this.localKeyword);
        }
      }, 500);
    }
  }
};
</script>

<style scoped>
section {
  max-width: 780px;
  .col-1 {
    width: calc(100% - 120px);
  }
  .col-2 {
    width: 120px;
  }
}
@media (min-width: 768px) {
  section {
    max-width: 780px;
    .col-1 {
      width: calc(100% - 180px);
    }
    .col-2 {
      width: 180px;
    }
  }
}
</style>
