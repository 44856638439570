var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "video-tutorials-index container px-5-safe mx-auto pt-10 pb-16"
    },
    [
      _c("video-tutorials-products-nav", {
        on: { onselectproduct: _vm.selectProduct, updateurl: _vm.updateUrl }
      }),
      _vm._v(" "),
      _c(
        "div",
        { class: ["lg:flex mt-10", _vm.isLoading ? "opacity-25" : ""] },
        [
          _c(
            "div",
            { staticClass: "lg:w-1/3 lg:pr-12" },
            [
              _c("video-tutorials-sidebar-nav", {
                on: { onselectvideo: _vm.selectVideo, updateurl: _vm.updateUrl }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "pt-10 lg:pt-0 lg:w-2/3" },
            [_c("video-tutorial")],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }