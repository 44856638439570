<template>
  <header class="blog-entry-header sm:px-5-safe bg-cover" :style="headerStyles">
    <div class="container mx-auto bg-white">
      <div
        class="bg-brown-lighter h-2"
        v-screen="{
          lg: 'h-3',
          xl: 'h-4'
        }"
      ></div>
      <div class="relative">
        <component
          :is="ribbon.url ? 'a' : 'div'"
          :href="ribbon.url"
          :target="ribbon.target"
          v-if="ribbon"
          :class="[
            'ribbon block md:absolute text-white text-center md:top-0 md:right-0 mr-5 lg:mr-8 xl:mr-12 py-3 lg:py-5 px-4',
            'bg-' + ribbon.color,
            ribbon.url ? `tr-bg hover:bg-${ribbon.color}-hover` : ''
          ]"
        >
          <div v-if="ribbon.smallText" class="text-lg lg:text-xl">{{ ribbon.smallText }}</div>
          <div v-if="ribbon.largeText" :class="['text-xl lg:text-2xl font-bold', ribbon.smallText ? 'mt-1' : '']">
            {{ ribbon.largeText }}
          </div>
        </component>
        <div
          class="px-5 pt-5 pb-2 md:pb-3 lg:pb-5"
          v-screen="{
            md: 'p-7',
            lg: 'px-10',
            xl: 'pt-12 pb-10 px-18'
          }"
        >
          <blog-category-heading :name="category" :url="category_url"></blog-category-heading>
          <base-heading size="h1" color="brown" class_names="md:hidden">{{ title }}</base-heading>
          <base-heading size="h1" color="brown" class_names="hidden md:block" :style="headingStyles">{{ title }}</base-heading>
          <div class="relative" style="min-height: 47px;">
            <div
              class="mt-6 lg:mt-10 flex items-center relative"
              v-screen="{
                lg: 'mt-10'
              }"
            >
              <div v-if="author_image_src">
                <base-image
                  :src="author_image_src"
                  :retina_src="author_image_retina_src"
                  :alt="author_image_alt"
                  class_names="rounded-full"
                ></base-image>
              </div>
              <div :class="['text-brown', author_image_src ? 'pl-4' : '']">
                <div v-if="author_first_name" class="text-base font-bold mb-1">{{ author_first_name }} {{ author_last_name }}</div>
                <div :class="['text-sm italic', !author_first_name ? 'md:mt-4' : '']">{{ post_date }}</div>
              </div>
            </div>
            <div class="mt-4 md:mt-0 text-right md:absolute md:bottom-0 md:right-0 print-hidden">
              <share-icons
                icon_size="40"
                :url="share_url"
                :title="share_title"
                :share_site_name="share_site_name"
                :share_twitter_handle="share_twitter_handle"
              ></share-icons>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
// @group Blog
// Header for blog entry
import HelpersMixin from "../mixins/Helpers";

export default {
  props: {
    title: {
      type: String,
      required: true
    },
    post_date: {
      type: String,
      required: true
    },
    category: {
      type: String,
      required: true
    },
    category_url: {
      type: String,
      required: true
    },
    author_first_name: String,
    author_last_name: String,
    author_image_src: String,
    author_image_retina_src: String,
    author_image_alt: String,
    background: String,
    background_webp: String,
    background_focal_point: Array,
    ribbon: Object,
    share_url: String,
    share_title: String,
    share_site_name: String,
    share_twitter_handle: String
  },
  components: {
    BaseHeading: () => import(/* webpackChunkName: "base-heading" */ "../components/BaseHeading.vue"),
    BaseImage: () => import(/* webpackChunkName: "base-image" */ "../components/BaseImage.vue"),
    BlogCategoryHeading: () => import(/* webpackChunkName: "blog-category-heading" */ "./BlogCategoryHeading.vue"),
    ShareIcons: () => import(/* webpackChunkName: "share-icons" */ "../components/ShareIcons.vue")
  },
  mixins: [HelpersMixin],
  computed: {
    headerStyles() {
      if (this.background) {
        return {
          ...this.backgroundPosition(this.background_focal_point),
          "background-image": `url(${this.backgroundSrc})`
        };
      } else {
        return {
          "background-color": "#4B3D2A"
        };
      }
    },
    backgroundSrc() {
      if (this.canUseWebP() && this.background_webp) {
        return this.background_webp;
      }
      return this.background;
    },
    headingStyles() {
      if (this.background) {
        return { "padding-right": "180px" };
      } else {
        return null;
      }
    }
  }
};
</script>

<style scoped>
.ribbon {
  width: 100%;
}
@media (min-width: 576px) {
  header {
    padding-top: 90px;
  }
}
@media (min-width: 768px) {
  header {
    padding-top: 120px;
  }
  .ribbon {
    width: 160px;
  }
}
@media (min-width: 992px) {
  header {
    padding-top: 200px;
  }
  .ribbon {
    width: 178px;
  }
}
</style>
