var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "aside",
    { staticClass: "resellers-filter side-filter text-brown" },
    [
      _c(
        "div",
        { staticClass: "h-12" },
        [
          _c("base-heading", { attrs: { size: "h2", color: "brown" } }, [
            _vm._v("Select Your Region:")
          ])
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "ul",
        { staticClass: "border-b border-brown" },
        _vm._l(_vm.$parent.reseller_data, function(region, r) {
          return _c("li", { key: r }, [
            _c(
              "button",
              {
                class: [
                  "filter-btn block w-full cursor-pointer relative text-left py-2 border-t border-brown pl-6 pr-6 text-xl xl:text-2xl font-bold uppercase",
                  _vm.$parent.activeRegion === r ? "active" : ""
                ],
                on: {
                  click: function($event) {
                    return _vm.setActiveRegion(r)
                  }
                }
              },
              [_vm._v("\n        " + _vm._s(region.name) + "\n      ")]
            )
          ])
        }),
        0
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }