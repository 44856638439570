<template>
  <div></div>
</template>

<script>
import { gaAddShippingInfo, gaBeginCheckout, gaCheckout, gaPurchase } from "../../js/modules/wa-analytics";

export default {
  props: ['data'],
  mounted() {
    const currencyCode = this.$root.currencyCode()

    // Google Analytics checkout event
    if (this.data.method === 'checkout') {
      let productsData = this.data.products || null,
          step = this.data.step || null,
          option = this.data.option || null
      if (productsData && step) {
        gaCheckout(productsData, step, option, currencyCode)
      }
    }

    // Google Analytics purchase event
    if (this.data.method === 'purchase') {
      let productsData = this.data.products || null,
          actionData = this.data.action || null
      if (productsData && actionData) {
        gaPurchase(productsData, actionData, currencyCode)
      }
    }

    // Google Analytics begin checkout event
    if (this.data.method === 'begin_checkout') {
      let productsData = this.data.products || null
      if (productsData) {
        gaBeginCheckout(productsData, currencyCode, currencyCode)
      }
    }

    // Google Analytics begin checkout event
    if (this.data.method === 'add_shipping_info') {
      let productsData = this.data.products || null,
          shippingData = this.data.shipping || null
      if (productsData && shippingData) {
        gaAddShippingInfo(productsData, shippingData, currencyCode)
      }
    }

    // Google Analytics begin checkout event
    if (this.data.method === 'add_payment_info') {
      let productsData = this.data.products || null,
          shippingData = this.data.shipping || null
      if (productsData && shippingData) {
        gaAddShippingInfo(productsData, shippingData, currencyCode)
      }
    }
  }
}
</script>
