<template>
  <article :class="class_names">
    <base-heading size="h2" color="brown" hover_color="red" :url="item.url" :target="item.target">
      {{ item.num }}. {{ item.title }}
    </base-heading>
    <div class="mt-2" v-if="item.section">
      (in
      <span v-if="item.sectionUrl"
        ><a :href="item.sectionUrl">{{ item.section }}</a></span
      ><span v-else>{{ item.section }}</span
      >)
    </div>
    <div class="mt-3 break-all">
      <a :href="item.url" :target="item.target" class="underline">{{ item.url }}</a>
    </div>
  </article>
</template>

<script>
export default {
  props: ["item", "class_names"],
  components: {
    BaseHeading: () => import(/* webpackChunkName: "base-heading" */ "../components/BaseHeading.vue")
  }
};
</script>
