import { render, staticRenderFns } from "./IconAngleDown.vue?vue&type=template&id=6d3a1316&"
var script = {}


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/ian/wildlife-website.master/craftcms/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('6d3a1316')) {
      api.createRecord('6d3a1316', component.options)
    } else {
      api.reload('6d3a1316', component.options)
    }
    module.hot.accept("./IconAngleDown.vue?vue&type=template&id=6d3a1316&", function () {
      api.rerender('6d3a1316', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/vue/icons/IconAngleDown.vue"
export default component.exports