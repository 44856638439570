var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "grant-program" },
    [
      _c("div", { staticClass: "container px-5-safe mx-auto py-12" }, [
        _c("div", { staticClass: "lg:flex" }, [
          _c(
            "div",
            {
              staticClass:
                "col-1 mb-10 lg:mb-0 lg:pr-10 xl:pr-16 lg:border-r lg:border-brown"
            },
            [_c("grant-program-content")],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col-2 lg:pl-10 xl:pl-16" },
            [_c("sidebar-blocks", { attrs: { blocks: _vm.sidebar_blocks } })],
            1
          )
        ])
      ]),
      _vm._v(" "),
      _c("grant-program-guidelines")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }