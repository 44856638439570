var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    {
      class: [
        "grant-program-apply-deadlines max-w-md mx-auto lg:max-w-full",
        !_vm.block.isFirst ? "mt-10" : ""
      ]
    },
    [
      _c(
        "div",
        { staticClass: "bg-brown-lighter py-6 px-5" },
        [
          _c("base-heading", { attrs: { size: "h2", color: "brown" } }, [
            _vm._v("\n      " + _vm._s(_vm.block.heading) + "\n    ")
          ]),
          _vm._v(" "),
          _c(
            "table",
            {
              class: [
                "w-full text-white text-xl xl:text-2xl",
                _vm.block.heading !== "" ? "mt-5" : ""
              ]
            },
            [
              _vm._m(0),
              _vm._v(" "),
              _c(
                "tbody",
                _vm._l(_vm.block.rows, function(row, r) {
                  return _c(
                    "tr",
                    { key: r },
                    _vm._l(row, function(cell, c) {
                      return _c(
                        "td",
                        {
                          key: c,
                          class: [
                            "col-1 py-2",
                            c > 0 ? "pl-5" : "",
                            r > 0 ? "border-t border-brown" : ""
                          ]
                        },
                        [
                          _vm._v(
                            "\n            " + _vm._s(cell) + "\n          "
                          )
                        ]
                      )
                    }),
                    0
                  )
                }),
                0
              )
            ]
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm.block.footnote !== ""
        ? _c(
            "div",
            { staticClass: "mt-4" },
            [
              _c("base-rich-text", {
                attrs: { size: "tiny" },
                domProps: { innerHTML: _vm._s(_vm.block.footnote) }
              })
            ],
            1
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", { staticClass: "font-bold uppercase" }, [
        _c("td", { staticClass: "col-1 pb-2" }, [_vm._v("Award Period")]),
        _vm._v(" "),
        _c("td", { staticClass: "col-2 pb-2 pl-5" }, [
          _vm._v("Application Due")
        ]),
        _vm._v(" "),
        _c("td", { staticClass: "col-3 pb-2 pl-5" }, [_vm._v("Awarded By")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }