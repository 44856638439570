var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "relative h-full flex justify-center items-center" },
    [
      _vm._t("product", null, { open: _vm.open }),
      _vm._v(" "),
      _vm.opened ? _vm._t("details", null, { close: _vm.close }) : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }