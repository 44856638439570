<template>
  <section class="news-featured-story mb-8 p-6 lg:p-8" v-if="data">
    <div class="md:flex">
      <div class="md:w-1/3">
        <base-image :src="data.src" :retina_src="data.retinaSrc" :alt="data.alt" v-if="data.src"></base-image>
      </div>
      <div class="mt-6 md:mt-0 md:w-2/3 md:pl-10">
        <base-heading size="h4" color="red" :uppercase="true">Feature Story</base-heading>
        <base-heading size="h2" color="brown" :url="data.url" class_names="mt-3">{{ data.title }}</base-heading>
        <base-rich-text size="small" link_style="red" v-html="data.summary" v-if="data.summary" class_names="mt-3"></base-rich-text>
        <div class="mt-4">
          <a :href="data.url" class="italic text-brown text-base tr-color hover:text-brown-hover hover:underline ">Read more</a>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data: function() {
    return {
      data: this.$parent.featured_data
    };
  },
  components: {
    BaseHeading: () => import(/* webpackChunkName: "base-heading" */ "../components/BaseHeading.vue"),
    BaseImage: () => import(/* webpackChunkName: "base-image" */ "../components/BaseImage.vue"),
    BaseRichText: () => import(/* webpackChunkName: "base-rich-text" */ "../components/BaseRichText.vue")
  },
  methods: {}
};
</script>

<style scoped>
section {
  background-color: #e0ddda;
}
</style>
