var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    {
      class: [
        "block rich-text-block",
        "bg-" + (_vm.background ? _vm.background : "white"),
        _vm.background === "brown"
          ? "text-white text-links-on-brown"
          : "text-brown white-block"
      ]
    },
    [
      _c(
        "div",
        {
          class: [
            _vm.include_container
              ? "container mx-auto px-5-safe"
              : "full-container",
            _vm.paddingClasses
          ]
        },
        [
          _c(
            "base-rich-text",
            { attrs: { size: _vm.text_size } },
            [[_vm._t("default")]],
            2
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }