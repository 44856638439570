var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "product-images block white-block carousel-rounded-nav" },
    [
      _c(
        "div",
        { staticClass: "container-full py-8 lg:py-10" },
        [
          _c(
            "carousel",
            {
              staticClass: "flex",
              attrs: {
                perPage: 1,
                navigationEnabled: true,
                navigationNextLabel: "",
                navigationPrevLabel: "",
                perPageCustom: [
                  [768, 2],
                  [1280, 3]
                ],
                paginationEnabled: false,
                spacePadding: 100
              }
            },
            _vm._l(_vm.images, function(item, i) {
              return _c("slide", { key: i }, [
                _c(
                  "div",
                  { staticClass: "mx-2 flex items-start flex-wrap h-full" },
                  [
                    _c(
                      "div",
                      { staticClass: "w-full" },
                      [
                        _c("zoomable-image", {
                          attrs: {
                            alt: item.alt,
                            src: item.src,
                            srcZoom: item.zoom,
                            srcRetina: item.retina,
                            srcWebp: item.webpSrcset
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: item.caption,
                          expression: "item.caption"
                        }
                      ],
                      staticClass: "w-full text-brown text-base mt-3",
                      domProps: { innerHTML: _vm._s(item.caption) }
                    })
                  ]
                )
              ])
            }),
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }