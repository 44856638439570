var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    {
      class: [
        _vm.include_container ? "container mx-auto" : "full-container",
        "block white-block image-block"
      ]
    },
    [
      _c(
        "div",
        { class: ["inner py-10 px-5-safe"] },
        _vm._l(_vm.data.images, function(image, i) {
          return _c(
            "div",
            {
              key: "image-" + i,
              class: [
                "image mb-4 sm:mb-6 lg:mb-8",
                !image.url ? "cursor-pointer lg:cursor-auto" : ""
              ],
              on: {
                click: function($event) {
                  return _vm.click(image)
                }
              }
            },
            [
              _c("base-image", {
                attrs: {
                  src: image.src,
                  retina_src: image.retina,
                  alt: image.alt,
                  url: image.url,
                  class_names: "w-full",
                  target: image.target ? "_blank" : null
                }
              }),
              _vm._v(" "),
              image.caption
                ? _c("div", {
                    staticClass:
                      "text-sm sm:text-base lg:text-lg mt-2 leading-normal",
                    domProps: { innerHTML: _vm._s(image.caption) }
                  })
                : _vm._e()
            ],
            1
          )
        }),
        0
      ),
      _vm._v(" "),
      _vm.showImageModal
        ? _c("transition", { attrs: { name: "modal" } }, [
            _c(
              "div",
              {
                staticClass:
                  "modal-mask fixed top-0 left-0 w-full h-full cursor-pointer",
                on: {
                  click: function($event) {
                    return _vm.close()
                  }
                }
              },
              [
                _c("div", { staticClass: "table w-full h-full text-center" }, [
                  _c("div", { staticClass: "table-row" }, [
                    _c(
                      "span",
                      {
                        staticClass:
                          "table-cell relative p-4 mx-auto align-middle"
                      },
                      [
                        _c("span", { staticClass: "inline-block relative" }, [
                          _c(
                            "button",
                            {
                              staticClass:
                                "absolute top-0 right-0 p-4 sm:p-6 text-white tr-opacity hover:opacity-50",
                              attrs: { type: "button" },
                              on: { click: _vm.close }
                            },
                            [
                              _c(
                                "base-icon",
                                {
                                  attrs: {
                                    "icon-name": "close-icon",
                                    width: "20",
                                    height: "20",
                                    viewbox: "0 0 23 23"
                                  }
                                },
                                [_c("icon-x")],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("img", {
                            attrs: { src: _vm.showImageModal, alt: "" }
                          })
                        ])
                      ]
                    )
                  ])
                ])
              ]
            )
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }