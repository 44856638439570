<template>
  <section class="block comparison-chart white-block">
    <div class="container px-5 mx-auto pt-8 lg:pt-12 pb-12 lg:pb-16">
      <div v-if="data.heading" class="pb-8">
        <base-heading v-if="data.heading" size="h1" color="brown">
          {{ data.heading }}
        </base-heading>
      </div>
      <div class="chart mx-auto leading-normal max-w-2xl text-lg lg:text-xl xl:text-2xl num-columns-2">
        <div class="top-row flex flex-wrap">
          <div class="c1 border-r border-brown">&nbsp;</div>
          <div class="c2 sm:border-t border-brown bg-brown-lighter tr-bg hover:bg-brown-light border-r">
            <span class="block p-4 bg-white px-4 lg:px-6">
              <base-image
                :src="data.columnHeaderOne.headerImageSrc"
                :retina_src="data.columnHeaderOne.headerImageRetina"
                :srcset_webp="data.columnHeaderOne.headerImageWebpSrcset"
                :alt="data.columnHeaderOne.headerImageAlt"
              ></base-image>
            </span>
            <span class="block text-white w-full font-bold pad">
              {{ data.columnHeaderOne.headerText }}
            </span>
          </div>
          <div class="c2 sm:border-t border-brown bg-brown-lighter tr-bg hover:bg-brown-light sm:border-r">
            <span class="block p-4 bg-white px-4 lg:px-6">
              <base-image
                :src="data.columnHeaderTwo.headerImageSrc"
                :retina_src="data.columnHeaderTwo.headerImageRetina"
                :srcset_webp="data.columnHeaderTwo.headerImageWebpSrcset"
                :alt="data.columnHeaderTwo.headerImageAlt"
              ></base-image>
            </span>
            <span class="block text-white w-full font-bold pad">
              {{ data.columnHeaderTwo.headerText }}
            </span>
          </div>
        </div>

        <div v-for="(row, r) in data.rows" :key="`row-${r}`" class="flex flex-wrap">
          <div
            class="c1 text-center sm:text-left font-bold py-2 lg:py-3 px-5 sm:pl-0 sm:pr-5 sm:lg:pr-7 border-b sm:border-r border-brown"
            v-html="row.rowHeader"
          ></div>
          <div class="c2 rich-text border-b border-r border-brown pad">
            <div v-if="row.columnContentOne" v-html="row.columnContentOne"></div>
            <div>
              <audio controls v-if="row.columnAudioOne" :class="`max-w-full ${row.columnContentOne ? 'pt-2' : ''}`">
                <source :src="row.columnAudioOne" type="audio/mpeg" />
                Your browser does not support the audio tag.
              </audio>
            </div>
          </div>
          <div class="c2 rich-text border-b border-brown pad sm:border-r">
            <div v-if="row.columnContentTwo" v-html="row.columnContentTwo"></div>
            <div>
              <audio controls v-if="row.columnAudioTwo" :class="`max-w-full ${row.columnContentOne ? 'pt-2' : ''}`">
                <source :src="row.columnAudioTwo" type="audio/mpeg" />
                Your browser does not support the audio tag.
              </audio>
            </div>
          </div>
        </div>
        <div class="buttons-row flex flex-wrap mt-10">
          <div class="c1">&nbsp;</div>
          <div class="c2 px-5 lg:px-7 text-center">
            <base-button
              v-if="data.actionButtons.columnOne.url"
              :url="data.actionButtons.columnOne.url"
              :label="data.actionButtons.columnOne.text"
              :target="data.actionButtons.columnOne.target"
              color="red"
            ></base-button>
            <span v-else>&nbsp;</span>
          </div>
          <div class="c2 px-5 lg:px-7 text-center">
            <base-button
              v-if="data.actionButtons.columnTwo.url"
              :url="data.actionButtons.columnTwo.url"
              :label="data.actionButtons.columnTwo.text"
              :target="data.actionButtons.columnTwo.target"
              color="red"
            ></base-button>
            <span v-else>&nbsp;</span>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: ["data"],
  components: {
    BaseButton: () => import(/* webpackChunkName: "base-button" */ "./BaseButton.vue"),
    BaseImage: () => import(/* webpackChunkName: "base-image" */ "./BaseImage.vue")
  }
};
</script>

<style scoped>
.chart {
  .c1 {
    width: 100%;
  }
  &.num-columns-2 {
    .c2 {
      width: 50%;
    }
  }
  &.num-products-3 {
    .c2 {
      width: 33.333%;
    }
  }
}
.pad {
  @apply py-2 px-4;
}
.chart.num-products-3 .pad {
  @apply p-2;
}
@screen lg {
  .pad {
    @apply py-3 px-6;
  }
  .chart.num-products-3 .pad {
    @apply py-3 px-4;
  }
}
@media (max-width: 575px) {
  .top-row,
  .buttons-row {
    .c1 {
      display: none;
    }
  }
}
@media (min-width: 576px) {
  .chart {
    &.num-columns-2 {
      .c1 {
        width: 30%;
      }
      .c2 {
        width: 35%;
      }
    }
    &.num-products-3 {
      .c1 {
        width: 25%;
      }
      .c2 {
        width: 25%;
      }
    }
  }
}
</style>

<script>
// @group Blocks
export default {
  props: ["data", "include_container"],
  components: {
    BaseButton: () => import(/* webpackChunkName: "base-button" */ "../components/BaseButton.vue"),
    BaseHeading: () => import(/* webpackChunkName: "base-heading" */ "../components/BaseHeading.vue"),
    BaseImage: () => import(/* webpackChunkName: "base-image" */ "../components/BaseImage.vue")
  }
};
</script>

<style scoped>
.chart {
  .c1 {
    width: 100%;
  }
  &.num-columns-2 {
    .c2 {
      width: 50%;
    }
  }
}
.pad {
  @apply py-2 px-4;
}
@screen lg {
  .pad {
    @apply py-3 px-6;
  }
}
@media (max-width: 575px) {
  .top-row,
  .buttons-row {
    .c1 {
      display: none;
    }
  }
}
@media (min-width: 576px) {
  .chart {
    &.num-columns-2 {
      .c1 {
        width: 30%;
      }
      .c2 {
        width: 35%;
      }
    }
  }
}
</style>
