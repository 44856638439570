var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: ["product-raw-html", !_vm.block.isFirst ? "mt-6" : ""] },
    [_c("div", { domProps: { innerHTML: _vm._s(_vm.rawHtml) } })]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }