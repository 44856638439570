var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    {
      directives: [
        {
          name: "lazy",
          rawName: "v-lazy:background-image",
          value: _vm.backgroundSrc,
          expression: "backgroundSrc",
          arg: "background-image"
        }
      ],
      staticClass: "block icon-columns md:py-8 lg:py-16 xl:py-32 bg-cover",
      style: _vm.sectionStyles
    },
    [
      _c("div", {
        staticClass:
          "md:hidden text-white text-3xl font-bold text-center py-9 px-5-safe",
        domProps: { innerHTML: _vm._s(_vm.heading) }
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          class: [
            _vm.include_container
              ? "container mx-auto px-5-safe"
              : "full-container",
            "relative"
          ]
        },
        [
          _c("div", {
            staticClass:
              "absolute w-full h-full left-0 top-0 bg-brown-lighter bg z-10"
          }),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "px-6 py-8 md:px-12 md:pt-8 md:pb-10 text-white relative z-20"
            },
            [
              _c(
                "base-heading",
                {
                  attrs: {
                    size: "h1",
                    class_names: "hidden md:block",
                    centered: true
                  }
                },
                [_vm._v("\n        " + _vm._s(_vm.heading) + "\n      ")]
              ),
              _vm._v(" "),
              _c(
                "carousel",
                {
                  staticClass: "carousel md:mt-8",
                  attrs: {
                    "per-page": 1,
                    "mouse-drag": true,
                    perPageCustom: [[768, 3]],
                    paginationActiveColor: "#fff",
                    paginationColor: "transparent"
                  }
                },
                _vm._l(_vm.columns, function(column, i) {
                  return _c(
                    "slide",
                    { key: i, staticClass: "text-center px-5" },
                    [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: column.src !== "",
                              expression: "column.src !== ''"
                            }
                          ]
                        },
                        [
                          _c("base-image", {
                            attrs: {
                              class_names: "mx-auto",
                              src: column.src,
                              retina_src: column.retinaSrc,
                              srcset_webp: column.webpSrcset,
                              alt: column.alt
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "base-heading",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: column.heading !== "",
                              expression: "column.heading !== ''"
                            }
                          ],
                          attrs: {
                            size: "h2",
                            class_names: [
                              "text-brown",
                              column.src !== "" ? "mt-4" : ""
                            ]
                          }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(column.heading) +
                              "\n          "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c("div", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: column.text !== "",
                            expression: "column.text !== ''"
                          }
                        ],
                        class: [
                          "text-xl xl:text-2xl leading-compact lg:leading-normal",
                          column.src !== "" || column.heading !== ""
                            ? "mt-2"
                            : ""
                        ],
                        domProps: { innerHTML: _vm._s(column.text) }
                      })
                    ],
                    1
                  )
                }),
                1
              )
            ],
            1
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }