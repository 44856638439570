<template>
  <div :class="['results-info text-xl text-brown-lighter uppercase font-bold', class_names]">
    <div v-if="total > 0">Showing {{ firstResultNumber }} to {{ lastResultNumber }} of {{ total }} results</div>
    <div v-else>
      Your search for <span :class="['text-brown']">{{ keyword }}</span> didn’t return any results
    </div>
  </div>
</template>

<script>
export default {
  props: ["total", "current_page", "limit", "keyword", "class_names"],
  computed: {
    firstResultNumber() {
      if (this.total === 0) {
        return null;
      }
      return this.current_page * this.limit - this.limit + 1;
    },
    lastResultNumber() {
      if (this.total === 0) {
        return null;
      }
      var num = this.current_page * this.limit;
      if (num > this.total) {
        num = this.total;
      }
      return num;
    }
  }
};
</script>
