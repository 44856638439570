<template>
  <div v-if="show && resellers" class="fixed top-0 left-0 w-full h-full wrapper">

    <!-- Dark overlay -->
    <div @click="close"
         class="overlay bg-black absolute top-0 left-0 w-full h-full opacity-50"></div>

    <div class="z-20 bg-white shadow-sm w-full centered reseller-modal">

      <!-- Heading -->
      <div class="bg-green text-white px-6 py-4 text-lg font-bold relative">
        Authorized Reseller{{ resellers.length > 1 ? 's' : '' }}

        <!-- Close button -->
        <button @click="close"
                class="text-white tr-opacity hover:opacity-50 float-right">
            <base-icon icon-name="close" width="18" height="18" viewbox="0 0 23 23">
                <icon-x></icon-x>
            </base-icon>
        </button>

      </div>

      <!-- Content -->
      <div class="px-6 pt-4 pb-6">
        <div class="rich-text mb-4">
          <p>
            Please consider buying from one of our authorized resellers in your region. The pricing on our website does not include VAT, import fees or duties, which would be additional fees that we cannot estimate for you.
            By purchasing through an authorized reseller, you will know exact costs and will not need to navigate import paperwork.
          </p>
        </div>

        <!-- List of resellers -->
        <div v-for="(reseller, r) in resellers" :key="r" :class="r > 0 ? 'mt-5' : ''">
          <component v-bind="resellerNameProps(reseller)" class="block text-2xl font-bold">
            {{ reseller.name }}
          </component>
          <div v-if="reseller.description" class="text-base opacity-75 mt-1">
            {{ reseller.description }}
          </div>
          <div class="mt-1 text-base contact-details mt-2">
            <span v-if="reseller.contact.phoneNumber">
              {{ reseller.contact.phoneNumber }}
            </span>
            <span v-if="reseller.contact.emailAddress">
              <a :href="`mailto:` + reseller.contact.emailAddress" class="text-red tr-color hover:text-red-hover no-underline">
                Email
              </a>
            </span>
            <span v-if="reseller.contact.websiteUrl">
              <a :href="formatUrl(reseller.contact.websiteUrl)" target="_blank" class="text-red tr-color hover:text-red-hover no-underline">
                Website
              </a>
            </span>
          </div>
          <div v-if="reseller.resellerMessage" class="rich-text">
            <div v-html="reseller.resellerMessage"></div>
          </div>

        </div>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: function () {
    return {
      show: false,
      country: '',
      resellers: []
    };
  },
  components: {
    BaseIcon: () => import(/* webpackChunkName: "base-icon" */ "../components/BaseIcon.vue"),
    IconX: () => import(/* webpackChunkName: "icon-x" */ "../icons/IconX.vue"),
  },
  methods: {
    close() {
      this.show = false;
      document.documentElement.classList.toggle('overflow-hidden', false);
    },
    formatUrl(s) {
      let prefix = "http", url;
      if (s.substr(0, prefix.length) === prefix) {
        url = s;
      } else {
        url = 'http://' + s;
      }
      return url;
    },
    resellerNameProps(reseller) {
      if (reseller.contact.websiteUrl) {
        return {
          is: 'a',
          href: this.formatUrl(reseller.contact.websiteUrl),
          target: '_blank'
        }
      } else {
        return {
          is: 'div'
        }
      }
    }
  },
  mounted() {
    let self = this;
    self.$root.$on('open-checkout-resellers-modal', function(data) {
      self.resellers = data.resellers || null;
      self.country = data.country || null;
      if (self.resellers && self.country) {
        document.documentElement.classList.toggle('overflow-hidden', true);
        self.show = true;
      }
    });
  }
}
</script>

<style scoped>
.wrapper {
  z-index: 101;
}
.overlay {
  z-index: 102;
}
.reseller-modal {
  z-index: 103;
}
@media (min-width: 640px) {
  .reseller-modal {
    max-width: 600px;
  }
}
@media (max-width: 639px) {
  .reseller-modal {
    width: calc(100% - 40px);
  }
}
.contact-details span:not(:last-child) {
  @apply mr-4;
}
</style>
