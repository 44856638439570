<template>
  <div :class="['checkbox-field', class_names]">
    <label :class="['styled-checkbox', uneditable || disabled ? 'disabled' : '']">
      <span class="text text-links underlined-text-links leading-tight">{{ label }}<slot></slot></span>
      <input type="checkbox" :value="value || 'on'" :name="name" :disabled="uneditable" v-model="localValue" @change="change" />
      <span class="checkmark"
        ><base-icon icon-name="checkmark" width="16" height="16" viewbox="0 0 16 16" class="sprite"
          ><icon-checkmark></icon-checkmark></base-icon
      ></span>
    </label>
  </div>
</template>

<script>
// @group Form
export default {
  data: function() {
    return {
      localValue: this.checked
    };
  },
  props: ["label", "name", "value", "checked", "class_names", "disabled", "uneditable"],
  components: {
    BaseIcon: () => import(/* webpackChunkName: "base-icon" */ "../BaseIcon.vue"),
    IconCheckmark: () => import(/* webpackChunkName: "icon-checkmark" */ "../../icons/IconCheckmark.vue")
  },
  methods: {
    change() {
      this.$emit("input", this.localValue);
    }
  }
};
</script>

<style scoped>
.styled-checkbox {
  display: block;
  position: relative;
  height: auto;
  padding-left: 30px;
  min-height: 22px;
  cursor: pointer;
  line-height: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }
  &.disabled {
    cursor: not-allowed;
    .checkmark {
      color: #aaa;
      border: 1px solid #b0b0b0;
    }
  }
  &:not(.disabled) {
    .checkmark {
      color: #231f20;
      border: 1px solid #000;
    }
    &:hover input ~ .checkmark {
      background-color: #dedede;
    }
  }
  .checkmark {
    display: block;
    position: absolute;
    background: #fff;
    top: 0;
    left: 0;
    width: 22px;
    height: 22px;
    .sprite {
      opacity: 0;
      transition: opacity 0.2s;
      width: 16px;
      height: 16px;
      margin: 2px;
      padding: 0;
      svg {
        width: 16px;
        height: 16px;
        position: absolute;
        top: 2px;
        left: 2px;
      }
    }
  }
  input:checked ~ .checkmark .sprite {
    opacity: 1;
  }
}
</style>
