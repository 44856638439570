var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "g",
    { attrs: { transform: "translate(-585.000000, -770.000000)" } },
    [
      _c("g", { attrs: { transform: "translate(585.000000, 770.000000)" } }, [
        _c("circle", {
          attrs: { fill: "#e4000a", cx: "15.5", cy: "15.5", r: "15.5" }
        }),
        _vm._v(" "),
        _c("polygon", {
          attrs: {
            fill: "#ffffff",
            points:
              "18.3,16.9 18.3,7.8 13.4,7.8 13.4,16.9 8.5,16.9 15.9,24 23.2,16.9"
          }
        })
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }