var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "swipe-table-indicator text-center swipe-table-indicator" },
    [
      _c("div", { staticClass: "inline-block mx-auto" }, [
        _c("div", { staticClass: "flex items-center" }, [
          _c("span", { staticClass: "pr-2" }, [
            _c(
              "svg",
              {
                staticStyle: { "enable-background": "new 0 0 19 6" },
                attrs: {
                  version: "1.1",
                  xmlns: "http://www.w3.org/2000/svg",
                  "xmlns:xlink": "http://www.w3.org/1999/xlink",
                  x: "0px",
                  y: "0px",
                  viewBox: "0 0 19 6",
                  "xml:space": "preserve"
                }
              },
              [
                _c("polygon", {
                  attrs: {
                    points:
                      "0.1,3 5.1,0.4 5.1,2.9 18.8,2.9 18.9,2.9 18.9,3.2 18.8,3.2 5.1,3.2 5.1,5.6 ",
                    fill: "#000000"
                  }
                })
              ]
            )
          ]),
          _vm._v(" "),
          _c("span", { staticClass: "text-xs text-black uppercase" }, [
            _vm._v("\n        Swipe to view table\n      ")
          ]),
          _vm._v(" "),
          _c("span", { staticClass: "pl-2" }, [
            _c(
              "svg",
              {
                staticStyle: { "enable-background": "new 0 0 19 6" },
                attrs: {
                  version: "1.1",
                  xmlns: "http://www.w3.org/2000/svg",
                  "xmlns:xlink": "http://www.w3.org/1999/xlink",
                  x: "0px",
                  y: "0px",
                  viewBox: "0 0 19 6",
                  "xml:space": "preserve"
                }
              },
              [
                _c("polygon", {
                  attrs: {
                    points:
                      "18.9,3 13.9,0.4 13.9,2.9 0.2,2.9 0.1,2.9 0.1,3.2 0.2,3.2 13.9,3.2 13.9,5.6 ",
                    fill: "#000000"
                  }
                })
              ]
            )
          ])
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }