var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "cart-totals" }, [
    _c(
      "div",
      { staticClass: "bg-brown text-white font-bold py-2 px-5 xl:px-7" },
      [
        _c("base-heading", { attrs: { size: "h2" } }, [
          _vm._v("\n      Cart Totals\n    ")
        ])
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass:
          "border-l-3 border-b-3 border-r-3 border-brown py-4 px-5 xl:px-7 text-base md:text-xl"
      },
      [
        _c("div", { staticClass: "row flex" }, [
          _c("div", { staticClass: "w-1/2 py-2" }, [
            _vm._v("\n        Subtotal:\n      ")
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "w-1/2 py-2 text-right" }, [
            _vm._v(
              "\n        " +
                _vm._s(_vm.formattedPrice(_vm.$root.cart.subtotal)) +
                "\n      "
            )
          ])
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.totalNumItems > 0,
                expression: "totalNumItems > 0"
              }
            ],
            staticClass: "row flex"
          },
          [
            _c("div", { staticClass: "w-1/2 py-2" }, [_vm._v("No. of Items:")]),
            _vm._v(" "),
            _c("div", { staticClass: "w-1/2 py-2 text-right" }, [
              _vm._v("\n        " + _vm._s(_vm.totalNumItems) + "\n      ")
            ])
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "row flex" }, [
          _c("div", { staticClass: "w-1/3 py-2 font-bold" }, [
            _vm._v("\n        Total:\n      ")
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "w-2/3 py-2 text-right" }, [
            _c("div", { staticClass: "font-bold" }, [
              _vm._v(_vm._s(_vm.formattedPrice(_vm.$root.cart.total)))
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "text-xs mt-1" }, [
              _vm._v(
                _vm._s(
                  _vm.getSiteName() === "faunatech"
                    ? "shipping calculated at checkout"
                    : "shipping & taxes calculated at checkout"
                )
              )
            ])
          ])
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }