var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "customer-stories-index container px-5-safe mx-auto py-12",
      attrs: { id: "customer-stories-top" }
    },
    [
      _vm.showFilters ? _c("customer-stories-filter") : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { class: [_vm.showFilters ? "mt-8 lg:mt-10 xl:mt-12" : ""] },
        [
          _c("customer-stories-results", {
            on: { setPageNumber: _vm.setPageNumber, updateUrl: _vm.updateUrl }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }