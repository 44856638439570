var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "user-guides-page container px-5-safe mx-auto pt-8 pb-12" },
    [
      _c("nav", { staticClass: "border-b border-brown" }, [
        _c(
          "ul",
          { staticClass: "text-center md:text-left" },
          _vm._l(_vm.languages, function(language, l) {
            return _c(
              "li",
              {
                key: l,
                class: [
                  "inline-block pt-4",
                  l > 0 ? "pl-2 lg:pl-4 xl:pl-5" : "pl-2"
                ]
              },
              [
                _c(
                  "button",
                  {
                    class: [
                      "inline-block text-center px-2 text-base xl:text-lg uppercase font-bold text-green",
                      _vm.selectedLanguage === language.slug
                        ? "active border-green border-b-5"
                        : ""
                    ],
                    attrs: { type: "button" },
                    on: {
                      click: function($event) {
                        return _vm.setLanguage(language.slug)
                      }
                    }
                  },
                  [
                    _c("img", {
                      staticClass: "mx-auto",
                      attrs: {
                        src: language.flagSrc,
                        srcset: [
                          language.flagSrc +
                            " 1x, " +
                            language.flagRetinaSrc +
                            " 2x"
                        ],
                        alt: language.title
                      }
                    }),
                    _vm._v(" "),
                    _c(
                      "span",
                      { staticClass: "block label pt-2 xl:pt-4 pb-2" },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(language.local) +
                            "\n          "
                        )
                      ]
                    )
                  ]
                )
              ]
            )
          }),
          0
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "mt-10" }, [
        _c(
          "div",
          {
            staticClass:
              "md:flex md:flex-wrap md:justify-between text-links text-lg xl:text-xl leading-normal items"
          },
          [
            _vm._l(_vm.showCategories, function(category, c) {
              return _c(
                "div",
                {
                  key: c,
                  class: [
                    "md:w-1/2 px-10 mb-8",
                    (c + 1) % 2 !== 0 && c + 1 !== _vm.showCategories.length
                      ? "md:border-r md:border-brown"
                      : "md:border-0"
                  ]
                },
                [
                  _c(
                    "base-heading",
                    { attrs: { size: "h2", color: "brown" } },
                    [_vm._v(_vm._s(category.title))]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "mt-2" },
                    _vm._l(category.downloads, function(download, d) {
                      return _c(
                        "div",
                        { key: d, staticClass: "item mb-4 flex flex-col" },
                        [
                          _c("div", [
                            _c(
                              "a",
                              {
                                staticClass:
                                  "block relative pl-11 leading-loose",
                                attrs: { href: download.url, target: "_blank" }
                              },
                              [
                                _c(
                                  "base-icon",
                                  {
                                    staticClass: "absolute left-0 top-0",
                                    attrs: {
                                      "icon-name": "checkmark",
                                      width: "30",
                                      height: "30",
                                      viewbox: "0 0 31 31"
                                    }
                                  },
                                  [_c("icon-download")],
                                  1
                                ),
                                _vm._v(
                                  "\n                " +
                                    _vm._s(download.title) +
                                    "\n              "
                                )
                              ],
                              1
                            )
                          ]),
                          _vm._v(" "),
                          download.htmlUrl && download.htmlUrl.length > 0
                            ? _c("div", { staticClass: "mt-4" }, [
                                _c(
                                  "a",
                                  {
                                    staticClass:
                                      "block relative pl-11 leading-loose",
                                    attrs: {
                                      href: download.htmlUrl,
                                      target: "_blank"
                                    }
                                  },
                                  [
                                    _c(
                                      "base-icon",
                                      {
                                        staticClass:
                                          "absolute text-red left-0 top-0",
                                        attrs: {
                                          "icon-name": "checkmark",
                                          width: "30",
                                          height: "30",
                                          viewbox: "0 0 24 24"
                                        }
                                      },
                                      [_c("icon-info")],
                                      1
                                    ),
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(
                                          download.htmlTitle
                                            ? download.htmlTitle
                                            : download.title + " (HTML version)"
                                        ) +
                                        "\n              "
                                    )
                                  ],
                                  1
                                )
                              ])
                            : _vm._e()
                        ]
                      )
                    }),
                    0
                  )
                ],
                1
              )
            }),
            _vm._v(" "),
            _vm.showCategories.length % 2 === 0
              ? _c("div", { staticClass: "extra-col w-1/3 px-10" }, [
                  _vm._v(" ")
                ])
              : _vm._e()
          ],
          2
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }