var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "large-image-header" }, [
    _vm.hide_text
      ? _c(
          "header",
          [
            _c("base-image", {
              attrs: {
                src: _vm.background,
                srcset_webp: _vm.background_webp,
                class_names: "w-full block m-0"
              }
            })
          ],
          1
        )
      : _c(
          "header",
          {
            staticClass: "large-image-header bg-cover",
            style: _vm.headerStyles
          },
          [
            _c("div", { staticClass: "container mx-auto px-5-safe relative" }, [
              _vm.inset_image_src !== undefined && _vm.inset_image_src
                ? _c(
                    "span",
                    {
                      class: [
                        "hidden md:inline inset-image absolute top-0",
                        _vm.inset_image_position === "right"
                          ? "right-0 mr-5"
                          : "left-0 ml-5"
                      ]
                    },
                    [
                      _c("base-image", {
                        attrs: {
                          src: _vm.inset_image_src,
                          retina_src: _vm.inset_image_retina,
                          image_align: "left"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "outer" }, [
              _c("div", { staticClass: "inner text-white" }, [
                _c(
                  "div",
                  {
                    staticClass: "container mx-auto px-5-safe pt-5 pb-4 lg:pt-7"
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "lg:flex lg:items-center w-full" },
                      [
                        _c("div", [
                          _c(
                            "h1",
                            {
                              staticClass:
                                "uppercase font-heading font-bold text-4xl md:text-8xl lg:text-12xl"
                            },
                            [_vm._v(_vm._s(_vm.heading))]
                          )
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.sub_heading !== "",
                                expression: "sub_heading !== ''"
                              }
                            ],
                            staticClass:
                              "font-cursive mt-1 lg:mt-0 lg:text-right lg:flex-1 text-2xl md:text-3xl lg:text-4xl leading-loose"
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.sub_heading) +
                                "\n            "
                            )
                          ]
                        )
                      ]
                    )
                  ]
                )
              ])
            ])
          ]
        )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }