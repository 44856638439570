var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", {
    staticClass:
      "product-important-notice rich-text text-xl text-green text-center bg-white border border-brown py-4 px-5 leading-normal",
    domProps: { innerHTML: _vm._s(_vm.block.text) }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }