var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._l(_vm.sessions, function(session, s) {
      return _c(
        "div",
        {
          key: "session_" + s,
          class: ["py-6", s > 0 ? "border-t border-brown" : ""]
        },
        [
          _c("div", { staticClass: "md:flex md:items-start" }, [
            session.thumbnailSrc
              ? _c(
                  "div",
                  { staticClass: "col-1 mb-6 md:mb-0" },
                  [
                    _c("base-image", {
                      attrs: {
                        src: session.thumbnailSrc,
                        retina_src: session.thumbnailSrcRetina,
                        alt: session.thumbnailAlt,
                        class_names: "block m-0",
                        url: session.buttonUrl
                      }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              {
                class: [
                  "lg:flex lg:pr-8",
                  session.thumbnailSrc ? "col-2" : "w-full"
                ]
              },
              [
                _c(
                  "div",
                  { staticClass: "col-2-1 pl-8 lg:pr-8" },
                  [
                    _c(
                      "base-heading",
                      {
                        attrs: {
                          size: "h4",
                          color: "brown-lighter",
                          uppercase: true,
                          class_names: "mb-2"
                        }
                      },
                      [
                        _vm._v(
                          "\n                        " +
                            _vm._s(session.subheading) +
                            "\n                    "
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "base-heading",
                      { attrs: { size: "h2", color: "brown" } },
                      [_vm._v(_vm._s(session.heading))]
                    ),
                    _vm._v(" "),
                    _c(
                      "base-heading",
                      {
                        attrs: {
                          size: "h3",
                          color: "brown",
                          class_names: "my-2"
                        }
                      },
                      [_vm._v(_vm._s(session.date))]
                    ),
                    _vm._v(" "),
                    session.time
                      ? _c("div", { staticClass: "text-lg mt-4" }, [
                          _c("div", { staticClass: "sm:flex" }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "sm:flex-shrink font-bold pr-5 mb-1 sm:mb-0"
                              },
                              [
                                _vm._v(
                                  "\n                                Time:\n                             "
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c("div", {
                              staticClass: "sm:flex-grow",
                              domProps: { innerHTML: _vm._s(session.time) }
                            })
                          ])
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    session.description !== ""
                      ? _c(
                          "div",
                          { staticClass: "mt-4" },
                          [
                            _c("base-rich-text", {
                              domProps: {
                                innerHTML: _vm._s(session.description)
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e()
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-2-2 pl-8 mt-6 lg:pl-0 lg:mt-0" },
                  [
                    session.buttonText && session.buttonUrl
                      ? _c(
                          "div",
                          { staticClass: "mb-4" },
                          [
                            _c("base-button", {
                              staticStyle: { width: "170px" },
                              attrs: {
                                url: session.buttonUrl,
                                label: session.buttonText,
                                target: session.buttonTarget,
                                class_names: "button",
                                size: "smaller",
                                color: "red"
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    session.buttonText && !session.buttonUrl
                      ? _c(
                          "div",
                          { staticClass: "mb-4" },
                          [
                            _c("base-button", {
                              staticStyle: { width: "170px" },
                              attrs: {
                                url: null,
                                label: session.buttonText,
                                class_names: "button",
                                size: "smaller",
                                color: "grey"
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm._l(session.additionalButtons, function(button, b) {
                      return _c(
                        "div",
                        { key: "button-" + b, staticClass: "mb-4" },
                        [
                          button.url
                            ? _c("base-button", {
                                staticStyle: { width: "170px" },
                                attrs: {
                                  url: button.url,
                                  label: button.text,
                                  target: button.target,
                                  class_names: "button",
                                  size: "smaller",
                                  color: "red"
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      )
                    })
                  ],
                  2
                )
              ]
            )
          ])
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }