<template>
  <div class="add-to-cart-box wrapper mx-auto">
    <add-to-cart-box-price-tiers-flyout v-show="!hasPriceOverride">
      <template slot="bulk_prices_text">
        <slot name="bulk_prices_text"></slot>
      </template>
    </add-to-cart-box-price-tiers-flyout>
    <div :class="['box bg-brown shadow-md text-white px-5 pt-5 pb-4 relative z-30', class_names]">
      <div class="text-left">
        <div class="text-3xl lg:text-4xl font-bold">
          <span v-html="listPrice"></span>
          <span class="text-xs lg:text-sm font-bold ml-1" v-html="currency"></span>
        </div>
        <div class="stock text-base lg:text-lg uppercase mt-1" v-show="!hasPriceOverride && $parent.product.stock !== ''" v-html="$parent.product.stock"></div>
        <div v-if="ajaxError" class="mt-10 text-red text-lg" v-html="ajaxError"></div>
        <div :class="['flex items-center', ajaxError ? 'mt-4' : 'mt-10']">
          <template v-if="showContactSales || hasPriceOverride">
            <div class="w-p60 pr-4">
              <span>For additional details, contact our Sales team.</span>
            </div>
            <div class="w-p40">
              <a
                href="/sales"
                class="inline-flex items-center justify-center relative bg-brown-lighter text-xl inline-block font-bold rounded-lg text-center h-12 w-full uppercase text-white tr-color tr-bg cursor-pointer hover:text-white hover:bg-green"
              >
                Contact Sales
              </a>
            </div>
          </template>
          <template v-else>
            <div class="w-p40 flex items-center pr-4">
              <div class="flex-shrink text-center pl-2">
                <label class="inline-block text-xl" for="quantity">
                  Qty:
                </label>
              </div>
              <div class="pr-4 flex-grow-1">
                <input
                  type="number"
                  pattern="[0-9]"
                  onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                  step="1"
                  min="0"
                  id="quantity"
                  :name="['quantity_' + $parent.product_code]"
                  v-model="quantity"
                  class="ml-4 px-2 w-full inline-block h-12 bg-white text-black text-xl font-bold rounded-lg text-center"
                  value="1"
                />
              </div>
            </div>
            <div class="w-p60">
              <button
                type="submit"
                @click.prevent="addToCart()"
                :disabled="isLoading || quantity < 1"
                :class="[
                  'relative loading-btn bg-brown-lighter text-xl inline-block font-bold rounded-lg text-center h-12 w-full uppercase',
                  isLoading || quantity < 1 ? 'text-grey' : 'text-white tr-color tr-bg hover:text-white hover:bg-green',
                  isLoading ? 'pl-8' : ''
                ]"
              >
                <base-icon
                  icon-name="loading"
                  width="28"
                  height="28"
                  viewbox="0 0 32 32"
                  class="loading absolute ml-3 left-0 centered-y"
                  v-show="isLoading"
                >
                  <icon-loading></icon-loading>
                </base-icon>
                <span class="inline">Add to Cart</span>
              </button>
            </div>
          </template>
        </div>
      </div>
      <div v-show="$parent.has_price_footnote" class="text-base mt-6 leading-normal"><slot name="price_footnote"></slot></div>
      <add-to-cart-box-overlay v-show="showOverlay"></add-to-cart-box-overlay>
    </div>
  </div>
</template>

<script>
import VueAxios from "../../../js/modules/vue-axios";
import { gaAddToCart } from "../../../js/modules/wa-analytics";

export default {
  props: ["class_names"],
  data: function() {
    return {
      isLoading: false,
      showOverlay: false,
      quantity: 1,
      essentialProductsCarouselNavigateTo: -1,
      ajaxError: ""
    };
  },
  components: {
    AddToCartBoxPriceTiersFlyout: () =>
      import(/* webpackChunkName: "add-to-cart-box-price-tiers-flyout" */ "./AddToCartBoxPriceTiersFlyout.vue"),
    AddToCartBoxOverlay: () => import(/* webpackChunkName: "add-to-cart-box-overlay" */ "./AddToCartBoxOverlay.vue"),
    BaseIcon: () => import(/* webpackChunkName: "base-icon" */ "../../components/BaseIcon.vue"),
    IconLoading: () => import(/* webpackChunkName: "icon-loading" */ "../../icons/IconLoading.vue")
  },
  methods: {
    addToCart() {

      let self = this;
      self.isLoading = true;
      const productCode = self.$parent.product_code.replace(/\\/g, '\\\\');

      let formData = new FormData();
      formData.append(self.$root.csrfTokenName, self.$root.csrfTokenValue);
      formData.append("quantity_" + productCode, self.quantity);
      formData.append("add_" + productCode, "");
      formData.append("action", "wildlife/shop/update");
      let options = {
        method: "POST",
        data: formData,
        url: "/"
      };
      self.ajaxError = "";
      VueAxios(options)
        .then(response => {
          let data = response.data || null,
            cart = data.cart || null;
          self.isLoading = false;
          if (data.success && cart) {
            self.$root.cart = cart;
            // set carousel slide to 0 (first) to initialize, otherwise it doesn't initialize show when the overlay appears
            self.essentialProductsCarouselNavigateTo = 0;
            self.showOverlay = true;

            // Send to Google Analytics
            gaAddToCart(
              [
                {
                  name: this.$parent.product_name,
                  id: this.$parent.product_code,
                  price: this.$parent.list_price,
                  brand: self.$root.getSiteFullName(),
                  quantity: self.quantity
                }
              ],
              {
                list: "Product page"
              },
              self.quantity,
              self.$root.currencyCode()
            );
          } else {
            self.ajaxError = "An error occurred. Please try again.";
          }
        })
        .catch(error => {
          self.ajaxError = "An error occurred. Please try again.";
          self.isLoading = false;
        });
    }
  },
  computed: {
    addedToCartQty() {
      if (!this.$root.cart) {
        return null;
      }
      let items = this.$root.cart.items || null;
      if (!items || items.length === 0) {
        return null;
      }
      return items[`${this.$parent.product_code}`].qty || null;
    },
    addedToCartUnitPrice() {
      if (!this.$root.cart) {
        return null;
      }
      let items = this.$root.cart.items || null;
      if (!items || items.length === 0) {
        return null;
      }
      return items[`${this.$parent.product_code}`].unit || null;
    },
    showContactSales() {
      return this.$parent.requires_sales_contact === "true" || this.$parent.requires_sales_contact === true;
    },
    hasPriceOverride() {
      return this.$parent.price_override && this.$parent.price_override.length > 0;
    },
    listPrice() {
      // Show the price override if the item has one and requires sales contact
      return this.hasPriceOverride ? this.$parent.price_override : this.$root.formattedPrice(this.$parent.list_price);
    },
    currency() {
      return this.hasPriceOverride ? '' : this.$root.formattedCurrency();
    }
  }
};
</script>

<style scoped>
.wrapper {
  max-width: 400px;
}
.box {
  min-height: 150px;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}
.stock >>> a {
  @apply underline;
}
</style>
