var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.data.blocks.length > 0
    ? _c(
        "section",
        {
          directives: [
            {
              name: "lazy",
              rawName: "v-lazy:background-image",
              value: _vm.data.src,
              expression: "data.src",
              arg: "background-image"
            }
          ],
          staticClass: "grant-program-guidelines bg-cover",
          style: _vm.sectionStyles,
          attrs: { id: "guidelines" }
        },
        [
          _c("div", { staticClass: "container px-5 mx-auto" }, [
            _c("div", { staticClass: "lg:flex" }, [
              _c("div", { staticClass: "lg:w-1/4" }, [_vm._v(" ")]),
              _vm._v(" "),
              _c("div", { staticClass: "lg:w-3/4 bg-brown text-white" }, [
                _c("div", {
                  staticClass: "w-full h-3 xl:h-4 bg-brown-lighter"
                }),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "px-5 py-6 lg:px-8 lg:py-8 xl:px-12 xl:py-10"
                  },
                  _vm._l(_vm.data.blocks, function(block, b) {
                    return _c(
                      "div",
                      {
                        key: b,
                        class: [
                          !block.isFirst
                            ? block.previousBlock !== "heading"
                              ? "mt-6"
                              : "mt-2"
                            : ""
                        ]
                      },
                      [
                        block.type === "heading"
                          ? _c(
                              "div",
                              [
                                _c(
                                  "base-heading",
                                  {
                                    attrs: {
                                      size: _vm.headingSize(block.size),
                                      cursive: block.cursive,
                                      color: block.color ? block.color : "white"
                                    }
                                  },
                                  [_vm._v(_vm._s(block.text))]
                                )
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        block.type === "richText"
                          ? _c(
                              "div",
                              [
                                _c("base-rich-text", {
                                  domProps: { innerHTML: _vm._s(block.text) }
                                })
                              ],
                              1
                            )
                          : _vm._e()
                      ]
                    )
                  }),
                  0
                )
              ])
            ])
          ])
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }