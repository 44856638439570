var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("article", { class: ["flex", !_vm.last ? "mb-5" : ""] }, [
    _c("a", { staticClass: "date tr-border", attrs: { href: _vm.item.url } }, [
      _c(
        "div",
        {
          staticClass:
            "text-center flex items-center tr-color text-white hover:text-brown-lightest"
        },
        [
          _c("div", { staticClass: "text-center w-full" }, [
            _vm.item.day
              ? _c("div", {
                  staticClass: "font-cursive text-2xl xl:text-4xl",
                  domProps: { innerHTML: _vm._s(_vm.item.day) }
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.item.month
              ? _c("div", {
                  class: ["text-lg xl:text-hxl", _vm.item.day ? "mt-2" : ""],
                  domProps: { innerHTML: _vm._s(_vm.item.month) }
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.item.range
              ? _c("div", {
                  staticClass: "text-base xl:text-lg p-2",
                  domProps: { innerHTML: _vm._s(_vm.item.range) }
                })
              : _vm._e()
          ])
        ]
      )
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "text pl-5" }, [
      _vm.item.subheading
        ? _c("div", {
            staticClass: "italic mb-1 text-base",
            domProps: { innerHTML: _vm._s(_vm.item.subheading) }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("h4", { staticClass: "text-lg font-bold" }, [
        _c(
          "a",
          {
            staticClass: "tr-color hover:underline hover:text-brown-lightest",
            attrs: { href: _vm.item.url }
          },
          [_vm._v(_vm._s(_vm.item.title))]
        )
      ]),
      _vm._v(" "),
      _c("div", {
        staticClass: "rich-text text-base mt-1",
        domProps: { innerHTML: _vm._s(_vm.text) }
      })
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }