<template>
  <div class="text-center text-brown" v-if="num_pages > 1">
    <ul :class="pagination_disabled ? 'opacity-25' : ''">
      <li class="item text-base" v-if="show_first_last_btns">
        <button type="button" @click="onClickFirstPage" :disabled="pagination_disabled || isInFirstPage" aria-label="Go to first page">
          First
        </button>
      </li>
      <li class="item text-base" v-if="show_prev_next_btns" v-show="current_page > 1">
        <button
          type="button"
          @click="onClickPreviousPage"
          :disabled="pagination_disabled || isInFirstPage"
          class="relative tr-bg hover:bg-grey-lighter"
          aria-label="Go to previous page"
        >
          <base-icon icon-name="angle-left" width="11" height="15" viewbox="0 0 9 17" class="centered">
            <icon-angle-left></icon-angle-left>
          </base-icon>
        </button>
      </li>
      <li v-for="page in pages" class="item text-base">
        <button
          type="button"
          @click="onClickPage(page.name)"
          :disabled="pagination_disabled || page.isDisabled"
          :class="['tr-bg', isPageActive(page.name) ? 'bg-brown text-white' : 'hover:bg-grey-lighter']"
          :aria-label="`Go to page number ${page.name}`"
        >
          {{ page.name }}
        </button>
      </li>
      <li class="item text-base" v-if="show_prev_next_btns" v-show="current_page < num_pages">
        <button
          type="button"
          @click="onClickNextPage"
          :disabled="pagination_disabled || isInLastPage"
          class="relative tr-bg hover:bg-grey-lighter"
          aria-label="Go to next page"
        >
          <base-icon icon-name="angle-right" width="11" height="15" viewbox="0 0 9 17" class="centered">
            <icon-angle-right></icon-angle-right>
          </base-icon>
        </button>
      </li>
      <li class="item text-base" v-if="show_first_last_btns">
        <button type="button" @click="onClickLastPage" :disabled="pagination_disabled || isInLastPage" aria-label="Go to last page">
          Last
        </button>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    show_first_last_btns: {
      type: Boolean,
      required: false,
      default: false
    },
    show_prev_next_btns: {
      type: Boolean,
      required: false,
      default: true
    },
    max_visible_buttons: {
      type: Number,
      required: false,
      default: 3
    },
    num_pages: {
      type: Number,
      required: true
    },
    total: {
      type: Number,
      required: true
    },
    per_page: {
      type: Number,
      required: true
    },
    current_page: {
      type: Number,
      required: true
    },
    pagination_disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  components: {
    BaseIcon: () => import(/* webpackChunkName: "base-icon" */ "../components/BaseIcon.vue"),
    IconAngleLeft: () => import(/* webpackChunkName: "icon-angle-left" */ "../icons/IconAngleLeft.vue"),
    IconAngleRight: () => import(/* webpackChunkName: "icon-angle-right" */ "../icons/IconAngleRight.vue")
  },
  computed: {
    startPage() {
      if (this.current_page === 1) {
        return 1;
      }

      if (this.current_page === this.num_pages) {
        return this.num_pages - this.max_visible_buttons + 1;
      }

      return this.current_page - 1;
    },
    endPage() {
      return Math.min(this.startPage + this.max_visible_buttons - 1, this.num_pages);
    },
    pages() {
      const range = [];

      for (let i = this.startPage; i <= this.endPage; i += 1) {
        range.push({
          name: i,
          isDisabled: i === this.current_page
        });
      }

      return range;
    },
    isInFirstPage() {
      return this.current_page === 1;
    },
    isInLastPage() {
      return this.current_page === this.num_pages;
    }
  },
  methods: {
    onClickFirstPage() {
      this.$emit("pagechanged", 1);
    },
    onClickPreviousPage() {
      this.$emit("pagechanged", this.current_page - 1);
    },
    onClickPage(page) {
      this.$emit("pagechanged", page);
    },
    onClickNextPage() {
      this.$emit("pagechanged", this.current_page + 1);
    },
    onClickLastPage() {
      this.$emit("pagechanged", this.num_pages);
    },
    isPageActive(page) {
      return this.current_page === page;
    }
  }
};
</script>

<style scoped>
ul {
  font-size: 0;
}
li {
  margin: 0 4px;
  vertical-align: top;
}
.item {
  display: inline-block;
  button {
    vertical-align: top;
    height: 42px;
    line-height: 42px;
    text-align: center;
    min-width: 42px;
    border: 1px solid #4b3d2a;
    border-radius: 8px;
  }
}
</style>
