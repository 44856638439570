<template>
  <section :class="['sidebar-products-list sidebar-block max-w-sm mx-auto text-links', !block.isFirst ? 'mt-5' : '']">
    <div v-if="block.sections.length > 0" class="mt-6">
      <div v-for="(section, s) in block.sections" :key="s" :class="[s > 0 ? 'mt-3' : '']">
        <base-heading size="h4" color="green" :uppercase="true">{{ section.heading }}</base-heading>
        <ul v-if="section.products.length > 0" class="mt-2">
          <li v-for="(product, p) in section.products" :key="p" :class="['text-lg leading-compact', p > 0 ? 'mt-3' : '']">
            <component :is="product.url ? 'a' : 'span'" :href="product.url">{{ product.title }}</component>
          </li>
        </ul>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: ["block"],
  components: {
    BaseHeading: () => import(/* webpackChunkName: "base-heading" */ "../components/BaseHeading.vue")
  }
};
</script>
