<template>
  <div class="training-course-item">
    <base-image
      :src="item.thumbnailSrc !== '' ? item.thumbnailSrc : '/img/training-course-thumbnail-placeholder.png'"
      :retina_src="item.thumbnailSrcRetina !== '' ? item.thumbnailSrcRetina : '/img/training-course-thumbnail-placeholder@2x.png'"
      :alt="item.thumbnailAlt"
      :class_names="['block mx-0 mb-5', item.addImageBorder || item.thumbnailSrc === '' ? 'border border-brown' : '']"
      :url="item.url"
    ></base-image>
    <base-heading size="h4" color="brown-lighter" :uppercase="true">{{ item.location }}</base-heading>
    <base-heading size="h2" color="brown" hover_color="red" class_names="mt-1" :url="item.url">{{ item.title }}</base-heading>
    <base-rich-text size="small" class_names="mt-3" v-html="item.summary">
      <template>
        <slot name="text"></slot>
      </template>
    </base-rich-text>
    <div v-if="item.url !== '' && item.trainingCourseTileButton" class="mt-5">
      <base-button :url="item.url" :label="item.trainingCourseTileButton" color="red" size="smaller wider"></base-button>
    </div>
  </div>
</template>

<script>
export default {
  props: ["item", "isFirst"],
  components: {
    BaseButton: () => import(/* webpackChunkName: "base-button" */ "../components/BaseButton.vue"),
    BaseHeading: () => import(/* webpackChunkName: "base-heading" */ "../components/BaseHeading.vue"),
    BaseImage: () => import(/* webpackChunkName: "base-image" */ "../components/BaseImage.vue"),
    BaseRichText: () => import(/* webpackChunkName: "base-rich-text" */ "../components/BaseRichText.vue")
  }
};
</script>
