var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "event-row" }, [
    _c(
      "div",
      {
        class: [
          "md:flex",
          !_vm.isFirst ? "pt-7 mt-7 border-t border-brown" : ""
        ]
      },
      [
        _vm.item.thumbnailSrc
          ? _c(
              "div",
              { staticClass: "col-1 mb-6 md:mb-0" },
              [
                _c("base-image", {
                  attrs: {
                    src: _vm.item.thumbnailSrc,
                    retina_src: _vm.item.thumbnailSrcRetina,
                    alt: _vm.item.thumbnailAlt,
                    class_names: "block m-0",
                    url: _vm.item.moreInfoUrl
                  }
                })
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          { class: ["lg:flex", _vm.item.thumbnailSrc ? "col-2" : "w-full"] },
          [
            _c(
              "div",
              { staticClass: "col-2-1 pl-8 lg:pr-8" },
              [
                _c(
                  "base-heading",
                  {
                    attrs: {
                      size: "h4",
                      color: "brown-lighter",
                      uppercase: true
                    }
                  },
                  [_vm._v(_vm._s(_vm.item.date))]
                ),
                _vm._v(" "),
                _c(
                  "base-heading",
                  {
                    attrs: {
                      size: "h2",
                      color: "brown",
                      hover_color: "red",
                      class_names: "mt-1",
                      url: _vm.item.moreInfoUrl
                    }
                  },
                  [_vm._v(_vm._s(_vm.item.title))]
                ),
                _vm._v(" "),
                _vm.item.subheading
                  ? _c("div", {
                      staticClass: "text-lg italic lg:text-xl mt-2",
                      domProps: { innerHTML: _vm._s(_vm.item.subheading) }
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.item.location !== ""
                  ? _c("div", {
                      staticClass: "text-xl lg:text-2xl mt-2",
                      domProps: { innerHTML: _vm._s(_vm.item.location) }
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.item.signUpLink
                  ? _c(
                      "div",
                      { staticClass: "text-base lg:text-lg text-red mt-3" },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm.item.signUpText
                                ? _vm.item.signUpText
                                : "Sign up"
                            ) +
                            "\n          " +
                            _vm._s(_vm.item.signUpDate) +
                            "\n        "
                        )
                      ]
                    )
                  : _vm._e()
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-2-2 pl-8 mt-4 lg:pl-0 lg:mt-0" },
              [
                _vm.item.signUpLink
                  ? _c("base-button", {
                      staticStyle: { width: "170px" },
                      attrs: {
                        url: _vm.item.signUpLink,
                        label: "Sign Up",
                        color: "red",
                        size: "smaller",
                        class_names: "mr-3 lg:mr-0"
                      }
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.item.moreInfoUrl
                  ? _c("base-button", {
                      staticStyle: { width: "170px" },
                      attrs: {
                        url: _vm.item.moreInfoUrl,
                        label: "More Info",
                        color: "brown",
                        size: "smaller",
                        class_names: _vm.item.signUpLink ? "lg:mt-3" : ""
                      }
                    })
                  : _vm._e()
              ],
              1
            )
          ]
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }