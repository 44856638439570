<template>
  <section :class="['sidebar-links sidebar-block max-w-sm mx-auto', !is_first ? 'mt-4' : '']" v-if="block.links">
    <div v-if="block.links.length > 0"
         :class="[
           'text-lg leading-normal',
            block.links.length > 1 ? 'mt-5' : ''
         ]">
      <div v-for="(link, l) in block.links" :key="l" :class="l > 0 ? 'mt-2' : ''">
        <a :href="link.url"
           :target="link.target"
           :class="[
             'text-' + link.color,
             `hover:text-${link.color}-hover`,
             link.bold ? 'font-bold' : ''
           ]">
          {{ link.text }}
        </a>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: ["block", "is_first"]
};
</script>