var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "block boxed-side-by-side-image-and-text white-block" },
    [
      _c(
        "div",
        {
          class: [
            _vm.include_container
              ? "container mx-auto px-5-safe"
              : "full-container",
            "py-10 lg:py-14 xl:py-16"
          ]
        },
        [
          _c(
            "div",
            {
              class: [
                _vm.boxed_style === "yes"
                  ? "border-3 border-brown px-6 md:px-8 lg:px-10 xl:px-12 py-6 xl:py-8"
                  : ""
              ]
            },
            [
              _c(
                "div",
                {
                  class: [
                    "flex flex-col-reverse flex-wrap",
                    _vm.show_media_full_width
                      ? ""
                      : "md:flex-nowrap md:flex-row",
                    _vm.boxed_style === "yes" ? "items-center" : "items-start"
                  ]
                },
                [
                  _vm.image_src !== "" || _vm.video_src !== ""
                    ? _c(
                        "div",
                        {
                          class: [
                            "mt-8 w-full",
                            _vm.show_media_full_width ? "" : "md:mt-0 md:w-1/3"
                          ]
                        },
                        [
                          _vm.is_video
                            ? _c("div", [
                                _c(
                                  "video",
                                  {
                                    attrs: {
                                      width: "100%",
                                      controls: "",
                                      loop: "",
                                      muted: ""
                                    },
                                    domProps: { muted: true }
                                  },
                                  [
                                    _c("source", {
                                      attrs: {
                                        src: _vm.video_src,
                                        type: "video/mp4"
                                      }
                                    })
                                  ]
                                )
                              ])
                            : _c(
                                "div",
                                [
                                  _c("base-image", {
                                    attrs: {
                                      src: _vm.image_src,
                                      retina_src: _vm.image_retina_src,
                                      srcset_webp: _vm.image_srcset_webp,
                                      class_names: "mx-auto",
                                      alt: _vm.alt
                                    }
                                  })
                                ],
                                1
                              )
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      class: [
                        "w-full",
                        _vm.show_media_full_width ? "" : "md:w-2/3 md:pl-12"
                      ]
                    },
                    [
                      _vm.heading !== ""
                        ? _c(
                            "base-heading",
                            { attrs: { size: "h2", color: "brown" } },
                            [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(_vm.heading) +
                                  "\n                    "
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.boxed_style === "yes"
                        ? _c("hr", { staticClass: "mt-4 mb-3 border-0" })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.has_text
                        ? _c(
                            "base-rich-text",
                            {
                              attrs: {
                                class_names: _vm.heading !== "" ? "mt-3" : ""
                              }
                            },
                            [[_vm._t("text")]],
                            2
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.has_footnote
                        ? _c(
                            "base-rich-text",
                            {
                              attrs: {
                                size: "tiny",
                                class_names:
                                  _vm.has_text || _vm.heading !== ""
                                    ? "mt-4"
                                    : ""
                              }
                            },
                            [[_vm._t("footnote")]],
                            2
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.buttons.length > 0
                        ? _c(
                            "div",
                            { staticClass: "mt-5 -mx-2" },
                            _vm._l(_vm.buttons, function(button, b) {
                              return button.text !== "" && button.url !== ""
                                ? _c(
                                    "base-button",
                                    {
                                      key: "column-" + b,
                                      attrs: {
                                        url: button.url,
                                        label: button.text,
                                        target: button.target,
                                        class_names: "mx-2 mb-4",
                                        color: "red"
                                      }
                                    },
                                    [_vm._v("\n                      >")]
                                  )
                                : _vm._e()
                            }),
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ]
              )
            ]
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }