var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "mb-8 max-w-sm" }, [
      _c(
        "form",
        {
          staticClass: "block relative",
          attrs: { method: "get", action: _vm.data.url }
        },
        [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.keyword,
                expression: "keyword"
              }
            ],
            staticClass:
              "text-lg bg-white border-grey-light border-2 w-full text-black rounded p-3 leading-none text-black",
            attrs: {
              type: "text",
              name: "filter",
              placeholder: "Filter by keyword",
              autocomplete: "off"
            },
            domProps: { value: _vm.keyword },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.keyword = $event.target.value
              }
            }
          }),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass:
                "text-brown w-7 h-7 centered-y right-0 mr-4 tr-color hover:text-red",
              attrs: { type: "submit" }
            },
            [
              _c(
                "base-icon",
                {
                  attrs: {
                    "icon-name": "search",
                    width: "24",
                    height: "24",
                    viewbox: "0 0 28 28"
                  }
                },
                [_c("icon-search")],
                1
              )
            ],
            1
          )
        ]
      )
    ]),
    _vm._v(" "),
    _vm.showItems.length > 0
      ? _c(
          "ul",
          _vm._l(_vm.showItems, function(item, i) {
            return _c("li", { key: "faq-item-" + i }, [
              _c("a", { attrs: { href: item.url } }, [
                _vm._v("\n        " + _vm._s(item.title) + "\n      ")
              ])
            ])
          }),
          0
        )
      : _c("div", { staticClass: "rich-text" }, [
          _vm._v("\n    No questions found. "),
          _c(
            "a",
            {
              attrs: { href: _vm.data.url },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  _vm.keyword = ""
                }
              }
            },
            [_vm._v("Reset keyword")]
          )
        ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }