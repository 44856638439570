var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    _vm.type === "submit" ? "button" : "a",
    {
      tag: "component",
      class: [
        "button inline-block uppercase tr-all rounded font-bold leading-tighter text-center cursor-pointer",
        _vm.textsize ? _vm.textsize : "text-lg xl:text-xl",
        _vm.fat_outline ? "border-4" : "border-3",
        !_vm.disabled ? "border-" + _vm.color : "border-grey",
        !_vm.disabled ? "hover:border-" + _vm.color + "-hover" : "",
        !_vm.disabled
          ? "text-" + (_vm.outline ? _vm.color : "white")
          : "text-grey",
        "bg-" + (_vm.outline ? "white" : !_vm.disabled ? _vm.color : "grey"),
        _vm.outline
          ? "hover:bg-grey-light"
          : "hover:bg-" + _vm.color + "-hover",
        _vm.p !== undefined ? _vm.p : _vm.paddingClasses,
        _vm.class_names
      ],
      attrs: {
        type: _vm.type === "submit" ? "submit" : null,
        href: _vm.type !== "submit" ? _vm.url : null,
        value: _vm.value || null,
        name: _vm.name,
        target: _vm.target || null
      },
      on: { click: _vm.onClick }
    },
    [_vm._v("\n  " + _vm._s(_vm.label) + "\n")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }