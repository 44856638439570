var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "contact-details-page" },
    [
      _vm.heading !== ""
        ? _c("base-heading", { attrs: { size: "h1", color: "brown" } }, [
            _vm._v(_vm._s(_vm.heading))
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "lg:flex mt-6 text-brown" }, [
        _c("div", { staticClass: "lg:w-1/3" }, [
          _c(
            "div",
            [
              _c("base-heading", { attrs: { size: "h2", color: "brown" } }, [
                _vm._v(_vm._s(_vm.office_location_heading))
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "text-xl xl:text-2xl leading-normal mt-2" },
                [_vm._t("office_location")],
                2
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "mt-8" },
            [
              _c("base-heading", { attrs: { size: "h2", color: "brown" } }, [
                _vm._v(_vm._s(_vm.hours_heading))
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "text-xl xl:text-2xl leading-normal mt-2" },
                [_vm._t("hours")],
                2
              )
            ],
            1
          ),
          _vm._v(" "),
          _vm.has_buttons
            ? _c(
                "div",
                { staticClass: "mt-8", attrs: { id: "contact-buttons" } },
                [_vm._t("buttons")],
                2
              )
            : _vm._e()
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "mt-8 lg:mt-0 lg:w-2/3 lg:pl-12 red-text-links" },
          _vm._l(_vm.support_sections, function(section, s) {
            return _c(
              "div",
              { key: s, class: [s > 0 ? "mt-8" : ""] },
              [
                _c("base-heading", { attrs: { size: "h2", color: "brown" } }, [
                  _vm._v(_vm._s(section.heading))
                ]),
                _vm._v(" "),
                section.text !== ""
                  ? _c("div", {
                      staticClass: "mt-4 mb-8 rich-text",
                      domProps: { innerHTML: _vm._s(section.text) }
                    })
                  : _vm._e(),
                _vm._v(" "),
                section.email !== ""
                  ? _c(
                      "div",
                      { staticClass: "mt-4" },
                      [
                        _c(
                          "base-heading",
                          {
                            attrs: {
                              size: "h4",
                              color: "green",
                              uppercase: "true"
                            }
                          },
                          [_vm._v("Email")]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "text-xl xl:text-2xl leading-normal text-links"
                          },
                          [
                            _c(
                              "a",
                              { attrs: { href: "mailto:" + section.email } },
                              [_vm._v(_vm._s(section.email))]
                            )
                          ]
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    class: [
                      "sm:flex sm:flex-wrap sm:justify-between",
                      section.email !== "" ? "mt-6" : ""
                    ]
                  },
                  _vm._l(section.phoneNumbers, function(number, n) {
                    return _c(
                      "div",
                      {
                        key: n,
                        class: ["sm:w-p48", n > 0 ? "mt-4 sm:mt-0" : ""]
                      },
                      [
                        _c(
                          "base-heading",
                          {
                            attrs: {
                              size: "h4",
                              color: "green",
                              uppercase: "true"
                            }
                          },
                          [_vm._v(_vm._s(number.heading))]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "text-xl xl:text-2xl leading-normal mt-1"
                          },
                          [_vm._v(_vm._s(number.number))]
                        )
                      ],
                      1
                    )
                  }),
                  0
                )
              ],
              1
            )
          }),
          0
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }