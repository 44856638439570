<template>
  <div class="store-index container mx-auto px-5-safe my-6 lg:my-10">
    <div class="lg:flex">
      <div class="mb-10 lg:mb-0 lg:w-3/4 lg:pr-12 xl:pr-16">
        <div class="mb-10 lg:hidden">
          <store-selected-products></store-selected-products>
        </div>
        <expandable-categories page="store">
          <template slot="bulk_prices_text">
            <slot name="bulk_prices_text"></slot>
          </template>
        </expandable-categories>
      </div>
      <div class="lg:w-1/4 pt-10" sticky-container>
        <store-sidebar v-sticky="{ active: true, stickySide: 'top' }" sticky-offset="{ top: 15 }">
          <template>
            <slot name="sidebar-text"></slot>
          </template>
        </store-sidebar>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["sidebar_has_text", "data", "buttons"],
  components: {
    ExpandableCategories: () => import(/* webpackChunkName: "expandable-categories" */ "./ExpandableCategories.vue"),
    StoreSelectedProducts: () => import(/* webpackChunkName: "store-selected-products" */ "./StoreSelectedProducts.vue"),
    StoreSidebar: () => import(/* webpackChunkName: "store-sidebar" */ "./StoreSidebar.vue")
  },
  mounted: function() {
    window.productCategories = this.data;
  }
};
</script>
