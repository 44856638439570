<template>
  <div class="utility-nav container mx-auto px-5 text-right">
    <nav :class="['font-bold text-lg xl:text-xl', 'header-' + $parent.header_style]">
      <ul>
        <li
          :class="['inline-block relative py-3', $parent.header_style === 'dark' ? 'pl-0 pr-9' : 'pl-4 pr-12']"
          :style="searchLiStyles"
        >
          <form method="get" action="/search">
            <input
              type="text"
              name="keyword"
              @focus="searchFieldFocused = true"
              @blur="searchFieldFocused = false"
              placeholder="Search our site"
              autocomplete="off"
              :style="searchInputStyles"
              class="text-lg"
              style="width: 170px;"
            />
            <button
              type="submit"
              :class="[
                'tr-color',
                $parent.header_style === 'dark' ? 'text-white hover:text-brown-lighter' : 'text-brown hover:text-red'
              ]"
            >
              <base-icon
                icon-name="search"
                width="28"
                height="28"
                viewbox="0 0 28 28"
                :class="['centered-y right-0', $parent.header_style === 'dark' ? 'mr-0' : 'mr-3']"
              >
                <icon-search></icon-search>
              </base-icon>
            </button>
          </form>
          <span
            :class="['search-underline absolute w-full bg-white left-0 bottom-0', !searchFieldFocused ? 'opacity-25' : '']"
            v-if="$parent.header_style === 'dark'"
          ></span>
        </li>
        <li
          v-for="(item, i) in utilityNavItems"
          :key="i"
          v-show="item.display.value !== 'mobileOnly'"
          :class="['inline-block py-3 px-5', item.navHighlight ? 'bg-red' : '']"
        >
          <a :href="item.url" :target="item.target" :class="['u relative block uppercase', $parent.text_color]">
            <span :class="['text inline-block relative', item.navHighlight ? 'text-white' : '']">
              {{ item.text }}
            </span>
          </a>
        </li>
        <li class="cart-btn relative inline-block ml-6" style="height: 47px;">
          <a href="/shop/cart">
            <base-icon icon-name="cart" width="28" height="28" viewbox="0 0 28 28">
              <icon-cart></icon-cart>
            </base-icon>
            <span class="block circle bg-red" v-show="$root.totalCartQuantity > 0"></span>
          </a>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
// @group Nav
export default {
  data: function() {
    return {
      searchFieldFocused: false,
      utilityNavItems: this.$parent.utility_nav
    };
  },
  components: {
    BaseIcon: () => import(/* webpackChunkName: "base-icon" */ "../../components/BaseIcon.vue"),
    IconCart: () => import(/* webpackChunkName: "icon-cart" */ "../../icons/IconCart.vue"),
    IconSearch: () => import(/* webpackChunkName: "icon-search" */ "../../icons/IconSearch.vue")
  },
  computed: {
    searchLiStyles() {
      if (this.$parent.header_style === "dark") {
        return {};
      }
      if (this.searchFieldFocused) {
        return {
          "border-left": "1px solid #ddd",
          "border-right": "1px solid #ddd",
          "border-bottom": "1px solid #4B3D2A"
        };
      } else {
        return {
          "border-left": "1px solid #ddd",
          "border-right": "1px solid #ddd",
          "border-bottom": "1px solid #ddd"
        };
      }
    },
    searchInputStyles() {
      if (this.$parent.header_style === "dark") {
        return {
          "background-color": "transparent"
        };
      } else {
        return {
          "background-color": "#fff"
        };
      }
    }
  }
};
</script>

<style scoped>
.utility-nav {
  height: 44px;
}
.search-underline {
  height: 1px;
}
.header-light {
  input::placeholder {
    color: #4b3d2a;
    font-size: 1.25rem;
    text-transform: uppercase;
    opacity: 1;
    font-weight: bold;
  }
  input:focus::placeholder {
    color: #ccc;
  }
  input:-ms-input-placeholder {
    font-size: 1.25rem;
    text-transform: uppercase;
    color: #4b3d2a;
    font-weight: bold;
  }
  input:focus::-ms-input-placeholder {
    color: #ccc;
  }
  input::-ms-input-placeholder {
    font-size: 1.25rem;
    text-transform: uppercase;
    color: #4b3d2a;
    font-weight: bold;
  }
}
.header-dark {
  input::placeholder {
    color: #fff;
    font-size: 1.25rem;
    text-transform: uppercase;
    opacity: 1;
    font-weight: bold;
  }
  input:focus::placeholder {
    color: #ccc;
    opacity: 0.3;
  }
  input:-ms-input-placeholder {
    font-size: 1.25rem;
    text-transform: uppercase;
    color: #fff;
    font-weight: bold;
  }
  input:focus::-ms-input-placeholder {
    color: #ccc;
    opacity: 0.3;
  }
  input::-ms-input-placeholder {
    font-size: 1.25rem;
    text-transform: uppercase;
    color: #fff;
    font-weight: bold;
  }
}

li {
  vertical-align: top;
  a.u {
    .text:after {
      content: "";
      height: 3px;
      background-color: #e1251b;
      position: absolute;
      bottom: -5px;
      left: 0;
      right: 100%;
      opacity: 0;
      transition-delay: 0.15s;
      transition-property: left right;
      transition-duration: 0.25s;
      transition-timing-function: ease-out;
    }

    &:hover {
      .text:after {
        right: 0;
        opacity: 1;
      }
    }
  }
}
.cart-btn {
  display: inline-block;
  width: 28px;
  height: 23px;
  svg {
    width: 28px;
    height: 28px;
    position: absolute;
    top: 50%;
    left: 0;
    margin-top: -14px;
  }
  .circle {
    width: 14px;
    height: 14px;
    border-radius: 50%;
    position: absolute;
    left: 10px;
    top: 6px;
    border: 2px solid #fff;
  }
}
</style>
