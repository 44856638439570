<template>
  <div v-if='success' class='my-4 text-2xl text-white font-bold'>
    Thank you for signing up.
  </div>

  <div v-else>
    <div class='mt-10 flex flex-col md:flex-row'>
      <input
        type='text'
        placeholder='First Name*'
        name='first_name'
        class='input-text md:w-1/4 mr-8 mb-4 md:mb-0'
        v-model='first_name'
        :disabled='is_loading'
        />

      <input
        type='text'
        placeholder='Last Name*'
        name='last_name'
        class='input-text md:w-1/4 mr-8 mb-4 md:mb-0'
        v-model='last_name'
        :disabled='is_loading'
        />

      <input
        type='text'
        placeholder='Email*'
        name='email'
        class='input-text md:w-1/4 mr-8 mb-4 md:mb-0'
        v-model='email'
        :disabled='is_loading'
        />

      <button
        type='submit'
        @click.prevent="submit()"
        :class="[
          'relative bg-white font-bold uppercase rounded border-4 text-xl py-3 tr-color tr-bg hover:bg-grey-lighter',
          'px-10 text-brown border-brown hover:text-black hover:bg-grey-light'
        ]">
          Sign Up
        </button>
    </div>
    <div class='mt-4 text-white text-2xl'>
      <form-checkbox
        name='subscribe'
       :label="'Sign me up for ' + $parent.getSiteFullName() + ' emails about free trainings, customer stories, and more'"
      ></form-checkbox>
    </div>
  </div>
</template>

<script>
import VueAxios from "../../js/modules/vue-axios";

export default {
  props: {
    buttonText: String,
    tag: String,
  },

  data: function() {
    return {
      first_name: "",
      last_name: "",
      email: "",
      subscribe: false,
      success: false,
      is_loading: false,
      errors: [],
    }
  },

  methods: {
    submit() {
      let self = this;

      // check for errors

      self.errors = [];

      if (!self.first_name) { self.errors.push('first_name'); }
      if (!self.last_name) { self.errors.push('last_name'); }
      if (!self.email) { self.errors.push('email'); }

      if (self.errors.length > 0) { return; }

      // upload the data

      self.is_loading = true;

      let formData = new FormData();
      formData.append('action', 'wildlife/mail-list/signup-simple');
      formData.append(self.$root.csrfTokenName, self.$root.csrfTokenValue);
      formData.append('fname', self.first_name);
      formData.append('lname', self.last_name);
      formData.append('email', self.email);
      formData.append('subscribe', self.subscribe);
      formData.append('tag', self.tag);

      let options = {
        method: 'POST',
        data: formData,
        url: '/'
      };

      VueAxios(options)
        .then(response => {
          let data = response.data;
          
          if (data.success) {
            self.success = true;
          } else {
            self.message = data.message;
          }

          self.is_loading = false;
        })
        .catch(error => {
          self.message = 'An error occured. Please try again.';
          self.is_loading = false;
        });
    }
  },

  components: {
    FormCheckbox: () => import(/* webpackChunkName: "form-input" */ "../components/form/FormCheckbox.vue"),
  }
};
</script>
