var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.priceTiers.length > 1
    ? _c("div", { staticClass: "add-to-cart-tiers-flyout tiers px-7" }, [
        _c(
          "div",
          {
            staticClass:
              "bg-brown-lighter shadow-md py-1 px-3 text-base xl:text-lg"
          },
          [
            _c(
              "button",
              {
                staticClass: "block relative w-full py-1 text-left",
                attrs: { type: "button" },
                on: {
                  click: function($event) {
                    _vm.showTiers = !_vm.showTiers
                  }
                }
              },
              [
                _c("span", { staticClass: "text" }, [
                  _vm._v("Quantity Discount")
                ]),
                _vm._v(" "),
                _c("span", {
                  class: [
                    "arrow block absolute right-0 centered-y",
                    _vm.showTiers ? "down" : "up"
                  ]
                })
              ]
            ),
            _vm._v(" "),
            _c(
              "slide-up-down",
              { attrs: { active: _vm.showTiers, duration: 350 } },
              [
                _c(
                  "div",
                  { staticClass: "flex flex-wrap text-brown" },
                  _vm._l(_vm.priceTiers, function(item) {
                    return _c("div", { staticClass: "w-1/2 flex pt-4" }, [
                      _c("div", { staticClass: "w-p40 text-center" }, [
                        _vm._v(_vm._s(item.label) + ":")
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "w-p60" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$root.formattedPrice(item.price)) +
                            " \n            "
                        ),
                        _c("br"),
                        _vm._v(" "),
                        _c("span", { staticClass: "text-xs" }, [
                          _vm._v(_vm._s(_vm.$root.formattedCurrency()))
                        ])
                      ])
                    ])
                  }),
                  0
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "mt-1 mb-2 text-white text-base" },
                  [_vm._t("bulk_prices_text")],
                  2
                )
              ]
            )
          ],
          1
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }