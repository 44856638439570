<template>
    <component :is="s.url ? 'a' : 'span'"
               :href="s.url ? s.url : null"
               :target="s.url ? s.target : null"
               v-if="active"
               :class="['block bar tr-all bg-green py-2 lg:py-3 text-white relative print:hidden', s.url ? 'cursor-pointer' : '']">
        <div class="container mx-auto px-5 relative">
            <div :class="['inner tr-all text-lg lg:text-2xl leading-normal text-center', s.allowClosing ? 'pr-10' : '']">
                <div class="inline-flex items-center mx-auto">
                    <div v-if="s.hasHeading" class="heading-col uppercase font-bold text-center text-right">
                        {{ s.heading }}
                    </div>
                    <div v-if="s.src" class="pr-3 sm:pr-5 md:px-5 lg:px-7 icon-col">
                        <base-image width="30" height="30" :src="s.src" :retina_src="s.retinaSrc" :srcset_webp="s.webpSrcset" :alt="s.alt" :disable_lazy="true" class_names="my-0 inline-block"></base-image>
                    </div>
                    <div class="text-left">
                        <div v-if="s.hasHeading" class="uppercase font-bold mb-1 md:hidden">
                            {{ s.heading }}
                        </div>
                        <div class="text">
                            <slot name="text"></slot>
                        </div>
                    </div>
                    <div v-if="s.buttonText && s.url" class="pl-6 sm:pl-8">
                        <a :href="s.url" :target="s.target" class="bg-white text-base sm:text-lg rounded text-green py-2 px-4 font-bold whitespace-no-wrap tr-opacity hover:opacity-75">
                            {{ s.buttonText }}
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <button
            v-if="s.allowClosing"
            type="button"
            @click="close()"
            class="cursor-pointer block absolute centered-y right-0 mr-5 tr-all text-white tr-opacity hover:opacity-75"
        >
            <base-icon icon-name="link.icon" width="16" height="16" viewbox="0 0 23 23">
                <icon-x></icon-x>
            </base-icon>
        </button>
    </component>
</template>

<script>
import HelpersMixin from "../mixins/Helpers";

export default {
    data: function() {
        return {
            active: false,
            slideDuration: 0
        };
    },
    props: ["s"],
    components: {
        BaseIcon: () => import(/* webpackChunkName: "base-icon" */ "./BaseIcon.vue"),
        BaseImage: () => import(/* webpackChunkName: "base-image" */ "./BaseImage.vue"),
        IconX: () => import(/* webpackChunkName: "icon-x" */ "../icons/IconX.vue")
    },
    mixins: [HelpersMixin],
    methods: {
        close() {
            sessionStorage.setItem(this.configName, 'closed');
            this.active = false;
        }
    },
    computed: {
        configName() {
            return "announcement-bar-status-" + this.s.id;
        }
    },
    mounted: function() {
        const status = sessionStorage.getItem(this.configName);

        if (status !== 'closed') {
            sessionStorage.setItem(this.configName, 'opened');
            this.active = true;
            self.active = true;
        }
    }
};
</script>

<style scoped>
.container {
    max-width: 1132px;
}
.heading-col {
    max-width: 180px;
}
.bar {
    &.small {
        padding-top: 6px !important;
        padding-bottom: 6px !important;
        button,
        button span,
        button svg {
            width: 16px;
            height: 16px;
        }
    }
    .text {
        a {
            text-decoration: underline;
        }
    }
}
@media (max-width: 479px) {
    .icon-col {
        display: none;
    }
}
@media (max-width: 767px) {
    .heading-col {
        display: none;
    }
}
@media (max-width: 1139px) {
    .bar {
        .container {
            max-width: 100%;
        }
    }
}
@media (min-width: 992px) {
    .bar {
        button,
        button span,
        button svg {
            width: 20px;
            height: 20px;
        }
    }
}
</style>
