<template>
  <div class="training-course-info-row sm:flex border-b border-brown">
    <div class="sm:w-1/4 font-bold px-3 lg:px-4 xl:px-5 pt-3 sm:py-3 xl:py-4">
      {{ label }}
    </div>
    <div class="sm:w-3/4 px-3 lg:px-4 xl:px-5 pt-2 pb-3 sm:py-3 xl:py-4">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: ["label"]
};
</script>
