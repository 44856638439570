<template>
  <component
    :is="type !== 'label' ? size : 'label'"
    :for="label_for"
    v-if="text !== ''"
    :class="[
      cursive ? 'font-cursive' : 'font-bold',
      uppercase ? 'uppercase' : '',
      centered ? 'text-center' : '',
      sizeClass,
      color ? 'text-' + color : 'text-brown',
      class_names
    ]"
  >
    <a
      :href="url"
      :target="target ? target : null"
      v-if="url !== undefined && url !== ''"
      :class="[
        'tr-color',
        color ? 'text-' + color : 'text-brown',
        url ? (hover_color ? `hover:text-${hover_color}` : `hover:text-${color}-hover`) : ''
      ]"
    >
      <slot></slot>
    </a>
    <span v-if="url === undefined || url === ''"><slot></slot></span>
  </component>
</template>

<script>
export default {
  props: [
    "size",
    "color",
    "text",
    "uppercase",
    "centered",
    "class_names",
    "url",
    "target",
    "hover_color",
    "cursive",
    "type",
    "label_for"
  ],
  computed: {
    sizeClass() {
      switch (this.size) {
        case "h1":
          return "text-4xl lg:text-5xl xl:text-6xl"; // 48px
        case "h3":
          return "text-xl lg:text-2xl"; // 24px
        case "h4":
          return "text-lg md:text-xl"; // 20px
        default:
          // h2
          return "text-2hxl xl:text-3xl"; // 30px
      }
    }
  }
};
</script>
