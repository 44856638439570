var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "author-profile pt-6 pb-6 lg:pb-12" }, [
    _c("div", { staticClass: "container px-5 mx-auto" }, [
      _c("div", { staticClass: "sm:flex" }, [
        _vm.photo_src !== ""
          ? _c(
              "div",
              { staticClass: "sm:w-1/3 md:w-p40 polaroid lg:pl-6 xl:pl-12" },
              [
                _c(
                  "div",
                  { staticClass: "inline-block mx-auto" },
                  [
                    _c("base-image", {
                      attrs: {
                        src: _vm.photo_src,
                        retina_src: _vm.photo_retina_src,
                        srcset_webp: _vm.photo_webp_srcset,
                        alt: _vm.photo_alt
                      }
                    })
                  ],
                  1
                )
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "sm:pl-6 lg:pl-0 sm:w-2/3 md:w-p60 lg:pr-6 xl:pr-12" },
          [
            _vm._m(0),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "text-red font-cursive leading-loose text-center mt-6 sm:mt-0 sm:text-left text-xl lg:text-2xl lg:pl-10 xl:pl-0 xl:text-2xl"
              },
              [
                _vm._v(
                  "\n          That's me, " +
                    _vm._s(_vm.first_name) +
                    " " +
                    _vm._s(_vm.last_name) +
                    "\n        "
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "lg:pl-12 xl:pl-20 pt-4 lg:pt-6 xl:pt-10" },
              _vm._l(_vm.details, function(row, r) {
                return _c(
                  "div",
                  {
                    key: r,
                    class: ["py-3", r > 0 ? "border-t border-brown" : ""]
                  },
                  [
                    row.heading !== ""
                      ? _c("div", {
                          staticClass:
                            "text-base lg:text-lg xl:text-xl text-brown-lighter font-bold uppercase",
                          domProps: { innerHTML: _vm._s(row.heading) }
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    row.text !== ""
                      ? _c("div", {
                          class: [
                            "text-lg lg:text-xl xl:text-2xl text-brown",
                            row.heading !== "" ? "mt-1" : ""
                          ],
                          domProps: { innerHTML: _vm._s(row.text) }
                        })
                      : _vm._e()
                  ]
                )
              }),
              0
            )
          ]
        )
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "hidden sm:block mb-1 md:mb-2 lg:mb-3" }, [
      _c("img", {
        staticClass: "arrow",
        attrs: { src: "/img/author-profile-arrow.png", alt: "" }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }