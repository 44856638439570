<template>
  <div>
    <div>
      <base-heading size="h2" color="brown">Filter by:</base-heading>
    </div>
    <div class="mt-4 flex flex-wrap items-center">
      <div class="w-full sm:w-p48 lg:w-p24" v-if="Object.keys($parent.associated_products).length > 0">
        <form-select @selectchanged="filterChangeAssociatedProduct" v-model="$parent.selectedAssociatedProduct" name="associated_products"
          :value="$parent.selectedAssociatedProduct" :options="associatedProductOptions"
          :class_names="$parent.selectedAssociatedProduct !== '' ? ['bg-grey-lighter'] : ['bg-white']"></form-select>
      </div>
      <div class="w-full sm:w-p48 lg:w-p24 lg:ml-8" v-if="Object.keys($parent.accessory_categories).length > 0">
        <form-select @selectchanged="filterChangeAccessoryCategory" v-model="$parent.selectedAccessoryCategory"
          name="accessory_categories" :value="$parent.selectedAccessoryCategory" :options="accessoryCategoryOptions"
          :class_names="$parent.selectedAccessoryCategory !== '' ? ['bg-grey-lighter'] : ['bg-white']"></form-select>
      </div>
      <div class="w-full sm:w-p48 lg:w-p24 lg:ml-8">
        <button type="button" @click="reset" v-if="showResetButton"
          class="button inline-block uppercase tr-all rounded font-bold leading-tighter text-center cursor-pointer text-lg xl:text-xl border-3 border-brown hover:border-brown-hover text-brown bg-white hover:bg-grey-light px-8 lg:px-16 py-2 xl:py-3">
          Reset Filters
        </button>
      </div>
      <div class="mb-4 w-full sm:w-p48 lg:w-p24" v-if="Object.keys($parent.associated_products).length === 0">&nbsp;
      </div>
      <div class="mb-4 w-full sm:w-p48 lg:w-p24" v-if="Object.keys($parent.accessory_categories).length === 0">&nbsp;</div>
    </div>
  </div>
</template>

<script>
export default {
  components: {
    BaseHeading: () => import(/* webpackChunkName: "base-heading" */ "../components/BaseHeading.vue"),
    FormSelect: () => import(/* webpackChunkName: "form-select" */ "../components/form/FormSelect.vue")
  },
  methods: {
    reset() {
      this.$parent.selectedAssociatedProduct = "";
      this.$parent.selectedAccessoryCategory = "";
    },
    filterSubmit() {
      this.$parent.selectedAssociatedProduct = value;
      this.$parent.selectedAccessoryCategory = value;
    },
    filterChangeAssociatedProduct(value) {
      this.$parent.selectedAssociatedProduct = value;
    },
    filterChangeAccessoryCategory(value) {
      this.$parent.selectedAccessoryCategory = value;
    },
    selectOptions(str, categories) {
      let options = {
        "": str
      };
      const array = Object.values(categories);
      array.forEach(function (item) {
        options[item.slug] = item.title;
      });
      return options;
    }

  },
  computed: {
    showResetButton() {
      return (
        this.$parent.selectedAccessoryCategory !== "" ||
        this.$parent.selectedAssociatedProduct !== ""
      );
    },
    associatedProductOptions() {
      return this.selectOptions("Product", this.$parent.associated_products);
    },
    accessoryCategoryOptions() {
      return this.selectOptions("Accessory Type", this.$parent.accessory_categories);
    },
  }
};
</script>
