<template>
  <aside class="publications-filter side-filter text-brown">
    <div class="h-12">
      <base-heading size="h2" color="brown">Filter by:</base-heading>
    </div>
    <ul class="border-b border-brown">
      <li v-for="(filter, f) in filters" :key="f" v-if="filter.categories">
        <sidebar-filter-button
          :label="filter.label"
          :has_submenu="true"
          :active="filter.active"
          @onclick="filter.active = !filter.active"
        >
        </sidebar-filter-button>
        <slide-up-down :active="filter.active" :duration="350">
          <ul class="sub border-t border-brown py-1 pl-4 pr-4 xl:pl-12 xl:pr-10 text-lg xl:text-xl">
            <li v-for="(category, c) in filter.categories" :key="c" class="pt-1 pb-2">
              <form-checkbox
                :name="category.slug"
                :checked="category.active"
                :label="category.name"
                v-model="category.active"
                class_names="text-lg xl:text-xl"
              ></form-checkbox>
            </li>
          </ul>
        </slide-up-down>
      </li>
    </ul>
  </aside>
</template>

<script>
export default {
  data: function() {
    return {
      filters: {
        products: {
          label: "Product",
          categories: this.$parent.products,
          active: this.$parent.products_is_active
        },
        species: {
          label: "Species",
          categories: this.$parent.species,
          active: this.$parent.species_is_active
        },
        types: {
          label: "Type",
          categories: this.$parent.types,
          active: this.$parent.types_is_active
        }
      }
    };
  },
  components: {
    BaseHeading: () => import(/* webpackChunkName: "base-heading" */ "../components/BaseHeading.vue"),
    FormCheckbox: () => import(/* webpackChunkName: "form-checkbox" */ "../components/form/FormCheckbox.vue"),
    SidebarFilterButton: () => import(/* webpackChunkName: "sidebar-filter-button" */ "../components/SidebarFilterButton.vue")
  }
};
</script>

<style scoped>
button {
  background-color: #f4ede3;
}
</style>
