<template>
  <aside class="sidebar-blocks">
    <component
      :is="'SidebarBlock' + capitalizeFirstLetter(block.type)"
      :block="block"
      :is_first="b === 0"
      v-for="(block, b) in blocks"
      :key="b"
    ></component>
  </aside>
</template>

<script>
import HelpersMixin from "../mixins/Helpers";

export default {
  props: ["blocks"],
  mixins: [HelpersMixin],
  components: {
    SidebarBlockAudio: () => import(/* webpackChunkName: "sidebar-block-audio" */ "./SidebarBlockAudio.vue"),
    SidebarBlockAppButtons: () => import(/* webpackChunkName: "sidebar-block-app-buttons" */ "./SidebarBlockAppButtons.vue"),
    SidebarBlockButtons: () => import(/* webpackChunkName: "sidebar-block-buttons" */ "./SidebarBlockButtons.vue"),
    SidebarBlockFacebookButton: () =>
      import(/* webpackChunkName: "sidebar-block-facebook-button" */ "./SidebarBlockFacebookButton.vue"),
    SidebarBlockHeading: () => import(/* webpackChunkName: "sidebar-block-heading" */ "./SidebarBlockHeading.vue"),
    SidebarBlockImageAndText: () => import(/* webpackChunkName: "sidebar-block-image-and-text" */ "./SidebarBlockImageAndText.vue"),
    SidebarBlockPerson: () => import(/* webpackChunkName: "sidebar-block-person" */ "./SidebarBlockPerson.vue"),
    SidebarBlockProductsList: () => import(/* webpackChunkName: "sidebar-block-products-list" */ "./SidebarBlockProductsList.vue"),
    SidebarBlockProducts: () => import(/* webpackChunkName: "sidebar-block-products" */ "./SidebarBlockProducts.vue"),
    SidebarBlockRichText: () => import(/* webpackChunkName: "sidebar-block-rich-text" */ "./SidebarBlockRichText.vue"),
    SidebarBlockSeparator: () => import(/* webpackChunkName: "sidebar-block-separator" */ "./SidebarBlockSeparator.vue"),
    SidebarBlockTextLinks: () => import(/* webpackChunkName: "sidebar-block-text-links" */ "./SidebarBlockTextLinks.vue")
  }
};
</script>
