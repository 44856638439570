<template>
  <section
    :class="[
      'sidebar-image-and-text sidebar-block max-w-sm mx-auto',
      !is_first ? 'mt-8 mb-6' : 'mb-6',
      block.backgroundColor !== 'white' ? 'p-4 lg:p-6 text-white bg-' + block.backgroundColor : ''
    ]"
  >
    <!-- image -->
    <div v-if="block.src">
      <base-image :src="block.src" :retina_src="block.retinaSrc" :alt="block.alt"></base-image>
    </div>

    <!-- caption -->
    <div v-if="block.caption">
      <base-rich-text size="tiny" link_style="red" v-html="block.caption"></base-rich-text>
    </div>

    <!-- heading -->
    <div v-if="block.heading">
      <base-heading size="h2" :color="block.backgroundColor !== 'white' ? 'white' : 'brown'">
        {{ block.heading }}
      </base-heading>
    </div>

    <!-- text -->
    <div v-if="block.text">
      <base-rich-text
        size="small"
        link_style="red"
        v-html="block.text"
      ></base-rich-text>
    </div>

    <!-- button -->
    <div v-if="block.button">
      <base-button
        :url="block.button.url"
        :label="block.button.text"
        :color="block.backgroundColor !== 'white' ? 'brown' : 'red'"
        size="smaller"
        class_names="w-full"
        :outline="block.backgroundColor !== 'white'">
      </base-button>
    </div>

    <!-- footnote -->
    <div v-if="block.footnote">
      <base-rich-text
        size="tiny"
        link_style="red"
        v-html="block.footnote"
      ></base-rich-text>
    </div>
  </section>
</template>

<script>
export default {
  props: ["block", "is_first"],
  components: {
    BaseButton: () => import(/* webpackChunkName: "base-button" */ "../components/BaseButton.vue"),
    BaseHeading: () => import(/* webpackChunkName: "base-heading" */ "../components/BaseHeading.vue"),
    BaseImage: () => import(/* webpackChunkName: "base-image" */ "../components/BaseImage.vue"),
    BaseRichText: () => import(/* webpackChunkName: "base-rich-text" */ "../components/BaseRichText.vue")
  },
  computed: {
    size() {
      if (this.block.textSize === "small") {
        return "smaller";
      } else {
        return "small";
      }
    }
  }
};
</script>

<style scoped>
section > div:not(:first-child) {
  margin-top: 16px;
}
</style>
