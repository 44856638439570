var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "site-footer-news-events" },
    [
      _c(
        "base-heading",
        { attrs: { size: "h3", uppercase: true, color: "white" } },
        [_vm._v("\n    " + _vm._s(_vm.$parent.middle_column_heading) + "\n  ")]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "footer-news mt-6" },
        _vm._l(_vm.items, function(item, i) {
          return _c("site-footer-news-events-item", {
            key: i,
            attrs: {
              item: item,
              itemType:
                _vm.$parent.middle_column_type === "latestBlogEntries"
                  ? "news"
                  : "event"
            }
          })
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }