<template>
  <div class='relative h-full flex justify-center items-center'>
    <slot name='product' :open='open'></slot>
    <slot name='details' :close='close' v-if='opened'></slot>
  </div>
</template>

<script>
export default {
  data: function() {
    return {
      opened: false
    };
  },

  methods: {
    open: function() {
      this.opened = true;
    },

    close: function() {
      this.opened = false;
    }
  }
};
</script>
