var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "training-courses-index container mx-auto px-5-safe py-12",
      attrs: { id: "blog-entries" }
    },
    [
      _vm.ajaxError
        ? _c("div", {
            staticClass: "mb-10 text-red text-lg",
            domProps: { innerHTML: _vm._s(_vm.ajaxError) }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("div", { class: [_vm.isLoading ? "opacity-25" : ""] }, [
        _c(
          "div",
          { staticClass: "md:flex md:flex-wrap md:justify-between" },
          [
            _vm._l(_vm.localItems, function(item, i) {
              return _c(
                "training-course-item",
                {
                  key: i,
                  staticClass: "mb-10 md:w-p48 lg:w-p30",
                  attrs: { item: item, isFirst: i === 0 }
                },
                [_vm._v("\n        " + _vm._s(item.summary) + "\n      ")]
              )
            }),
            _vm._v(" "),
            _vm.localItems !== null &&
            _vm.localItems !== undefined &&
            _vm.localItems.length % 3 !== 0
              ? _c("div", { staticClass: "mb-10 md:w-p48 lg:w-p30" })
              : _vm._e()
          ],
          2
        )
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "mt-6" },
        [
          _c("base-pagination", {
            attrs: {
              max_visible_buttons:
                _vm.num_pages >= 3 ? 3 : parseInt(_vm.num_pages),
              per_page: parseInt(_vm.limit),
              num_pages: parseInt(_vm.num_pages),
              current_page: _vm.currentPage,
              total: parseInt(_vm.total),
              show_prev_next_btns: true,
              pagination_disabled: _vm.isLoading
            },
            on: { pagechanged: _vm.onPageChange }
          })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }