var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "video-tutorial" }, [
    _vm.video
      ? _c(
          "div",
          [
            _c("base-video", {
              attrs: {
                platform: _vm.video.platform,
                video_id: _vm.video.videoId
              }
            }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "mt-6" },
              [
                _c("base-heading", { attrs: { size: "h2", color: "brown" } }, [
                  _vm._v(_vm._s(_vm.video.title))
                ]),
                _vm._v(" "),
                _vm.video.text !== ""
                  ? _c(
                      "div",
                      { staticClass: "mt-3" },
                      [
                        _c("base-rich-text", {
                          domProps: { innerHTML: _vm._s(_vm.video.text) }
                        })
                      ],
                      1
                    )
                  : _vm._e()
              ],
              1
            ),
            _vm._v(" "),
            _vm.video.downloads.length > 0
              ? _c("div", { staticClass: "mt-6 text-links" }, [
                  _c(
                    "ul",
                    { staticClass: "text-xl" },
                    _vm._l(_vm.video.downloads, function(download, d) {
                      return _c(
                        "li",
                        {
                          key: "download_" + d,
                          class: ["block download", d > 0 ? "mt-3" : ""]
                        },
                        [
                          _c(
                            "a",
                            {
                              staticClass: "block relative pt-1 pl-11",
                              attrs: { href: download.url, target: "_blank" }
                            },
                            [
                              _c(
                                "base-icon",
                                {
                                  staticClass: "absolute left-0 top-0",
                                  attrs: {
                                    "icon-name": "checkmark",
                                    width: "30",
                                    height: "30",
                                    viewbox: "0 0 31 31"
                                  }
                                },
                                [_c("icon-download")],
                                1
                              ),
                              _vm._v(
                                "\n            " +
                                  _vm._s(download.text) +
                                  "\n          "
                              )
                            ],
                            1
                          )
                        ]
                      )
                    }),
                    0
                  )
                ])
              : _vm._e()
          ],
          1
        )
      : _c("div", [_vm._v("\n     \n  ")])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }