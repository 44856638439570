var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "store-selected-products" }, [
    _c(
      "div",
      {
        staticClass:
          "bg-brown text-white text-xl font-bold uppercase text-center px-5 py-2"
      },
      [_vm._v("\n    Selected Products\n  ")]
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "bg-grey-lighter py-2" },
      [
        _vm.selectedProducts.length === 0
          ? _c("div", { staticClass: "py-5 px-5 text-center italic" }, [
              _vm._v("\n      No products selected\n    ")
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm._l(_vm.selectedProducts, function(product, p) {
          return _c(
            "div",
            {
              key: p,
              class: ["px-5 py-2", p > 0 ? "border-t border-brown" : ""]
            },
            [
              _c("div", { staticClass: "flex" }, [
                _c(
                  "div",
                  { staticClass: "flex-auto text-base leading-tight pr-4" },
                  [
                    _vm._v(
                      "\n          " + _vm._s(product.title) + "\n        "
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "flex-auto text-right text-sm leading-normal"
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.calculatedProductPrice(product))
                    ),
                    _c("br"),
                    _vm._v(
                      "\n          Qty: " + _vm._s(product.qty) + "\n        "
                    )
                  ]
                )
              ])
            ]
          )
        }),
        _vm._v(" "),
        _vm.ajaxError
          ? _c("div", {
              staticClass: "py-3 px-5 text-red text-lg",
              domProps: { innerHTML: _vm._s(_vm.ajaxError) }
            })
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "py-2 px-5" }, [
          _c(
            "button",
            {
              class: [
                "button relative inline-block uppercase tr-bg rounded text-xl font-bold leading-tight text-center cursor-pointer w-full py-2 px-5",
                _vm.isLoading || _vm.selectedProducts.length === 0
                  ? "text-white bg-grey"
                  : "bg-red text-white hover:bg-red-hover",
                _vm.isLoading ? "pl-8" : ""
              ],
              attrs: {
                type: "submit",
                disabled: _vm.isLoading || _vm.selectedProducts.length === 0
              },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.addToCart()
                }
              }
            },
            [
              _c(
                "base-icon",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isLoading,
                      expression: "isLoading"
                    }
                  ],
                  staticClass: "loading absolute ml-3 left-0 centered-y",
                  attrs: {
                    "icon-name": "loading",
                    width: "28",
                    height: "28",
                    viewbox: "0 0 32 32"
                  }
                },
                [_c("icon-loading")],
                1
              ),
              _vm._v(" "),
              _c("span", { staticClass: "inline" }, [_vm._v("Add to Cart")])
            ],
            1
          )
        ])
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }