var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.show &&
    _vm.src &&
    _vm.srcZoom &&
    _vm.src.length > 0 &&
    _vm.srcZoom.length > 0
    ? _c("div", { class: _vm.classList }, [
        _c("div", {
          staticClass:
            "overlay bg-black absolute top-0 left-0 w-full h-full opacity-50",
          on: { click: _vm.close }
        }),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "z-20 bg-white shadow border-grey-dark centered" },
          [
            _c(
              "div",
              {
                staticClass:
                  "bg-green text-white px-6 py-4 text-lg font-bold relative"
              },
              [
                _vm._v("\n      Click or hover over image to zoom\n\n      "),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass:
                      "text-white tr-opacity hover:opacity-50 float-right",
                    on: {
                      click: function($event) {
                        return _vm.$emit("close")
                      }
                    }
                  },
                  [
                    _c(
                      "base-icon",
                      {
                        attrs: {
                          "icon-name": "close",
                          width: "18",
                          height: "18",
                          viewbox: "0 0 23 23"
                        }
                      },
                      [_c("icon-x")],
                      1
                    )
                  ],
                  1
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "flex justify-center align-center" },
              [
                _c("zoomable-image", {
                  attrs: { src: _vm.src, srcZoom: _vm.srcZoom, alt: _vm.alt }
                })
              ],
              1
            )
          ]
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }