var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "customer-stories-results" }, [
    _vm.ajaxError
      ? _c("div", {
          staticClass: "mb-10 text-red text-lg",
          domProps: { innerHTML: _vm._s(_vm.ajaxError) }
        })
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      { class: [_vm.isLoading ? "opacity-25" : ""] },
      [
        _vm.items !== null && _vm.items !== undefined && _vm.items.length > 0
          ? _c(
              "div",
              { staticClass: "flex justify-between flex-wrap" },
              [
                _vm._l(_vm.items, function(item, i) {
                  return _c("customer-story-item", {
                    key: i,
                    attrs: {
                      item: item,
                      class_names: "mx-auto md:mx-0 md:w-p48 lg:w-p31 mb-8"
                    }
                  })
                }),
                _vm._v(" "),
                _vm.items !== null &&
                _vm.items !== undefined &&
                _vm.items.length % 3 !== 0
                  ? _c("div", { staticClass: "md:w-p48 lg:w-p31 mb-8" })
                  : _vm._e()
              ],
              2
            )
          : _vm._e(),
        _vm._v(" "),
        !_vm.isLoading
          ? _c("no-results", {
              attrs: { results: _vm.items, filters: true },
              on: { resetFilters: _vm.reset }
            })
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "mt-6" },
          [
            _c("base-pagination", {
              attrs: {
                max_visible_buttons:
                  _vm.numPages >= 3 ? 3 : parseInt(_vm.numPages),
                per_page: parseInt(_vm.$parent.limit),
                num_pages: parseInt(_vm.numPages),
                current_page: _vm.$parent.currentPage,
                total: parseInt(_vm.total),
                show_prev_next_btns: true,
                pagination_disabled: _vm.isLoading
              },
              on: { pagechanged: _vm.onPageChange }
            })
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }