var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    {
      staticClass: "callout-handwritten block w-full py-12 sm:py-16 lg:py-20",
      style: _vm.innerStyles
    },
    [
      _c(
        "div",
        {
          class: _vm.include_container
            ? "container mx-auto px-5-safe"
            : "full-container"
        },
        [
          _c(
            "div",
            {
              staticClass:
                "xl:max-w-2xl mx-auto bg-white border-2 border-brown p-8 md:p-12 lg:p-16"
            },
            [
              _c("div", { staticClass: "sm:flex" }, [
                _c(
                  "div",
                  { staticClass: "sm:w-1/4 sm:pr-6 md:pr-8" },
                  [
                    _vm.data.icon
                      ? _c("base-image", {
                          attrs: {
                            src: _vm.data.icon.src,
                            retina_src: _vm.data.icon.retina,
                            srcset_webp: _vm.data.icon.webpSrcset,
                            class_names: "mx-auto"
                          }
                        })
                      : _vm._e()
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "mt-6 text-center sm:text-left sm:mt-0 sm:w-3/4 text-4xl md:text-5xl lg:text-6xl font-cursive leading-loose"
                  },
                  [
                    _vm._v(
                      "\n          " + _vm._s(_vm.data.text) + "\n        "
                    )
                  ]
                )
              ]),
              _vm._v(" "),
              _vm.data.button
                ? _c(
                    "div",
                    { staticClass: "mt-12 text-center" },
                    [
                      _c("base-button", {
                        attrs: {
                          url: _vm.data.button.url,
                          label: _vm.data.button.text,
                          color: "red",
                          size: "extrawide",
                          target: _vm.data.button.target
                        }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ]
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }