var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "video-tutorials-products-nav" }, [
    _vm.ajaxError
      ? _c("div", {
          staticClass: "mb-8 text-red text-lg",
          domProps: { innerHTML: _vm._s(_vm.ajaxError) }
        })
      : _vm._e(),
    _vm._v(" "),
    _c("nav", { staticClass: "border-b border-brown" }, [
      _c(
        "ul",
        { staticClass: "text-center md:text-left" },
        _vm._l(_vm.$parent.products, function(product, p) {
          return _c(
            "li",
            {
              key: p,
              class: [
                "inline-block pt-4",
                p > 0 ? "pl-2 lg:pl-4 xl:pl-5" : "pl-2"
              ]
            },
            [
              _c(
                "button",
                {
                  class: [
                    "inline-block text-center px-2 text-base xl:text-lg uppercase font-bold text-green",
                    _vm.$parent.selectedProduct === product.slug
                      ? "active border-green border-b-5"
                      : ""
                  ],
                  attrs: { type: "button" },
                  on: {
                    click: function($event) {
                      return _vm.setProduct(product.slug)
                    }
                  }
                },
                [
                  _c("img", {
                    staticClass: "mx-auto",
                    attrs: {
                      src: product.src,
                      srcset: [
                        product.src + " 1x, " + product.retinaSrc + " 2x"
                      ],
                      alt: product.name
                    }
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "block label py-2" }, [
                    _vm._v(
                      "\n            " + _vm._s(product.name) + "\n          "
                    )
                  ])
                ]
              )
            ]
          )
        }),
        0
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }