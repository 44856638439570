import { render, staticRenderFns } from "./BlockAudio.vue?vue&type=template&id=42a5de80&scoped=true&"
import script from "./BlockAudio.vue?vue&type=script&lang=js&"
export * from "./BlockAudio.vue?vue&type=script&lang=js&"
import style0 from "./BlockAudio.vue?vue&type=style&index=0&id=42a5de80&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "42a5de80",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/ian/wildlife-website.master/craftcms/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('42a5de80')) {
      api.createRecord('42a5de80', component.options)
    } else {
      api.reload('42a5de80', component.options)
    }
    module.hot.accept("./BlockAudio.vue?vue&type=template&id=42a5de80&scoped=true&", function () {
      api.rerender('42a5de80', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/vue/blocks/BlockAudio.vue"
export default component.exports