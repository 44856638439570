<template>
    <div>
        <base-heading size="h2" color="brown" class_names="mb-6">
            Select your product
        </base-heading>

        <div class="flex items-start justify-between flex-wrap">
            <button
                v-for="(item, i) in items"
                :key="i"
                class="product-category mb-6 text-center"
                @click="selectCategory(i)">
                <span class="block thumb m-0 border border-grey-alt w-full">
                    <base-image
                        v-if="item.image"
                        :src="item.image.src"
                        :retina_src="item.image.retina"
                        :alt="item.image.alt"
                        class_names="block m-0"
                    ></base-image>
                    <span v-else class="block product-category-empty"></span>
                </span>
                <span class="block font-bold text-brown mt-3 text mx-auto">
                    {{ item.title }}
                </span>
            </button>
            <div v-for="n in 6" class="product-category product-category-placeholder"></div>
        </div>
    </div>
</template>

<script>
  export default {
    props: ['items'],
    components: {
      BaseHeading: () => import(/* webpackChunkName: "base-heading" */ "../components/BaseHeading.vue"),
      BaseImage: () => import(/* webpackChunkName: "base-image" */ "../components/BaseImage.vue")
    },
    methods: {
      selectCategory(category) {
        this.$emit("select", category)
      }
    }
  }
</script>

<style scoped>
.product-category {
    width: 48%;
    @media (min-width: 380px) {
        width: 31%;
    }
    @media (min-width: 480px) {
        width: 23.5%;
    }
    @media (min-width: 768px) {
        width: 19%;
    }
    @media (min-width: 992px) {
        width: 23.5%;
    }
    @media (min-width: 1280px) {
        width: 15%;
    }
    .text {
        max-width: 120px;
    }
}

.product-category-placeholder {
    height: 1px;
}

.product-category-empty {
    content: "";
    display: block;
    padding-bottom: 100%;
}
</style>
