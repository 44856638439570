<template>
  <section class="block custom-table white-block">
    <div :class="[
            include_container ? 'container mx-auto px-5-safe' : 'full-container',
            'py-8 lg:py-10 xl:py-12'
         ]">
      <div class="max-w-2xl mx-auto">
        <div class="mb-3 sm:hidden">
          <swipe-table-indicator></swipe-table-indicator>
        </div>
        <div class="table-wrapper min-sm">
          <table class="w-full text-base md:text-lg lg:text-xl leading-normal">
            <thead>
              <tr>
                <td
                  v-for="(column, i) in data.columns"
                  :width="column.width"
                  :align="column.align"
                  v-html="column.heading"
                  valign="bottom"
                  :class="[
                    'font-bold uppercase text-brown-lighter border-b border-brown py-3',
                    i > 0 ? 'pl-3' : '',
                    i < data.columns.length - 1 ? 'pr-3' : ''
                  ]"
                  :key="i"
                ></td>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(row, j) in data.rows" :key="j">
                <td
                  v-for="(cell, k) in row"
                  :key="k"
                  :width="data.columns[k].width"
                  :align="data.columns[k].align"
                  :class="[
                    'py-3 border-b border-brown',
                    data.firstColumnBold && k === 0 ? 'font-bold' : '',
                    k > 0 ? 'pl-3' : '',
                    k < row.length - 1 ? 'pr-3' : ''
                  ]"
                  v-html="cell"
                  valign="top"
                >
                  {{ k }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: ["data", "include_container"],
  components: {
    SwipeTableIndicator: () => import(/* webpackChunkName: "swipe-table-indicator" */ "../components/SwipeTableIndicator.vue")
  }
};
</script>
