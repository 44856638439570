<template>
  <div :class="['product-video', !block.isFirst ? 'mt-6' : '']">
    <base-video :platform="block.platform" :video_id="block.videoId"></base-video>
  </div>
</template>

<script>
export default {
  props: ["block"],

  components: {
    BaseVideo: () => import(/* webpackChunkName: "base-video" */ "../../components/BaseVideo.vue"),
  },
};
</script>
