<template>
  <div class="publications-index container px-5-safe mx-auto py-12" id="publications-top">
    <div class="lg:flex">
      <div class="lg:w-1/3 lg:pr-12">
        <publications-filter></publications-filter>
      </div>
      <div class="pt-10 lg:pt-0 lg:w-2/3 lg:pt-12">
        <publications-results></publications-results>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: [
    "limit",
    "types",
    "species",
    "products",
    "keyword",
    "selected_types",
    "selected_species",
    "selected_products",
    "types_is_active",
    "species_is_active",
    "products_is_active"
  ],
  components: {
    PublicationsFilter: () => import(/* webpackChunkName: "publications-filter" */ "./PublicationsFilter.vue"),
    PublicationsResults: () => import(/* webpackChunkName: "publications-results" */ "./PublicationsResults.vue")
  }
};
</script>
