var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("g", [
    _c("path", {
      attrs: {
        fill: "currentColor",
        d:
          "M19.7,12.3c-4.2,0-7.6,3.4-7.6,7.5s3.4,7.5,7.6,7.5s7.6-3.4,7.6-7.5C27.3,15.7,23.9,12.3,19.7,12.3z M19.7,24\n          c-2.3,0-4.2-1.9-4.2-4.2s1.9-4.2,4.2-4.2s4.2,1.9,4.2,4.2C24,22.1,22.1,24,19.7,24z"
      }
    }),
    _vm._v(" "),
    _c("path", {
      attrs: {
        fill: "currentColor",
        d:
          "M27.6,10.3c-1,0-1.8,0.8-1.8,1.8s0.8,1.8,1.8,1.8s1.8-0.8,1.8-1.8S28.5,10.3,27.6,10.3z"
      }
    }),
    _vm._v(" "),
    _c("path", {
      attrs: {
        fill: "currentColor",
        d:
          "M34.2,0.3H5.3C2.4,0.3,0,2.6,0,5.5V34c0,3,2.4,5.3,5.3,5.3h28.9c2.9,0,5.3-2.3,5.3-5.2V5.5C39.5,2.6,37.1,0.3,34.2,0.3z\n           M31.3,27c0,2.3-1.9,4.2-4.2,4.2H12.4c-2.3,0-4.2-1.9-4.2-4.2V12.6c0-2.3,1.9-4.2,4.2-4.2H27c2.3,0,4.2,1.9,4.2,4.2L31.3,27\n          L31.3,27z"
      }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }