var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "block block-icons-grid white-block" }, [
    _c(
      "div",
      {
        class: [
          _vm.include_container
            ? "container mx-auto px-5-safe"
            : "full-container",
          "py-12"
        ]
      },
      [
        _c(
          "div",
          { staticClass: "flex flex-wrap text-center" },
          _vm._l(_vm.icons, function(icon, i) {
            return _c(
              "div",
              {
                class: [
                  "w-1/2 md:w-1/3 p-6 lg:p-8 border-brown",
                  (i + 1) % 2 !== 0 ? "border-r" : "",
                  i + 1 <
                  (_vm.icons.length % 2 === 0
                    ? _vm.icons.length - 1
                    : _vm.icons.length)
                    ? "border-b"
                    : "",
                  (i + 1) % 3 !== 0 ? "md:border-r" : "md:border-r-0",
                  i + 1 < _vm.icons.length - 2 ? "md:border-b" : "md:border-b-0"
                ]
              },
              [
                _c("base-image", {
                  attrs: {
                    src: icon.src,
                    retina_src: icon.retinaSrc,
                    srcset_webp: icon.webpSrcset,
                    alt: icon.alt,
                    class_names: "block m-0",
                    url: icon.url
                  }
                }),
                _vm._v(" "),
                _c("div", { staticClass: "mt-7" }, [
                  _c(
                    "a",
                    {
                      staticClass:
                        "text-lg md:text-2xl lg:text-4xl font-bold text-brown text-hover",
                      attrs: { href: icon.url, target: icon.target }
                    },
                    [
                      _vm._v(
                        "\n            " + _vm._s(icon.heading) + "\n          "
                      )
                    ]
                  )
                ])
              ],
              1
            )
          }),
          0
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }