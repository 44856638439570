var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    {
      class: [
        "sidebar-image-and-text sidebar-block max-w-sm mx-auto",
        !_vm.is_first ? "mt-8 mb-6" : "mb-6",
        _vm.block.backgroundColor !== "white"
          ? "p-4 lg:p-6 text-white bg-" + _vm.block.backgroundColor
          : ""
      ]
    },
    [
      _vm.block.src
        ? _c(
            "div",
            [
              _c("base-image", {
                attrs: {
                  src: _vm.block.src,
                  retina_src: _vm.block.retinaSrc,
                  alt: _vm.block.alt
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.block.caption
        ? _c(
            "div",
            [
              _c("base-rich-text", {
                attrs: { size: "tiny", link_style: "red" },
                domProps: { innerHTML: _vm._s(_vm.block.caption) }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.block.heading
        ? _c(
            "div",
            [
              _c(
                "base-heading",
                {
                  attrs: {
                    size: "h2",
                    color:
                      _vm.block.backgroundColor !== "white" ? "white" : "brown"
                  }
                },
                [_vm._v("\n      " + _vm._s(_vm.block.heading) + "\n    ")]
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.block.text
        ? _c(
            "div",
            [
              _c("base-rich-text", {
                attrs: { size: "small", link_style: "red" },
                domProps: { innerHTML: _vm._s(_vm.block.text) }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.block.button
        ? _c(
            "div",
            [
              _c("base-button", {
                attrs: {
                  url: _vm.block.button.url,
                  label: _vm.block.button.text,
                  color:
                    _vm.block.backgroundColor !== "white" ? "brown" : "red",
                  size: "smaller",
                  class_names: "w-full",
                  outline: _vm.block.backgroundColor !== "white"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.block.footnote
        ? _c(
            "div",
            [
              _c("base-rich-text", {
                attrs: { size: "tiny", link_style: "red" },
                domProps: { innerHTML: _vm._s(_vm.block.footnote) }
              })
            ],
            1
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }