<template>
  <aside class="resellers-filter side-filter text-brown">
    <div class="h-12">
      <base-heading size="h2" color="brown">Select Your Region:</base-heading>
    </div>
    <ul class="border-b border-brown">
      <li v-for="(region, r) in $parent.reseller_data" :key="r">
        <button
          @click="setActiveRegion(r)"
          :class="[
            'filter-btn block w-full cursor-pointer relative text-left py-2 border-t border-brown pl-6 pr-6 text-xl xl:text-2xl font-bold uppercase',
            $parent.activeRegion === r ? 'active' : ''
          ]"
        >
          {{ region.name }}
        </button>
      </li>
    </ul>
  </aside>
</template>

<script>
export default {
  components: {
    BaseHeading: () => import(/* webpackChunkName: "base-heading" */ "../components/BaseHeading.vue")
  },
  methods: {
    setActiveRegion(region) {
      this.$emit("set", region);
    }
  }
};
</script>

<style scoped>
button {
  background-color: #f4ede3;
  &.active {
    background-color: #e9decb;
  }
}
</style>
