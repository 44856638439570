<template>
  <div :class="['product-raw-html', !block.isFirst ? 'mt-6' : '']">
    <div v-html="rawHtml"></div>
  </div>
</template>

<script>
export default {
  props: ["block"],
  computed: {
    // The whole purpose of this code is to clean up video embeds in the rawHtml.
    // For now, it just cleans up vimeo embeds, but it can be expanded easily.
    // This might be too much coe to fix a small problem, so feel free to remove.
    rawHtml() {
      var html = this.block.rawHtml;

      // make sure any vimeo links have the `dnt` parameter
      const vimeoRegex = /https:\/\/player\.vimeo\.com\/video\/[^"]*/g;

      const vimeoMatches = html.matchAll(vimeoRegex);

      for (const match of vimeoMatches) {
        let matchedText = match[0];

        if (matchedText.includes('dnt=1')) {
          continue;
        }

        if (matchedText.includes('?')) {
          html = html.replace(matchedText, `${matchedText}&dnt=1`);
        } else {
          html = html.replace(matchedText, `${matchedText}?dnt=1`);
        }
      }

      return html;
    }
  }
};
</script>
