var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "training-course container px-5-safe mx-auto pt-8 pb-12 lg:pt-12 pb-20 text-brown"
    },
    [
      _c(
        "div",
        { class: [_vm.data.displaySessions === "sidebar" ? "lg:flex" : ""] },
        [
          _c(
            "div",
            {
              class: [
                _vm.data.displaySessions === "sidebar"
                  ? "lg:w-2/3 lg:pr-20"
                  : ""
              ]
            },
            [
              _vm.data.src && _vm.data.src !== ""
                ? _c(
                    "div",
                    { staticClass: "mb-8" },
                    [
                      _c("base-image", {
                        attrs: {
                          src: _vm.data.src,
                          retina_src: _vm.data.retinaSrc,
                          alt: _vm.data.alt,
                          class_names: "block m-0"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.data.description
                ? _c(
                    "div",
                    [
                      _c("base-rich-text", {
                        domProps: { innerHTML: _vm._s(_vm.data.description) }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.data.date ||
              _vm.data.time ||
              _vm.data.location ||
              _vm.data.cost
                ? _c(
                    "div",
                    {
                      class: [
                        _vm.data.src || _vm.data.description ? "mt-8" : ""
                      ]
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "details-table border-t border-brown text-lg lg:text-xl xl:text-2xl leading-normal"
                        },
                        [
                          _vm.data.date
                            ? _c(
                                "training-course-info-row",
                                {
                                  attrs: {
                                    label:
                                      "Date" + (_vm.data.multiday ? "(s)" : "")
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(_vm.data.date) +
                                      "\n          "
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.data.time
                            ? _c(
                                "training-course-info-row",
                                { attrs: { label: "Time" } },
                                [
                                  _c("span", {
                                    domProps: {
                                      innerHTML: _vm._s(_vm.data.time)
                                    }
                                  })
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.data.location
                            ? _c(
                                "training-course-info-row",
                                { attrs: { label: "Location" } },
                                [
                                  _c("span", {
                                    domProps: {
                                      innerHTML: _vm._s(_vm.data.location)
                                    }
                                  })
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.data.cost
                            ? _c(
                                "training-course-info-row",
                                { attrs: { label: "Cost" } },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(_vm.data.cost) +
                                      "\n          "
                                  )
                                ]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ]
                  )
                : _vm._e()
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              class: [
                _vm.data.displaySessions === "sidebar"
                  ? "mt-10 lg:mt-0 lg:w-1/3"
                  : _vm.data.src ||
                    _vm.data.description ||
                    _vm.data.date ||
                    _vm.data.time ||
                    _vm.data.location ||
                    _vm.data.cost
                  ? "mt-10"
                  : ""
              ]
            },
            [
              _vm.data.sessions.length > 0
                ? _c(
                    "div",
                    [
                      _vm.data.displaySessions === "sidebar"
                        ? _c("training-course-sessions-sidebar", {
                            attrs: { sessions: _vm.data.sessions }
                          })
                        : _c("training-course-sessions-full-width", {
                            attrs: { sessions: _vm.data.sessions }
                          })
                    ],
                    1
                  )
                : _c(
                    "div",
                    {
                      staticClass:
                        "text-2xl text-center border border-grey py-12 px-6 max-w-3xl mx-auto"
                    },
                    [
                      _vm._v(
                        "\n        There are no scheduled sessions as this time.\n      "
                      )
                    ]
                  )
            ]
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }