var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "resellers-results md:flex md:justify-between text-brown" },
    _vm._l([1, 2], function(columnNumber) {
      return _c(
        "div",
        { class: ["md:w-p47", columnNumber === 2 ? "mt-10 md:mt-0" : ""] },
        _vm._l(_vm.column(columnNumber), function(item, i) {
          return _c("reseller-item", {
            key: i,
            attrs: {
              item: item,
              class_names: [
                item.showCountry && i > 0 ? "mt-10" : "",
                !item.showCountry ? "mt-6" : ""
              ]
            }
          })
        }),
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }