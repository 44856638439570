<template>
  <div class="payment-form">
    <slot></slot>
  </div>
</template>

<script>
export default {
  mounted: function() {
    document.anmanage.submit();
  }
};
</script>

<style scoped>
iframe {
  min-height: 640px;
}
</style>
