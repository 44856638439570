<template>
  <div v-if='success' class='my-4 text-2xl text-green font-bold'>
    Thank you for signing up.
  </div>

  <div v-else>
    <div class='my-4'>
      <input
        type='text'
        name='first_name'
        placeholder='First Name*'
        :class="[
          'input-text',
          errors.includes('first_name') ? 'border-red' : ''
        ]"
        v-model='first_name' 
        :disabled='is_loading' />
    </div>

    <div class='my-4'>
      <input
        type='text'
        name='last_name'
        placeholder='Last Name*'
        :class="[
          'input-text',
          errors.includes('last_name') ? 'border-red' : ''
        ]"
        v-model='last_name'
        :disabled='is_loading' />
    </div>

    <div class='my-4'>
      <input
        type='text'
        name='email'
        placeholder='Email*'
        :class="[
          'input-text',
          errors.includes('email') ? 'border-red' : ''
        ]"
        v-model='email'
        :disabled='is_loading' />
    </div>

    <div class='my-4'>
      <form-checkbox
        name='subscribe'
        :label="'Sign me up for ' + $parent.getSiteFullName() + ' emails about free trainings, customer stories, and more'"
        v-model='subscribe'
        :disabled='is_loading'
      ></form-checkbox>
    </div>

    <button
      type='button'
      @click.prevent="submit()"
      :class="[
        'button inline-block uppercase tr-all rounded text-lg xl:text-xl font-bold leading-tighter text-center',
        'border-3 border-red hover:border-red-hover text-white bg-red hover:bg-red-hover px-8 py-2 xl:py-3 mt-4'
      ]">
        {{ buttonText }}
      </button>
  </div>
</template>

<script>
import VueAxios from "../../js/modules/vue-axios";

export default {
  props: {
    buttonText: String,
    tag: String,
  },

  data: function() {
    return {
      first_name: "",
      last_name: "",
      email: "",
      subscribe: false,
      success: false,
      is_loading: false,
      errors: [],
    }
  },

  methods: {
    submit() {
      let self = this;

      // check for errors

      self.errors = [];

      if (!self.first_name) { self.errors.push('first_name'); }
      if (!self.last_name) { self.errors.push('last_name'); }
      if (!self.email) { self.errors.push('email'); }

      if (self.errors.length > 0) { return; }

      // upload the data

      self.is_loading = true;

      let formData = new FormData();
      formData.append('action', 'wildlife/mail-list/signup-simple');
      formData.append(self.$root.csrfTokenName, self.$root.csrfTokenValue);
      formData.append('fname', self.first_name);
      formData.append('lname', self.last_name);
      formData.append('email', self.email);
      formData.append('subscribe', self.subscribe);
      formData.append('tag', self.tag);

      let options = {
        method: 'POST',
        data: formData,
        url: '/'
      };

      VueAxios(options)
        .then(response => {
          let data = response.data;
          
          if (data.success) {
            self.success = true;
          } else {
            self.message = data.message;
          }

          self.is_loading = false;
        })
        .catch(error => {
          self.message = 'An error occured. Please try again.';
          self.is_loading = false;
        });
    }
  },

  components: {
    FormCheckbox: () => import(/* webpackChunkName: "form-input" */ "../components/form/FormCheckbox.vue"),
  }
};
</script>
