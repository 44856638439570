<template>
    <section :class="[
                include_container ? 'container mx-auto' : 'full-container',
                'block white-block image-block'
             ]">
        <div :class="['inner py-10 px-5-safe']">
            <div v-for="(image, i) in data.images"
                 @click="click(image)"
                 :key="'image-' + i"
                 :class="['image mb-4 sm:mb-6 lg:mb-8', !image.url ? 'cursor-pointer lg:cursor-auto' : '']">
                <base-image
                    :src="image.src"
                    :retina_src="image.retina"
                    :alt="image.alt"
                    :url="image.url"
                    class_names="w-full"
                    :target="image.target ? '_blank' : null"
                ></base-image>
                <div v-if="image.caption" class="text-sm sm:text-base lg:text-lg mt-2 leading-normal"
                     v-html="image.caption"></div>
            </div>
        </div>
        <transition name="modal" v-if="showImageModal">
            <div class="modal-mask fixed top-0 left-0 w-full h-full cursor-pointer" @click="close()">
                <div class="table w-full h-full text-center">
                    <div class="table-row">
                        <span class="table-cell relative p-4 mx-auto align-middle">
                            <span class="inline-block relative">
                                <button
                                    type="button"
                                    @click="close"
                                    class="absolute top-0 right-0 p-4 sm:p-6 text-white tr-opacity hover:opacity-50"
                                >
                                    <base-icon icon-name="close-icon" width="20" height="20" viewbox="0 0 23 23">
                                        <icon-x></icon-x>
                                    </base-icon>
                                </button>
                                <img :src="showImageModal" alt="">
                            </span>
                        </span>
                    </div>
                </div>
            </div>
        </transition>
    </section>
</template>

<script>
  export default {
    data: function() {
      return {
        active: null,
        showImageModal: null
      };
    },
    props: ["data", "include_container"],
    components: {
      BaseIcon: () => import(/* webpackChunkName: "base-icon" */ "../components/BaseIcon.vue"),
      BaseImage: () => import(/* webpackChunkName: "base-image" */ "../components/BaseImage.vue"),
      IconX: () => import(/* webpackChunkName: "icon-x" */ "../icons/IconX.vue"),
    },
    methods: {
      close() {
        this.showImageModal = null;
      },
      click(image) {
        // if mobile
        if (window.innerWidth < 992) {
          // if image doesn't have URL
          if (!image.url && (image.retina || image.src)) {
            // show image as modal (uses BaseImageModal.vue set in base.twig)
            this.showImageModal = image.retina ? image.retina : image.src;
          }
        }
      }
    },
    mounted() {
      // close contact model on ESC
      let self = this;
      document.body.addEventListener("keyup", function(e) {
        if (e.keyCode === 27) {
          self.close();
        }
      });
    },
    watch: {
      showImageModal(val) {
        if (val) {
          document.documentElement.classList.add("image-modal");
        } else {
          document.documentElement.classList.remove("image-modal");
        }
      }
    }
  }
</script>

<style scoped>
.modal-mask {
    z-index: 9998;
    background-color: rgba(0, 0, 0, 0.7);
    transition: opacity 0.3s ease;
    img {
        margin-left: auto;
        margin-right: auto;
        max-height: calc(100vh - 32px);
    }
}
    .inner {
        float: right;
        max-width: 33%;
        margin-left: 20px;
        @media (min-width: 992px) {
            margin-left: 40px;
        }
    }
</style>
