var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: [
        "product-specifications text-2xl text-brown",
        !_vm.block.isFirst ? "mt-6" : ""
      ]
    },
    _vm._l(_vm.block.rows, function(row, r) {
      return _c(
        "div",
        { class: ["lg:flex", r > 0 ? "border-t border-brown" : ""] },
        [
          _c("div", { staticClass: "lg:w-p40 font-bold pt-4 pb-3 lg:py-4" }, [
            _vm._v("\n      " + _vm._s(row.heading) + "\n    ")
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "lg:w-p60 pb-4 lg:py-4" }, [
            row.type === "text"
              ? _c("div", {
                  staticClass: "rich-text leading-normal text-2xl",
                  domProps: { innerHTML: _vm._s(row.text) }
                })
              : _vm._e(),
            _vm._v(" "),
            row.type === "columns"
              ? _c(
                  "div",
                  { staticClass: "xl:flex lg:justify-between" },
                  _vm._l(row.columns, function(column, c) {
                    return _c("div", {
                      class:
                        row.columns.length === 3
                          ? (c > 0 ? "mt-3 xl:mt-0 " : "") + "xl:w-p31"
                          : row.columns.length === 2
                          ? "xl:w-p48"
                          : "",
                      domProps: { innerHTML: _vm._s(column) }
                    })
                  }),
                  0
                )
              : _vm._e()
          ])
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }