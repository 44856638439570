var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: [
        "results-info text-xl text-brown-lighter uppercase font-bold",
        _vm.class_names
      ]
    },
    [
      _vm.total > 0
        ? _c("div", [
            _vm._v(
              "Showing " +
                _vm._s(_vm.firstResultNumber) +
                " to " +
                _vm._s(_vm.lastResultNumber) +
                " of " +
                _vm._s(_vm.total) +
                " results"
            )
          ])
        : _c("div", [
            _vm._v("\n    Your search for "),
            _c("span", { class: ["text-brown"] }, [
              _vm._v(_vm._s(_vm.keyword))
            ]),
            _vm._v(" didn’t return any results\n  ")
          ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }