<template>
  <base-heading
    :size="textSize"
    :color="block.color"
    :class_names="!block.isFirst ? (block.previousBlock === 'heading' ? 'mt-3' : 'mt-6') : ''"
  >
    {{ block.text }}
  </base-heading>
</template>

<script>
export default {
  props: ["block"],
  components: {
    BaseHeading: () => import(/* webpackChunkName: "base-heading" */ "../../components/BaseHeading.vue")
  },
  computed: {
    textSize() {
      switch (this.block.size) {
        case "small":
          return "h4";
        case "large":
          return "h1";
        default:
          return "h2";
      }
    }
  }
};
</script>
