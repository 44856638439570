var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    {
      staticClass: "callout-handwritten block w-full py-12 sm:py-16 lg:py-20",
      style: _vm.innerStyles
    },
    [
      _c(
        "div",
        {
          class: _vm.include_container
            ? "container mx-auto px-5-safe"
            : "full-container"
        },
        [
          _c(
            "div",
            {
              staticClass:
                "xl:max-w-2xl mx-auto bg-white border-2 border-brown p-6 sm:p-8 md:p-12 lg:p-16"
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "flex text-center flex-wrap text-base sm:text-lg uppercase"
                },
                _vm._l(_vm.data.icons, function(icon, i) {
                  return _c(
                    "div",
                    {
                      key: i,
                      class: [
                        _vm.columnClasses,
                        i > 0 ? "md:border-l md:border-brown" : "",
                        _vm.data.icons.length === 4 && (i === 1 || i === 3)
                          ? "border-l border-brown"
                          : "",
                        (_vm.data.icons.length === 3 ||
                          _vm.data.icons.length === 2) &&
                        i === 1
                          ? "border-l border-brown"
                          : "",
                        _vm.data.icons.length === 3 && i === 2
                          ? "mx-auto md:mx-0"
                          : "",
                        "p-4 sm:py-6 sm:px-8 mb-6"
                      ]
                    },
                    [
                      _c("base-image", {
                        attrs: {
                          src: icon.src,
                          retina_src: icon.retina,
                          srcset_webp: icon.webpSrcset,
                          class_names: "mx-auto"
                        }
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "mt-6" }, [
                        _vm._v(
                          "\n                        " +
                            _vm._s(icon.caption) +
                            "\n                    "
                        )
                      ])
                    ],
                    1
                  )
                }),
                0
              ),
              _vm._v(" "),
              _vm.data.button
                ? _c(
                    "div",
                    { staticClass: "mt-6 text-center" },
                    [
                      _c("base-button", {
                        attrs: {
                          url: _vm.data.button.url,
                          label: _vm.data.button.text,
                          color: "red",
                          size: "extrawide",
                          target: _vm.data.button.target
                        }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ]
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }