var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "training-course-info-row sm:flex border-b border-brown" },
    [
      _c(
        "div",
        {
          staticClass:
            "sm:w-1/4 font-bold px-3 lg:px-4 xl:px-5 pt-3 sm:py-3 xl:py-4"
        },
        [_vm._v("\n    " + _vm._s(_vm.label) + "\n  ")]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "sm:w-3/4 px-3 lg:px-4 xl:px-5 pt-2 pb-3 sm:py-3 xl:py-4"
        },
        [_vm._t("default")],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }