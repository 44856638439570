<template>
  <div class="add-to-cart-box wrapper mx-auto">
    <div class="text-brown text-left">
      <div class="text-2hxl xl:text-3xl font-bold">
        {{ $root.formattedPrice(product.list_price) }}
        <span class="text-sm">{{ $root.formattedCurrency() }}</span>
      </div>

      <div v-if="product.stock" class="text-sm uppercase mt-1 product-stock" v-html="product.stock"></div>

      <div class="stock text-sm mt-1">
        Product Code: {{ product.product_code }}
      </div>

      <div v-if="ajaxError" class="mt-10 text-red text-lg" v-html="ajaxError"></div>

      <div class="mt-4">
        <button
          type="submit"
          @click.prevent="addToCart()"
          :disabled="isLoading || quantity < 1"
          :class="[
            'relative loading-btn bg-brown-lighter text-xl inline-block font-bold rounded-lg text-center h-12 w-full uppercase',
            isLoading || quantity < 1 ? 'text-grey' : 'text-white tr-color tr-bg hover:text-white hover:bg-green',
            isLoading ? 'pl-8' : ''
          ]"
        >
          <base-icon
            icon-name="loading"
            width="28"
            height="28"
            viewbox="0 0 32 32"
            class="loading absolute ml-3 left-0 centered-y"
            v-show="isLoading"
          >
            <icon-loading></icon-loading>
          </base-icon>
          <span class="inline">Add to Cart</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import VueAxios from "../../../js/modules/vue-axios";
import { gaAddToCart } from "../../../js/modules/wa-analytics";

export default {
  props: ["product"],
  data: function() {
    return {
      isLoading: false,
      quantity: 1,
      ajaxError: ""
    };
  },
  components: {
    BaseIcon: () => import(/* webpackChunkName: "base-icon" */ "../../components/BaseIcon.vue"),
    IconLoading: () => import(/* webpackChunkName: "icon-loading" */ "../../icons/IconLoading.vue"),
  },
  methods: {
    addToCart() {
      let self = this;
      self.isLoading = true;
      const productCode = self.product.product_code.replace(/\\/g, '\\\\');

      let formData = new FormData();
      formData.append(self.$root.csrfTokenName, self.$root.csrfTokenValue);
      formData.append("quantity_" + productCode, self.quantity);
      formData.append("add_" + productCode, "");
      formData.append("action", "wildlife/shop/update");
      let options = {
        method: "POST",
        data: formData,
        url: "/"
      };
      self.ajaxError = "";
      VueAxios(options)
        .then(response => {
          let data = response.data || null,
              cart = data.cart || null;

          if (data.success && cart) {
            // Send to Google Analytics
            gaAddToCart(
                [{
                    name: this.product.product_name,
                    id: this.product.product_code,
                    price: this.product.list_price,
                    brand: self.$root.getSiteFullName(),
                    quantity: self.quantity
                }],
                {
                    list: "Accessories"
                },
                self.quantity,
                self.$root.currencyCode()
            );

            window.location.href = '/shop/cart';
          } else {
            self.isLoading = false;
            self.ajaxError = "An error occurred. Please try again.";
          }
        })
        .catch(error => {
          self.ajaxError = "An error occurred. Please try again.";
          self.isLoading = false;
        });
    }
  }
};
</script>

<style scoped>
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}
.product-stock >>> a {
  @apply underline text-red;
}
</style>
