var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "g",
    { attrs: { transform: "translate(-1177.000000, -6705.000000)" } },
    [
      _c("g", { attrs: { transform: "translate(0.000000, 6464.000000)" } }, [
        _c("path", {
          attrs: {
            fill: "currentColor",
            d:
              "M1185.1,241.2l0.8,0.9c0.1,0.1,0.2,0.2,0.2,0.4s-0.1,0.3-0.2,0.4l-6.1,6.7l6.1,6.7c0.1,0.1,0.2,0.2,0.2,0.4\n                  s-0.1,0.3-0.2,0.4l-0.8,0.9c-0.1,0.1-0.2,0.2-0.4,0.2c-0.1,0-0.3-0.1-0.4-0.2l-7.2-7.9c-0.1-0.1-0.2-0.2-0.2-0.4s0.1-0.3,0.2-0.4\n                  l7.2-7.9c0.1-0.1,0.2-0.2,0.4-0.2"
          }
        })
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }