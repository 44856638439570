var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    {
      directives: [
        {
          name: "lazy",
          rawName: "v-lazy:background-image",
          value: _vm.sectionBackgroundSrc,
          expression: "sectionBackgroundSrc",
          arg: "background-image"
        }
      ],
      staticClass:
        "block picture-squares-cta pics-cta overflow-hidden bg-cover",
      style: _vm.sectionStyles
    },
    [
      _c(
        "div",
        {
          class: [
            _vm.include_container
              ? "container mx-auto px-5-safe"
              : "full-container",
            "relative"
          ]
        },
        [
          _vm.images.length >= 9
            ? _c(
                "div",
                { staticClass: "row flex flex-row flex-wrap w-full" },
                _vm._l(5, function(i) {
                  return _vm.images[_vm.pos(1, i)]
                    ? _c(
                        "div",
                        { class: ["square", _vm.rowClasses[i - 1]] },
                        [
                          _c("base-image", {
                            attrs: {
                              src: _vm.images[_vm.pos(1, i)].src,
                              retina_src: _vm.images[_vm.pos(1, i)].retinaSrc,
                              srcset_webp: _vm.images[_vm.pos(1, i)].webpSrcset,
                              alt: _vm.images[_vm.pos(1, i)].alt,
                              class_names: "w-full"
                            }
                          }),
                          _vm._v(" "),
                          _c(_vm.images[_vm.pos(1, i)].url ? "a" : "span", {
                            tag: "component",
                            staticClass: "over",
                            attrs: { href: _vm.images[_vm.pos(1, i)].url }
                          })
                        ],
                        1
                      )
                    : _vm._e()
                }),
                0
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.images.length >= 19
            ? _c(
                "div",
                { staticClass: "row flex flex-row flex-wrap w-full" },
                _vm._l(5, function(i) {
                  return _vm.images[_vm.pos(5, i)]
                    ? _c(
                        "div",
                        { class: ["square", _vm.rowClasses[i - 1]] },
                        [
                          _c("base-image", {
                            attrs: {
                              src: _vm.images[_vm.pos(5, i)].src,
                              retina_src: _vm.images[_vm.pos(5, i)].retinaSrc,
                              srcset_webp: _vm.images[_vm.pos(5, i)].webpSrcset,
                              class_names: "w-full",
                              alt: _vm.images[_vm.pos(5, i)].alt
                            }
                          }),
                          _vm._v(" "),
                          _c(_vm.images[_vm.pos(5, i)].url ? "a" : "span", {
                            tag: "component",
                            staticClass: "over",
                            attrs: { href: _vm.images[_vm.pos(5, i)].url }
                          })
                        ],
                        1
                      )
                    : _vm._e()
                }),
                0
              )
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "row flex flex-row flex-wrap w-full" }, [
            _c(
              "div",
              { staticClass: "hidden md:inline md:w-1/4 lg:w-2/5" },
              _vm._l(2, function(r) {
                return _c(
                  "div",
                  { staticClass: "row flex flex-row flex-wrap w-full" },
                  _vm._l(2, function(i) {
                    return _vm.images[_vm.pos(r + 2, i)]
                      ? _c(
                          "div",
                          { staticClass: "square w-full lg:w-1/5" },
                          [
                            _c("base-image", {
                              attrs: {
                                src: _vm.images[_vm.pos(r + 2, i)].src,
                                retina_src:
                                  _vm.images[_vm.pos(r + 2, i)].retinaSrc,
                                srcset_webp:
                                  _vm.images[_vm.pos(r + 2, i)].webpSrcset,
                                class_names: "w-full",
                                alt: _vm.images[_vm.pos(r + 2, i)].alt
                              }
                            }),
                            _vm._v(" "),
                            _c(
                              _vm.images[_vm.pos(r + 2, i)].url ? "a" : "span",
                              {
                                tag: "component",
                                staticClass: "over",
                                attrs: {
                                  href: _vm.images[_vm.pos(r + 2, i)].url
                                }
                              }
                            )
                          ],
                          1
                        )
                      : _vm._e()
                  }),
                  0
                )
              }),
              0
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "lazy",
                    rawName: "v-lazy:background-image",
                    value: _vm.text_background,
                    expression: "text_background",
                    arg: "background-image"
                  }
                ],
                staticClass:
                  "text-container w-full md:w-3/4 lg:w-3/5 bg-red bg-cover",
                style: _vm.textContainerStyles
              },
              [
                _c(
                  "div",
                  { staticClass: "flex w-full h-full flex items-center" },
                  [
                    _c(
                      "div",
                      { staticClass: "py-10 md:py-4 px-20-safe text-white" },
                      [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  _vm.icon_src !== "" &&
                                  _vm.icon_retina_src !== "",
                                expression:
                                  "icon_src !== '' && icon_retina_src !== ''"
                              }
                            ]
                          },
                          [
                            _c("base-image", {
                              attrs: {
                                src: _vm.icon_src,
                                retina_src: _vm.icon_retina_src,
                                alt: _vm.icon_alt
                              }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "base-heading",
                          {
                            attrs: {
                              size: "h1",
                              centered: true,
                              class_names: "leading-none",
                              color: "white"
                            }
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.heading) +
                                "\n            "
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "mt-4 text-lg lg:text-xl xl:text-2hxl text-center leading-normal"
                          },
                          [_vm._t("text")],
                          2
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "text-center mt-6 xl:mt-10" },
                          [_vm._t("buttons")],
                          2
                        )
                      ],
                      1
                    )
                  ]
                )
              ]
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "hidden sm:block" }, [
            _vm.images.length >= 14
              ? _c(
                  "div",
                  { staticClass: "row flex flex-row flex-wrap w-full" },
                  _vm._l(5, function(i) {
                    return _vm.images[_vm.pos(2, i)]
                      ? _c(
                          "div",
                          { class: ["square", _vm.rowClasses[i - 1]] },
                          [
                            _c("base-image", {
                              attrs: {
                                src: _vm.images[_vm.pos(2, i)].src,
                                retina_src: _vm.images[_vm.pos(2, i)].retinaSrc,
                                srcset_webp:
                                  _vm.images[_vm.pos(2, i)].webpSrcset,
                                alt: _vm.images[_vm.pos(2, i)].alt,
                                class_names: "w-full"
                              }
                            }),
                            _vm._v(" "),
                            _c(_vm.images[_vm.pos(2, i)].url ? "a" : "span", {
                              tag: "component",
                              staticClass: "over",
                              attrs: { href: _vm.images[_vm.pos(2, i)].url }
                            })
                          ],
                          1
                        )
                      : _vm._e()
                  }),
                  0
                )
              : _vm._e()
          ])
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "over" })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }