import { render, staticRenderFns } from "./AddToCartBoxEssentialProducts.vue?vue&type=template&id=8604a770&scoped=true&"
import script from "./AddToCartBoxEssentialProducts.vue?vue&type=script&lang=js&"
export * from "./AddToCartBoxEssentialProducts.vue?vue&type=script&lang=js&"
import style0 from "./AddToCartBoxEssentialProducts.vue?vue&type=style&index=0&id=8604a770&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8604a770",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/ian/wildlife-website.master/craftcms/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('8604a770')) {
      api.createRecord('8604a770', component.options)
    } else {
      api.reload('8604a770', component.options)
    }
    module.hot.accept("./AddToCartBoxEssentialProducts.vue?vue&type=template&id=8604a770&scoped=true&", function () {
      api.rerender('8604a770', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/vue/product/add-to-cart/AddToCartBoxEssentialProducts.vue"
export default component.exports