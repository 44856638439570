<template>
  <div :class="['sidebar-separator sidebar-block max-w-sm mx-auto mb-8', !is_first ? 'mt-12' : '']">
    <hr class="block bg-brown-lighter border-0" />
  </div>
</template>

<script>
export default {
  props: ["block", "is_first"]
};
</script>

<style scoped>
hr {
  width: 120px;
  height: 3px;
}
</style>
