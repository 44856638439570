var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "news-index container px-5-safe mx-auto py-12",
      attrs: { id: "news-top" }
    },
    [
      _c("news-featured-story"),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "mb-8" },
        [
          _c("search-bar", {
            attrs: { keyword: _vm.keyword, placeholder: "Search news" },
            on: { updateResults: _vm.keywordChanged }
          })
        ],
        1
      ),
      _vm._v(" "),
      _vm.ajaxError
        ? _c("div", {
            staticClass: "mb-10 text-red text-lg",
            domProps: { innerHTML: _vm._s(_vm.ajaxError) }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("results-info", {
        attrs: {
          total: _vm.total,
          current_page: _vm.currentPage,
          limit: _vm.limit,
          keyword: _vm.keyword,
          class_names: "mb-6"
        }
      }),
      _vm._v(" "),
      _vm.items && _vm.items.length > 0
        ? _c(
            "div",
            {
              class: [
                "md:flex md:justify-between md:flex-wrap",
                _vm.isLoading ? "opacity-25" : ""
              ]
            },
            [
              _vm._l(_vm.items, function(item, i) {
                return _c("news-item", {
                  key: i,
                  attrs: { item: item, isFirst: i === 0 }
                })
              }),
              _vm._v(" "),
              _vm.items.length % 3 !== -1
                ? _c("div", { staticClass: "w-full md:w-p48 lg:w-p31 mb-6" }, [
                    _vm._v(" ")
                  ])
                : _vm._e()
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _c("no-results", {
        attrs: { results: _vm.items, keyword: _vm.keyword },
        on: { resetKeyword: _vm.resetKeyword }
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "mt-10" },
        [
          _c("base-pagination", {
            attrs: {
              max_visible_buttons:
                _vm.numPages >= 3 ? 3 : parseInt(_vm.numPages),
              per_page: parseInt(_vm.limit),
              num_pages: parseInt(_vm.numPages),
              current_page: _vm.currentPage,
              total: parseInt(_vm.total),
              show_prev_next_btns: true,
              pagination_disabled: _vm.isLoading
            },
            on: { pagechanged: _vm.onPageChange }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }