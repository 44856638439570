var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.results || _vm.results.length === 0
    ? _c("div", [
        _c(
          "div",
          {
            staticClass:
              "no-results w-full rounded-lg bg-grey-lighter text-center py-20"
          },
          [
            _c("div", { staticClass: "text-3xl uppercase text-grey-dark" }, [
              _vm._v("\n      No results found\n    ")
            ]),
            _vm._v(" "),
            _vm.filters
              ? _c("div", { staticClass: "mt-12" }, [
                  _c(
                    "button",
                    {
                      staticClass:
                        "button inline-block uppercase tr-all rounded text-lg xl:text-xl font-bold leading-tighter text-center cursor-pointer text-white bg-red hover:bg-red-hover px-4 py-2",
                      on: { click: _vm.resetFilters }
                    },
                    [_vm._v("\n        Reset Filters\n      ")]
                  )
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.keyword
              ? _c("div", [
                  _c(
                    "div",
                    {
                      staticClass:
                        "text-xl uppercase text-grey-dark mt-2 leading-normal"
                    },
                    [
                      _vm._v("\n        with keyword\n        "),
                      _c("span", { staticClass: "italic" }, [
                        _vm._v("`" + _vm._s(_vm.keyword) + "`")
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "mt-12" }, [
                    _c(
                      "button",
                      {
                        staticClass:
                          "button inline-block uppercase tr-all rounded text-lg xl:text-xl font-bold leading-tighter text-center cursor-pointer text-white bg-red hover:bg-red-hover px-4 py-2",
                        on: { click: _vm.resetKeyword }
                      },
                      [_vm._v("\n          Reset Search\n        ")]
                    )
                  ])
                ])
              : _vm._e()
          ]
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }