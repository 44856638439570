<template>
  <div class="customer-stories-index container px-5-safe mx-auto py-12" id="customer-stories-top">
    <customer-stories-filter v-if="showFilters"></customer-stories-filter>
    <div :class="[showFilters ? 'mt-8 lg:mt-10 xl:mt-12' : '']">
      <customer-stories-results @setPageNumber="setPageNumber" @updateUrl="updateUrl"></customer-stories-results>
    </div>
  </div>
</template>

<script>
import HelpersMixin from "../mixins/Helpers";

export default {
  data: function() {
    return {
      currentPage: 1,
      selectedCustomerStoryType: "",
      selectedSpecies: "",
      selectedProduct: "",
      selectedResearchType: ""
    };
  },
  props: [
    "limit",
    "customer_story_types",
    "species",
    "products",
    "research_types",
    "page_from_url",
    "customer_story_type_from_url",
    "species_from_url",
    "product_from_url",
    "research_type_from_url"
  ],
  mixins: [HelpersMixin],
  components: {
    CustomerStoriesFilter: () => import(/* webpackChunkName: "customer-stories-filter" */ "./CustomerStoriesFilter.vue"),
    CustomerStoriesResults: () => import(/* webpackChunkName: "customer-stories-results" */ "./CustomerStoriesResults.vue")
  },
  methods: {
    setPageNumber(pageNumber) {
      this.currentPage = pageNumber;
    },
    updateUrl() {
      let url = "/customer-stories",
        andChar = "?";
      if (this.currentPage > 1) {
        url += "/p" + this.currentPage;
      }
      if (this.selectedCustomerStoryType !== "") {
        url += andChar + "st=" + encodeURIComponent(this.selectedCustomerStoryType);
        andChar = "&";
      }
      if (this.selectedSpecies !== "") {
        url += andChar + "sp=" + encodeURIComponent(this.selectedSpecies);
        andChar = "&";
      }
      if (this.selectedProduct !== "") {
        url += andChar + "pr=" + encodeURIComponent(this.selectedProduct);
        andChar = "&";
      }
      if (this.selectedResearchType !== "") {
        url += andChar + "rt=" + encodeURIComponent(this.selectedResearchType);
        andChar = "&";
      }
      this.setUrl("customer_stories", document.title, url);
    }
  },
  computed: {
    showFilters() {
      return (
        Object.keys(this.customer_story_types).length > 0 ||
        Object.keys(this.species).length > 0 ||
        Object.keys(this.products).length > 0 ||
        Object.keys(this.research_types).length > 0
      );
    }
  },
  mounted: function() {
    if (this.page_from_url !== "") {
      this.currentPage = parseInt(this.page_from_url);
    }
    if (this.customer_story_type_from_url !== "") {
      this.selectedCustomerStoryType = this.customer_story_type_from_url;
    }
    if (this.species_from_url !== "") {
      this.selectedSpecies = this.species_from_url;
    }
    if (this.product_from_url !== "") {
      this.selectedProduct = this.product_from_url;
    }
    if (this.research_type_from_url !== "") {
      this.selectedResearchType = this.research_type_from_url;
    }
  }
};
</script>
