var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: [
        "product-side-by-side-text-and-image md:flex",
        _vm.block.position === "right" ? "flex-row-reverse" : "",
        !_vm.block.isFirst ? "mt-6" : "",
        _vm.block.previousBlock === "sideBySideTextAndImage"
          ? "border-t border-brown pt-6"
          : ""
      ]
    },
    [
      _c(
        "div",
        { staticClass: "col-1 mb-6 md:mb-0" },
        [
          _vm.block.product
            ? _c("base-image", {
                attrs: {
                  src: _vm.block.product.main_product_image,
                  class_names: "mx-auto"
                }
              })
            : _c("base-image", {
                attrs: {
                  src: _vm.block.src,
                  retina_src: _vm.block.retinaSrc,
                  alt: _vm.block.alt,
                  class_names: "mx-auto"
                }
              })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          class: [
            "col-2",
            _vm.block.position === "right"
              ? "md:pr-6 lg:pr-8 xl:pr-10"
              : "md:pl-6 lg:pl-8 xl:pl-10",
            "md:flex"
          ]
        },
        [
          _c(
            "div",
            { staticClass: "flex-auto" },
            [
              _c(
                "base-heading",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.block.text !== "",
                      expression: "block.text !== ''"
                    }
                  ],
                  attrs: { size: "h2", color: "brown" }
                },
                [_vm._v("\n        " + _vm._s(_vm.block.heading) + "\n      ")]
              ),
              _vm._v(" "),
              _c("base-rich-text", {
                attrs: { class_names: _vm.block.heading !== "" ? "mt-2" : "" },
                domProps: { innerHTML: _vm._s(_vm.block.text) }
              })
            ],
            1
          ),
          _vm._v(" "),
          _vm.block.product
            ? _c(
                "div",
                {
                  staticClass:
                    "flex-none md:w-64 md:ml-6 lg:ml-8 xl:ml-10 mt-10 md:mt-0"
                },
                [
                  _c("add-to-cart-simple", {
                    attrs: { product: _vm.block.product }
                  })
                ],
                1
              )
            : _vm._e()
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }