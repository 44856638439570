<template>
  <div v-if="isModalOpen" class="modal-overlay">
    <div class="modal rounded-lg min-w-64 p-0">
      <div class="modal-header bg-green text-white pt-5 pb-4">
        <button class="modal-close" @click="closeModal">✖</button>
        <h2 class="text-center" style="margin:0;">Authorize IP Access</h2>
        <div class="text-brown-lightest text-center text-xl">System: {{ item.thing_name }}</div>
      </div>
      <div class="modal-content flex">
        <div class="modal-left w-1/2 pr-8 flex flex-col items-center justify-center">
          <form method="post">
            <input type="hidden" :name="$root.csrfTokenName" :value="$root.csrfTokenValue">
            <input type="hidden" name="action" value="wildlife/iot/access">
            <input type="hidden" name="sn" :value="item.thing_sn">
            <input type="hidden" name="device_name" :value="item.thing_name">
            <table>
              <tr class="flex flex-col items-center justify-center">
                <h3 class="text-center">Enter an IP Address</h3>
                <td class="w-full" style="padding:0;">
                  <input
                      type="text"
                      class="input-text smaller rounded-lg"
                      name="ip"
                      v-if="!item.operator_ip"
                      :value="userIp"
                  >
                  <input
                      type="text"
                      class="input-text smaller rounded-lg"
                      name="ip"
                      v-else
                      :value="item.operator_ip"
                  >
                </td>
              </tr>
              <tr>
                <td class="w-full flex justify-between" style="padding-top:0.75rem;">
                  <base-button
                      type="submit"
                      name="change"
                      value="1"
                      label="Authorize"
                      size="wider"
                      color="red"
                      textsize="text-base"
                      class_names="mx-1"
                  ></base-button>

                  <base-button
                      v-if="item.operator_ip"
                      type="submit"
                      name="revoke"
                      value="1"
                      label="Revoke"
                      size="wider"
                      color="red"
                      textsize="text-base"
                      class_names="mx-1"
                  ></base-button>
                  <button v-else disabled type="submit" value="1" name="revoke" class="button uppercase inline-block tr-all rounded font-bold leading-tighter text-center cursor-pointer text-base border-3 border-grey text-white bg-grey px-4 py-2 xl:px-5 xl:py-3 mx-1">
                    Revoke
                  </button>

                  <base-button
                      v-if="item.is_connected === 'f'"
                      type="submit"
                      name="delete"
                      value="1"
                      label="Delete"
                      size="wider"
                      color="red"
                      textsize="text-base"
                      class_names="mx-1"
                  ></base-button>
                </td>
              </tr>
            </table>
          </form>
        </div>

        <div class="flex flex-col items-center justify-center">
          <div class="w-1 bg-black h-32 relative text-transparent">.</div>
          <span class="py-4 text-2xl">or</span>
          <div class="w-1 bg-black h-32 relative text-transparent">.</div>
        </div>

        <div class="modal-right w-1/2 pl-8 flex flex-col items-center justify-center pt-2 pb-4">
          <form method="post">
            <input type="hidden" :name="$root.csrfTokenName" :value="$root.csrfTokenValue">
            <input type="hidden" name="action" value="wildlife/iot/access">
            <input type="hidden" name="sn" :value="item.thing_sn">
            <input type="hidden" name="device_name" :value="item.thing_name">
            <input type="hidden" name="ip" :value="userIp">
            <base-button
                type="submit"
                name="change"
                value="1"
                label="Authorize My IP Address"
                size="extrawide"
                color="red"
                textsize="text-lg"
                class_names="mb-4 mt-8"
            ></base-button>
          </form>
          <h2>Your IP: {{ userIp }}</h2>
        </div>
      </div>

      <div class="modal-footer w-full flex items-center justify-center pb-8">
        <button
            @click="closeModal"
            class="button inline-block tr-all w-full max-w-1/2 rounded font-bold leading-tighter text-center cursor-pointer border-3 border-red hover:border-red-hover text-white bg-red hover:bg-red-hover p-2"
        >
          Cancel
        </button>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'device-modal',
    props: {
      isModalOpen: Boolean,
      userIp: String,
      item: Object,
    },
    components: {
      BaseButton: () => import(/* webpackChunkName: "base-button" */ "../components/BaseButton.vue"),
    },
    methods: {
      closeModal() {
        this.$emit('close-modal');
      },
    },
  };
</script>

<style scoped>
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 50;
  }

  .modal {
    background: white;
    max-width: 750px;
    width: 100%;
    height: auto;
    border-radius: 10px;
    position: relative;
    padding: 0;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }

  .modal-close {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
  }

  .modal-content {
    padding: 20px;
  }

  .modal-footer {
    padding: 10px;
  }

  .confirmation-message {
    background-color: #e6ffed;
    color: #2d7f2e;
    padding: 10px;
    margin: 10px;
    text-align: center;
    border-radius: 5px;
  }
</style>
