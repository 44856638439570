<template>
  <section class="block timeline-block bg-cover" v-lazy:background-image="background">
    <div :class="[include_container ? 'container mx-auto px-5-safe' : 'full-container', 'py-10']">
      <div class="mb-8 text-white">
        <base-heading size="h1" color="white" class_names="mb-6">{{ heading }}</base-heading>
        <base-rich-text>
          <slot name="text"></slot>
        </base-rich-text>
      </div>
      <div class="timeline-container relative">
        <div v-if="showInstructions"
          class="instructions absolute bottom-0 left-0 text-center leading-normal text-red font-cursive text-4xl">
          <div v-html="instructions_text"></div>
          <div class="mt-3">
            <base-image src="/img/timeline-arrow.png" retina="/img/timeline-arrow@2x.png" alt=""></base-image>
          </div>
        </div>
        <carousel id="timeline" :navigationEnabled="true" :paginationEnabled="false" :centerMode="true"
          :scrollPerPage="false" :perPage="1" :perPageCustom="[
            [480, 1],
            [600, 3],
            [768, 6],
            [1200, 12]
          ]" class="flex timeline-item-row">
          <slide v-for="(item, i) in items" :key="i">
            <div @click="(active = i), (showInstructions = false)" :class="{ active: i == active }"
              class="timeline-item relative">
              <div :class="['modal', i === 0 ? 'first' : '', items.length === i + 1 ? 'last' : '']">
                <base-image :src="item.src" :retina="item.retinaSrc" :srcset_webp="item.webpSrcset" :alt="item.alt">
                </base-image>
                <div class="p-3">
                  <base-heading :uppercase="true" size="h4" color="brown-lighter">
                    {{ item.month }}
                    {{ item.year }}
                  </base-heading>
                  <div class="text-base leading-normal mt-1 text-brown" v-html="item.text"></div>
                </div>
              </div>
              <div class="timeline-item--block"></div>
              <div class="text-white text-lg italic mt-2 text-center">
                {{ item.year }}
              </div>
            </div>
          </slide>
        </carousel>
      </div>
    </div>
  </section>
</template>

<script>
import { Carousel, Slide } from "vue-carousel";

export default {
  props: ["background", "heading", "instructions_text", "items", "include_container"],
  name: "timeline",
  data: function () {
    return {
      tooltip: require("../../assets/instructions.png"),
      showInstructions: true,
      active: 8
    };
  },
  components: {
    BaseHeading: () => import(/* webpackChunkName: "base-heading" */ "../components/BaseHeading.vue"),
    BaseImage: () => import(/* webpackChunkName: "base-image" */ "../components/BaseImage.vue"),
    BaseRichText: () => import(/* webpackChunkName: "base-rich-text" */ "../components/BaseRichText.vue"),
    Carousel,
    Slide
  }
};
</script>

<style>
.timeline-block .timeline-container {
  padding-top: 520px;
  padding-bottom: 120px;
}

.timeline-block .timeline-item-row {
  display: flex;
}

.timeline-block .timeline-item {
  flex-grow: 1;
  cursor: pointer;
}

.timeline-block .instructions {
  text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.5);
  bottom: 215px;
  max-width: 320px;
}

.timeline-block .timeline-item--block {
  width: 100%;
  max-width: 100%;
  height: 25px;
  background: #bc955c;
  opacity: 0.5;
  transition: opacity 0.25s ease-out;
  margin: 0 auto;
}

.timeline-block .VueCarousel-slide-active .timeline-item .timeline-item--block:hover {
  opacity: 1;
  transition: opacity 0.25s ease-out;
}

.timeline-block .VueCarousel-slide .timeline-item--block {
  opacity: 0.5;
}

.timeline-block .modal {
  display: none;
  position: absolute;
  background: #fff;
  width: 300px;
  height: auto;
  bottom: 80px;
  padding: 3px;
  cursor: default !important;
  box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.35);

  &:not(.first):not(.last) {
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
  }

  &.first {
    left: 0;
  }

  &.last {
    right: 0;
  }
}

.timeline-block .timeline-item.active .modal {
  display: block;
}

.timeline-block .VueCarousel-slide-active .active .timeline-item--block {
  opacity: 1;
  transition: opacity 0.25s ease-in-out;
}

.timeline-block #timeline .VueCarousel-slide.VueCarousel-slide {
  padding: 0 0.5%;
  box-sizing: border-box;
}

.timeline-block #timeline button.VueCarousel-navigation-button {
  top: 90px;
  color: #fff;
  font-size: 24px;
}

.timeline-block button.VueCarousel-navigation-button.VueCarousel-navigation-prev.VueCarousel-navigation--disabled {
  display: none;
}

.timeline-block button.VueCarousel-navigation-button.VueCarousel-navigation-next.VueCarousel-navigation--disabled {
  display: none;
}

.timeline-block .VueCarousel-wrapper {
  overflow: visible !important;
}

@media (max-width: 600px) {
  .timeline-block .VueCarousel-slide .modal {
    display: block;
  }

  .timeline-block .VueCarousel-slide-active .timeline-item--block {
    opacity: 1;
  }

  .timeline-block .instructions {
    display: none;
  }
}
</style>
