<template>
  <tr class="align-top" :class="`accordion-` + index">
    <td @click="toggleAccordion(index)">
      <span class="accordion-arrow" v-if="isAccordionOpen(index)">&#9660;</span>
      <span class="accordion-arrow" v-else>&#9654;</span>
    </td>
    <td class="cursor-pointer" @click="toggleAccordion(index)">
      {{ item.thing_name }}
      <br />
      <span class="text-lg italic text-grey-alt">{{ item.status.version }}</span>
    </td>
    <td>{{ roundToThreeDecimals(item.status.free) }} GB of 50 GB</td>
    <td>{{ item.status.voltage }} V</td>
    <td>
      {{ formatDate(item.last_status) }}
      <br />
      <span class="text-lg italic text-grey-alt">{{ timeAgo(item.last_status) }}</span>
    </td>
    <td v-if="item.authorized && item.is_connected == 't'">
      <a :href="'https://' + item.server_ip + ':' + item.server_https" target="_blank">
        https://{{ item.server_ip }}:{{ item.server_https }}
      </a>
    </td>
    <td v-else>
      <span>
        https://{{ item.server_ip }}:{{ item.server_https }}/
        <br />
        <span class="text-lg italic text-grey-alt">current IP not authorized</span>
      </span>
    </td>
    <td>{{ item.server_ssh }}</td>
    <td v-if="item.operator_ip">{{ item.operator_ip }}</td>
    <td v-else>
      <span class="text-lg italic text-grey-alt">none</span>
    </td>
    <td class="flex">
      <button
          class="button inline-block tr-all rounded font-bold leading-tighter text-center cursor-pointer border-3 border-red hover:border-red-hover text-white bg-red hover:bg-red-hover p-2 mr-2"
          @click="openModal(index)"
      >
        Change IP
      </button>
      <form method="post">
        <input type="hidden" :name="$root.csrfTokenName" :value="$root.csrfTokenValue">
        <input type="hidden" name="action" value="wildlife/iot/access">
        <input type="hidden" name="sn" :value="item.thing_sn">
        <input type="hidden" name="device_name" :value="item.thing_name">
        <input type="hidden" name="ip" :value="userIp">
        <base-button
            v-if="item.operator_ip"
            type="submit"
            name="revoke"
            value="1"
            label="Revoke"
            size="wide"
            color="red"
            textsize="text-base"
            class_names="mx-1"
        ></base-button>
        <button v-else disabled type="submit" value="1" name="revoke" class="button uppercase inline-block tr-all rounded font-bold leading-tighter text-center cursor-pointer text-base border-3 border-grey text-white bg-grey px-4 py-2 xl:px-5 xl:py-3 mx-1">
          Revoke
        </button>
      </form>
    </td>
  </tr>
</template>

<script>
export default {
  name: 'smart-system',
  props: {
    item: Object,
    index: Number,
    openAccordion: Number,
    userIp: String,
  },
  components: {
    BaseButton: () => import(/* webpackChunkName: "base-button" */ "../components/BaseButton.vue"),
  },
  methods: {
    toggleAccordion(index) {
      this.$emit('toggle-accordion', index);
    },
    isAccordionOpen(index) {
      return this.openAccordion === index;
    },
    formatDate(lastStatus) {
      const options = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
        timeZone: 'America/New_York',
      };
      const date = new Date(lastStatus);
      return new Intl.DateTimeFormat('en-US', options).format(date).replace(',', '');
    },
    timeAgo(lastStatus) {
      const now = new Date();
      const lastStatusTime = new Date(lastStatus);
      const diffInSeconds = Math.floor((now - lastStatusTime) / 1000);

      if (diffInSeconds >= 86400) {
        const days = Math.floor(diffInSeconds / 86400);
        return days === 1 ? `${days} day ago` : `${days} days ago`;
      } else if (diffInSeconds >= 3600) {
        const hours = Math.floor(diffInSeconds / 3600);
        return hours === 1 ? `${hours} hour ago` : `${hours} hours ago`;
      } else if (diffInSeconds >= 60) {
        const minutes = Math.floor(diffInSeconds / 60);
        return minutes === 1 ? `${minutes} min ago` : `${minutes} mins ago`;
      } else {
        return diffInSeconds === 1 ? `${diffInSeconds} sec ago` : `${diffInSeconds} secs ago`;
      }
    },
    roundToThreeDecimals(value) {
      return parseFloat(value).toFixed(3);
    },
    openModal(index) {
      this.$emit('open-modal', index);
    },
  },
};
</script>

<style scoped>
  .accordion-arrow {
    cursor: pointer;
  }
</style>
