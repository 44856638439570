var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "mb-6" }, [
        _c(
          "button",
          {
            staticClass: "text-lg underline text-red hover:text-red-hover",
            on: {
              click: function($event) {
                return _vm.back()
              }
            }
          },
          [_vm._v("\n            Back to list\n        ")]
        )
      ]),
      _vm._v(" "),
      _c("base-heading", { attrs: { size: "h2", class_names: "mb-3" } }, [
        _vm._v(_vm._s(_vm.item.title))
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "mb-8" }, [
        _c("div", { staticClass: "rich-text text-lg mb-4" }, [
          _vm._v("\n            " + _vm._s(_vm.item.note) + "\n        ")
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "max-w-md text-lg" }, [
          _c("div", { staticClass: "flex content-center" }, [
            _c(
              "div",
              {
                staticClass: "w-1/3 md:w-1/4 py-2 border-b border-grey italic"
              },
              [_vm._v("\n                    Latest Version\n                ")]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "w-2/3 md:w-3/4 py-2 border-b border-grey" },
              [_c("strong", [_vm._v(_vm._s(_vm.item.version))])]
            )
          ]),
          _vm._v(" "),
          _vm.item.hasOldVersions
            ? _c("div", { staticClass: "flex" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "w-1/3 md:w-1/4 py-2 border-b border-grey italic"
                  },
                  [
                    _vm._v(
                      "\n                    Older versions\n                "
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "w-2/3 md:w-3/4 py-2 border-b border-grey" },
                  [
                    _vm._v(
                      "\n                    " +
                        _vm._s(_vm.item.oldVersions.length) +
                        " older version" +
                        _vm._s(_vm.item.oldVersions.length > 1 ? "s" : "") +
                        " available\n                    "
                    ),
                    _c(
                      "button",
                      {
                        staticClass:
                          "inline-block tr-bg bg-red hover:bg-red-hover rounded ml-3 align-middle px-3 py-1 text-base uppercase font-bold text-white no-underline",
                        on: { click: _vm.scrollToVersions }
                      },
                      [
                        _vm._v(
                          "\n                        View\n                    "
                        )
                      ]
                    )
                  ]
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.item.platform &&
          _vm.item.platform.title !== undefined &&
          _vm.item.platform.title !== "Firmware"
            ? _c("div", { staticClass: "flex" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "w-1/3 md:w-1/4 py-2 border-b border-grey italic"
                  },
                  [_vm._v("Platform")]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "w-2/3 md:w-3/4 py-2 border-b border-grey" },
                  [_vm._v(_vm._s(_vm.item.platform.title))]
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "flex" }, [
            _c("div", { staticClass: "w-1/3 md:w-1/4 py-2 italic" }, [
              _vm._v("Filename")
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "w-2/3 md:w-3/4 py-2" }, [
              _vm._v(_vm._s(_vm.item.filename))
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _vm.item.export
        ? _c("base-heading", { attrs: { size: "h3", class_names: "mb-4" } }, [
            _vm._v("Download")
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.item.hasOldVersions
        ? _c("div", { staticClass: "mb-4" }, [
            _c("label", { staticClass: "form-label" }, [
              _vm._v("Download Version")
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "max-w-xs" },
              [
                _c("form-select", {
                  attrs: {
                    name: "version",
                    title: "Download Version",
                    options: _vm.versionOptions,
                    value: _vm.item.version
                  },
                  model: {
                    value: _vm.selectedVersion,
                    callback: function($$v) {
                      _vm.selectedVersion = $$v
                    },
                    expression: "selectedVersion"
                  }
                })
              ],
              1
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.item.export
        ? _c("div", { staticClass: "mb-6" }, [
            _c(
              "div",
              {
                class: [
                  "rich-text text-lg",
                  _vm.item.instructions || _vm.item.hasOldVersions ? "mb-8" : ""
                ]
              },
              [
                _c(
                  "base-heading",
                  {
                    attrs: {
                      size: "h4",
                      uppercase: true,
                      color: "green",
                      class_names: "mb-2"
                    }
                  },
                  [
                    _vm._v(
                      "\n                Export Certification for " +
                        _vm._s(_vm.item.title) +
                        "\n            "
                    )
                  ]
                ),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    "\n                I understand that this software is subject to U.S. export controls and that the export or\n                reexport of this software may\n                require a license from the U.S. Government.\n            "
                  )
                ]),
                _vm._v(" "),
                _vm._m(0),
                _vm._v(" "),
                _c("form-checkbox", {
                  attrs: {
                    name: "agree",
                    label:
                      "By checking this box, I am certifying the truthfulness of my response",
                    checked: _vm.checkbox,
                    value: "on",
                    class_names: "text-lg xl:text-xl"
                  },
                  model: {
                    value: _vm.checkbox,
                    callback: function($$v) {
                      _vm.checkbox = $$v
                    },
                    expression: "checkbox"
                  }
                })
              ],
              1
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "mb-8" }, [
        _c(
          "button",
          {
            class: [
              "button relative inline-block uppercase tr-all rounded text-xl font-bold leading-tight text-center border-3 cursor-pointer py-2 px-5",
              _vm.item.export && !_vm.checkbox
                ? "text-grey bg-white"
                : "bg-brown border-brown text-white hover:bg-brown-hover hover:border-brown-hover"
            ],
            attrs: {
              type: "button",
              disabled: _vm.item.export && !_vm.checkbox
            },
            on: {
              click: function($event) {
                $event.preventDefault()
                return _vm.downloadFile()
              }
            }
          },
          [_vm._v("\n            Download\n        ")]
        )
      ]),
      _vm._v(" "),
      _vm.item.instructions
        ? _c(
            "div",
            { class: [_vm.item.hasOldVersions ? "mb-8" : ""] },
            [
              _c(
                "base-heading",
                { attrs: { size: "h3", class_names: "mb-3" } },
                [_vm._v("Instructions")]
              ),
              _vm._v(" "),
              _c("div", {
                staticClass: "rich-text text-lg",
                domProps: { innerHTML: _vm._s(_vm.item.instructions) }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.item.hasOldVersions
        ? _c("div", { attrs: { id: "versions" } }, [
            _c(
              "div",
              { staticClass: "relative red-text-links" },
              [
                _c(
                  "base-heading",
                  { attrs: { size: "h3", class_names: "mb-4" } },
                  [_vm._v("Available Versions")]
                ),
                _vm._v(" "),
                _vm.item.releaseNotesUrl
                  ? _c(
                      "a",
                      {
                        staticClass:
                          "block mt-2 mb-4 sm:mt-1 sm:mb-0 sm:inline sm:absolute sm:top-0 sm:right-0 text-red text-lg tr-color hover:text-red-hover underline",
                        attrs: { href: _vm.item.releaseNotesUrl }
                      },
                      [
                        _vm._v(
                          "\n                View Release Notes\n            "
                        )
                      ]
                    )
                  : _vm._e()
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "mb-3 sm:hidden" },
              [_c("swipe-table-indicator")],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "rich-text" }, [
              _c("div", { staticClass: "table-wrapper min-sm" }, [
                _c(
                  "table",
                  {
                    staticClass: "history w-full text-base leading-normal lined"
                  },
                  [
                    _vm._m(1),
                    _vm._v(" "),
                    _c(
                      "tbody",
                      [
                        _c("tr", [
                          _c("td", { staticClass: "col-1 align-top" }, [
                            _vm._v(_vm._s(_vm.item.version) + " (Latest)")
                          ]),
                          _vm._v(" "),
                          _c("td", { staticClass: "col-2 align-top" }, [
                            _vm._v(_vm._s(_vm.item.filename))
                          ]),
                          _vm._v(" "),
                          _c("td", { staticClass: "col-3 align-top" }, [
                            _vm._v(_vm._s(_vm.item.note))
                          ])
                        ]),
                        _vm._v(" "),
                        _vm._l(_vm.item.oldVersions, function(row) {
                          return _c("tr", [
                            _c("td", { staticClass: "col-1 align-top" }, [
                              _vm._v(_vm._s(row.version))
                            ]),
                            _vm._v(" "),
                            _c("td", { staticClass: "col-2 align-top" }, [
                              _vm._v(_vm._s(row.filename))
                            ]),
                            _vm._v(" "),
                            _c("td", { staticClass: "col-3 align-top" }, [
                              _vm._v(_vm._s(row.notes))
                            ])
                          ])
                        })
                      ],
                      2
                    )
                  ]
                )
              ])
            ])
          ])
        : _vm.item.releaseNotesUrl
        ? _c(
            "div",
            { staticClass: "mt-8 red-text-links" },
            [
              _c(
                "base-heading",
                { attrs: { size: "h3", class_names: "mb-4" } },
                [_vm._v("Release Notes")]
              ),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass:
                    "block mt-2 mb-4 text-red text-lg tr-color hover:text-red-hover underline",
                  attrs: { href: _vm.item.releaseNotesUrl }
                },
                [_vm._v("\n            View Release Notes\n        ")]
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(
        "\n                I certify that I am not located in, or a citizen or permanent resident of, Cuba, Iran, North\n                Korea, Syria or the Crimea\n                Region of Ukraine. I am not listed on the U.S. Consolidated List (see\n                "
      ),
      _c(
        "a",
        {
          attrs: { href: "https://www.export.gov/csl-search", target: "_blank" }
        },
        [_vm._v("https://www.export.gov/csl-search")]
      ),
      _vm._v(
        "), I do not own 50% or\n                more of any entity listed on the Consolidated List. I will not ship, transfer, or transmit this\n                software (directly or\n                indirectly) to any of the above named regions, to citizens or permanent residents of the above\n                named regions, to any\n                persons or entities listed on the U.S. Consolidated list, or to any person or entity who owns\n                more than 50% or more of any\n                entity listed on the Consolidated List.\n            "
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("td", { staticClass: "col-1" }, [_vm._v("Version")]),
        _vm._v(" "),
        _c("td", { staticClass: "col-2" }, [_vm._v("Filename")]),
        _vm._v(" "),
        _c("td", { staticClass: "col-3" }, [_vm._v("Notes")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }