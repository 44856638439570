<template>
  <section class="block product-content white-block">
    <div class="container mx-auto px-5-safe pt-6 pb-10 lg:pt-10 lg:pb-14">
      <div v-if="detail_sections_show_expand_all_links" class="mb-6 text-2xl">
        <button @click="toggleAllSections(true)" type="button" class="underline tr-color text-brown hover:text-red">
          Expand All
        </button>
        <button @click="toggleAllSections(false)" type="button" class="underline tr-color text-brown hover:text-red ml-4">
          Collapse All
        </button>
      </div>
      <div :class="has_sidebar_blocks ? 'lg:flex' : ''">
        <div :class="has_sidebar_blocks ? 'mb-10 lg:mb-0 lg:w-3/4 lg:pr-12 xl:pr-16' : ''">
          <product-detail-sections :active="false"></product-detail-sections>
        </div>
        <div class="lg:w-1/4" v-if="has_sidebar_blocks">
          <sidebar-blocks :blocks="sidebar_blocks"></sidebar-blocks>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data: function() {
    return {
      detailSections: this.detail_sections,
      accessories: this.product_accessories
    };
  },
  props: ["detail_sections", "sidebar_blocks", "has_sidebar_blocks", "detail_sections_show_expand_all_links", "product_accessories"],
  components: {
    ProductDetailSections: () => import(/* webpackChunkName: "product-detail-sections" */ "./details/ProductDetailSections.vue"),
    SidebarBlocks: () => import(/* webpackChunkName: "product-sidebar" */ "../sidebar/SidebarBlocks.vue")
  },
  methods: {
    toggleAllSections(active) {
       this.detailSections.forEach(function(section) {
        section.active = active;
      });
    }
  }
};
</script>
