<template>
  <div v-click-outside="hideOverlay" class="add-to-cart-overlay overlay bg-grey-light shadow-md absolute text-brown">
    <header class="block relative py-3 px-5 border-b border-brown">
      <div class="uppercase font-bold text-lg">
        Added to your cart
      </div>
      <button @click="hideOverlay" class="text-brown absolute right-0 centered-y pr-5">
        <base-icon icon-name="x" width="16" height="16" viewbox="0 0 23 23">
          <icon-x></icon-x>
        </base-icon>
      </button>
    </header>
    <div class="pt-4 pb-5 px-5">
      <div class="flex">
        <div class="w-1/3 pr-4">
          <base-image
            :src="$parent.$parent.product_thumbnail_src"
            :retina_src="$parent.$parent.product_thumbnail_retina_src"
          ></base-image>
        </div>
        <div class="w-2/3 pt-1">
          <div class="font-bold text-lg" v-html="$parent.$parent.product_name"></div>
          <div class="text-xs mt-1">Product Code: {{ $parent.$parent.product_code }}</div>
          <div class="mt-3 flex">
            <div class="w-1/2 leading-normal">
              <div v-show="$parent.addedToCartUnitPrice">{{ $root.formattedPrice($parent.addedToCartUnitPrice) }}</div>
              <div class="italic" v-show="$parent.addedToCartUnitPrice">{{ $root.formattedCurrency() }}</div>
              <div v-show="$parent.addedToCartQty">Qty: {{ $parent.addedToCartQty }}</div>
            </div>
            <div class="w-1/2">
              <base-button url="/shop/cart" label="View Cart" color="brown" outline="true" size="smaller"></base-button>
            </div>
          </div>
        </div>
      </div>
      <div v-show="products.length > 0">
        <div
          class="bg-red shadow-md text-white mt-3 p-3"
          v-if="$parent.$parent.essential_products_heading !== '' || $parent.$parent.essential_products_text !== ''"
        >
          <div class="text-lg font-bold" v-html="$parent.$parent.essential_products_heading"></div>
          <div
            :class="[$parent.$parent.essential_products_heading !== '' ? 'mt-1' : '']"
            v-html="$parent.$parent.essential_products_text"
          ></div>
        </div>
      </div>
    </div>
    <add-to-cart-box-essential-products
      v-if="$parent.$parent.essential_products_heading !== '' || $parent.$parent.essential_products_text !== ''"
    ></add-to-cart-box-essential-products>
  </div>
</template>

<script>
import ClickOutside from "vue-click-outside";

export default {
  data: function() {
    return {
      products: this.$parent.$parent.essential_products
    };
  },
  components: {
    BaseButton: () => import(/* webpackChunkName: "base-button" */ "../../components/BaseButton.vue"),
    BaseImage: () => import(/* webpackChunkName: "base-image" */ "../../components/BaseImage.vue"),
    AddToCartBoxEssentialProducts: () =>
      import(/* webpackChunkName: "add-to-cart-box-essential-products" */ "./AddToCartBoxEssentialProducts.vue"),
    BaseIcon: () => import(/* webpackChunkName: "base-icon" */ "../../components/BaseIcon.vue"),
    IconX: () => import(/* webpackChunkName: "icon-x" */ "../../icons/IconX.vue")
  },
  methods: {
    hideOverlay() {
      this.$parent.showOverlay = false;
    }
  },
  directives: {
    ClickOutside
  }
};
</script>

<style scoped>
.overlay {
  top: 100px;
  min-height: 160px;
  z-index: 9901;
  left: -10px;
  width: calc(100% + 20px);
  @media (min-width: 506px) and (max-width: 567px),
    (min-width: 1013px) and (max-width: 1099px),
    (min-width: 1335px) and (max-width: 1399px) {
    left: -30px;
    width: calc(100% + 60px);
  }
  @media (min-width: 568px) and (max-width: 991px), (min-width: 1100px) and (max-width: 1279px), (min-width: 1400px) {
    left: -60px;
    width: calc(100% + 120px);
  }
}
</style>
