<template>
    <section class="callout-handwritten block w-full py-12 sm:py-16 lg:py-20"
             :style="innerStyles">
        <div :class="include_container ? 'container mx-auto px-5-safe' : 'full-container'">
            <div class="xl:max-w-2xl mx-auto bg-white border-2 border-brown p-6 sm:p-8 md:p-12 lg:p-16">
                <div class="flex text-center flex-wrap text-base sm:text-lg uppercase">
                    <div v-for="(icon, i) in data.icons"
                         :key="i"
                         :class="[
                            columnClasses,
                            i > 0 ? 'md:border-l md:border-brown' : '',
                            data.icons.length === 4 && (i === 1 || i === 3) ? 'border-l border-brown' : '',
                            (data.icons.length === 3 || data.icons.length === 2) && i === 1 ? 'border-l border-brown' : '',
                            data.icons.length === 3 && i === 2 ? 'mx-auto md:mx-0' : '',
                            'p-4 sm:py-6 sm:px-8 mb-6'
                         ]">
                        <base-image
                            :src="icon.src"
                            :retina_src="icon.retina"
                            :srcset_webp="icon.webpSrcset"
                            class_names="mx-auto"
                        ></base-image>
                        <div class="mt-6">
                            {{ icon.caption }}
                        </div>
                    </div>
                </div>
                <div v-if="data.button"
                     class="mt-6 text-center">
                    <base-button
                        :url="data.button.url"
                        :label="data.button.text"
                        color="red"
                        size="extrawide"
                        :target="data.button.target"
                    ></base-button>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
  import HelpersMixin from "../mixins/Helpers";

  // @group Blocks
  export default {
    props: ["data", "include_container"],
    mixins: [HelpersMixin],
    components: {
      BaseButton: () => import(/* webpackChunkName: "base-button" */ "../components/BaseButton.vue"),
      BaseHeading: () => import(/* webpackChunkName: "base-heading" */ "../components/BaseHeading.vue"),
      BaseImage: () => import(/* webpackChunkName: "base-image" */ "../components/BaseImage.vue")
    },
    computed: {
      columnClasses() {
        switch (this.data.icons.length) {
          case 4:
            return 'w-1/2 md:w-1/4';
          case 3:
            return 'w-1/2 md:w-1/3';
          case 2:
            return 'w-1/2';
          default:
            return 'w-full';
        }
      },
      innerStyles() {
        return {
          ...this.backgroundPosition(this.data.background.focalPoint),
          "background-image": `url(${this.data.background.src})`
        };
      }
    }
  };
</script>
