var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.$parent.cart !== undefined && _vm.$parent.cart
    ? _c("div", { staticClass: "sidebar-order-summary" }, [
        _c(
          "div",
          {
            staticClass:
              "bg-red-hover text-white text-xl uppercase font-bold py-3 px-5 text-center"
          },
          [_vm._v("\n    Order Summary\n  ")]
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "bg-grey-lighter text-lg leading-normal px-5" },
          _vm._l(_vm.$parent.cart.items, function(
            lineItem,
            productCode,
            index
          ) {
            return _c(
              "div",
              {
                key: "checkout_sidebar_lineitem_" + productCode,
                class: ["flex py-4", index > 0 ? "border-t border-grey" : ""]
              },
              [
                _c("div", { staticClass: "w-3/4 leading-compact pr-4" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.productName(productCode)) +
                      "\n        "
                  ),
                  _vm.productName(productCode) !== productCode
                    ? _c("div", { staticClass: "text-xs mt-1" }, [
                        _vm._v("Product Code: " + _vm._s(productCode))
                      ])
                    : _vm._e()
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "w-1/4 text-right" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$parent.formattedPrice(lineItem.unit))
                  ),
                  _c("br"),
                  _vm._v("\n        Qty: " + _vm._s(lineItem.qty) + "\n      ")
                ])
              ]
            )
          }),
          0
        ),
        _vm._v(" "),
        _vm.$parent.cart.tax < 0
          ? _c(
              "div",
              {
                staticClass: "pt-4 px-5",
                staticStyle: { "background-color": "#dcdcdc" }
              },
              [
                _c("div", { staticClass: "flex text-lg" }, [
                  _c("div", { staticClass: "w-1/2" }, [
                    _vm._v("\n        Tax:"),
                    _c("br"),
                    _c("span", { staticClass: "text-xs italic" }, [
                      _vm._v("* " + _vm._s(_vm.$parent.cart.taxcode))
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "w-1/2 text-right" }, [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm.$parent.formattedPrice(_vm.$parent.cart.tax)
                        ) +
                        "\n      "
                    )
                  ])
                ])
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "pt-4 pb-1 px-5",
            staticStyle: { "background-color": "#dcdcdc" }
          },
          [
            _c("div", { staticClass: "flex text-lg" }, [
              _c("div", { staticClass: "w-1/2" }, [
                _vm._v("\n        Subtotal:\n      ")
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "w-1/2 text-right" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(
                      _vm.$parent.formattedPrice(_vm.$parent.cart.subtotal)
                    ) +
                    "\n      "
                )
              ])
            ])
          ]
        ),
        _vm._v(" "),
        _vm.$parent.cart.tax > 0
          ? _c(
              "div",
              {
                staticClass: "py-1 px-5",
                staticStyle: { "background-color": "#dcdcdc" }
              },
              [
                _c("div", { staticClass: "flex text-lg" }, [
                  _c("div", { staticClass: "w-1/2" }, [
                    _vm._v("\n        Tax:"),
                    _c("br"),
                    _c("span", { staticClass: "text-xs italic" }, [
                      _vm._v("* " + _vm._s(_vm.$parent.cart.taxcode))
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "w-1/2 text-right" }, [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm.$parent.formattedPrice(_vm.$parent.cart.tax)
                        ) +
                        "\n      "
                    )
                  ])
                ])
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.$parent.cart.oz > 0
          ? _c(
              "div",
              {
                staticClass: "py-1 px-5",
                staticStyle: { "background-color": "#dcdcdc" }
              },
              [
                _c("div", { staticClass: "flex text-lg" }, [
                  _c("div", { staticClass: "w-1/2" }, [
                    _vm._v("\n        Estimated Shipping:\n      ")
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "w-1/2 text-right" }, [
                    _vm.$parent.cart.shipoverride !== undefined
                      ? _c("span", [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm.$parent.formattedPrice(
                                  _vm.$parent.cart.shipoverriderate
                                )
                              ) +
                              "\n        "
                          )
                        ])
                      : _vm.$parent.cart.shipmethod
                      ? _c("span", [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm.$parent.formattedPrice(
                                  _vm.$parent.cart.shiprate
                                )
                              ) +
                              "\n        "
                          )
                        ])
                      : _c("span", [_vm._v("\n          TBD\n        ")])
                  ])
                ])
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "pt-1 pb-4 px-5",
            staticStyle: { "background-color": "#dcdcdc" }
          },
          [
            _c("div", { staticClass: "flex text-lg font-bold" }, [
              _c("div", { staticClass: "w-1/2" }, [
                _vm._v("\n        Total:\n      ")
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "w-1/2 text-right" }, [
                _vm.$parent.cart.shipoverride !== undefined ||
                _vm.$parent.cart.shipmethod
                  ? _c("span", [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.$parent.formattedPrice(_vm.$parent.cart.total)
                          ) +
                          "\n        "
                      )
                    ])
                  : _c("span", [_vm._v("\n          TBD\n        ")])
              ])
            ])
          ]
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }