<template>
  <div class="customer-stories-results">
    <div v-if="ajaxError" class="mb-10 text-red text-lg" v-html="ajaxError"></div>
    <div :class="[isLoading ? 'opacity-25' : '']">
      <div class="flex justify-between flex-wrap" v-if="items !== null && items !== undefined && items.length > 0">
        <customer-story-item
          v-for="(item, i) in items"
          :key="i"
          :item="item"
          class_names="mx-auto md:mx-0 md:w-p48 lg:w-p31 mb-8"
        ></customer-story-item>
        <div class="md:w-p48 lg:w-p31 mb-8" v-if="items !== null && items !== undefined && items.length % 3 !== 0"></div>
      </div>
      <no-results :results="items" :filters="true" @resetFilters="reset" v-if="!isLoading"></no-results>
      <div class="mt-6">
        <base-pagination
          :max_visible_buttons="numPages >= 3 ? 3 : parseInt(numPages)"
          :per_page="parseInt($parent.limit)"
          :num_pages="parseInt(numPages)"
          :current_page="$parent.currentPage"
          :total="parseInt(total)"
          :show_prev_next_btns="true"
          :pagination_disabled="isLoading"
          @pagechanged="onPageChange"
        ></base-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import VueAxios from "../../js/modules/vue-axios";
import VueScrollTo from "vue-scrollto";

Vue.use(VueScrollTo);

export default {
  data: function() {
    return {
      items: null,
      total: null,
      numPages: null,
      isLoading: false,
      ajaxError: ""
    };
  },
  components: {
    CustomerStoryItem: () => import(/* webpackChunkName: "customer-story-item" */ "./CustomerStoryItem.vue"),
    BasePagination: () => import(/* webpackChunkName: "base-pagination" */ "../components/BasePagination.vue"),
    NoResults: () => import(/* webpackChunkName: "no-results" */ "../components/NoResults.vue")
  },
  methods: {
    reset() {
      this.$parent.selectedCustomerStoryType = "";
      this.$parent.selectedSpecies = "";
      this.$parent.selectedProduct = "";
      this.$parent.selectedResearchType = "";
    },
    updateResults() {
      let self = this,
        queryStrings = [],
        ajaxUrl =
          "/actions/site-module/customer-stories/get-entries?limit=" + this.$parent.limit + "&page=" + this.$parent.currentPage;
      if (this.localSelectedCustomerStoryType !== "") {
        queryStrings.push("cst=" + this.localSelectedCustomerStoryType);
      }
      if (this.localSelectedSpecies !== "") {
        queryStrings.push("cs=" + this.localSelectedSpecies);
      }
      if (this.localSelectedProduct !== "") {
        queryStrings.push("cp=" + this.localSelectedProduct);
      }
      if (this.localSelectedResearchType !== "") {
        queryStrings.push("crt=" + this.localSelectedResearchType);
      }
      if (queryStrings.length > 0) {
        ajaxUrl += "&" + queryStrings.join("&");
      }
      self.isLoading = true;
      self.ajaxError = "";
      VueAxios.get(ajaxUrl)
        .then(response => {
          self.isLoading = false;
          let data = response.data || null;
          if (data.items !== undefined) {
            self.items = data.items.items || null;
            self.numPages = data.items.numPages || null;
            self.total = data.items.total || null;
            self.$emit("updateUrl");
            if (self.$parent.currentPage > 1) {
              self.scrollToTop();
            }
          } else {
            self.ajaxError = "An error occurred. Please try again.";
            self.scrollToTop();
          }
        })
        .catch(error => {
          self.ajaxError = "An error occurred. Please try again.";
          self.isLoading = false;
          self.scrollToTop();
        });
    },
    scrollToTop() {
      VueScrollTo.scrollTo("#customer-stories-top", 350, {
        offset: 5
      });
    },
    onPageChange(new_current_page) {
      this.$emit("setPageNumber", new_current_page);
      this.updateResults();
    }
  },
  computed: {
    localSelectedCustomerStoryType() {
      return this.$parent.selectedCustomerStoryType;
    },
    localSelectedSpecies() {
      return this.$parent.selectedSpecies;
    },
    localSelectedProduct() {
      return this.$parent.selectedProduct;
    },
    localSelectedResearchType() {
      return this.$parent.selectedResearchType;
    }
  },
  watch: {
    localSelectedCustomerStoryType: function() {
      this.$emit("setPageNumber", 1);
      this.updateResults();
    },
    localSelectedSpecies: function() {
      this.$emit("setPageNumber", 1);
      this.updateResults();
    },
    localSelectedProduct: function() {
      this.$emit("setPageNumber", 1);
      this.updateResults();
    },
    localSelectedResearchType: function() {
      this.$emit("setPageNumber", 1);
      this.updateResults();
    }
  },
  mounted() {
    this.updateResults();
  }
};
</script>
