var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.block.buttons
    ? _c(
        "section",
        {
          class: [
            "sidebar-buttons sidebar-block max-w-sm mx-auto",
            !_vm.is_first ? "mt-4" : ""
          ]
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.block.buttons.length > 0,
                  expression: "block.buttons.length > 0"
                }
              ],
              class: [_vm.block.buttons.length > 1 ? "mt-5" : ""]
            },
            _vm._l(_vm.block.buttons, function(button, b) {
              return _c(
                "div",
                { key: b, class: b > 0 ? "mt-3" : "" },
                [
                  _c("base-button", {
                    attrs: {
                      url: button.url,
                      label: button.text,
                      target: button.target,
                      color: button.color,
                      outline: button.outlined,
                      py: "2",
                      class_names: "w-full"
                    }
                  })
                ],
                1
              )
            }),
            0
          )
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }