<template>
  <section :class="['grant-program-heading', !block.isFirst ? 'mt-10' : '']">
    <base-heading size="h1" color="brown">
      {{ block.text }}
    </base-heading>
  </section>
</template>

<script>
export default {
  props: ["block"],
  components: {
    BaseHeading: () => import(/* webpackChunkName: "base-heading" */ "../components/BaseHeading.vue")
  }
};
</script>
