var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "base-heading",
        { attrs: { size: "h2", color: "brown", class_names: "mb-6" } },
        [_vm._v("\n        Select your product\n    ")]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "flex items-start justify-between flex-wrap" },
        [
          _vm._l(_vm.items, function(item, i) {
            return _c(
              "button",
              {
                key: i,
                staticClass: "product-category mb-6 text-center",
                on: {
                  click: function($event) {
                    return _vm.selectCategory(i)
                  }
                }
              },
              [
                _c(
                  "span",
                  {
                    staticClass: "block thumb m-0 border border-grey-alt w-full"
                  },
                  [
                    item.image
                      ? _c("base-image", {
                          attrs: {
                            src: item.image.src,
                            retina_src: item.image.retina,
                            alt: item.image.alt,
                            class_names: "block m-0"
                          }
                        })
                      : _c("span", {
                          staticClass: "block product-category-empty"
                        })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    staticClass: "block font-bold text-brown mt-3 text mx-auto"
                  },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(item.title) +
                        "\n            "
                    )
                  ]
                )
              ]
            )
          }),
          _vm._v(" "),
          _vm._l(6, function(n) {
            return _c("div", {
              staticClass: "product-category product-category-placeholder"
            })
          })
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }