<template>
  <div class="cart-totals">
    <div class="bg-brown text-white font-bold py-2 px-5 xl:px-7">
      <base-heading size="h2">
        Cart Totals
      </base-heading>
    </div>
    <div class="border-l-3 border-b-3 border-r-3 border-brown py-4 px-5 xl:px-7 text-base md:text-xl">
      <div class="row flex">
        <div class="w-1/2 py-2">
          Subtotal:
        </div>
        <div class="w-1/2 py-2 text-right">
          {{ formattedPrice($root.cart.subtotal) }}
        </div>
      </div>

      <div class="row flex" v-show="totalNumItems > 0">
        <div class="w-1/2 py-2">No. of Items:</div>
        <div class="w-1/2 py-2 text-right">
          {{ totalNumItems }}
        </div>
      </div>

      <div class="row flex">
        <div class="w-1/3 py-2 font-bold">
          Total:
        </div>
        <div class="w-2/3 py-2 text-right">
          <div class="font-bold">{{ formattedPrice($root.cart.total) }}</div>
          <div class="text-xs mt-1">{{ getSiteName() === 'faunatech' ? 'shipping calculated at checkout' : 'shipping & taxes calculated at checkout' }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CartMixin from "../mixins/Cart";
import HelpersMixin from "../mixins/Helpers";

export default {
  mixins: [CartMixin, HelpersMixin],
  components: {
    BaseHeading: () => import(/* webpackChunkName: "base-heading" */ "../components/BaseHeading.vue")
  },
  computed: {
    totalNumItems() {
      let num = 0;
      if (!this.$root.cart) {
        return num;
      }
      let items = this.$root.cart.items || null;
      if (items) {
        Object.keys(items).forEach(key => {
          const item = items[key];
          num = num + parseInt(item.qty);
        });
      }
      return num;
    }
  }
};
</script>

<style scoped>
.row:not(:first-child) {
  border-top: 1px solid #4b3d2a;
}
</style>
