var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    {
      class: [
        "sidebar-products-list sidebar-block max-w-sm mx-auto text-links",
        !_vm.block.isFirst ? "mt-5" : ""
      ]
    },
    [
      _vm.block.sections.length > 0
        ? _c(
            "div",
            { staticClass: "mt-6" },
            _vm._l(_vm.block.sections, function(section, s) {
              return _c(
                "div",
                { key: s, class: [s > 0 ? "mt-3" : ""] },
                [
                  _c(
                    "base-heading",
                    { attrs: { size: "h4", color: "green", uppercase: true } },
                    [_vm._v(_vm._s(section.heading))]
                  ),
                  _vm._v(" "),
                  section.products.length > 0
                    ? _c(
                        "ul",
                        { staticClass: "mt-2" },
                        _vm._l(section.products, function(product, p) {
                          return _c(
                            "li",
                            {
                              key: p,
                              class: [
                                "text-lg leading-compact",
                                p > 0 ? "mt-3" : ""
                              ]
                            },
                            [
                              _c(
                                product.url ? "a" : "span",
                                {
                                  tag: "component",
                                  attrs: { href: product.url }
                                },
                                [_vm._v(_vm._s(product.title))]
                              )
                            ],
                            1
                          )
                        }),
                        0
                      )
                    : _vm._e()
                ],
                1
              )
            }),
            0
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }