var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.languages.length > 1
    ? _c("aside", { staticClass: "video-tutorials-languages-nav mb-4" }, [
        _c("div", { staticClass: "text-xl leading-normal" }, [
          _vm._v(
            "\n    For video translations, please select your language of choice below:\n  "
          )
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "mt-4 -mb-4" },
          _vm._l(_vm.languages, function(language, l) {
            return _c(
              "button",
              {
                key: "language_" + l,
                class: [
                  "inline-block mb-4 w-1/4",
                  _vm.clicked_language && _vm.clicked_language !== language.slug
                    ? "opacity-25"
                    : ""
                ],
                on: {
                  click: function($event) {
                    return _vm.selectLanguage(language)
                  }
                }
              },
              [
                _c("base-image", {
                  attrs: {
                    src: language.flagSrc,
                    retina_src: language.flagRetinaSrc,
                    alt: language.title
                  }
                }),
                _vm._v(" "),
                _c("span", { staticClass: "block mt-2 uppercase text-sm" }, [
                  _vm._v(_vm._s(language.localName))
                ])
              ],
              1
            )
          }),
          0
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }