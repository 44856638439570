var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    {
      directives: [
        {
          name: "lazy",
          rawName: "v-lazy:background-image",
          value: _vm.backgroundSrc,
          expression: "backgroundSrc",
          arg: "background-image"
        }
      ],
      staticClass: "block expandable-icon-blocks bg-cover",
      style: _vm.sectionStyles
    },
    [
      _c(
        "div",
        {
          class: _vm.include_container
            ? "container mx-auto px-5-safe"
            : "full-container"
        },
        [
          _c("div", { staticClass: "lg:w-2/3" }, [
            _c(
              "div",
              { staticClass: "blocks text-brown" },
              _vm._l(_vm.blocks, function(item, i) {
                return _c(
                  "div",
                  [
                    _c(
                      "button",
                      {
                        class: [
                          "block relative w-full cursor-pointer text-left px-5 md:pl-7 lg:pl-10 xl:pl-12 pr-24 py-4 lg:py-6 font-bold text-xl lg:text-2xl xl:text-3xl",
                          _vm.headingColors[i],
                          i === 3 ? "text-brown" : "text-white",
                          _vm.isActive === i ? "active" : ""
                        ],
                        on: {
                          click: function($event) {
                            _vm.isActive === i
                              ? (_vm.isActive = null)
                              : (_vm.isActive = i)
                          }
                        }
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(item.heading) +
                            "\n            "
                        ),
                        _c(
                          "base-icon",
                          {
                            staticClass:
                              "absolute angle centered-y right-0 mr-5 md:mr-7 lg:mr-10 xl:mr-12",
                            attrs: {
                              "icon-name": "angle-down-alt",
                              width: "14",
                              height: "7",
                              viewbox: "0 0 41 23"
                            }
                          },
                          [_c("icon-angle-down-alt")],
                          1
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "slide-up-down",
                      { attrs: { active: _vm.isActive === i, duration: 350 } },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "px-5 md:px-7 lg:px-10 xl:px-12 py-4 lg:py-6 text-lg leading-normal lg:text-xl xl:text-2hxl leading-normal"
                          },
                          [
                            _c(
                              "div",
                              {
                                class: [
                                  item.src !== "" ? "flex items-start" : ""
                                ]
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: item.src !== "",
                                        expression: "item.src !== ''"
                                      }
                                    ],
                                    staticClass: "w-1/3 pt-8"
                                  },
                                  [
                                    _c("base-image", {
                                      attrs: {
                                        src: item.src,
                                        retina_src: item.retinaSrc,
                                        alt: item.alt
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c("div", {
                                  class: [item.src !== "" ? "w-2/3 pl-5" : ""],
                                  domProps: { innerHTML: _vm._s(item.text) }
                                })
                              ]
                            )
                          ]
                        )
                      ]
                    )
                  ],
                  1
                )
              }),
              0
            )
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }