var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "publications-index container px-5-safe mx-auto py-12",
      attrs: { id: "publications-top" }
    },
    [
      _c("div", { staticClass: "lg:flex" }, [
        _c(
          "div",
          { staticClass: "lg:w-1/3 lg:pr-12" },
          [_c("publications-filter")],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "pt-10 lg:pt-0 lg:w-2/3 lg:pt-12" },
          [_c("publications-results")],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }