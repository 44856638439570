<template>
  <section :class="['block image-columns', background_color === 'brown-tint' ? 'bg-brown-tint' : '']">
    <div :class="[include_container ? 'container mx-auto px-5-safe' : 'full-container', 'relative py-8 lg:py-10 xl:py-16']">
      <h1
          :class="[
          'font-bold',
          'text-brown text-center',
          'text-xl md:text-4xl lg:text-6xl xl:text-8xl',
          heading_centered ? '' : 'lg:text-left'
        ]"
      >
        {{ heading }}
      </h1>

      <!-- columns -->
      <div
          v-if="columns.length > 0"
          :class="['md:flex md:justify-around md:flex-wrap', heading ? 'mt-10' : '', columns.length === 2 ? 'max-w-3xl mx-auto' : '']"
      >
        <div
            v-for="(column, i) in columns"
            :key="i"
            :class="[
            'max-w-sm mb-8 mx-auto relative md:max-w-full md:px-6',
            max_columns_per_row === 2 ? 'md:w-1/2' : '',
            max_columns_per_row === 3 ? 'md:w-1/2 lg:w-1/3' : '',
            max_columns_per_row === 4 ? 'md:w-1/2 lg:w-1/4' : '',
            column_padding,
            'bg-' + columns_background_color,
            columns_text_centered ? 'text-center' : 'text-left'
          ]"
        >
          <div class="flex flex-col h-full">
            <!-- image -->
            <div v-if="column.src" class="matchheight" :ref="[`block_${block_id}_image`]">
              <base-image
                  :src="column.src"
                  :retina_src="column.retinaSrc"
                  :srcset_webp="column.webpSrcset"
                  class_names="mx-auto"
                  :alt="column.alt"
                  :width="column.width"
                  :height="column.height"
              ></base-image>
            </div>

            <!-- headings -->
            <div
                v-if="column.leading_heading || column.heading"
                :ref="[`block_${block_id}_heading`]"
                :class="[column.src ? 'mt-6' : '', heading_centered ? 'text-center' : '']"
            >
              <base-heading v-if="column.leading_heading" size="h4" :uppercase="true" color="green">
                {{ column.leading_heading }}
              </base-heading>
              <base-heading
                  v-if="column.heading"
                  size="h2"
                  :color="text_color"
                  :class_names="column.leading_heading !== '' ? 'mt-1' : ''"
              >
                {{ column.heading }}
              </base-heading>
            </div>

            <!-- text -->
            <div v-if="column.text" :ref="[`block_${block_id}_text`]" :class="['mt-3', 'text-' + text_color]">
              <base-rich-text :class_names="column.src || column.heading ? 'mt-2' : ''" size="small" v-html="column.text">
              </base-rich-text>
            </div>

            <!-- footnote -->
            <div v-if="column.footnotes !== ''" class="mt-4 red-text-links">
              <base-rich-text size="tiny" v-html="column.footnotes"></base-rich-text>
            </div>

            <!-- buttons -->
            <div
                :class="[
                `flex flex-col mt-6 w-full ${columns.length}`,
                bottom_align_buttons ? 'flex-grow justify-end' : '',
                bottom_align_buttons && columns_background_color === 'brown-lighter' ? 'px-6 pb-6' : '',
                buttons_alignment === 'leftAligned' ? 'items-start' : 'items-center'
              ]"
            >
              <div v-if="columnButtons(column).length > 0" class="flex flex-wrap justify-center -mb-4 -mx-3">
                <div
                    v-for="(button, b) in columnButtons(column)"
                    :key="`column-${i}-${b}`"
                    :class="['px-3 mb-4', columns.length > 2 ? 'w-full' : '']"
                >
                  <base-button
                      :url="button.url"
                      :label="button.text"
                      :target="button.target"
                      :color="buttons_color"
                      :outline="buttons_color === 'brown'"
                      :size="columns.length < 2 ? 'extrawide' : ''"
                      :class_names="'whitespace-no-wrap' + (columns.length > 2 ? ' w-full' : '')"
                  ></base-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import MatchHeightMixin from "../mixins/MatchHeight";
// @group Blocks
export default {
  props: [
    "block_id",
    "heading",
    "heading_centered",
    "columns",
    "columns_text_centered",
    "background_color",
    "columns_background_color",
    "match_height",
    "max_columns_per_row",
    "buttons_color",
    "bottom_align_buttons",
    "buttons_alignment",
    "include_container"
  ],
  mixins: [MatchHeightMixin],
  components: {
    BaseHeading: () => import(/* webpackChunkName: "base-heading" */ "../components/BaseHeading.vue"),
    BaseImage: () => import(/* webpackChunkName: "base-image" */ "../components/BaseImage.vue"),
    BaseButton: () => import(/* webpackChunkName: "base-button" */ "../components/BaseButton.vue"),
    BaseRichText: () => import(/* webpackChunkName: "base-rich-text" */ "../components/BaseRichText.vue")
  },
  methods: {
    columnButtons(column) {
      let buttons = [];
      for (let i = 1; i <= 3; i++) {
        if (column[`button_${i}_text`] && column[`button_${i}_url`]) {
          buttons.push({
            text: column[`button_${i}_text`],
            url: column[`button_${i}_url`],
            target: column[`button_${i}_target`]
          });
        }
      }
      return buttons;
    }
  },
  computed: {
    text_color() {
      return this.columns_background_color === "brown-lighter" ? "white" : "brown";
    },
    column_padding() {
      if (this.bottom_align_buttons) {
        if (this.columns_background_color === "brown-lighter") {
          return "pt-6 pb-20 px-12";
        } else {
          return "pt-6 px-6";
        }
      } else {
        if (this.columns_background_color === "brown-lighter") {
          return "p-6";
        } else {
          return "p-0";
        }
      }
    }
  },
  mounted: function() {
    let self = this;
    if (self.match_height) {
      window.addEventListener("load", () => {
        self.matchHeight(self, "block_" + self.block_id + "_heading", 992);
        self.matchHeight(self, "block_" + self.block_id + "_text", 992);
      });
    }
  }
};
</script>
