var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    {
      class: [
        "grant-program-horizontal-line",
        !_vm.block.isFirst ? "mt-10" : ""
      ]
    },
    [_c("hr", { staticClass: "bg-brown border-0 w-full" })]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }