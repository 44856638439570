var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.block.text !== "",
          expression: "block.text !== ''"
        }
      ],
      class: ["product-rich-text", !_vm.block.isFirst ? "mt-6" : ""]
    },
    [
      _c("base-rich-text", {
        attrs: { size: _vm.size },
        domProps: { innerHTML: _vm._s(_vm.block.text) }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }