<template>
  <div class="animal-acoustics-page container px-5-safe mx-auto py-12">
    <div class="md:flex">
      <div :class="[data.buttons.length > 0 ? 'md:w-3/4 md:pr-12' : 'md:w-full']">
        <base-rich-text v-html="data.text"></base-rich-text>
      </div>
      <div class="mt-8 max-w-xs mx-auto md:mx-0 md:max-w-full md:mt-0 md:w-1/4" v-if="data.buttons.length > 0">
        <div v-for="(button, b) in data.buttons" :key="b" :class="[b > 0 ? 'mt-6' : '']">
          <base-button
            :url="button.url"
            :label="button.text"
            :color="button.color"
            :outline="button.outlined"
            size="smaller"
            class_names="w-full"
          ></base-button>
        </div>
      </div>
    </div>
    <div class="mt-10 text-center md:text-left">
      <base-heading size="h1" color="brown">{{ data.heading }}</base-heading>
    </div>
    <div class="mt-6">
      <div class="flex justify-between flex-row flex-wrap" style="margin-left: -12px; margin-right: -12px;">
        <div v-for="(block, b) in data.blocks" :key="b" class="block w-full md:w-p48 lg:w-p31 square p-3">
          <!-- Image Block -->
          <component
            :is="block.url ? 'a' : 'div'"
            :href="block.url"
            :target="block.target"
            v-if="block.type === 'imageBlock'"
            class="bg-grey-lighter w-full h-full"
          >
            <base-image :src="block.src" :retina_src="block.retinaSrc" :alt="block.alt" class_names="w-full"></base-image>
          </component>
          <!-- Icon Block -->
          <component
            :is="block.url ? 'a' : 'div'"
            :href="block.url"
            :target="block.target"
            v-if="block.type === 'iconBlock'"
            :class="['flex items-center w-full h-full', 'bg-' + block.backgroundColor]"
          >
            <div class="text-center w-full text-white p-4 lg:p-5 xl:p-6">
              <div v-if="block.src !== ''" class="mb-3">
                <base-image
                  :src="block.src"
                  :retina_src="block.retinaSrc"
                  :alt="block.alt"
                  class_names="animal-acoustics-icon"
                ></base-image>
              </div>
              <base-heading size="h2" color="white">{{ block.heading }}</base-heading>
              <div v-if="block.text !== ''" class="mt-2">
                <base-rich-text size="smallAlt" v-html="block.text"></base-rich-text>
              </div>
            </div>
          </component>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["data"],
  components: {
    BaseButton: () => import(/* webpackChunkName: "base-button" */ "../components/BaseButton.vue"),
    BaseHeading: () => import(/* webpackChunkName: "base-heading" */ "../components/BaseHeading.vue"),
    BaseImage: () => import(/* webpackChunkName: "base-image" */ "../components/BaseImage.vue"),
    BaseRichText: () => import(/* webpackChunkName: "base-rich-text" */ "../components/BaseRichText.vue")
  }
};
</script>

<style scoped>
.square {
  flex: 1 0 auto;
  height: auto;
  position: relative;

  &:before {
    content: "";
    float: left;
    padding-top: 100%;
  }
}
@media (max-width: 767px) {
  .block {
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
  }
}
</style>

<style>
.animal-acoustics-icon {
  height: 64px;
}
@media (min-width: 1280px) {
  .animal-acoustics-icon {
    height: 92px;
  }
}
</style>
