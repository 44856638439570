var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "header",
    {
      staticClass: "blog-entry-header sm:px-5-safe bg-cover",
      style: _vm.headerStyles
    },
    [
      _c("div", { staticClass: "container mx-auto bg-white" }, [
        _c("div", {
          directives: [
            {
              name: "screen",
              rawName: "v-screen",
              value: {
                lg: "h-3",
                xl: "h-4"
              },
              expression: "{\n        lg: 'h-3',\n        xl: 'h-4'\n      }"
            }
          ],
          staticClass: "bg-brown-lighter h-2"
        }),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "relative" },
          [
            _vm.ribbon
              ? _c(
                  _vm.ribbon.url ? "a" : "div",
                  {
                    tag: "component",
                    class: [
                      "ribbon block md:absolute text-white text-center md:top-0 md:right-0 mr-5 lg:mr-8 xl:mr-12 py-3 lg:py-5 px-4",
                      "bg-" + _vm.ribbon.color,
                      _vm.ribbon.url
                        ? "tr-bg hover:bg-" + _vm.ribbon.color + "-hover"
                        : ""
                    ],
                    attrs: { href: _vm.ribbon.url, target: _vm.ribbon.target }
                  },
                  [
                    _vm.ribbon.smallText
                      ? _c("div", { staticClass: "text-lg lg:text-xl" }, [
                          _vm._v(_vm._s(_vm.ribbon.smallText))
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.ribbon.largeText
                      ? _c(
                          "div",
                          {
                            class: [
                              "text-xl lg:text-2xl font-bold",
                              _vm.ribbon.smallText ? "mt-1" : ""
                            ]
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.ribbon.largeText) +
                                "\n        "
                            )
                          ]
                        )
                      : _vm._e()
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "screen",
                    rawName: "v-screen",
                    value: {
                      md: "p-7",
                      lg: "px-10",
                      xl: "pt-12 pb-10 px-18"
                    },
                    expression:
                      "{\n          md: 'p-7',\n          lg: 'px-10',\n          xl: 'pt-12 pb-10 px-18'\n        }"
                  }
                ],
                staticClass: "px-5 pt-5 pb-2 md:pb-3 lg:pb-5"
              },
              [
                _c("blog-category-heading", {
                  attrs: { name: _vm.category, url: _vm.category_url }
                }),
                _vm._v(" "),
                _c(
                  "base-heading",
                  {
                    attrs: {
                      size: "h1",
                      color: "brown",
                      class_names: "md:hidden"
                    }
                  },
                  [_vm._v(_vm._s(_vm.title))]
                ),
                _vm._v(" "),
                _c(
                  "base-heading",
                  {
                    style: _vm.headingStyles,
                    attrs: {
                      size: "h1",
                      color: "brown",
                      class_names: "hidden md:block"
                    }
                  },
                  [_vm._v(_vm._s(_vm.title))]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "relative",
                    staticStyle: { "min-height": "47px" }
                  },
                  [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "screen",
                            rawName: "v-screen",
                            value: {
                              lg: "mt-10"
                            },
                            expression:
                              "{\n              lg: 'mt-10'\n            }"
                          }
                        ],
                        staticClass: "mt-6 lg:mt-10 flex items-center relative"
                      },
                      [
                        _vm.author_image_src
                          ? _c(
                              "div",
                              [
                                _c("base-image", {
                                  attrs: {
                                    src: _vm.author_image_src,
                                    retina_src: _vm.author_image_retina_src,
                                    alt: _vm.author_image_alt,
                                    class_names: "rounded-full"
                                  }
                                })
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            class: [
                              "text-brown",
                              _vm.author_image_src ? "pl-4" : ""
                            ]
                          },
                          [
                            _vm.author_first_name
                              ? _c(
                                  "div",
                                  { staticClass: "text-base font-bold mb-1" },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.author_first_name) +
                                        " " +
                                        _vm._s(_vm.author_last_name)
                                    )
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                class: [
                                  "text-sm italic",
                                  !_vm.author_first_name ? "md:mt-4" : ""
                                ]
                              },
                              [_vm._v(_vm._s(_vm.post_date))]
                            )
                          ]
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "mt-4 md:mt-0 text-right md:absolute md:bottom-0 md:right-0 print-hidden"
                      },
                      [
                        _c("share-icons", {
                          attrs: {
                            icon_size: "40",
                            url: _vm.share_url,
                            title: _vm.share_title,
                            share_site_name: _vm.share_site_name,
                            share_twitter_handle: _vm.share_twitter_handle
                          }
                        })
                      ],
                      1
                    )
                  ]
                )
              ],
              1
            )
          ],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }