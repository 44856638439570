var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "block products-list" }, [
    _c(
      "div",
      {
        class: [
          _vm.include_container
            ? "container mx-auto px-5-safe"
            : "full-container",
          "py-12 lg:py-12 xl:py-14"
        ]
      },
      _vm._l(_vm.products, function(product, p) {
        return _c("div", { key: p, class: [p > 0 ? "mt-16" : ""] }, [
          _c("div", { staticClass: "md:flex" }, [
            _c(
              "div",
              { staticClass: "col-1 text-center xl:pl-12" },
              [
                _c("base-image", {
                  attrs: {
                    src: product.src,
                    retina_src: product.retinaSrc,
                    srcset_webp: product.webpSrcset,
                    alt: product.alt,
                    url: product.url
                  }
                }),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "mt-5 text-center" },
                  [
                    _c("base-button", {
                      attrs: {
                        url: product.url,
                        label: "Learn More",
                        color: "red",
                        size: "smaller",
                        outline: true
                      }
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-2 pl-8 mt-8 md:mt-0 xl:pl-12" },
              [
                _c(
                  "base-heading",
                  { attrs: { size: "h2", url: product.url } },
                  [_vm._v(_vm._s(product.heading))]
                ),
                _vm._v(" "),
                _c(
                  "base-heading",
                  {
                    attrs: { size: "h4", color: "green", class_names: "mt-2" }
                  },
                  [_vm._v(_vm._s(product.subheading))]
                ),
                _vm._v(" "),
                _c("hr", { staticClass: "my-4", attrs: { align: "left" } }),
                _vm._v(" "),
                _c("base-rich-text", {
                  domProps: { innerHTML: _vm._s(product.text) }
                }),
                _vm._v(" "),
                _c("div", { staticClass: "text-center" }, [
                  product.icons.length > 0
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "flex flex-wrap justify-center lg:justify-start mt-6 -mb-4 text-xs leading-normal uppercase text-center"
                        },
                        _vm._l(product.icons, function(icon, i) {
                          return _c(
                            "div",
                            {
                              key: i,
                              class: [
                                "feature inline-block align-top mb-4",
                                i > 0 ? "pl-4 md:pl-5 lg:pl-6" : "",
                                i < product.icons.length
                                  ? "pr-4 md:pr-5 lg:pr-6"
                                  : "",
                                i < product.icons.length - 1
                                  ? "border-r border-brown"
                                  : ""
                              ]
                            },
                            [
                              _c(
                                "div",
                                [
                                  _c("base-image", {
                                    attrs: {
                                      src: icon.src,
                                      retina_src: icon.retinaSrc,
                                      alt: icon.alt
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "mt-3" }, [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(icon.text) +
                                    "\n                "
                                )
                              ])
                            ]
                          )
                        }),
                        0
                      )
                    : _vm._e()
                ])
              ],
              1
            )
          ])
        ])
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }