import { render, staticRenderFns } from "./AuthorProfile.vue?vue&type=template&id=52f1da07&scoped=true&"
import script from "./AuthorProfile.vue?vue&type=script&lang=js&"
export * from "./AuthorProfile.vue?vue&type=script&lang=js&"
import style0 from "./AuthorProfile.vue?vue&type=style&index=0&id=52f1da07&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "52f1da07",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/ian/wildlife-website.master/craftcms/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('52f1da07')) {
      api.createRecord('52f1da07', component.options)
    } else {
      api.reload('52f1da07', component.options)
    }
    module.hot.accept("./AuthorProfile.vue?vue&type=template&id=52f1da07&scoped=true&", function () {
      api.rerender('52f1da07', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/vue/components/AuthorProfile.vue"
export default component.exports