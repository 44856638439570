var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.num_pages > 1
    ? _c("div", { staticClass: "text-center text-brown" }, [
        _c(
          "ul",
          { class: _vm.pagination_disabled ? "opacity-25" : "" },
          [
            _vm.show_first_last_btns
              ? _c("li", { staticClass: "item text-base" }, [
                  _c(
                    "button",
                    {
                      attrs: {
                        type: "button",
                        disabled: _vm.pagination_disabled || _vm.isInFirstPage,
                        "aria-label": "Go to first page"
                      },
                      on: { click: _vm.onClickFirstPage }
                    },
                    [_vm._v("\n        First\n      ")]
                  )
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.show_prev_next_btns
              ? _c(
                  "li",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.current_page > 1,
                        expression: "current_page > 1"
                      }
                    ],
                    staticClass: "item text-base"
                  },
                  [
                    _c(
                      "button",
                      {
                        staticClass: "relative tr-bg hover:bg-grey-lighter",
                        attrs: {
                          type: "button",
                          disabled:
                            _vm.pagination_disabled || _vm.isInFirstPage,
                          "aria-label": "Go to previous page"
                        },
                        on: { click: _vm.onClickPreviousPage }
                      },
                      [
                        _c(
                          "base-icon",
                          {
                            staticClass: "centered",
                            attrs: {
                              "icon-name": "angle-left",
                              width: "11",
                              height: "15",
                              viewbox: "0 0 9 17"
                            }
                          },
                          [_c("icon-angle-left")],
                          1
                        )
                      ],
                      1
                    )
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm._l(_vm.pages, function(page) {
              return _c("li", { staticClass: "item text-base" }, [
                _c(
                  "button",
                  {
                    class: [
                      "tr-bg",
                      _vm.isPageActive(page.name)
                        ? "bg-brown text-white"
                        : "hover:bg-grey-lighter"
                    ],
                    attrs: {
                      type: "button",
                      disabled: _vm.pagination_disabled || page.isDisabled,
                      "aria-label": "Go to page number " + page.name
                    },
                    on: {
                      click: function($event) {
                        return _vm.onClickPage(page.name)
                      }
                    }
                  },
                  [_vm._v("\n        " + _vm._s(page.name) + "\n      ")]
                )
              ])
            }),
            _vm._v(" "),
            _vm.show_prev_next_btns
              ? _c(
                  "li",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.current_page < _vm.num_pages,
                        expression: "current_page < num_pages"
                      }
                    ],
                    staticClass: "item text-base"
                  },
                  [
                    _c(
                      "button",
                      {
                        staticClass: "relative tr-bg hover:bg-grey-lighter",
                        attrs: {
                          type: "button",
                          disabled: _vm.pagination_disabled || _vm.isInLastPage,
                          "aria-label": "Go to next page"
                        },
                        on: { click: _vm.onClickNextPage }
                      },
                      [
                        _c(
                          "base-icon",
                          {
                            staticClass: "centered",
                            attrs: {
                              "icon-name": "angle-right",
                              width: "11",
                              height: "15",
                              viewbox: "0 0 9 17"
                            }
                          },
                          [_c("icon-angle-right")],
                          1
                        )
                      ],
                      1
                    )
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.show_first_last_btns
              ? _c("li", { staticClass: "item text-base" }, [
                  _c(
                    "button",
                    {
                      attrs: {
                        type: "button",
                        disabled: _vm.pagination_disabled || _vm.isInLastPage,
                        "aria-label": "Go to last page"
                      },
                      on: { click: _vm.onClickLastPage }
                    },
                    [_vm._v("\n        Last\n      ")]
                  )
                ])
              : _vm._e()
          ],
          2
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }