<template>
  <div class="resellers-results md:flex md:justify-between text-brown">
    <div v-for="columnNumber in [1, 2]" :class="['md:w-p47', columnNumber === 2 ? 'mt-10 md:mt-0' : '']">
      <reseller-item
        v-for="(item, i) in column(columnNumber)"
        :item="item"
        :key="i"
        :class_names="[item.showCountry && i > 0 ? 'mt-10' : '', !item.showCountry ? 'mt-6' : '']"
      ></reseller-item>
    </div>
  </div>
</template>

<script>
export default {
  components: {
    ResellerItem: () => import(/* webpackChunkName: "reseller-item" */ "./ResellerItem.vue")
  },
  methods: {
    column(columnNumber) {
      let self = this,
        items = [],
        previousCountry = null,
        count = 0;
      this.region.resellers.forEach(function(item) {
        count++;
        let show = false;
        if ((columnNumber === 1 && count <= self.region.perCol) || (columnNumber === 2 && count > self.region.perCol)) {
          show = true;
        }
        if (show) {
          item["showCountry"] = item.countrySlug !== previousCountry;
          items.push(item);
          previousCountry = item.countrySlug;
        }
      });
      return items;
    }
  },
  computed: {
    region() {
      let activeRegion = null;
      Object.keys(this.$parent.reseller_data).forEach(key => {
        const region = this.$parent.reseller_data[key];
        if (key === this.$parent.activeRegion) {
          activeRegion = region;
        }
      });
      return activeRegion;
    }
  }
};
</script>
