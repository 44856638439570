import { render, staticRenderFns } from "./NewsIndex.vue?vue&type=template&id=eff8a2b6&"
import script from "./NewsIndex.vue?vue&type=script&lang=js&"
export * from "./NewsIndex.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/ian/wildlife-website.master/craftcms/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('eff8a2b6')) {
      api.createRecord('eff8a2b6', component.options)
    } else {
      api.reload('eff8a2b6', component.options)
    }
    module.hot.accept("./NewsIndex.vue?vue&type=template&id=eff8a2b6&", function () {
      api.rerender('eff8a2b6', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/vue/news/NewsIndex.vue"
export default component.exports