import { render, staticRenderFns } from "./SidebarFilterButton.vue?vue&type=template&id=941ffc22&"
import script from "./SidebarFilterButton.vue?vue&type=script&lang=js&"
export * from "./SidebarFilterButton.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/ian/wildlife-website.master/craftcms/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('941ffc22')) {
      api.createRecord('941ffc22', component.options)
    } else {
      api.reload('941ffc22', component.options)
    }
    module.hot.accept("./SidebarFilterButton.vue?vue&type=template&id=941ffc22&", function () {
      api.rerender('941ffc22', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/vue/components/SidebarFilterButton.vue"
export default component.exports