<template>
  <section
    :class="[
      'block rich-text-block',
      'bg-' + (background ? background : 'white'),
      background === 'brown' ? 'text-white text-links-on-brown' : 'text-brown white-block'
    ]"
  >
    <div :class="[include_container ? 'container mx-auto px-5-safe' : 'full-container', paddingClasses]">
      <base-rich-text :size="text_size">
        <template>
          <slot></slot>
        </template>
      </base-rich-text>
    </div>
  </section>
</template>

<script>
// @group Blocks
export default {
  props: ["background", "text_size", "include_container"],
  components: {
    BaseRichText: () => import(/* webpackChunkName: "base-rich-text" */ "../components/BaseRichText.vue")
  },
  computed: {
    paddingClasses() {
      switch (this.text_size) {
        case "largest":
          return "py-16 lg:py-20 xl:py-24";
        case "larger":
          return "py-10 lg:py-12 xl:py-16";
        default:
          return "py-8 lg:py-10 xl:py-12";
      }
    }
  }
};
</script>
