var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "add-to-cart-box wrapper mx-auto" }, [
    _c("div", { staticClass: "text-brown text-left" }, [
      _c("div", { staticClass: "text-2hxl xl:text-3xl font-bold" }, [
        _vm._v(
          "\n      " +
            _vm._s(_vm.$root.formattedPrice(_vm.product.list_price)) +
            "\n      "
        ),
        _c("span", { staticClass: "text-sm" }, [
          _vm._v(_vm._s(_vm.$root.formattedCurrency()))
        ])
      ]),
      _vm._v(" "),
      _vm.product.stock
        ? _c("div", {
            staticClass: "text-sm uppercase mt-1 product-stock",
            domProps: { innerHTML: _vm._s(_vm.product.stock) }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "stock text-sm mt-1" }, [
        _vm._v(
          "\n      Product Code: " + _vm._s(_vm.product.product_code) + "\n    "
        )
      ]),
      _vm._v(" "),
      _vm.ajaxError
        ? _c("div", {
            staticClass: "mt-10 text-red text-lg",
            domProps: { innerHTML: _vm._s(_vm.ajaxError) }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "mt-4" }, [
        _c(
          "button",
          {
            class: [
              "relative loading-btn bg-brown-lighter text-xl inline-block font-bold rounded-lg text-center h-12 w-full uppercase",
              _vm.isLoading || _vm.quantity < 1
                ? "text-grey"
                : "text-white tr-color tr-bg hover:text-white hover:bg-green",
              _vm.isLoading ? "pl-8" : ""
            ],
            attrs: {
              type: "submit",
              disabled: _vm.isLoading || _vm.quantity < 1
            },
            on: {
              click: function($event) {
                $event.preventDefault()
                return _vm.addToCart()
              }
            }
          },
          [
            _c(
              "base-icon",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isLoading,
                    expression: "isLoading"
                  }
                ],
                staticClass: "loading absolute ml-3 left-0 centered-y",
                attrs: {
                  "icon-name": "loading",
                  width: "28",
                  height: "28",
                  viewbox: "0 0 32 32"
                }
              },
              [_c("icon-loading")],
              1
            ),
            _vm._v(" "),
            _c("span", { staticClass: "inline" }, [_vm._v("Add to Cart")])
          ],
          1
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }