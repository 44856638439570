<template>
  <div>
    <base-heading size="h2" color="brown-lighter" class_names="w-full mt-16">
      {{ product_versions_heading && product_versions_heading.length ? product_versions_heading : "Other Versions" }}
    </base-heading>
    <div class="pt-5 pb-8 lg:pb-0 flex flex-wrap flex-col lg:flex-row gap-4">
      <div
        v-for="version in product_versions"
        :key="`${version.id}`"
        class="cursor-pointer"
        tabindex="0"
      >
        <div v-if="version.product_version_image && version.product_version_image.src" class="block lg:inline-block lg:relative popover-wrapper">
          <div class="hidden lg:block">
            <a :href="version.url">
              <base-image
                :src="version.product_version_image.src"
                :retina_src="version.product_version_image.retina_src"
                :srcset_webp="version.product_version_image.webp_src_set"
                :alt="version.product_version_image.alt"
                class_names="bg-white rounded-sm w-24 h-24"
                wrapper_class_names="flex justify-start"
              ></base-image>
            </a>
            <div class="popover-content flex items-start text-left opacity-0 invisible absolute overflow-visible">
              <span class="text-3xl px-3 py-1 bg-white text-green rounded-md shadow-md bg-white border border-white">{{ version.product_version_name }}</span>
            </div>
          </div>
          <a :href="version.url" class="block flex flex-row justify-start items-end gap-4 lg:hidden ml-1">
              <base-image
                :src="version.product_version_image.src"
                :retina_src="version.product_version_image.retina_src"
                :srcset_webp="version.product_version_image.webp_src_set"
                :alt="version.product_version_image.alt"
                class_names="bg-white rounded-sm w-8 h-8 md:w-12 md:h-12"
                wrapper_class_names="flex justify-start"
              ></base-image>
              <span class="flex-1 hover:underline hover:text-blue">{{ version.product_version_name }}</span>
          </a>
        </div>
        <a v-else :href="version.url" class="block ml-3 text-2xl hover:underline hover:text-blue">{{ version.product_version_name }}</a>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: [
    "product_versions",
    "product_versions_heading"
  ],
  components: {
    BaseImage: () => import(/* webpackChunkName: "base-image" */ "../components/BaseImage.vue"),
    BaseHeading: () => import(/* webpackChunkName: "base-heading" */ "../components/BaseHeading.vue")
  },
};
</script>
<style scoped>
.popover-content {
  top: 90%;
  width: 500%;
}

.popover-wrapper:hover .popover-content {
  z-index: 10;
  transform: translate(0, 20px);
  transition: all 0.2s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  @apply opacity-100 visible overflow-visible;
}

.popover-content:before {
  content: '';
  position: absolute;
  display: block;
  width: 0px;
  left: 40px;
  top: 5px;
  border: 5px solid transparent;
  border-top: 0;
  border-bottom: 5px solid white;
  transform: translate(-50%, calc(-90% - 5px));
}
</style>
