<template>
  <div :class="['rich-text', textClasses, class_names]">
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: ["size", "class_names", "link_style"],
  computed: {
    textClasses() {
      let classes = "";
      if (this.size === "largest") {
        classes += "leading-normal text-2xl lg:text-6xl";
      } else if (this.size === "larger") {
        classes += "leading-normal text-xl lg:text-3xl";
      } else if (this.size === "small") {
        classes += "leading-normal text-lg lg:text-xl";
      } else if (this.size === "smallAlt") {
        classes += "leading-normal text-lg xl:text-xl";
      } else if (this.size === "smaller") {
        classes += "leading-normal text-base lg:text-lg";
      } else if (this.size === "tiny") {
        classes += "leading-compact text-sm";
      } else {
        classes += "leading-normal text-lg lg:text-xl xl:text-2xl";
      }
      if (this.link_style === "red") {
        classes += " red-text-links";
      }
      return classes;
    }
  }
};
</script>

<style scoped>
h3 {
  @apply font-bold text-xl text-brown;
}
h4 {
  @apply font-bold text-base text-green;
}
@screen xl {
  h3 {
    @apply text-2hxl;
  }
}
</style>
