var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    {
      directives: [
        {
          name: "lazy",
          rawName: "v-lazy:background-image",
          value: _vm.background,
          expression: "background",
          arg: "background-image"
        }
      ],
      staticClass: "block timeline-block bg-cover"
    },
    [
      _c(
        "div",
        {
          class: [
            _vm.include_container
              ? "container mx-auto px-5-safe"
              : "full-container",
            "py-10"
          ]
        },
        [
          _c(
            "div",
            { staticClass: "mb-8 text-white" },
            [
              _c(
                "base-heading",
                { attrs: { size: "h1", color: "white", class_names: "mb-6" } },
                [_vm._v(_vm._s(_vm.heading))]
              ),
              _vm._v(" "),
              _c("base-rich-text", [_vm._t("text")], 2)
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "timeline-container relative" },
            [
              _vm.showInstructions
                ? _c(
                    "div",
                    {
                      staticClass:
                        "instructions absolute bottom-0 left-0 text-center leading-normal text-red font-cursive text-4xl"
                    },
                    [
                      _c("div", {
                        domProps: { innerHTML: _vm._s(_vm.instructions_text) }
                      }),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "mt-3" },
                        [
                          _c("base-image", {
                            attrs: {
                              src: "/img/timeline-arrow.png",
                              retina: "/img/timeline-arrow@2x.png",
                              alt: ""
                            }
                          })
                        ],
                        1
                      )
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "carousel",
                {
                  staticClass: "flex timeline-item-row",
                  attrs: {
                    id: "timeline",
                    navigationEnabled: true,
                    paginationEnabled: false,
                    centerMode: true,
                    scrollPerPage: false,
                    perPage: 1,
                    perPageCustom: [
                      [480, 1],
                      [600, 3],
                      [768, 6],
                      [1200, 12]
                    ]
                  }
                },
                _vm._l(_vm.items, function(item, i) {
                  return _c("slide", { key: i }, [
                    _c(
                      "div",
                      {
                        staticClass: "timeline-item relative",
                        class: { active: i == _vm.active },
                        on: {
                          click: function($event) {
                            ;(_vm.active = i), (_vm.showInstructions = false)
                          }
                        }
                      },
                      [
                        _c(
                          "div",
                          {
                            class: [
                              "modal",
                              i === 0 ? "first" : "",
                              _vm.items.length === i + 1 ? "last" : ""
                            ]
                          },
                          [
                            _c("base-image", {
                              attrs: {
                                src: item.src,
                                retina: item.retinaSrc,
                                srcset_webp: item.webpSrcset,
                                alt: item.alt
                              }
                            }),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "p-3" },
                              [
                                _c(
                                  "base-heading",
                                  {
                                    attrs: {
                                      uppercase: true,
                                      size: "h4",
                                      color: "brown-lighter"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(item.month) +
                                        "\n                  " +
                                        _vm._s(item.year) +
                                        "\n                "
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c("div", {
                                  staticClass:
                                    "text-base leading-normal mt-1 text-brown",
                                  domProps: { innerHTML: _vm._s(item.text) }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "timeline-item--block" }),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "text-white text-lg italic mt-2 text-center"
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(item.year) +
                                "\n            "
                            )
                          ]
                        )
                      ]
                    )
                  ])
                }),
                1
              )
            ],
            1
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }