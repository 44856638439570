var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: [
        "product-two-columns flex justify-between",
        !_vm.block.isFirst
          ? _vm.block.previousBlock === "heading"
            ? "mt-3"
            : "mt-6"
          : ""
      ]
    },
    _vm._l(_vm.columns, function(column, c) {
      return _c(
        "div",
        { key: c, staticClass: "w-p48" },
        [
          _c("base-rich-text", {
            attrs: { size: "smaller" },
            domProps: { innerHTML: _vm._s(column) }
          })
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }