var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "block featured-content" }, [
    _c(
      "div",
      {
        staticClass:
          "container mx-auto px-5-safe relative py-8 lg:py-10 xl:py-16"
      },
      [
        _c(
          "h1",
          {
            staticClass:
              "font-bold text-brown text-center text-xl md:text-4xl lg:text-6xl xl:text-8xl lg:text-left mb-10"
          },
          [_vm._v("\n      Featured Content\n    ")]
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "md:flex" },
          [
            _vm._l(_vm.data, function(contentItem, i) {
              return [
                _c(
                  "div",
                  { staticClass: "flex-1 mb-12 md:mb-0" },
                  [
                    _c("thumbnail-image-with-overlay", {
                      attrs: {
                        src: contentItem.imageSrc,
                        retina_src: contentItem.imageSrcRetina,
                        alt: contentItem.alt,
                        width: contentItem.width,
                        height: contentItem.height,
                        url: contentItem.url,
                        text: "Read Post",
                        class_names: [
                          "mb-5 mx-auto text-center",
                          !contentItem.imageSrc ? "shadow" : ""
                        ]
                      }
                    }),
                    _vm._v(" "),
                    _c(
                      "h3",
                      {
                        staticClass:
                          "uppercase text-lg lg:text-xl xl:text-2xl font-bold mb-1 text-brown-lighter"
                      },
                      [_vm._v(_vm._s(contentItem.eyebrow))]
                    ),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        staticClass:
                          "text-brown hover:text-brown-hover hover:underline",
                        attrs: { href: contentItem.url }
                      },
                      [
                        _c(
                          "h2",
                          {
                            staticClass: "font-bold text-2hxl xl:text-3xl mt-1"
                          },
                          [_vm._v(_vm._s(contentItem.title))]
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "mt-4 text-xl lg:text-2xl xl:text-3xl text-black leading-normal"
                      },
                      [_vm._v(_vm._s(contentItem.blurb))]
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                i < _vm.data.length - 1
                  ? _c("div", { staticClass: "flex-none w-12 sm:mb-8 md:mb-0" })
                  : _vm._e()
              ]
            })
          ],
          2
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }