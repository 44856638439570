var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.block.facebookLink
    ? _c(
        "section",
        {
          class: [
            "sidebar-facebook-button sidebar-block text-xl max-w-sm mx-auto",
            !_vm.is_first ? "mt-5" : ""
          ]
        },
        [
          _c("div", { staticClass: "inline-flex items-center" }, [
            _c(
              "div",
              [
                _c("social-icon", {
                  attrs: {
                    url: _vm.block.facebookLink,
                    type: "facebook",
                    class_names: "inline-block",
                    icon_size: "40"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "pl-3" }, [
              _c("a", { attrs: { href: _vm.block.facebookLink } }, [
                _vm._v(
                  "\n        " +
                    _vm._s(
                      _vm.block.linkText
                        ? _vm.block.linkText
                        : "Find us on Facebook"
                    ) +
                    "\n      "
                )
              ])
            ])
          ])
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }