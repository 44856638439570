var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "article",
    { staticClass: "blog-entry-item sm:w-p47 lg:w-p30 pb-10" },
    [
      _c(
        "div",
        { staticClass: "text-center" },
        [
          _c("thumbnail-image-with-overlay", {
            attrs: {
              src: _vm.item.thumbnailSrc
                ? _vm.item.thumbnailSrc
                : "/img/blog-thumbnail-placeholder.png",
              retina_src: _vm.item.thumbnailSrcRetina
                ? _vm.item.thumbnailSrcRetina
                : "/img/blog-thumbnail-placeholder@2x.png",
              alt: _vm.item.alt,
              url: _vm.item.url,
              text: "Read Post",
              class_names: [
                "mb-5 mx-auto text-center",
                !_vm.item.thumbnailSrc ? "shadow" : ""
              ]
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("blog-category-heading", {
        attrs: { name: _vm.item.category, url: _vm.item.categoryUrl }
      }),
      _vm._v(" "),
      _c("div", { staticClass: "text-sm italic text-brown mt-1" }, [
        _vm._v("\n    " + _vm._s(_vm.item.date) + "\n    "),
        _vm.item.author.firstName !== "" && _vm.item.author.lastName !== ""
          ? _c("span", [
              _vm._v(
                "\n      / By " +
                  _vm._s(_vm.item.author.firstName) +
                  " " +
                  _vm._s(_vm.item.author.lastName) +
                  "\n    "
              )
            ])
          : _vm._e()
      ]),
      _vm._v(" "),
      _c(
        "base-heading",
        {
          attrs: {
            size: "h2",
            color: "brown",
            url: _vm.item.url,
            class_names: "mt-3 hover:underline"
          }
        },
        [_vm._v(_vm._s(_vm.item.title))]
      ),
      _vm._v(" "),
      _vm.item.summary !== ""
        ? _c("div", {
            staticClass: "text-xl xl:text-2xl leading-normal mt-2",
            domProps: { innerHTML: _vm._s(_vm.item.summary) }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }