var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("path", {
    attrs: {
      fill: "currentColor",
      d:
        "M26,19.7h-4v14.5h-5.5V19.7h-3.6v-5h3.6v-2.9c0-4.1,1.1-6.6,5.9-6.6h4v5H24c-1.9,0-2,0.7-2,2v2.5h4.5L26,19.7z M34.2,0H5.3\n\t\tC2.4,0,0,2.4,0,5.3v28.8c0,2.9,2.4,5.3,5.3,5.3h28.9c2.9,0,5.3-2.4,5.3-5.3V5.3C39.5,2.4,37.1,0,34.2,0L34.2,0z"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }