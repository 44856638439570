<template>
  <section
    class="block picture-squares-cta pics-cta overflow-hidden bg-cover"
    :style="sectionStyles"
    v-lazy:background-image="sectionBackgroundSrc"
  >
    <div :class="[
            include_container ? 'container mx-auto px-5-safe' : 'full-container',
            'relative'
         ]">

      <!-- first row of 5 images (only if 9 or more images available) -->
      <div v-if="images.length >= 9" class="row flex flex-row flex-wrap w-full">
        <div v-for="i in 5" :class="['square', rowClasses[i - 1]]" v-if="images[pos(1, i)]">
          <base-image
            :src="images[pos(1, i)].src"
            :retina_src="images[pos(1, i)].retinaSrc"
            :srcset_webp="images[pos(1, i)].webpSrcset"
            :alt="images[pos(1, i)].alt"
            class_names="w-full"
          ></base-image>
          <component :is="images[pos(1, i)].url ? 'a' : 'span'" :href="images[pos(1, i)].url" class="over"></component>
        </div>
      </div>

      <!-- second row of 5 images (only if 19 or more images available) -->
      <div v-if="images.length >= 19" class="row flex flex-row flex-wrap w-full">
        <div v-for="i in 5" :class="['square', rowClasses[i - 1]]" v-if="images[pos(5, i)]">
          <base-image
            :src="images[pos(5, i)].src"
            :retina_src="images[pos(5, i)].retinaSrc"
            :srcset_webp="images[pos(5, i)].webpSrcset"
            class_names="w-full"
            :alt="images[pos(5, i)].alt"
          ></base-image>
          <component :is="images[pos(5, i)].url ? 'a' : 'span'" :href="images[pos(5, i)].url" class="over"></component>
        </div>
      </div>

      <div class="row flex flex-row flex-wrap w-full">

        <!-- 2 rows of 2 images next to content (block needs at least these 4 images) -->
        <div class="hidden md:inline md:w-1/4 lg:w-2/5">
          <div v-for="r in 2" class="row flex flex-row flex-wrap w-full">
            <div v-for="i in 2" class="square w-full lg:w-1/5" v-if="images[pos(r + 2, i)]">
              <base-image
                :src="images[pos(r + 2, i)].src"
                :retina_src="images[pos(r + 2, i)].retinaSrc"
                :srcset_webp="images[pos(r + 2, i)].webpSrcset"
                class_names="w-full"
                :alt="images[pos(r + 2, i)].alt"
              ></base-image>
              <component
                :is="images[pos(r + 2, i)].url ? 'a' : 'span'"
                :href="images[pos(r + 2, i)].url"
                class="over"
              ></component>
            </div>
          </div>
        </div>

        <!-- content -->
        <div
          class="text-container w-full md:w-3/4 lg:w-3/5 bg-red bg-cover"
          :style="textContainerStyles"
          v-lazy:background-image="text_background"
        >
          <div class="flex w-full h-full flex items-center">
            <div class="py-10 md:py-4 px-20-safe text-white">
              <div v-show="icon_src !== '' && icon_retina_src !== ''">
                <base-image :src="icon_src" :retina_src="icon_retina_src" :alt="icon_alt"></base-image>
              </div>
              <base-heading size="h1" :centered="true" class_names="leading-none" color="white">
                {{ heading }}
              </base-heading>
              <div class="mt-4 text-lg lg:text-xl xl:text-2hxl text-center leading-normal">
                <slot name="text"></slot>
              </div>
              <div class="text-center mt-6 xl:mt-10">
                <slot name="buttons"></slot>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- last row of 5 images (only if 14 or more images available) -->
      <div class="hidden sm:block">
        <div v-if="images.length >= 14" class="row flex flex-row flex-wrap w-full">
          <div v-for="i in 5" :class="['square', rowClasses[i - 1]]" v-if="images[pos(2, i)]">
            <base-image
              :src="images[pos(2, i)].src"
              :retina_src="images[pos(2, i)].retinaSrc"
              :srcset_webp="images[pos(2, i)].webpSrcset"
              :alt="images[pos(2, i)].alt"
              class_names="w-full"
            ></base-image>
            <component :is="images[pos(2, i)].url ? 'a' : 'span'" :href="images[pos(2, i)].url" class="over"></component>
          </div>
        </div>
      </div>

    </div>
    <div class="over"></div>
  </section>
</template>

<script>
import HelpersMixin from "../mixins/Helpers";
// @group Blocks

export default {
  props: [
    "section_background",
    "section_background_webp",
    "section_background_focal_point",
    "text_background",
    "text_background_focal_point",
    "images",
    "heading",
    "icon_src",
    "icon_retina_src",
    "icon_alt",
    "include_container"
  ],
  mixins: [HelpersMixin],
  components: {
    BaseHeading: () => import(/* webpackChunkName: "base-heading" */ "../components/BaseHeading.vue"),
    BaseImage: () => import(/* webpackChunkName: "base-image" */ "../components/BaseImage.vue")
  },
  data: function() {
    return {
      rowClasses: [
        "w-1/2 sm:w-1/3 md:w-1/4 lg:w-1/5",
        "w-1/2 sm:w-1/3 md:w-1/4 lg:w-1/5",
        "hidden sm:inline sm:w-1/3 md:w-1/4 lg:w-1/5",
        "hidden md:inline md:w-1/4 lg:w-1/5",
        "hidden lg:block lg:w-1/5"
      ]
    };
  },
  methods: {
    pos(row, i) {
      // 1st row of 5 images
      if (row === 1) {
        return i - 1;
      }
      // 2nd row of 5 images (only if 14 or more images)
      if (row === 2) {
        return i + 4;
      }
      // first row of 2 images next to content
      if (row === 3) {
        // first image
        if (i === 1) {
          if (this.images.length >= 14) {
            return 10;
          } else if (this.images.length >= 9) {
            return 5;
          } else {
            return 0;
          }
        }
        // second image
        if (i === 2) {
          if (this.images.length >= 14) {
            return 11;
          } else if (this.images.length >= 9) {
            return 6;
          } else {
            return 1;
          }
        }
      }
      // second row of 2 images next to content
      if (row === 4) {
        // first image
        if (i === 1) {
          if (this.images.length >= 14) {
            return 12;
          } else if (this.images.length >= 9) {
            return 7;
          } else {
            return 2;
          }
        }
        // second image
        if (i === 2) {
          if (this.images.length >= 14) {
            return 13;
          } else if (this.images.length >= 9) {
            return 8;
          } else {
            return 3;
          }
        }
      }
      // last row of 5 images (only if 19 or more images)
      if (row === 5) {
        return i + 13;
      }
      return 0;
    },
  },
  computed: {
    sectionStyles() {
      return this.backgroundPosition(this.section_background_focal_point);
    },
    textContainerStyles() {
      return this.backgroundPosition(this.text_background_focal_point);
    },
    sectionBackgroundSrc() {
      if (this.canUseWebP() && this.section_background_webp) {
        return this.section_background_webp;
      }
      return this.section_background;
    }
  }
};
</script>

<!-- style is not scoped because style for generated img tag would not work -->
<style>
.pics-cta {
  position: relative;
  .container {
    max-width: 1240px;
    box-shadow: 0 0 100px rgba(0, 0, 0, 0.75);
    z-index: 30;
  }
  .over {
    content: "";
    width: 100%;
    height: 100%;
    background: rgba(75, 61, 42, 0.3);
    z-index: 20;
    position: absolute;
    top: 0;
    left: 0;
    transition: opacity 0.2s;
  }
  .square {
    flex: 1 0 auto;
    height: auto;
    position: relative;

    &:before {
      content: "";
      float: left;
      padding-top: 100%;
    }
    .over {
      z-index: 40;
    }

    img {
      filter: grayscale(100%);
      transition: filter 0.2s;
      position: relative;
      z-index: 30;
    }
    &:hover {
      .over {
        opacity: 0;
      }
      img {
        filter: grayscale(0%);
      }
    }
  }
  .icon {
    width: 100px;
    height: 100px;
  }
  @media (min-width: 1120px) {
    .icon {
      width: 150px;
      height: 150px;
    }
  }
}
</style>
