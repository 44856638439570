<template>
  <div class="video-tutorials-products-nav">
    <div v-if="ajaxError" class="mb-8 text-red text-lg" v-html="ajaxError"></div>
    <nav class="border-b border-brown">
      <ul class="text-center md:text-left">
        <li v-for="(product, p) in $parent.products" :key="p" :class="['inline-block pt-4', p > 0 ? 'pl-2 lg:pl-4 xl:pl-5' : 'pl-2']">
          <button
            type="button"
            @click="setProduct(product.slug)"
            :class="[
              'inline-block text-center px-2 text-base xl:text-lg uppercase font-bold text-green',
              $parent.selectedProduct === product.slug ? 'active border-green border-b-5' : ''
            ]"
          >
            <img :src="product.src" :srcset="[`${product.src} 1x, ${product.retinaSrc} 2x`]" :alt="product.name" class="mx-auto" />
            <span class="block label py-2">
              {{ product.name }}
            </span>
          </button>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
import VueAxios from "../../js/modules/vue-axios";

export default {
  data: function() {
    return {
      ajaxError: ""
    };
  },
  components: {
    BaseImage: () => import(/* webpackChunkName: "base-image" */ "../components/BaseImage.vue")
  },
  methods: {
    setProduct(productSlug) {
      let self = this,
        ajaxUrl = "/actions/site-module/video-tutorials/get-entries?product=" + productSlug;
      self.$parent.selectedProduct = productSlug;
      self.$parent.isLoading = true;

      // get data
      self.ajaxError = "";
      VueAxios.get(ajaxUrl)
        .then(response => {
          self.$parent.isLoading = false;
          let data = response.data || null;
          if (data !== undefined) {
            // set videos
            if (data && data.videos !== undefined) {
              self.$parent.videos = data.videos;
            }

            // set categories
            if (data && data.categories !== undefined) {
              self.$parent.categories = data.categories;
              // open first category
              if (self.$parent.categories && self.$parent.categories.length > 0) {
                self.$parent.categories[0].active = true;
              }
            }

            // set languages
            if (data && data.languages !== undefined) {
              self.$parent.languages = data.languages;
            }

            // check if selectedLanguage is still available
            let selectedLanguage = null;
            data.languages.forEach(function(language) {
              if (language.slug === self.$parent.selectedLanguage) {
                selectedLanguage = language.slug;
              }
            });
            // if previously selected language not found, set to english
            if (!selectedLanguage) {
              selectedLanguage = "en";
            }
            if (selectedLanguage) {
              self.$parent.selectedLanguage = selectedLanguage;
            }

            self.$parent.updateUrl();

            setTimeout(function() {
              self.$parent.selectFirstVideo();
            }, 500);
          } else {
            self.ajaxError = "An error occurred. Please try again.";
          }
        })
        .catch(error => {
          self.ajaxError = "An error occurred. Please try again.";
          self.$parent.isLoading = false;
        });
    }
  }
};
</script>

<style scoped>
li {
  max-width: 140px;
}
@media (min-width: 992px) {
  li {
    max-width: 150px;
  }
}
@media (min-width: 1280px) {
  li {
    max-width: 175px;
  }
}
</style>
