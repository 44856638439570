<template>
    <section class="block boxed-side-by-side-image-and-text white-block">
        <div :class="[
                include_container ? 'container mx-auto px-5-safe' : 'full-container',
                'py-10 lg:py-14 xl:py-16'
             ]">
            <div :class="[
                    boxed_style === 'yes' ? 'border-3 border-brown px-6 md:px-8 lg:px-10 xl:px-12 py-6 xl:py-8' : ''
                ]">
                <div :class="[
                        'flex flex-col-reverse flex-wrap',
                        show_media_full_width ? '' : 'md:flex-nowrap md:flex-row',
                        boxed_style === 'yes' ? 'items-center' : 'items-start'
                    ]">
                    <div v-if="image_src !== '' || video_src !== ''"
                         :class="[
                           'mt-8 w-full',
                           show_media_full_width ? '' : 'md:mt-0 md:w-1/3',
                         ]">

                        <!-- video -->
                        <div v-if="is_video">
                            <video width="100%" controls loop muted>
                                <source :src="video_src" type="video/mp4">
                            </video>
                        </div>

                        <!-- image -->
                        <div v-else>
                            <base-image
                                :src="image_src"
                                :retina_src="image_retina_src"
                                :srcset_webp="image_srcset_webp"
                                class_names="mx-auto"
                                :alt="alt"
                            ></base-image>
                        </div>
                    </div>
                    <div :class="[
                            'w-full',
                            show_media_full_width ? '' : 'md:w-2/3 md:pl-12'
                         ]">

                        <!-- heading -->
                        <base-heading v-if="heading !== ''" size="h2" color="brown">
                            {{ heading }}
                        </base-heading>

                        <hr v-if="boxed_style === 'yes'" class="mt-4 mb-3 border-0"/>

                        <!-- text -->
                        <base-rich-text v-if="has_text" :class_names="heading !== '' ? 'mt-3' : ''">
                            <template>
                                <slot name="text"></slot>
                            </template>
                        </base-rich-text>

                        <!-- footnote -->
                        <base-rich-text v-if="has_footnote" size="tiny" :class_names="has_text || heading !== '' ? 'mt-4' : ''">
                            <template>
                                <slot name="footnote"></slot>
                            </template>
                        </base-rich-text>

                        <!-- button -->
                        <div v-if="buttons.length > 0"
                             class="mt-5 -mx-2">
                          <base-button
                            v-for="(button, b) in buttons"
                            v-if="button.text !== '' && button.url !== ''"
                            :key="`column-${b}`"
                            :url="button.url"
                            :label="button.text"
                            :target="button.target"
                            class_names="mx-2 mb-4"
                            color="red">
                          ></base-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
  // @group Blocks
  export default {
    props: {
      boxed_style: String,
      is_video: Boolean,
      image_src: String,
      image_retina_src: String,
      image_srcset_webp: String,
      video_src: String,
      alt: String,
      heading: String,
      has_text: Boolean,
      has_footnote: Boolean,
      button_text: String,
      button_url: String,
      button_target: String,
      second_button_text: String,
      second_button_url: String,
      second_button_target: String,
      third_button_text: String,
      third_button_url: String,
      third_button_target: String,
      include_container: Boolean,
      show_media_full_width: Boolean
    },
    components: {
      BaseButton: () => import(/* webpackChunkName: "base-button" */ "../components/BaseButton.vue"),
      BaseHeading: () => import(/* webpackChunkName: "base-heading" */ "../components/BaseHeading.vue"),
      BaseImage: () => import(/* webpackChunkName: "base-image" */ "../components/BaseImage.vue"),
      BaseRichText: () => import(/* webpackChunkName: "base-rich-text" */ "../components/BaseRichText.vue")
    },
    computed: {
      buttons() {
        let buttons = [];
        if (this.button_text !== '' && this.button_url !== '') {
          buttons.push({
            text: this.button_text,
            url: this.button_url,
            target: this.button_target
          })
        }
        if (this.second_button_text !== '' && this.second_button_url !== '') {
          buttons.push({
            text: this.second_button_text,
            url: this.second_button_url,
            target: this.second_button_target
          })
        }
        if (this.third_button_text !== '' && this.third_button_url !== '') {
          buttons.push({
            text: this.third_button_text,
            url: this.third_button_url,
            target: this.third_button_target
          })
        }
        return buttons;
      }
    }
  };
</script>

<style scoped>
    hr {
        display: block;
        width: 137px;
        height: 3px;
        background: #e1251b;
    }
</style>
