<template>
  <div :class="['product-rich-text', !block.isFirst ? 'mt-6' : '']" v-show="block.text !== ''">
    <base-rich-text :size="size" v-html="block.text"></base-rich-text>
  </div>
</template>

<script>
export default {
  props: ["block"],
  components: {
    BaseRichText: () => import(/* webpackChunkName: "base-rich-text" */ "../../components/BaseRichText.vue")
  },
  computed: {
    size() {
      if (this.block.textSize === "small") {
        return "tiny";
      } else {
        return "";
      }
    }
  }
};
</script>
