<template>
  <a :href="url" :class="['thumbnail-image-with-overlay inline-block relative', class_names]">
    <base-image :src="src" :retina_src="retina_src" :alt="alt" :width="width" :height="height" class_names="z-10"></base-image>
    <span class="block overlay z-20 absolute left-0 top-0 w-full h-full opacity-0 tr-opacity">
      <span class="block px-10 w-full centered-y text-white text-center text-3xl uppercase font-bold">{{ text }}</span>
    </span>
  </a>
</template>

<script>
export default {
  props: ["href", "src", "retina_src", "alt", "class_names", "url", "text", "width", "height"],
  components: {
    BaseImage: () => import(/* webpackChunkName: "base-image" */ "../components/BaseImage.vue")
  }
};
</script>

<style scoped>
.overlay {
  background-color: rgba(75, 61, 42, 0.8);
}
a:hover {
  .overlay {
    opacity: 1;
  }
}
</style>
