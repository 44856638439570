<template>
  <aside class="faqs-filter side-filter text-brown">
    <div class="h-12">
      <base-heading size="h2" color="brown">Filter by:</base-heading>
    </div>
    <ul class="border-b border-brown">
      <li v-for="(category, c) in $parent.categories" :key="c">
        <sidebar-filter-button
          :label="category.name"
          :has_submenu="true"
          :active="category.active"
          @onclick="category.active = !category.active"
        >
        </sidebar-filter-button>
        <slide-up-down :active="category.active" :duration="350">
          <ul class="sub border-t border-brown py-1 pl-4 pr-4 xl:pl-12 xl:pr-10 text-lg xl:text-xl">
            <li v-for="(subCategory, s) in category.children" :key="s" class="pt-1 pb-2">
              <form-checkbox
                name="subCategory.slug"
                :checked="subCategory.active"
                :label="subCategory.name"
                v-model="subCategory.active"
                class_names="text-lg xl:text-xl"
              ></form-checkbox>
            </li>
          </ul>
        </slide-up-down>
      </li>
    </ul>
  </aside>
</template>

<script>
export default {
  components: {
    BaseHeading: () => import(/* webpackChunkName: "base-heading" */ "../components/BaseHeading.vue"),
    FormCheckbox: () => import(/* webpackChunkName: "form-checkbox" */ "../components/form/FormCheckbox.vue"),
    SidebarFilterButton: () => import(/* webpackChunkName: "sidebar-filter-button" */ "../components/SidebarFilterButton.vue")
  }
};
</script>
