<template>
    <div class="downloads-page" id="download-top">

        <base-heading size="h2" color="brown" class_names="mb-6">
            {{ pageHeading }}
        </base-heading>

        <div v-if="has_intro_text" class="text rich-text mb-4">
            <slot name="intro"></slot>
        </div>

        <div v-if="!selectedDownload">

            <!-- select product -->
            <div v-if="!selectedCategory" class="product-categories">
                <products-list :items="data.categories" @select="selectCategory"></products-list>
            </div>

            <!-- back link -->
            <div v-if="selectedCategory" class="mt-4">
                <button @click="selectedCategory = ''" class="text-lg text-red underline">Back to all products</button>
            </div>

            <!-- select download -->
            <div v-if="selectedCategory" class="mt-6">
                <software-list :items="showSoftware" @selectDownload="selectDownload"></software-list>
            </div>

        </div>
        <div v-if="selectedDownload">
            <download-entry @back="setDownload(null)" :data="data" :version="selectedVersion" :item="selectedDownload"></download-entry>
        </div>
    </div>
</template>

<script>
import Vue from "vue";
import VueScrollTo from "vue-scrollto";
Vue.use(VueScrollTo);
  import HelpersMixin from "../mixins/Helpers";

  export default {
    data: function() {
      return {
        selectedCategory: "",
        selectedDownload: null,
        showPlatforms: []
      };
    },
    props: ["data", "default_category", "has_intro_text"],
    components: {
      BaseHeading: () => import(/* webpackChunkName: "base-heading" */ "../components/BaseHeading.vue"),
      DownloadEntry: () => import(/* webpackChunkName: "download-entry" */ "./DownloadEntry.vue"),
      ProductsList: () => import(/* webpackChunkName: "products-list" */ "./ProductsList.vue"),
      SoftwareList: () => import(/* webpackChunkName: "software-list" */ "./SoftwareList.vue")
    },
    mixins: [HelpersMixin],
    methods: {
      selectCategory(category) {
        this.selectedCategory = category;
        this.setUrl("software-downloads", document.title, "/account/downloads/" + this.selectedCategory);
      },
      selectDownload(download) {
        this.setDownload(download)
        // scroll to top
        this.$nextTick(function() {
          VueScrollTo.scrollTo("#download-top", 350, {
            offset: -10
          });
        });
      },
      setDownload(download) {
        this.selectedDownload = download;
        this.selectedVersion = download ? this.selectedDownload.version : null;
      },
    },
    computed: {
      pageHeading() {
        let str = 'Downloads';
        if (this.selectedCategory && this.data.categories[this.selectedCategory] !== undefined) {
          str += ' for ' + this.data.categories[this.selectedCategory].title;
        }
        return str;
      },
      showSoftware() {
        let self = this;
        if (!self.selectedCategory) {
          return null;
        }
        let softwareSlugs = self.data.categories[self.selectedCategory].software || null;
        if (!softwareSlugs) {
          return null;
        }
        let array = [];
        softwareSlugs.forEach(function(slug) {
          let software = self.data.software[slug] || null;
          if (software) {
            array.push(software);
          }
        });
        return array;
      },
    },
    mounted() {
      let self = this;
      self.showPlatforms = [];
      if (self.default_category) {
        if (self.data.categories[self.default_category] !== undefined) {
          self.selectedCategory = self.default_category;
        }
      }
      let agreeCookie = this.getCookie("software_agree");
      if (typeof agreeCookie !== undefined && agreeCookie === "yes") {
        this.checkbox = true;
        this.agreeCookieSet = true;
      }
    },
    watch: {
      checkbox: function() {
        if (this.checkbox && !this.agreeCookieSet) {
          this.setCookie("software_agree", "yes");
        }
      }
    }
  };
</script>

<style scoped>
button:not(.active) {
    .label {
        transition: color 0.2s ease;
    }

    &:hover {
        .label {
            color: #e1251b;
        }
    }
}

.download-item:hover {
    .download-title {
        text-decoration: underline;
    }
}

nav {
    img {
        height: 64px;
    }

    button:not(.active) {
        filter: grayscale(1);
        opacity: 0.8;
    }
}

.history {
    .col-1 {
        width: 15%;
    }

    .col-2 {
        width: 25%;
    }

    .col-3 {
        width: 60%;
    }
}
</style>
