var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("table", { staticClass: "text-xl" }, [
      _vm._m(0),
      _vm._v(" "),
      _c(
        "tbody",
        [
          _vm._l(_vm.items, function(item, index) {
            return [
              _c("smart-system", {
                attrs: {
                  item: item,
                  index: index,
                  openAccordion: _vm.openAccordion,
                  userIp: _vm.userIp
                },
                on: {
                  "toggle-accordion": _vm.toggleAccordion,
                  "open-modal": _vm.openModal
                }
              }),
              _vm._v(" "),
              _c("smart-system-device", {
                attrs: { item: item, isOpen: _vm.isAccordionOpen(index) }
              }),
              _vm._v(" "),
              _vm.isModalOpen && _vm.currentModalIndex === index
                ? _c("device-modal", {
                    attrs: {
                      isModalOpen: _vm.isModalOpen !== null,
                      userIp: _vm.userIp,
                      item: _vm.items[_vm.currentModalIndex]
                    },
                    on: { "close-modal": _vm.closeModal }
                  })
                : _vm._e()
            ]
          })
        ],
        2
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", { staticClass: "pb-1" }, [
        _c("td"),
        _vm._v(" "),
        _c("td", [_vm._v("SMART System")]),
        _vm._v(" "),
        _c("td", [_vm._v("Internal Storage")]),
        _vm._v(" "),
        _c("td", [_vm._v("Voltage")]),
        _vm._v(" "),
        _c("td", [
          _vm._v("Status Received "),
          _c("br"),
          _vm._v("(America/New York)")
        ]),
        _vm._v(" "),
        _c("td", [_vm._v("Control Panel URL")]),
        _vm._v(" "),
        _c("td", [_vm._v("SSH Port")]),
        _vm._v(" "),
        _c("td", [_vm._v("Authorized IP")]),
        _vm._v(" "),
        _c("td")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }