<template>
  <header class="text-white product-header bg-green">
    <div class="container mx-auto px-5-safe">
      <div class="pt-6 pb-8 lg:pt-8 lg:pb-10 xl:pt-10 xl:pb-py-12">
        <div class="items-start lg:flex">
          <div class="mb-8 xl:pr-12 lg:mb-0 lg:w-1/2 xl:w-2/3">
            <base-heading size="h1" :uppercase="true" color="white">
              {{ product_name }}
            </base-heading>
            <base-rich-text size="larger" class_names="mt-2">
              {{ product_cont }}
            </base-rich-text>
            <base-rich-text class_names="mb-6 text-white">
              <slot name="suggested_product"></slot>
            </base-rich-text>
            <base-heading size="h2" color="brown-lighter" class_names="mt-3">
              {{ subheading }}
            </base-heading>
            <base-rich-text size="larger" class_names="mt-5">
              <slot name="description"></slot>
            </base-rich-text>
            <product-version v-if="product_versions && product_versions.length" :product_versions="product_versions" :product_versions_heading="product_versions_heading" />
          </div>
          <div class="lg:w-1/2 xl:w-1/3 lg:pl-12 xl:pl-10">
            <add-to-cart-box v-if="is_purchasable === 'true'">
              <template slot="price_footnote">
                <slot name="price_footnote"></slot>
              </template>
              <template slot="bulk_prices_text">
                <slot name="bulk_prices_text"></slot>
              </template>
            </add-to-cart-box>
            <div v-else class="relative z-30 px-5 pt-5 pb-4 text-white shadow-md box bg-brown">
              <div class="text-3xl font-bold lg:text-4xl">
                This product is currently unavailable.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  props: [
    "product_name",
    "product_cont",
    "subheading",
    "product_code",
    "is_purchasable",
    "product_thumbnail_src",
    "product_thumbnail_retina_src",
    "list_price",
    "product",
    "stock",
    "add_to_cart_url",
    "has_price_footnote",
    "essential_products_heading",
    "essential_products_text",
    "price_tiers",
    "essential_products",
    "requires_sales_contact",
    "price_override",
    "product_versions",
    "product_versions_heading",
  ],
  components: {
    BaseRichText: () => import(/* webpackChunkName: "base-rich-text" */ "../components/BaseRichText.vue"),
    BaseHeading: () => import(/* webpackChunkName: "base-heading" */ "../components/BaseHeading.vue"),
    AddToCartBox: () => import(/* webpackChunkName: "add-to-cart-box" */ "./add-to-cart/AddToCartBox.vue"),
    ProductVersion: () => import(/* webpackChunkName: "product-version" */ "./ProductVersion.vue")
  }
};
</script>

<style scoped>
.stock {
  letter-spacing: 1.5px;
}
</style>
