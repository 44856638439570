<template>
  <section class="cta buttons-cta pt-14 lg:pt-20 xl:pt-28 bg-cover" :style="sectionStyles" v-lazy:background-image="background">
    <div :class="['text-white', 'bg-' + background_color]">
      <div class="container mx-auto px-5 pt-6 pb-8 lg:pt-10 lg:pb-16 xl:pt-14 xl:pb-20 text-center">
        <div class="font-bold text-2xl md:text-3xl lg:text-4xl" v-show="heading !== ''" v-html="heading"></div>
        <slot name="text"></slot>
        <slot name="buttons"></slot>
      </div>
    </div>
  </section>
</template>

<script>
import HelpersMixin from "../mixins/Helpers";

export default {
  props: ["heading", "background", "background_focal_point", "background_color"],
  mixins: [HelpersMixin],
  computed: {
    sectionStyles() {
      return this.backgroundPosition(this.background_focal_point);
    }
  }
};
</script>
