var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.block.links
    ? _c(
        "section",
        {
          class: [
            "sidebar-links sidebar-block max-w-sm mx-auto",
            !_vm.is_first ? "mt-4" : ""
          ]
        },
        [
          _vm.block.links.length > 0
            ? _c(
                "div",
                {
                  class: [
                    "text-lg leading-normal",
                    _vm.block.links.length > 1 ? "mt-5" : ""
                  ]
                },
                _vm._l(_vm.block.links, function(link, l) {
                  return _c("div", { key: l, class: l > 0 ? "mt-2" : "" }, [
                    _c(
                      "a",
                      {
                        class: [
                          "text-" + link.color,
                          "hover:text-" + link.color + "-hover",
                          link.bold ? "font-bold" : ""
                        ],
                        attrs: { href: link.url, target: link.target }
                      },
                      [_vm._v("\n        " + _vm._s(link.text) + "\n      ")]
                    )
                  ])
                }),
                0
              )
            : _vm._e()
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }