var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    {
      directives: [
        {
          name: "lazy",
          rawName: "v-lazy:background-image",
          value: _vm.background,
          expression: "background",
          arg: "background-image"
        }
      ],
      staticClass: "cta buttons-cta pt-14 lg:pt-20 xl:pt-28 bg-cover",
      style: _vm.sectionStyles
    },
    [
      _c("div", { class: ["text-white", "bg-" + _vm.background_color] }, [
        _c(
          "div",
          {
            staticClass:
              "container mx-auto px-5 pt-6 pb-8 lg:pt-10 lg:pb-16 xl:pt-14 xl:pb-20 text-center"
          },
          [
            _c("div", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.heading !== "",
                  expression: "heading !== ''"
                }
              ],
              staticClass: "font-bold text-2xl md:text-3xl lg:text-4xl",
              domProps: { innerHTML: _vm._s(_vm.heading) }
            }),
            _vm._v(" "),
            _vm._t("text"),
            _vm._v(" "),
            _vm._t("buttons")
          ],
          2
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }