<template>
  <slide-up-down :class="['cart-line-item', lineItemLoading ? 'opacity-25' : '']" :active="!isDeleted" :duration="350">
    <div
      class="flex flex-wrap w-full pt-3 sm:py-3 xl:py-4 border-t border-brown text-base lg:text-lg xl:text-xl"
      :id="['cart-line-item-' + code]"
    >
      <div class="col-1 flex pb-2 sm:pb-0">
        <div class="col-1-1 text-center">
          <a :href="productData[code].url" v-if="productData[code] !== undefined">
            <base-image
              :src="productData[code].src !== '' ? productData[code].src : '/img/cart-prod-img-placeholder.png'"
              :retina_src="productData[code].retinaSrc !== '' ? productData[code].retinaSrc : '/img/cart-prod-img-placeholder@2x.png'"
              :alt="productData[code].src !== '' ? productData[code].alt : ''"
              class_names="image"
            ></base-image>
          </a>
        </div>
        <div class="col-1-2 pl-3 lg:pl-4">
          <a
            :href="productData[code].url"
            class="block font-bold text-xl xl:text-2xl leading-tight text-brown hover:underline hover:text-brown-hover"
            v-if="productData[code] !== undefined"
          >
            {{ productData[code].title }}
          </a>
          <div class="text-sm sm:text-base mt-1 sm:mt-2">Product Code: {{ code }}</div>
          <div v-if="ajaxError" class="mt-4 text-red text-lg" v-html="ajaxError"></div>
          <div class="mt-2 sm:mt-3" v-show="!deleteConfirm">
            <button
              :disabled="is_disabled || lineItemLoading || qtyLoading"
              @click="deleteConfirm = true"
              type="button"
              :class="['text-xs hover:underline', is_disabled || lineItemLoading || qtyLoading ? 'text-grey' : 'text-black']"
            >
              Remove
            </button>
          </div>
          <div class="mt-3 text-xs" v-show="deleteConfirm">
            Remove this from your cart?
            <div class="mt-2">
              <button
                type="button"
                @click="removeLineItem(code)"
                class="bg-brown hover:bg-brown-hover tr-bg text-white py-1 px-3 text-sm uppercase font-bold rounded"
              >
                Yes, Remove
              </button>
              <button
                type="button"
                @click="deleteConfirm = false"
                class="bg-grey-alt hover:bg-grey-dark tr-bg text-white py-1 px-3 text-sm uppercase font-bold rounded ml-3"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="relative col-2 px-3 bg-grey-lighter sm:bg-white text-right sm:px-0">
        <span class="left-0 ml-3 centered-y pt-3 pb-1 sm:pb-0 sm:hidden">Price:</span>
        <div v-if="$parent.is_admin" class="pl-5">
          <span class="relative">
            <input
              type="text"
              name="price_"
              :disabled="is_disabled"
              v-model="item.unit"
              :class="[
                'rounded text-right border py-2 pl-6 pr-4 w-full',
                is_disabled ? 'border-grey text-grey' : 'border-blue text-blue'
              ]"
              style="max-width: 150px;"
            />
            <span class="absolute centered-y left-0 text-blue text-2xl opacity-50 ml-2">$</span>
          </span>
        </div>
        <div v-else class="pt-3 pb-1 sm:pt-2 sm:pb-0">
          {{ formattedPrice(item.unit) }}
        </div>
      </div>
      <div class="col-3 relative px-3 py-1 bg-grey-lighter sm:bg-white text-right sm:text-center sm:p-0">
        <span class="absolute left-0 ml-3 centered-y sm:hidden">Quantity:</span>
        <input-product-quantity
          v-model="item.newQty"
          :value="item.newQty"
          border_color="brown"
          bg_color="white"
          :is_disabled="is_disabled || lineItemLoading || qtyLoading"
          :is_loading="qtyLoading"
          :allow_enter_key_submit="true"
          :product_code="code"
          @quantitychanged="updateQuantity"
          class_names="float-right ml-4 sm:float-none sm:mx-auto"
        ></input-product-quantity>
        <div v-show="!is_disabled && !lineItemLoading && item.qty !== item.newQty" class="mt-2">
          <div>
            <button :disabled="qtyLoading" @click="updateQuantity(code)" type="button" class="text-xs hover:underline">
              Update cart
            </button>
          </div>
          <div>
            <button :disabled="qtyLoading" @click="item.newQty = item.qty" type="button" class="text-xs hover:underline">
              Reset
            </button>
          </div>
        </div>
      </div>
      <div class="col-4 relative px-3 pb-3 pt-1 bg-grey-lighter sm:bg-white text-right sm:pt-2 sm:pb-0 sm:px-0">
        <span class="absolute left-0 ml-3 sm:hidden">Total:</span>
        {{ item.newQty > 0 ? formattedPrice(item.unit * item.newQty) : "&ndash;" }}
      </div>
    </div>
  </slide-up-down>
</template>

<script>
import VueAxios from "../../js/modules/vue-axios";
import CartMixin from "../mixins/Cart";
import { gaAddToCart, gaRemoveFromCart } from "../../js/modules/wa-analytics";

export default {
  data: function() {
    return {
      productData: window.baseCartProductData,
      isDeleted: false,
      deleteConfirm: false,
      qtyLoading: false,
      lineItemLoading: false,
      ajaxError: ""
    };
  },
  components: {
    BaseImage: () => import(/* webpackChunkName: "base-image" */ "../components/BaseImage.vue"),
    FormInput: () => import(/* webpackChunkName: "form-input" */ "../components/form/FormInput.vue"),
    InputProductQuantity: () => import(/* webpackChunkName: "input-product-quantity" */ "../components/InputProductQuantity.vue")
  },
  props: ["item", "code", "is_disabled"],
  mixins: [CartMixin],
  methods: {
    removeLineItem(productCode) {
      const code = productCode.replace(/\\/g, '\\\\');
      var self = this;
      self.deleteConfirm = false;
      self.lineItemLoading = true;
      let formData = new FormData();
      formData.append(self.$root.csrfTokenName, self.$root.csrfTokenValue);
      formData.append("del_" + code, "");
      formData.append("action", "wildlife/shop/update");
      let options = {
        method: "POST",
        data: formData,
        url: "/"
      };
      self.ajaxError = "";
      VueAxios(options)
        .then(response => {
          self.isDeleted = true;
          self.qtyLoading = false;
          setTimeout(() => {
            let data = response.data || null,
              cart = data.cart || null;
            if (data.success && cart) {
              self.$root.cart = cart;

              // Send to Google Analytics
              gaRemoveFromCart(
                  [{
                      name: this.productData[this.code].title,
                      id: this.code,
                      price: this.item.unit,
                      brand: self.$root.getSiteFullName(),
                      quantity: this.item.qty
                  }],
                  {
                      list: "Cart"
                  },
                this.item.qty,
                self.$root.currencyCode()
              )

            } else {
              self.ajaxError = "An error occurred. Please try again.";
              self.lineItemLoading = false;
            }
          }, 700);
        })
        .catch(error => {
          self.ajaxError = "An error occurred. Please try again.";
          self.lineItemLoading = false;
        });
    },
    updateQuantity(productCode) {
      const code = productCode.replace(/\\/g, '\\\\');
      var self = this;
      let newQty = self.$root.cart.items[productCode].newQty || null;
      if (parseInt(newQty) === 0 || newQty === null) {
        // remove line item (if qty = 0)
        self.removeLineItem(productCode);
      } else {

        let previousQty = self.$root.cart.items[productCode].qty;

        // update line item quantity
        self.$root.cart.items[productCode].qty = newQty;

        self.qtyLoading = true;
        if (newQty) {
          let formData = new FormData();
          formData.append(self.$root.csrfTokenName, self.$root.csrfTokenValue);
          formData.append("quantity_" + code, newQty);
          formData.append("update_" + code, "");
          formData.append("action", "wildlife/shop/update");
          let options = {
            method: "POST",
            data: formData,
            url: "/"
          };
          self.ajaxError = "";
          VueAxios(options)
            .then(response => {
              setTimeout(() => {
                let data = response.data || null,
                  cart = data.cart || null;
                if (data.success && cart) {
                  self.$root.cart = cart;

                  // Send to Google Analytics
                  if (newQty < previousQty) {
                    gaRemoveFromCart(
                      [{
                        name: this.productData[this.code].title,
                        id: this.code,
                        price: this.item.unit,
                        brand: self.$root.getSiteFullName(),
                        quantity: previousQty - newQty
                      }],
                      {
                        list: "Cart"
                      },
                      previousQty - newQty,
                      self.$root.currencyCode()
                    )
                  } else {
                    gaAddToCart(
                      [{
                        name: this.productData[this.code].title,
                        id: this.code,
                        price: this.item.price,
                        brand: self.$root.getSiteFullName(),
                        quantity: newQty - previousQty
                      }],
                      {
                        list: "Cart"
                      },
                      newQty - previousQty,
                      self.$root.currencyCode()
                    )
                  }

                } else {
                  self.ajaxError = "An error occurred. Please try again.";
                }
                self.qtyLoading = false;
              }, 350);
            })
            .catch(error => {
              self.ajaxError = "An error occurred. Please try again.";
              self.qtyLoading = false;
            });
        }
      }
    }
  }
};
</script>

<style scoped>
.image {
  width: 75px;
  height: 75px;
}
@media (min-width: 768px) {
  .image {
    width: 120px;
    height: 120px;
  }
}
@media (min-width: 992px) {
  .image {
    width: 150px;
    height: 150px;
  }
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}
</style>
