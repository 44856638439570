var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "article",
    { staticClass: "news-item w-full md:w-p48 lg:w-p31 mb-8" },
    [
      _c(
        "div",
        { class: ["mb-6 bg-gradient-1", !_vm.item.src ? "shadow" : ""] },
        [
          _c("base-image", {
            attrs: {
              src: _vm.item.src
                ? _vm.item.src
                : "/img/news-thumbnail-placeholder.png",
              retina_src: _vm.item.retinaSrc
                ? _vm.item.retinaSrc
                : "/img/news-thumbnail-placeholder@2x.png",
              alt: _vm.item.thumbnailAlt,
              class_names: "block m-0",
              url: _vm.item.url
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "text-xl uppercase font-bold text-brown-lighter" },
        _vm._l(_vm.item.categories, function(category, c) {
          return _c("span", { key: "category_" + c }, [
            _vm._v(
              "\n      " +
                _vm._s(
                  c > 0
                    ? _vm.item.categories.length === c + 1
                      ? " & "
                      : ", "
                    : ""
                ) +
                "\n      "
            ),
            _c(
              "a",
              {
                staticClass:
                  "text-brown-lighter tr-color hover:text-brown-light",
                attrs: { href: category.url }
              },
              [_vm._v(_vm._s(category.name))]
            )
          ])
        }),
        0
      ),
      _vm._v(" "),
      _c("div", { staticClass: "text-sm italic text-brown mt-1" }, [
        _vm._v("\n    " + _vm._s(_vm.item.date) + "\n    "),
        _vm.item.author
          ? _c("span", [_vm._v("/ By " + _vm._s(_vm.item.author))])
          : _vm._e()
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "mt-3" },
        [
          _c(
            "base-heading",
            {
              attrs: {
                size: "h2",
                color: "brown",
                hover_color: "red",
                url: _vm.item.url
              }
            },
            [_vm._v(_vm._s(_vm.item.title))]
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm.item.summary
        ? _c(
            "div",
            { staticClass: "mt-2" },
            [
              _c("base-rich-text", {
                domProps: {
                  innerHTML: _vm._s(_vm.widowProtect(_vm.item.summary))
                }
              })
            ],
            1
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }