<template>
  <component
    v-if="cta.type === 'image'"
    :is="cta.imageLink ? 'a' : 'div'"
    :href="cta.imageLink">
    <img :src="cta.src" :alt="cta.alt" class="max-w-full" />
  </component>

  <div v-else-if="cta.type === 'richText'">
    <h1 class="text-brown-lighter font-bold text-6xl">{{ cta.title }}</h1>
    <base-rich-text v-html="cta.text" class_names="mt-6"></base-rich-text>
    <base-button
      v-if="cta.buttonLink && cta.buttonText"
      :url="cta.buttonLink"
      :label="cta.buttonText"
      color="red"
      class_names="mt-6">
    </base-button>
  </div>

  <div v-else-if="cta.type == 'upcomingTraining'">
    <h1 class="text-brown-lighter font-bold text-6xl mb-6">Upcoming Trainings</h1>

    <div v-for="event in cta.events" class="mb-6">
      <h2 class="font-bold mb-2">{{ event.date }}</h2>
      <a class="hover:text-red" :href="event.url">{{ event.name }}</a>
    </div>

    <div class="mt-16">
      Explore our library of product videos.
    </div>

    <base-button
      url="/resources/video-tutorials"
      label="Video Tutorials"
      color="red"
      class_names="mt-6">
    </base-button>
  </div>
</template>

<script>
export default {
  props: [
    "cta",
    "foo",
  ],

  components: {
    BaseButton: () => import(/* webpackChunkName: "base-button" */ "../BaseButton.vue"),
    BaseRichText: () => import(/* webpackChunkName: "base-rich-text" */ "../BaseRichText.vue"),
    UtilityNav: () => import(/* webpackChunkName: "utility-nav" */ "../nav/UtilityNav.vue")
  },

  methods: {
  },

  mixins: [ ],
};
</script>
