var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      [
        _c("base-heading", { attrs: { size: "h2", color: "brown" } }, [
          _vm._v("Filter by:")
        ])
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "mt-4 flex flex-wrap items-center" }, [
      Object.keys(_vm.$parent.associated_products).length > 0
        ? _c(
            "div",
            { staticClass: "w-full sm:w-p48 lg:w-p24" },
            [
              _c("form-select", {
                attrs: {
                  name: "associated_products",
                  value: _vm.$parent.selectedAssociatedProduct,
                  options: _vm.associatedProductOptions,
                  class_names:
                    _vm.$parent.selectedAssociatedProduct !== ""
                      ? ["bg-grey-lighter"]
                      : ["bg-white"]
                },
                on: { selectchanged: _vm.filterChangeAssociatedProduct },
                model: {
                  value: _vm.$parent.selectedAssociatedProduct,
                  callback: function($$v) {
                    _vm.$set(_vm.$parent, "selectedAssociatedProduct", $$v)
                  },
                  expression: "$parent.selectedAssociatedProduct"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      Object.keys(_vm.$parent.accessory_categories).length > 0
        ? _c(
            "div",
            { staticClass: "w-full sm:w-p48 lg:w-p24 lg:ml-8" },
            [
              _c("form-select", {
                attrs: {
                  name: "accessory_categories",
                  value: _vm.$parent.selectedAccessoryCategory,
                  options: _vm.accessoryCategoryOptions,
                  class_names:
                    _vm.$parent.selectedAccessoryCategory !== ""
                      ? ["bg-grey-lighter"]
                      : ["bg-white"]
                },
                on: { selectchanged: _vm.filterChangeAccessoryCategory },
                model: {
                  value: _vm.$parent.selectedAccessoryCategory,
                  callback: function($$v) {
                    _vm.$set(_vm.$parent, "selectedAccessoryCategory", $$v)
                  },
                  expression: "$parent.selectedAccessoryCategory"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "w-full sm:w-p48 lg:w-p24 lg:ml-8" }, [
        _vm.showResetButton
          ? _c(
              "button",
              {
                staticClass:
                  "button inline-block uppercase tr-all rounded font-bold leading-tighter text-center cursor-pointer text-lg xl:text-xl border-3 border-brown hover:border-brown-hover text-brown bg-white hover:bg-grey-light px-8 lg:px-16 py-2 xl:py-3",
                attrs: { type: "button" },
                on: { click: _vm.reset }
              },
              [_vm._v("\n        Reset Filters\n      ")]
            )
          : _vm._e()
      ]),
      _vm._v(" "),
      Object.keys(_vm.$parent.associated_products).length === 0
        ? _c("div", { staticClass: "mb-4 w-full sm:w-p48 lg:w-p24" }, [
            _vm._v(" \n    ")
          ])
        : _vm._e(),
      _vm._v(" "),
      Object.keys(_vm.$parent.accessory_categories).length === 0
        ? _c("div", { staticClass: "mb-4 w-full sm:w-p48 lg:w-p24" }, [
            _vm._v(" ")
          ])
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }