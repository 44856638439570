<template>
  <section :class="['sidebar-heading sidebar-block max-w-sm mx-auto', !is_first ? 'mt-10' : '']">
    <base-heading size="h2" color="brown">
      {{ block.text }}
    </base-heading>
  </section>
</template>

<script>
export default {
  props: ["block", "is_first"],
  components: {
    BaseHeading: () => import(/* webpackChunkName: "base-heading" */ "../components/BaseHeading.vue")
  }
};
</script>
