<template>
  <div>
    <div class="mb-8 max-w-sm">
      <form method="get" :action="data.url" class="block relative">
          <input
            type="text"
            name="filter"
            placeholder="Filter by keyword"
            autocomplete="off"
            v-model="keyword"
            class="text-lg bg-white border-grey-light border-2 w-full text-black rounded p-3 leading-none text-black"
          />
          <button type="submit" class="text-brown w-7 h-7 centered-y right-0 mr-4 tr-color hover:text-red">
            <base-icon icon-name="search" width="24" height="24" viewbox="0 0 28 28">
              <icon-search></icon-search>
            </base-icon>
          </button>
        </form>
    </div>
    <ul v-if="showItems.length > 0">
      <li v-for="(item, i) in showItems"
          :key="`faq-item-${i}`">
        <a :href="item.url">
          {{ item.title }}
        </a>
      </li>
    </ul>
    <div v-else class="rich-text">
      No questions found. <a :href="data.url" @click.prevent="keyword = ''">Reset keyword</a>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      keyword: this.data.filter
    }
  },
  props: ["data"],
  components: {
    BaseIcon: () => import(/* webpackChunkName: "base-icon" */ "../components/BaseIcon.vue"),
    IconSearch: () => import(/* webpackChunkName: "icon-search" */ "../icons/IconSearch.vue")
  },
  computed: {
    showItems() {
      let self = this
      return self.data.items.filter(function(item) {
        if (!self.keyword || self.keyword === '') {
          return true
        }
        let title = item.title.toLowerCase();
        return title.indexOf(self.keyword) !== -1
      })
    }
  }
};
</script>