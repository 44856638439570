var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "g",
    { attrs: { transform: "translate(-1147.000000, -749.000000)" } },
    [
      _c("g", { attrs: { transform: "translate(100.000000, 714.000000)" } }, [
        _c("polygon", {
          attrs: {
            fill: "currentColor",
            points:
              "1069.9,36.8 1068.2,35 1058.5,44.7 1048.9,35 1047.1,36.8 1056.7,46.5 1047.1,56.2 1048.9,58 1058.5,48.3\n\t\t\t1068.2,58 1069.9,56.2 1060.3,46.5"
          }
        })
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }