var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "grant-program-content" },
    _vm._l(_vm.$parent.content_blocks, function(block, b) {
      return _c(
        "div",
        { key: b },
        [
          _c("GrantProgramBlock" + _vm.capitalizeFirstLetter(block.type), {
            tag: "component",
            attrs: { block: block }
          })
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }