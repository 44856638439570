var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "block heading-block white-block" }, [
    _c(
      "div",
      {
        class: [
          _vm.include_container
            ? "container px-5-safe mx-auto"
            : "full-container",
          _vm.include_container && _vm.centered ? "max-w-2xl" : "",
          "py-8 lg:py-10 xl:py-12"
        ]
      },
      [
        _c(
          "base-heading",
          {
            attrs: {
              size: _vm.heading_size,
              centered: _vm.centered,
              color: _vm.text_color,
              cursive: _vm.cursive
            }
          },
          [_vm._t("default")],
          2
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }