var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: [
        "form-radio-group",
        _vm.inline ? "inline-options" : "block",
        _vm.disabled ? "disabled" : ""
      ]
    },
    _vm._l(_vm.options, function(option, v) {
      return _c(
        "div",
        {
          class: [
            "radio-field",
            _vm.inline ? "block lg:inline-block" : "block mb-3"
          ]
        },
        [
          _c(
            "label",
            { class: ["styled-radio", _vm.disabled ? "disabled" : ""] },
            [
              _vm._v(_vm._s(option) + "\n      "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.localValue,
                    expression: "localValue"
                  }
                ],
                attrs: { type: "radio", name: _vm.name },
                domProps: { value: v, checked: _vm._q(_vm.localValue, v) },
                on: {
                  change: [
                    function($event) {
                      _vm.localValue = v
                    },
                    _vm.change
                  ]
                }
              }),
              _vm._v(" "),
              _vm._m(0, true)
            ]
          )
        ]
      )
    }),
    0
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "checkmark" }, [
      _c("span", { staticClass: "bg-brown" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }