<template>
  <div :class="['cart-buttons', class_names]">
    <base-button url="/shop" label="Keep Shopping" color="red" :outline="true" class_names="mb-3"></base-button>
    <button
      type="button"
      @click.prevent="proceedToCheckout"
      :disabled="is_disabled"
      :class="[
        'button inline-block uppercase tr-all rounded text-lg xl:text-xl font-bold leading-tighter text-center border-3 cursor-pointer px-4 py-2 xl:px-5 xl:py-3 ml-7 mb-3',
        is_disabled ? 'text-grey bg-white' : 'bg-red border-red text-white hover:bg-red-hover hover:border-red-hover'
      ]"
    >
      Proceed to Checkout
    </button>
  </div>
</template>

<script>
export default {
  props: ["class_names", "is_disabled"],
  components: {
    BaseButton: () => import(/* webpackChunkName: "base-button" */ "../components/BaseButton.vue")
  },
  methods: {
    proceedToCheckout() {
      this.$emit("proceed");
    }
  }
};
</script>
