var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "store-index container mx-auto px-5-safe my-6 lg:my-10" },
    [
      _c("div", { staticClass: "lg:flex" }, [
        _c(
          "div",
          { staticClass: "mb-10 lg:mb-0 lg:w-3/4 lg:pr-12 xl:pr-16" },
          [
            _c(
              "div",
              { staticClass: "mb-10 lg:hidden" },
              [_c("store-selected-products")],
              1
            ),
            _vm._v(" "),
            _c(
              "expandable-categories",
              { attrs: { page: "store" } },
              [
                _c(
                  "template",
                  { slot: "bulk_prices_text" },
                  [_vm._t("bulk_prices_text")],
                  2
                )
              ],
              2
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "lg:w-1/4 pt-10", attrs: { "sticky-container": "" } },
          [
            _c(
              "store-sidebar",
              {
                directives: [
                  {
                    name: "sticky",
                    rawName: "v-sticky",
                    value: { active: true, stickySide: "top" },
                    expression: "{ active: true, stickySide: 'top' }"
                  }
                ],
                attrs: { "sticky-offset": "{ top: 15 }" }
              },
              [[_vm._t("sidebar-text")]],
              2
            )
          ],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }