var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "block images-grid heading-block white-block" },
    [
      _c(
        "div",
        {
          class: [
            _vm.include_container
              ? "container mx-auto px-5-safe"
              : "full-container",
            "pt-8 pb-4 lg:pt-10 lg:pb-4 xl:pt-12 xl:pb-6"
          ]
        },
        [
          _c(
            "div",
            { staticClass: "flex justify-between flex-wrap" },
            _vm._l(_vm.images, function(item, i) {
              return item.src
                ? _c(
                    "div",
                    {
                      key: "image_" + i,
                      class: [
                        "image mb-4 lg:mb-6",
                        parseInt(_vm.num_columns) === 2
                          ? "w-p48"
                          : "sm:w-p48 md:w-p31"
                      ]
                    },
                    [
                      _c("base-image", {
                        attrs: {
                          src: item.src,
                          retina_src: item.retina,
                          srcset_webp: item.webpSrcset,
                          class_names: "mx-auto",
                          alt: item.alt
                        }
                      }),
                      _vm._v(" "),
                      item.caption
                        ? _c("div", {
                            staticClass:
                              "text-sm lg:text-base leading-normal mt-4",
                            domProps: { innerHTML: _vm._s(item.caption) }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e()
            }),
            0
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }