<template>
  <section
    :style="sectionStyles"
    class="grant-program-guidelines bg-cover"
    v-lazy:background-image="data.src"
    v-if="data.blocks.length > 0"
    id="guidelines"
  >
    <div class="container px-5 mx-auto">
      <div class="lg:flex">
        <div class="lg:w-1/4">&nbsp;</div>
        <div class="lg:w-3/4 bg-brown text-white">
          <div class="w-full h-3 xl:h-4 bg-brown-lighter"></div>
          <div class="px-5 py-6 lg:px-8 lg:py-8 xl:px-12 xl:py-10">
            <div
              v-for="(block, b) in data.blocks"
              :key="b"
              :class="[!block.isFirst ? (block.previousBlock !== 'heading' ? 'mt-6' : 'mt-2') : '']"
            >
              <div v-if="block.type === 'heading'">
                <base-heading :size="headingSize(block.size)" :cursive="block.cursive" :color="block.color ? block.color : 'white'">{{
                  block.text
                }}</base-heading>
              </div>
              <div v-if="block.type === 'richText'">
                <base-rich-text v-html="block.text"></base-rich-text>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import HelpersMixin from "../mixins/Helpers";

export default {
  data: function() {
    return {
      data: this.$parent.guidelines_data
    };
  },
  mixins: [HelpersMixin],
  components: {
    BaseHeading: () => import(/* webpackChunkName: "base-heading" */ "../components/BaseHeading.vue"),
    BaseRichText: () => import(/* webpackChunkName: "base-rich-text" */ "../components/BaseRichText.vue")
  },
  methods: {
    headingSize(size) {
      if (size === "large") {
        return "h1";
      } else if (size === "small") {
        return "h3";
      }
      return "h2";
    }
  },
  computed: {
    sectionStyles() {
      return this.backgroundPosition(this.section_background_focal_point);
    }
  }
};
</script>

<style scoped>
section {
  padding-top: 50px;
  padding-bottom: 50px;
}
@media (min-width: 992px) {
  section {
    padding-top: 100px;
    padding-bottom: 80px;
  }
}
@media (min-width: 1280px) {
  section {
    padding-top: 130px;
    padding-bottom: 100px;
  }
}
</style>
