<template>
  <section class="block featured-content">
    <div class="container mx-auto px-5-safe relative py-8 lg:py-10 xl:py-16">
      <h1 class="font-bold text-brown text-center text-xl md:text-4xl lg:text-6xl xl:text-8xl lg:text-left mb-10">
        Featured Content
      </h1>

      <div class="md:flex">
        <template v-for="(contentItem, i) in data">
          <div class="flex-1 mb-12 md:mb-0">
            <thumbnail-image-with-overlay
              :src="contentItem.imageSrc"
              :retina_src="contentItem.imageSrcRetina"
              :alt="contentItem.alt"
              :width="contentItem.width"
              :height="contentItem.height"
              :url="contentItem.url"
              text="Read Post"
              :class_names="['mb-5 mx-auto text-center', !contentItem.imageSrc ? 'shadow' : '']"
            ></thumbnail-image-with-overlay>
            <h3 class="uppercase text-lg lg:text-xl xl:text-2xl font-bold mb-1 text-brown-lighter">{{ contentItem.eyebrow }}</h3>
            <a :href="contentItem.url" class="text-brown hover:text-brown-hover hover:underline">
              <h2 class="font-bold text-2hxl xl:text-3xl mt-1">{{ contentItem.title }}</h2>
            </a>
            <div class="mt-4 text-xl lg:text-2xl xl:text-3xl text-black leading-normal">{{ contentItem.blurb }}</div>
          </div>
          <div v-if="i < (data.length - 1)" class="flex-none w-12 sm:mb-8 md:mb-0"></div>
        </template>
      </div>
    </div>
  </section>
</template>

<script>
// @group Blocks
export default {
  props: ["data"],
  components: {
    ThumbnailImageWithOverlay: () =>
      import(/* webpackChunkName: "thumbnail-image-with-overlay" */ "../components/ThumbnailImageWithOverlay.vue")
  }
};
</script>
