var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "faqs-results" },
    [
      _c("search-bar", {
        attrs: { keyword: _vm.keyword, placeholder: "Search FAQs" },
        on: { updateResults: _vm.updateResults }
      }),
      _vm._v(" "),
      _vm.ajaxError
        ? _c("div", {
            staticClass: "mt-6 text-red text-lg",
            domProps: { innerHTML: _vm._s(_vm.ajaxError) }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("div", { class: ["mt-6", _vm.isLoading ? "opacity-25" : ""] }, [
        _vm.items && _vm.items.length > 0
          ? _c(
              "div",
              _vm._l(_vm.items, function(item, i) {
                return _c(
                  "div",
                  {
                    key: i,
                    class: [
                      "question py-3 text-brown border-b border-brown",
                      item.active ? "active" : ""
                    ],
                    attrs: { id: "faq-" + item.id }
                  },
                  [
                    _c(
                      "button",
                      {
                        class: [
                          "block toggle text-xl xl:text-2xl pl-9 relative text-left",
                          item.active ? "font-bold active" : ""
                        ],
                        attrs: { type: "button" },
                        on: {
                          click: function($event) {
                            return _vm.selectQuestion(item)
                          }
                        }
                      },
                      [
                        _vm._v(
                          "\n          " + _vm._s(item.question) + "\n        "
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "slide-up-down",
                      { attrs: { active: item.active, duration: 350 } },
                      [
                        _c(
                          "div",
                          { staticClass: "pl-9 mt-3" },
                          [
                            _c("base-rich-text", {
                              attrs: { size: "smaller" },
                              domProps: { innerHTML: _vm._s(item.answer) }
                            })
                          ],
                          1
                        )
                      ]
                    )
                  ],
                  1
                )
              }),
              0
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.keyword !== "" && (!_vm.items || _vm.items.length === 0)
          ? _c("div", [
              _c(
                "div",
                {
                  staticClass:
                    "w-full rounded-lg bg-grey-lighter text-center py-20"
                },
                [
                  _c(
                    "div",
                    { staticClass: "text-3xl uppercase text-grey-dark" },
                    [_vm._v("\n          No questions found\n        ")]
                  ),
                  _vm._v(" "),
                  _vm.keyword !== ""
                    ? _c("div", [
                        _c(
                          "div",
                          {
                            staticClass:
                              "text-xl uppercase text-grey-dark mt-2 leading-normal"
                          },
                          [
                            _vm._v("\n            with keyword\n            "),
                            _c("span", { staticClass: "italic" }, [
                              _vm._v("`" + _vm._s(_vm.keyword) + "`")
                            ])
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "mt-12" }, [
                          _c(
                            "button",
                            {
                              staticClass:
                                "button inline-block uppercase tr-all rounded text-lg xl:text-xl font-bold leading-tighter text-center cursor-pointer text-white bg-red hover:bg-red-hover px-4 py-2",
                              on: {
                                click: function($event) {
                                  _vm.keyword = ""
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n              Reset Search\n            "
                              )
                            ]
                          )
                        ])
                      ])
                    : _vm._e()
                ]
              )
            ])
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }