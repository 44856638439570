var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.active
    ? _c(
        _vm.s.url ? "a" : "span",
        {
          tag: "component",
          class: [
            "block bar tr-all bg-green py-2 lg:py-3 text-white relative print:hidden",
            _vm.s.url ? "cursor-pointer" : ""
          ],
          attrs: {
            href: _vm.s.url ? _vm.s.url : null,
            target: _vm.s.url ? _vm.s.target : null
          }
        },
        [
          _c("div", { staticClass: "container mx-auto px-5 relative" }, [
            _c(
              "div",
              {
                class: [
                  "inner tr-all text-lg lg:text-2xl leading-normal text-center",
                  _vm.s.allowClosing ? "pr-10" : ""
                ]
              },
              [
                _c("div", { staticClass: "inline-flex items-center mx-auto" }, [
                  _vm.s.hasHeading
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "heading-col uppercase font-bold text-center text-right"
                        },
                        [
                          _vm._v(
                            "\n                    " +
                              _vm._s(_vm.s.heading) +
                              "\n                "
                          )
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.s.src
                    ? _c(
                        "div",
                        {
                          staticClass: "pr-3 sm:pr-5 md:px-5 lg:px-7 icon-col"
                        },
                        [
                          _c("base-image", {
                            attrs: {
                              width: "30",
                              height: "30",
                              src: _vm.s.src,
                              retina_src: _vm.s.retinaSrc,
                              srcset_webp: _vm.s.webpSrcset,
                              alt: _vm.s.alt,
                              disable_lazy: true,
                              class_names: "my-0 inline-block"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "text-left" }, [
                    _vm.s.hasHeading
                      ? _c(
                          "div",
                          { staticClass: "uppercase font-bold mb-1 md:hidden" },
                          [
                            _vm._v(
                              "\n                        " +
                                _vm._s(_vm.s.heading) +
                                "\n                    "
                            )
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c("div", { staticClass: "text" }, [_vm._t("text")], 2)
                  ]),
                  _vm._v(" "),
                  _vm.s.buttonText && _vm.s.url
                    ? _c("div", { staticClass: "pl-6 sm:pl-8" }, [
                        _c(
                          "a",
                          {
                            staticClass:
                              "bg-white text-base sm:text-lg rounded text-green py-2 px-4 font-bold whitespace-no-wrap tr-opacity hover:opacity-75",
                            attrs: { href: _vm.s.url, target: _vm.s.target }
                          },
                          [
                            _vm._v(
                              "\n                        " +
                                _vm._s(_vm.s.buttonText) +
                                "\n                    "
                            )
                          ]
                        )
                      ])
                    : _vm._e()
                ])
              ]
            )
          ]),
          _vm._v(" "),
          _vm.s.allowClosing
            ? _c(
                "button",
                {
                  staticClass:
                    "cursor-pointer block absolute centered-y right-0 mr-5 tr-all text-white tr-opacity hover:opacity-75",
                  attrs: { type: "button" },
                  on: {
                    click: function($event) {
                      return _vm.close()
                    }
                  }
                },
                [
                  _c(
                    "base-icon",
                    {
                      attrs: {
                        "icon-name": "link.icon",
                        width: "16",
                        height: "16",
                        viewbox: "0 0 23 23"
                      }
                    },
                    [_c("icon-x")],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }