<template>
  <section class="block block-icons-grid white-block">
    <div :class="[include_container ? 'container mx-auto px-5-safe' : 'full-container', 'py-12']">
      <div class="flex flex-wrap text-center">
        <div
          v-for="(icon, i) in icons"
          :class="[
            'w-1/2 md:w-1/3 p-6 lg:p-8 border-brown',
            (i + 1) % 2 !== 0 ? 'border-r' : '',
            i + 1 < (icons.length % 2 === 0 ? icons.length - 1 : icons.length) ? 'border-b' : '',
            (i + 1) % 3 !== 0 ? 'md:border-r' : 'md:border-r-0',
            i + 1 < icons.length - 2 ? 'md:border-b' : 'md:border-b-0'
          ]"
        >
          <base-image
            :src="icon.src"
            :retina_src="icon.retinaSrc"
            :srcset_webp="icon.webpSrcset"
            :alt="icon.alt"
            class_names="block m-0"
            :url="icon.url"
          ></base-image>
          <div class="mt-7">
            <a :href="icon.url" :target="icon.target" class="text-lg md:text-2xl lg:text-4xl font-bold text-brown text-hover">
              {{ icon.heading }}
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: ["icons", "include_container"],
  components: {
    BaseImage: () => import(/* webpackChunkName: "base-image" */ "../components/BaseImage.vue")
  }
};
</script>
