var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "faqs-index container mx-auto px-5-safe pt-10 pb-14" },
    [
      _c("div", { staticClass: "lg:flex" }, [
        _c(
          "div",
          { staticClass: "lg:w-1/3 lg:pr-12" },
          [
            _c("faqs-filter"),
            _vm._v(" "),
            _c("div", { staticClass: "mt-10 rich-text" }, [
              _c("a", { attrs: { href: _vm.overview_url } }, [
                _vm._v("\n          See All FAQs\n        ")
              ])
            ])
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "mt-10 lg:mt-0 lg:w-2/3 lg:pt-12" },
          [_c("faqs-results", { attrs: { faqs_type: _vm.faqs_type } })],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }