<template>
  <section class="block audio-block bg-white text-brown white-block">
    <div :class="[
            include_container ? 'container mx-auto px-5-safe' : 'full-container',
            'py-8 lg:py-8 xl:py-10'
         ]">
      <audio controls v-if="s.showAudioPlayer" class="w-full">
        <source :src="s.file" type="audio/mpeg" />
        Your browser does not support the audio tag.
      </audio>
      <div v-if="s.showDownloadLink" :class="s.showAudioPlayer ? 'mt-4' : ''">
        <a :href="s.file" target="_blank" class="download-link block relative pl-10 text-lg text-brown tr-color hover:text-red">
          <base-icon icon-name="checkmark" width="24" height="24" viewbox="0 0 31 31" class="absolute left-0 top-0">
            <icon-download></icon-download>
          </base-icon>
          {{ s.downloadLinkText }}
        </a>
      </div>
      <div v-if="s.description" class="rich-text text-lg mt-4 leading-normal" v-html="s.description"></div>
    </div>
  </section>
</template>

<script>
export default {
  props: ["s", "include_container"],
  components: {
    BaseIcon: () => import(/* webpackChunkName: "base-icon" */ "../components/BaseIcon.vue"),
    IconDownload: () => import(/* webpackChunkName: "icon-download" */ "../icons/IconDownload.vue")
  }
};
</script>

<style scoped>
.container {
  max-width: 576px;
}
.download-link {
  display: block;
  min-height: 24px;
  line-height: 24px;
}
</style>
