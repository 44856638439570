<template>
  <div class="training-course container px-5-safe mx-auto pt-8 pb-12 lg:pt-12 pb-20 text-brown">
    <div :class="[data.displaySessions === 'sidebar' ? 'lg:flex' : '']">
      <div :class="[data.displaySessions === 'sidebar' ? 'lg:w-2/3 lg:pr-20' : '']">
        <div v-if="data.src && data.src !== ''" class="mb-8">
          <base-image :src="data.src" :retina_src="data.retinaSrc" :alt="data.alt" class_names="block m-0"></base-image>
        </div>
        <div v-if="data.description">
          <base-rich-text v-html="data.description"></base-rich-text>
        </div>
        <div :class="[data.src || data.description ? 'mt-8' : '']" v-if="data.date || data.time || data.location || data.cost">
          <div class="details-table border-t border-brown text-lg lg:text-xl xl:text-2xl leading-normal">
            <training-course-info-row v-if="data.date" :label="'Date' + (data.multiday ? '(s)' : '')">
              {{ data.date }}
            </training-course-info-row>
            <training-course-info-row label="Time" v-if="data.time">
              <span v-html="data.time"></span>
            </training-course-info-row>
            <training-course-info-row label="Location" v-if="data.location">
              <span v-html="data.location"></span>
            </training-course-info-row>
            <training-course-info-row label="Cost" v-if="data.cost">
              {{ data.cost }}
            </training-course-info-row>
          </div>
        </div>
      </div>
      <div :class="[data.displaySessions === 'sidebar' ? 'mt-10 lg:mt-0 lg:w-1/3' : (data.src || data.description || data.date || data.time || data.location || data.cost ? 'mt-10' : '')]">
        <div v-if="data.sessions.length > 0">
          <training-course-sessions-sidebar v-if="data.displaySessions === 'sidebar'" :sessions="data.sessions"></training-course-sessions-sidebar>
          <training-course-sessions-full-width v-else :sessions="data.sessions"></training-course-sessions-full-width>
        </div>
        <div v-else class="text-2xl text-center border border-grey py-12 px-6 max-w-3xl mx-auto">
          There are no scheduled sessions as this time.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["data"],
  components: {
    BaseButton: () => import(/* webpackChunkName: "base-button" */ "../components/BaseButton.vue"),
    BaseHeading: () => import(/* webpackChunkName: "base-heading" */ "../components/BaseHeading.vue"),
    BaseImage: () => import(/* webpackChunkName: "base-image" */ "../components/BaseImage.vue"),
    BaseRichText: () => import(/* webpackChunkName: "base-rich-text" */ "../components/BaseRichText.vue"),
    TrainingCourseInfoRow: () => import(/* webpackChunkName: "training-course-info-row" */ "./TrainingCourseInfoRow.vue"),
    TrainingCourseSessionsFullWidth: () => import(/* webpackChunkName: "training-course-sessions-full-width" */ "./TrainingCourseSessionsFullWidth.vue"),
    TrainingCourseSessionsSidebar: () => import(/* webpackChunkName: "training-course-sessions-sidebar" */ "./TrainingCourseSessionsSidebar.vue")
  }
};
</script>

<style scoped>
.details-table {
  background-color: #f2eade;
}
</style>
