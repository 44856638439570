var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.success
    ? _c("div", { staticClass: "rich-text" }, [
        _c("p", [
          _vm._v(
            "\n    Thank you for contacting Wildlife Acoustics Support! A support case number has been created and a confirmation email has been sent to "
          ),
          _c("span", { domProps: { textContent: _vm._s(_vm.email) } }),
          _vm._v(".\n  ")
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n    A support ticket has been created. For most support requests, we respond within 1-2 business days.\n  "
          )
        ]),
        _vm._v(" "),
        _vm._m(0),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n    If these resources helped you find your answer or if you think our resources could be improved, please let us know in our support survey.\n  "
          )
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n    If your support request is urgent, please call us at 1 (978) 369-5225 and choose option 2 for Support. Our standard Support hours are 9am - 5pm Monday - Friday Eastern (Boston) time excluding U.S. holidays. When calling outside of business hours, please leave a message and we will get back to you as quickly as we can.\n  "
          )
        ]),
        _vm._v(" "),
        _vm._m(1),
        _vm._v(" "),
        _c("p", [
          _vm._v("\n    Thank you again and we will respond shortly.\n  ")
        ]),
        _vm._v(" "),
        _c("p", [_vm._v("\n    -The Wildlife Acoustics Support Team\n  ")])
      ])
    : _c("div", [
        _c("div", { staticClass: "text-left text-lg" }, [
          _c("div", { staticClass: "lg:flex" }, [
            _c("div", { staticClass: "lg:w-1/2 lg:pr-10" }, [
              _c(
                "h1",
                { staticClass: "mb-8 font-bold text-2hxl xl:text-3xl" },
                [_vm._v("Contact Information")]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "sm:w-p60 lg:w-full mb-5" },
                [
                  _c("label", { attrs: { for: "first_name" } }, [
                    _vm._v("Given Name or Nickname *")
                  ]),
                  _vm._v(" "),
                  _c("form-input", {
                    class: { "border-red": _vm.errors.includes("first_name") },
                    attrs: {
                      input_type: "text",
                      name: "first_name",
                      disabled: _vm.is_loading
                    },
                    model: {
                      value: _vm.first_name,
                      callback: function($$v) {
                        _vm.first_name = $$v
                      },
                      expression: "first_name"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "sm:w-p60 lg:w-full mb-5" },
                [
                  _c("label", { attrs: { for: "last_name" } }, [
                    _vm._v("Surname *")
                  ]),
                  _vm._v(" "),
                  _c("form-input", {
                    class: { "border-red": _vm.errors.includes("last_name") },
                    attrs: {
                      type: "text",
                      name: "last_name",
                      disabled: _vm.is_loading
                    },
                    model: {
                      value: _vm.last_name,
                      callback: function($$v) {
                        _vm.last_name = $$v
                      },
                      expression: "last_name"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "sm:w-p60 lg:w-full mb-5" },
                [
                  _c("label", { attrs: { for: "email" } }, [
                    _vm._v("Email Address *")
                  ]),
                  _vm._v(" "),
                  _c("form-input", {
                    class: { "border-red": _vm.errors.includes("email") },
                    attrs: {
                      type: "text",
                      name: "email",
                      disabled: _vm.is_loading
                    },
                    model: {
                      value: _vm.email,
                      callback: function($$v) {
                        _vm.email = $$v
                      },
                      expression: "email"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "sm:w-p60 lg:w-full mb-5" },
                [
                  _c("label", { attrs: { for: "job_title" } }, [
                    _vm._v("Job Title")
                  ]),
                  _vm._v(" "),
                  _c("form-input", {
                    class: { "border-red": _vm.errors.includes("job_title") },
                    attrs: {
                      type: "text",
                      name: "job_title",
                      disabled: _vm.is_loading
                    },
                    model: {
                      value: _vm.job_title,
                      callback: function($$v) {
                        _vm.job_title = $$v
                      },
                      expression: "job_title"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "sm:w-p60 lg:w-full mb-5" },
                [
                  _c("label", { attrs: { for: "country" } }, [
                    _vm._v("Country *")
                  ]),
                  _vm._v(" "),
                  _c("form-select", {
                    attrs: {
                      name: "country",
                      class_names: [
                        _vm.errors.includes("country") ? "border-red" : ""
                      ],
                      options: _vm.country_options,
                      disabled: _vm.is_loading
                    },
                    model: {
                      value: _vm.country,
                      callback: function($$v) {
                        _vm.country = $$v
                      },
                      expression: "country"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "sm:w-p60 lg:w-full mb-5" },
                [
                  _c("label", { attrs: { for: "organization_name" } }, [
                    _vm._v("Organization Name")
                  ]),
                  _vm._v(" "),
                  _c("form-input", {
                    class: {
                      "border-red": _vm.errors.includes("organization_name")
                    },
                    attrs: {
                      type: "text",
                      name: "organization_name",
                      disabled: _vm.is_loading
                    },
                    model: {
                      value: _vm.organization_name,
                      callback: function($$v) {
                        _vm.organization_name = $$v
                      },
                      expression: "organization_name"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "sm:w-p60 lg:w-full mb-5" },
                [
                  _c("label", { attrs: { for: "organization_type" } }, [
                    _vm._v("Organization Type")
                  ]),
                  _vm._v(" "),
                  _c("form-select", {
                    attrs: {
                      name: "organization_type",
                      options: _vm.organization_types,
                      class_names: [
                        _vm.errors.includes("organization_type")
                          ? "border-red"
                          : ""
                      ],
                      disabled: _vm.is_loading
                    },
                    model: {
                      value: _vm.organization_type,
                      callback: function($$v) {
                        _vm.organization_type = $$v
                      },
                      expression: "organization_type"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "sm:w-p60 lg:w-full mb-5" },
                [
                  _c("label", { attrs: { for: "phone_number" } }, [
                    _vm._v("Phone Number")
                  ]),
                  _vm._v(" "),
                  _c("form-input", {
                    class: { "border-red": _vm.errors.includes("phone") },
                    attrs: {
                      type: "text",
                      name: "phone",
                      disabled: _vm.is_loading
                    },
                    model: {
                      value: _vm.phone,
                      callback: function($$v) {
                        _vm.phone = $$v
                      },
                      expression: "phone"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "sm:w-p60 lg:w-full mb-5" },
                [
                  _c("label", { attrs: { for: "address_1" } }, [
                    _vm._v("Address 1")
                  ]),
                  _vm._v(" "),
                  _c("form-input", {
                    class: { "border-red": _vm.errors.includes("address_1") },
                    attrs: {
                      type: "text",
                      name: "address_1",
                      disabled: _vm.is_loading
                    },
                    model: {
                      value: _vm.address_1,
                      callback: function($$v) {
                        _vm.address_1 = $$v
                      },
                      expression: "address_1"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "sm:w-p60 lg:w-full mb-5" },
                [
                  _c("label", { attrs: { for: "address_2" } }, [
                    _vm._v("Address 2")
                  ]),
                  _vm._v(" "),
                  _c("form-input", {
                    class: { "border-red": _vm.errors.includes("address_2") },
                    attrs: {
                      type: "text",
                      name: "address_2",
                      disabled: _vm.is_loading
                    },
                    model: {
                      value: _vm.address_2,
                      callback: function($$v) {
                        _vm.address_2 = $$v
                      },
                      expression: "address_2"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "sm:w-p60 lg:w-full mb-5" },
                [
                  _c("label", { attrs: { for: "city" } }, [_vm._v("City")]),
                  _vm._v(" "),
                  _c("form-input", {
                    class: { "border-red": _vm.errors.includes("city") },
                    attrs: {
                      type: "text",
                      name: "city",
                      disabled: _vm.is_loading
                    },
                    model: {
                      value: _vm.city,
                      callback: function($$v) {
                        _vm.city = $$v
                      },
                      expression: "city"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "sm:w-p60 lg:w-full mb-5" },
                [
                  _c("label", { attrs: { for: "state" } }, [
                    _vm._v(_vm._s(_vm.state_label))
                  ]),
                  _vm._v(" "),
                  _vm.has_states
                    ? _c(
                        "div",
                        {
                          class: [
                            "input-select-wrapper relative",
                            _vm.is_loading ? "disabled" : ""
                          ]
                        },
                        [
                          _vm.country === "UNITED KINGDOM"
                            ? [
                                _c(
                                  "select",
                                  {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.state,
                                        expression: "state"
                                      }
                                    ],
                                    class: [
                                      "input-select",
                                      _vm.errors.includes("state")
                                        ? "border-red"
                                        : ""
                                    ],
                                    attrs: {
                                      name: "state",
                                      disabled: _vm.is_loading
                                    },
                                    on: {
                                      change: function($event) {
                                        var $$selectedVal = Array.prototype.filter
                                          .call($event.target.options, function(
                                            o
                                          ) {
                                            return o.selected
                                          })
                                          .map(function(o) {
                                            var val =
                                              "_value" in o ? o._value : o.value
                                            return val
                                          })
                                        _vm.state = $event.target.multiple
                                          ? $$selectedVal
                                          : $$selectedVal[0]
                                      }
                                    }
                                  },
                                  _vm._l(_vm.states_for_country, function(
                                    states,
                                    group
                                  ) {
                                    return _c(
                                      "optgroup",
                                      {
                                        key: "state-group-" + group,
                                        attrs: { label: group }
                                      },
                                      _vm._l(states, function(state) {
                                        return _c(
                                          "option",
                                          {
                                            key: state,
                                            domProps: { value: state }
                                          },
                                          [_vm._v(_vm._s(state))]
                                        )
                                      }),
                                      0
                                    )
                                  }),
                                  0
                                )
                              ]
                            : [
                                _c(
                                  "select",
                                  {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.state,
                                        expression: "state"
                                      }
                                    ],
                                    class: [
                                      "input-select",
                                      _vm.errors.includes("state")
                                        ? "border-red"
                                        : ""
                                    ],
                                    attrs: {
                                      name: "state",
                                      disabled: _vm.is_loading
                                    },
                                    on: {
                                      change: function($event) {
                                        var $$selectedVal = Array.prototype.filter
                                          .call($event.target.options, function(
                                            o
                                          ) {
                                            return o.selected
                                          })
                                          .map(function(o) {
                                            var val =
                                              "_value" in o ? o._value : o.value
                                            return val
                                          })
                                        _vm.state = $event.target.multiple
                                          ? $$selectedVal
                                          : $$selectedVal[0]
                                      }
                                    }
                                  },
                                  _vm._l(_vm.states_for_country, function(
                                    state
                                  ) {
                                    return _c(
                                      "option",
                                      {
                                        key: state,
                                        domProps: { value: state }
                                      },
                                      [_vm._v(_vm._s(state))]
                                    )
                                  }),
                                  0
                                )
                              ]
                        ],
                        2
                      )
                    : _c("form-input", {
                        class: { "border-red": _vm.errors.includes("state") },
                        attrs: {
                          type: "text",
                          name: "state",
                          disabled: _vm.is_loading
                        },
                        model: {
                          value: _vm.state,
                          callback: function($$v) {
                            _vm.state = $$v
                          },
                          expression: "state"
                        }
                      })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "sm:w-p60 lg:w-full mb-5" },
                [
                  _c("label", { attrs: { for: "postal_code" } }, [
                    _vm._v("Postal Code")
                  ]),
                  _vm._v(" "),
                  _c("form-input", {
                    class: { "border-red": _vm.errors.includes("postal_code") },
                    attrs: {
                      type: "text",
                      name: "postal_code",
                      disabled: _vm.is_loading
                    },
                    model: {
                      value: _vm.postal_code,
                      callback: function($$v) {
                        _vm.postal_code = $$v
                      },
                      expression: "postal_code"
                    }
                  })
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "lg:w-1/2 lg:pl-10" }, [
              _c(
                "h1",
                { staticClass: "my-8 lg:mt-0 font-bold text-2hxl xl:text-3xl" },
                [_vm._v("Product and Support Request Information")]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "sm:w-p60 lg:w-full mb-5" },
                [
                  _c("label", { attrs: { for: "product" } }, [
                    _vm._v("Product *")
                  ]),
                  _vm._v(" "),
                  _c("form-select", {
                    attrs: {
                      name: "product",
                      options: _vm.products,
                      class_names: [
                        _vm.errors.includes("product") ? "border-red" : ""
                      ],
                      disabled: _vm.is_loading
                    },
                    model: {
                      value: _vm.product,
                      callback: function($$v) {
                        _vm.product = $$v
                      },
                      expression: "product"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "sm:w-p60 lg:w-full mb-5" },
                [
                  _c("label", { attrs: { for: "request_type" } }, [
                    _vm._v("Type of Support Request *")
                  ]),
                  _vm._v(" "),
                  _c("form-select", {
                    attrs: {
                      name: "request_type",
                      options: _vm.request_type_options,
                      class_names: [
                        _vm.errors.includes("request_type") ? "border-red" : ""
                      ],
                      disabled: _vm.is_loading && !_vm.product
                    },
                    model: {
                      value: _vm.request_type,
                      callback: function($$v) {
                        _vm.request_type = $$v
                      },
                      expression: "request_type"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.ask_serial_number,
                      expression: "ask_serial_number"
                    }
                  ],
                  staticClass: "sm:w-p60 lg:w-full mb-5"
                },
                [
                  _c("label", { attrs: { for: "serial_number" } }, [
                    _vm._v("Product Serial Number")
                  ]),
                  _vm._v(" "),
                  _c("form-input", {
                    class: {
                      "border-red": _vm.errors.includes("serial_number")
                    },
                    attrs: {
                      type: "text",
                      name: "serial_number",
                      disabled: _vm.is_loading
                    },
                    model: {
                      value: _vm.serial_number,
                      callback: function($$v) {
                        _vm.serial_number = $$v
                      },
                      expression: "serial_number"
                    }
                  }),
                  _vm._v(" "),
                  _c(
                    "p",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.serial_number_instructions,
                          expression: "serial_number_instructions"
                        }
                      ],
                      staticClass: "mt-2 italic"
                    },
                    [_vm._v(_vm._s(_vm.serial_number_instructions))]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.show_software_fields,
                      expression: "show_software_fields"
                    }
                  ]
                },
                [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: Object.keys(_vm.oses).length > 0,
                          expression: "Object.keys(oses).length > 0"
                        }
                      ],
                      staticClass: "sm:w-p60 lg:w-full mb-5"
                    },
                    [
                      _c("label", { attrs: { for: "os" } }, [
                        _vm._v("Operating System")
                      ]),
                      _vm._v(" "),
                      _c("form-select", {
                        attrs: {
                          name: "os",
                          value:
                            Object.keys(_vm.oses).length === 1
                              ? Object.keys(_vm.oses)[0]
                              : null,
                          options: _vm.oses,
                          class_names: [
                            _vm.errors.includes("os") ? "border-red" : ""
                          ],
                          disabled: _vm.is_loading
                        },
                        model: {
                          value: _vm.os,
                          callback: function($$v) {
                            _vm.os = $$v
                          },
                          expression: "os"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.os,
                          expression: "os"
                        }
                      ],
                      staticClass: "sm:w-p60 lg:w-full mb-5"
                    },
                    [
                      _c("label", { attrs: { for: "os_version" } }, [
                        _vm._v("Operating System Version")
                      ]),
                      _vm._v(" "),
                      _c("form-input", {
                        class: {
                          "border-red": _vm.errors.includes("os_version")
                        },
                        attrs: {
                          type: "text",
                          name: "os_version",
                          disabled: _vm.is_loading
                        },
                        model: {
                          value: _vm.os_version,
                          callback: function($$v) {
                            _vm.os_version = $$v
                          },
                          expression: "os_version"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.ask_software_version,
                          expression: "ask_software_version"
                        }
                      ],
                      staticClass: "sm:w-p60 lg:w-full mb-5"
                    },
                    [
                      _c("label", { attrs: { for: "software_version" } }, [
                        _vm._v(_vm._s(_vm.software_version_label))
                      ]),
                      _vm._v(" "),
                      _c("form-input", {
                        class: {
                          "border-red": _vm.errors.includes("software_version")
                        },
                        attrs: {
                          type: "text",
                          name: "software_version",
                          disabled: _vm.is_loading
                        },
                        model: {
                          value: _vm.software_version,
                          callback: function($$v) {
                            _vm.software_version = $$v
                          },
                          expression: "software_version"
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "p",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.software_version_instructions,
                              expression: "software_version_instructions"
                            }
                          ],
                          staticClass: "mt-2 italic"
                        },
                        [_vm._v(_vm._s(_vm.software_version_instructions))]
                      )
                    ],
                    1
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.ask_firmware_version,
                      expression: "ask_firmware_version"
                    }
                  ],
                  staticClass: "sm:w-p60 lg:w-full mb-5"
                },
                [
                  _c("label", { attrs: { for: "firmware_version" } }, [
                    _vm._v("Firmware Version")
                  ]),
                  _vm._v(" "),
                  _c("form-input", {
                    class: {
                      "border-red": _vm.errors.includes("firmware_version")
                    },
                    attrs: {
                      type: "text",
                      name: "firmware_version",
                      disabled: _vm.is_loading
                    },
                    model: {
                      value: _vm.firmware_version,
                      callback: function($$v) {
                        _vm.firmware_version = $$v
                      },
                      expression: "firmware_version"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _vm.getSiteName() !== "faunatech"
                ? _c(
                    "div",
                    { staticClass: "sm:w-p60 lg:w-full mb-5" },
                    [
                      _c("label", { attrs: { for: "point_of_sale" } }, [
                        _vm._v("Company Where Purchased")
                      ]),
                      _vm._v(" "),
                      _c("form-select", {
                        attrs: {
                          name: "point_of_sale",
                          class_names: [
                            _vm.errors.includes("point_of_sale")
                              ? "border-red"
                              : ""
                          ],
                          options: _vm.point_of_sales,
                          disabled: _vm.is_loading
                        },
                        model: {
                          value: _vm.point_of_sale,
                          callback: function($$v) {
                            _vm.point_of_sale = $$v
                          },
                          expression: "point_of_sale"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "sm:w-p60 lg:w-full mb-5" },
                [
                  _c("label", { attrs: { for: "subject" } }, [
                    _vm._v("Subject of Support Request")
                  ]),
                  _vm._v(" "),
                  _c("form-input", {
                    class: { "border-red": _vm.errors.includes("subject") },
                    attrs: {
                      type: "text",
                      name: "subject",
                      disabled: _vm.is_loading
                    },
                    model: {
                      value: _vm.subject,
                      callback: function($$v) {
                        _vm.subject = $$v
                      },
                      expression: "subject"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "w-full mb-5" }, [
                _c("label", { attrs: { for: "message" } }, [
                  _vm._v("Description of Support Request *")
                ]),
                _vm._v(" "),
                _c("textarea", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.message,
                      expression: "message"
                    }
                  ],
                  staticClass: "input-text",
                  class: { "border-red": _vm.errors.includes("message") },
                  staticStyle: { "font-family": "inherit" },
                  attrs: {
                    type: "text",
                    name: "message",
                    rows: "4",
                    disabled: _vm.is_loading
                  },
                  domProps: { value: _vm.message },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.message = $event.target.value
                    }
                  }
                })
              ]),
              _vm._v(" "),
              _c(
                "h1",
                { staticClass: "my-8 font-bold text-2hxl xl:text-3xl" },
                [_vm._v("Project Information")]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "sm:w-p60 lg:w-full mb-5" },
                [
                  _c("label", { attrs: { for: "project_type" } }, [
                    _vm._v("Project Type")
                  ]),
                  _vm._v(" "),
                  _c("form-select", {
                    attrs: {
                      name: "project_type",
                      multiple: "true",
                      rows: Object.keys(_vm.project_types).length,
                      options: _vm.project_types,
                      class_names: [
                        _vm.errors.includes("project_type") ? "border-red" : ""
                      ],
                      disabled: _vm.is_loading
                    },
                    model: {
                      value: _vm.project_type,
                      callback: function($$v) {
                        _vm.project_type = $$v
                      },
                      expression: "project_type"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "sm:w-p60 lg:w-full mb-5" },
                [
                  _c("label", { attrs: { for: "study_subject" } }, [
                    _vm._v("Study Subject")
                  ]),
                  _vm._v(" "),
                  _c("form-select", {
                    attrs: {
                      name: "study_subject",
                      multiple: "true",
                      rows: Object.keys(_vm.study_subjects).length,
                      options: _vm.study_subjects,
                      class_names: [
                        _vm.errors.includes("study_subject") ? "border-red" : ""
                      ],
                      disabled: _vm.is_loading
                    },
                    model: {
                      value: _vm.study_subject,
                      callback: function($$v) {
                        _vm.study_subject = $$v
                      },
                      expression: "study_subject"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.study_subject.includes("Other"),
                      expression: "study_subject.includes('Other')"
                    }
                  ],
                  staticClass: "sm:w-p60 lg:w-full mb-5"
                },
                [
                  _c("label", { attrs: { for: "study_subject_other" } }, [
                    _vm._v("Study Subject (Other)")
                  ]),
                  _vm._v(" "),
                  _c("form-input", {
                    class: {
                      "border-red": _vm.errors.includes("study_subject_other")
                    },
                    attrs: {
                      type: "text",
                      name: "study_subject_other",
                      disabled: _vm.is_loading
                    },
                    model: {
                      value: _vm.study_subject_other,
                      callback: function($$v) {
                        _vm.study_subject_other = $$v
                      },
                      expression: "study_subject_other"
                    }
                  })
                ],
                1
              )
            ])
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "mb-5 lg:mt-5" },
            [
              _c("form-checkbox", {
                attrs: {
                  label:
                    "Subscribe to our mailing list and stay up-to-date on Technical Support Bulletins, Quarterly Newsletters and General Announcements.",
                  name: "subscribe",
                  disabled: _vm.is_loading
                },
                model: {
                  value: _vm.subscribe,
                  callback: function($$v) {
                    _vm.subscribe = $$v
                  },
                  expression: "subscribe"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _vm.error_message
            ? _c("div", { staticClass: "mt-4 text-lg leading-normal" }, [
                _c("span", {
                  staticClass: "text-red",
                  domProps: { innerHTML: _vm._s(_vm.error_message) }
                })
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "mt-4" }, [
            _c(
              "button",
              {
                class: [
                  "relative bg-white font-bold uppercase rounded border-4 text-xl py-3 tr-color tr-bg hover:bg-grey-lighter",
                  "px-10 text-brown border-brown hover:text-black hover:bg-grey-light"
                ],
                attrs: { type: "submit" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.submit()
                  }
                }
              },
              [
                _c(
                  "base-icon",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.isLoading,
                        expression: "isLoading"
                      }
                    ],
                    staticClass: "loading absolute ml-3 left-0 centered-y",
                    attrs: {
                      "icon-name": "loading",
                      width: "28",
                      height: "28",
                      viewbox: "0 0 32 32"
                    }
                  },
                  [_c("icon-loading")],
                  1
                ),
                _vm._v(" "),
                _c("span", { staticClass: "inline" }, [_vm._v("Submit")])
              ],
              1
            )
          ])
        ])
      ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(
        "\n    Want to find your answer faster? Check out the following links:\n    "
      ),
      _c("ul", [
        _c("li", [
          _vm._v(
            "For answers to a wide variety of common questions about all of our products, see our "
          ),
          _c("a", { attrs: { href: "/resources/faqs" } }, [_vm._v("FAQ page")]),
          _vm._v(".")
        ]),
        _vm._v(" "),
        _c("li", [
          _vm._v(
            "For guided demonstrations on using our recorders and software, see our "
          ),
          _c(
            "a",
            {
              attrs: {
                href:
                  "/resources/video-tutorials/kaleidoscope-pro-software/en/kaleidoscope-pro-software-viewer-for-bat-analysis-english"
              }
            },
            [_vm._v("Video Tutorials")]
          ),
          _vm._v(".")
        ]),
        _vm._v(" "),
        _c("li", [
          _vm._v(
            "For questions about phone and tablet compatibility with the Echo Meter Touch, please see our "
          ),
          _c("a", { attrs: { href: "/products/ios-device-compatibility" } }, [
            _vm._v("iOS Compatibility Page")
          ]),
          _vm._v(" and "),
          _c(
            "a",
            { attrs: { href: "/products/android-device-compatibility" } },
            [_vm._v("Android Compatibility Page")]
          ),
          _vm._v(".")
        ]),
        _vm._v(" "),
        _c("li", [
          _vm._v(
            "For more specific questions about any of our products, please see our "
          ),
          _c("a", { attrs: { href: "/resources/user-guides" } }, [
            _vm._v("User Guides")
          ]),
          _vm._v(".")
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(
        "\n    If you are not already a member, we also invite you to join our mailing list.  You’ll be the first to know about technical service bulletins, new products, training sessions, webinars, news, and more. We are careful about the emails we send and strive to include valuable information in all of our communications. You can customize your email settings in the "
      ),
      _c("a", { attrs: { href: "/account" } }, [_vm._v("My Account")]),
      _vm._v(
        " section of the website to only get notified of the things that are important to you. You can subscribe to our mailing list "
      ),
      _c("a", { attrs: { href: "/products#mailinglist" } }, [_vm._v("here")]),
      _vm._v(".\n  ")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }