var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container px-5-safe mx-auto py-12" },
    [
      _vm.showFilters ? _c("accessories-filter") : _vm._e(),
      _vm._v(" "),
      _c("accessories-results", {
        attrs: {
          initial_items: _vm.data.data,
          num_pages: _vm.data.metadata.pages
        },
        on: { updateUrl: _vm.updateUrl, setPageNumber: _vm.setPageNumber }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }