<template>
  <section :class="['grant-program-apply-deadlines max-w-md mx-auto lg:max-w-full', !block.isFirst ? 'mt-10' : '']">
    <div class="bg-brown-lighter py-6 px-5">
      <base-heading size="h2" color="brown">
        {{ block.heading }}
      </base-heading>
      <table :class="['w-full text-white text-xl xl:text-2xl', block.heading !== '' ? 'mt-5' : '']">
        <thead>
          <tr class="font-bold uppercase">
            <td class="col-1 pb-2">Award Period</td>
            <td class="col-2 pb-2 pl-5">Application Due</td>
            <td class="col-3 pb-2 pl-5">Awarded By</td>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(row, r) in block.rows" :key="r">
            <td v-for="(cell, c) in row" :key="c" :class="['col-1 py-2', c > 0 ? 'pl-5' : '', r > 0 ? 'border-t border-brown' : '']">
              {{ cell }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div v-if="block.footnote !== ''" class="mt-4">
      <base-rich-text size="tiny" v-html="block.footnote"></base-rich-text>
    </div>
  </section>
</template>

<script>
export default {
  props: ["block"],
  components: {
    BaseHeading: () => import(/* webpackChunkName: "base-heading" */ "../components/BaseHeading.vue"),
    BaseRichText: () => import(/* webpackChunkName: "base-rich-text" */ "../components/BaseRichText.vue")
  }
};
</script>

<style scoped>
.col-1 {
  width: 28%;
}
.col-2 {
  width: 36%;
}
.col-3 {
  width: 36%;
}
</style>
