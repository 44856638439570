<template>
  <div :class="['reseller-item red-text-links', class_names]">
    <base-heading size="h4" uppercase="true" color="green" class_names="mb-3" v-if="item.showCountry">{{ item.country }}</base-heading>
    <base-heading size="h3" color="brown" class_names="mt-1">{{ item.name }}</base-heading>
    <div class="text-xl xl:text-2xl leading-normal mt-1 text-links">
      <div v-html="item.description"></div>
      <div v-if="item.details.address !== undefined" v-html="item.details.address"></div>
      <div v-if="item.details.contactPerson !== undefined">Contact: {{ item.details.contactPerson }}</div>
      <div v-if="item.details.phoneNumber !== undefined"><span class="inline-block w-6">p:</span> {{ item.details.phoneNumber }}</div>
      <div v-if="item.details.faxNumber !== undefined"><span class="inline-block w-6">f:</span> {{ item.details.faxNumber }}</div>
      <div v-if="item.details.mobileNumber !== undefined">
        <span class="inline-block w-6">m:</span> {{ item.details.mobileNumber }}
      </div>
      <div v-if="item.details.emailAddress !== undefined">
        <a :href="['mailto:' + item.details.emailAddress]" v-if="item.details.emailAddress.indexOf('@') !== -1">{{
          item.details.emailAddress
        }}</a>
        <span v-else>{{ item.details.emailAddress }}</span>
      </div>
      <div v-if="item.details.websiteUrl !== undefined && item.details.websiteUrl !== null">
        <a :href="formatUrl(item.details.websiteUrl)" target="_blank">{{ item.details.websiteUrl }}</a>
      </div>
      <div v-if="item.details.other !== undefined && item.details.other">
        <div v-for="other in item.details.other">
          {{ other.label ? other.label + ": " : "" }}
          <span v-if="other.type === 'email'">
            <a :href="'mailto:' + other.value">{{ other.value }}</a>
          </span>
          <span v-else-if="other.type === 'url'">
            <a :href="formatUrl(other.value)" target="_blank">{{ other.value }}</a>
          </span>
          <span v-else v-html="other.value"></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["item", "class_names"],
  components: {
    BaseHeading: () => import(/* webpackChunkName: "base-heading" */ "../components/BaseHeading.vue")
  },
  methods: {
    formatUrl(s) {
      let prefix = "http", url;
      if (s.substr(0, prefix.length) === prefix) {
        url = s;
      } else {
        url = 'http://' + s;
      }
      return url;
    }
  }
};
</script>
