<template>
  <aside class="video-tutorials-languages-nav mb-4" v-if="languages.length > 1">
    <div class="text-xl leading-normal">
      For video translations, please select your language of choice below:
    </div>
    <div class="mt-4 -mb-4">
      <button
        @click="selectLanguage(language)"
        :class="['inline-block mb-4 w-1/4', clicked_language && clicked_language !== language.slug ? 'opacity-25' : '']"
        v-for="(language, l) in languages"
        :key="'language_' + l"
      >
        <base-image :src="language.flagSrc" :retina_src="language.flagRetinaSrc" :alt="language.title"></base-image>
        <span class="block mt-2 uppercase text-sm">{{ language.localName }}</span>
      </button>
    </div>
  </aside>
</template>

<script>
export default {
  props: ["languages", "clicked_language"],
  components: {
    BaseImage: () => import(/* webpackChunkName: "base-image" */ "../components/BaseImage.vue")
  },
  methods: {
    selectLanguage(language) {
      this.$emit("onselect", language);
    }
  }
};
</script>
