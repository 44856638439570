<template>
  <div class="video-tutorial">
    <div v-if="video">
      <base-video :platform="video.platform" :video_id="video.videoId"></base-video>
      <div class="mt-6">
        <base-heading size="h2" color="brown">{{ video.title }}</base-heading>
        <div v-if="video.text !== ''" class="mt-3">
          <base-rich-text v-html="video.text"></base-rich-text>
        </div>
      </div>
      <div v-if="video.downloads.length > 0" class="mt-6 text-links">
        <ul class="text-xl">
          <li v-for="(download, d) in video.downloads" :key="'download_' + d" :class="['block download', d > 0 ? 'mt-3' : '']">
            <a :href="download.url" target="_blank" class="block relative pt-1 pl-11">
              <base-icon icon-name="checkmark" width="30" height="30" viewbox="0 0 31 31" class="absolute left-0 top-0">
                <icon-download></icon-download>
              </base-icon>
              {{ download.text }}
            </a>
          </li>
        </ul>
      </div>
    </div>
    <div v-else>
      &nbsp;
    </div>
  </div>
</template>

<script>
export default {
  components: {
    BaseHeading: () => import(/* webpackChunkName: "base-heading" */ "../components/BaseHeading.vue"),
    BaseRichText: () => import(/* webpackChunkName: "base-rich-text" */ "../components/BaseRichText.vue"),
    BaseVideo: () => import(/* webpackChunkName: "base-video" */ "../components/BaseVideo.vue"),
    BaseIcon: () => import(/* webpackChunkName: "base-icon" */ "../components/BaseIcon.vue"),
    IconDownload: () => import(/* webpackChunkName: "icon-download" */ "../icons/IconDownload.vue")
  },
  computed: {
    video() {
      return this.$parent.showVideo;
    }
  },
  head: {
    script: function() {
      return [
        {
          t: "application/ld+json",
          i:
            '{ "@context": "http://schema.org", "@type": "VideoObject", "name": "' +
            this.video.title +
            '", "thumbnailUrl": "' +
            this.video.thumbnail +
            '", "contentUrl": "' +
            this.video.url +
            '", "uploadDate": "' +
            this.video.date +
            '", "description": "' +
            this.video.text.replace(/(<([^>]+)>)/gi, "") +
            '" }',
          id: "videoObject"
        }
      ];
    }
  },
  watch: {
    video: {
      deep: true,
      handler() {
        this.$emit("updateHead");
      }
    }
  }
};
</script>

<style scoped>
.download {
  min-height: 30px;
}
</style>
