var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "aside",
    { staticClass: "sidebar-blocks" },
    _vm._l(_vm.blocks, function(block, b) {
      return _c("SidebarBlock" + _vm.capitalizeFirstLetter(block.type), {
        key: b,
        tag: "component",
        attrs: { block: block, is_first: b === 0 }
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }