var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    {
      class: [
        "side-filter-btn block w-full cursor-pointer relative text-left py-2 border-t border-brown pr-6 text-xl xl:text-2xl font-bold uppercase",
        _vm.has_submenu ? "has-sub pl-12" : "pl-6",
        _vm.active ? "active" : ""
      ],
      on: { click: _vm.onclick }
    },
    [_vm._v("\n  " + _vm._s(_vm.label) + "\n")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }