var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "g",
    { attrs: { transform: "translate(-1177.000000, -6705.000000)" } },
    [
      _c("g", { attrs: { transform: "translate(0.000000, 6464.000000)" } }, [
        _c("path", {
          attrs: {
            fill: "currentColor",
            d:
              "M1194,242.3c0,0.1-0.1,0.3-0.2,0.4l-7.9,7.2c-0.1,0.1-0.2,0.2-0.4,0.2s-0.3-0.1-0.4-0.2l-7.9-7.2\n                      c-0.1-0.1-0.2-0.2-0.2-0.4s0.1-0.3,0.2-0.4l0.9-0.8c0.1-0.1,0.2-0.2,0.4-0.2s0.3,0.1,0.4,0.2l6.7,6.1l6.7-6.1\n                      c0.1-0.1,0.2-0.2,0.4-0.2s0.3,0.1,0.4,0.2l0.9,0.8"
          }
        })
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }