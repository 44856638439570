<template>
  <div class="glossary-index container px-5-safe mx-auto py-12">
    <nav class="text-left">
      <ul v-if="data.items !== undefined" class="flex justify-center lg:justify-between flex-wrap">
        <li>
          <button
            type="button"
            @click="clearLetter()"
            :class="['w-12 h-12 py-2 m-1 text-2xl font-bold bg-brown-tint hover:text-red', activeLetter === null ? 'text-red' : 'text-green tr-color']"
          >
            All
          </button>
        </li>
        <li v-for="(terms, letter) in data.items" :key="letter" class="inline-block">
          <button
            type="button"
            @click="selectLetter(letter)"
            :class="['h-12 w-12 m-1 text-2xl font-bold bg-brown-tint hover:text-red', activeLetter === letter ? 'text-red' : 'text-green tr-color']"
          >
            {{ letter }}
          </button>
        </li>
      </ul>
    </nav>
    <div class="mt-10 md:flex flex-wrap">
      <div v-for="(terms, letter) in items" :key="letter" class="terms-group md:w-1/3 lg:w-1/4 xl:w-1/5 md:pr-4">
        <base-heading size="h2" color="brown">{{ letter }}</base-heading>
        <ul>
          <li v-for="item in terms" class="my-2">
            <a :href="item.url" class="underline text-xl">{{ item.title }}</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import HelpersMixin from "../mixins/Helpers";

export default {
  data: function() {
    return {
      activeLetter: this.data.firstLetter
    };
  },
  props: ["data"],
  components: {
    BaseHeading: () => import(/* webpackChunkName: "base-heading" */ "../components/BaseHeading.vue")
  },
  mixins: [HelpersMixin],
  methods: {
    selectLetter(letter) {
      this.activeLetter = letter;
      this.setUrl("glossary", document.title, "/glossary/" + letter.toLowerCase());
    },

    clearLetter() {
      this.activeLetter = null;
      this.setUrl("glossary", document.title, "/glossary");
    }
  },
  computed: {
    items() {
      if (this.activeLetter && this.data.items[this.activeLetter] !== undefined) {
        // if active letter set, return items from that letter
        let items = {};

        items[this.activeLetter] = this.data.items[this.activeLetter];

        return items;
      } else {
        return this.data.items;
      }
    }
  }
};
</script>

<style scoped>
  .terms-group {
    margin-bottom: 2rem;
  }

  .terms-group:last-child {
    margin-bottom: 0;
  }
</style>
