<template>
  <div class="events-index container mx-auto px-5-safe py-12">
    <div v-if="ajaxError" class="mb-10 text-red text-lg" v-html="ajaxError"></div>
    <div :class="[isLoading ? 'opacity-25' : '']">
      <event-row v-for="(item, i) in localItems" :item="item" :isFirst="i === 0" :key="i"></event-row>
    </div>
    <div class="mt-10">
      <base-pagination
        :max_visible_buttons="num_pages >= 3 ? 3 : parseInt(num_pages)"
        :per_page="parseInt(limit)"
        :num_pages="parseInt(num_pages)"
        :current_page="currentPage"
        :total="parseInt(total)"
        :show_prev_next_btns="true"
        :pagination_disabled="isLoading"
        @pagechanged="onPageChange"
      ></base-pagination>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import VueAxios from "../../js/modules/vue-axios";
import VueScrollTo from "vue-scrollto";
import HelpersMixin from "../mixins/Helpers";

Vue.use(VueScrollTo);

export default {
  data: function() {
    return {
      localItems: null,
      currentPage: 1,
      isLoading: false,
      ajaxError: ""
    };
  },
  props: ["items", "total", "limit", "num_pages", "current_page"],
  components: {
    EventRow: () => import(/* webpackChunkName: "event-row" */ "./EventRow.vue"),
    BasePagination: () => import(/* webpackChunkName: "base-pagination" */ "../components/BasePagination.vue")
  },
  mixins: [HelpersMixin],
  methods: {
    updateEntries() {
      let self = this;
      self.isLoading = true;
      self.ajaxError = "";
      VueAxios.get("/actions/site-module/events/get-entries?limit=" + this.limit + "&page=" + this.currentPage)
        .then(response => {
          self.isLoading = false;
          let data = response.data || null;
          if (data.items !== undefined) {
            self.localItems = data.items.items;
            self.updateUrl();
            if (self.currentPage > 1) {
              self.scrollToTop();
            }
          } else {
            self.ajaxError = "An error occurred. Please try again.";
            self.scrollToTop();
          }
          self.$emit("updateHead");
        })
        .catch(error => {
          self.ajaxError = "An error occurred. Please try again.";
          self.isLoading = false;
          self.scrollToTop();
        });
    },
    scrollToTop() {
      VueScrollTo.scrollTo("#events-index", 350, {
        offset: 20
      });
    },
    onPageChange(newPageNum) {
      this.currentPage = newPageNum;
      this.updateEntries();
    },
    updateUrl() {
      let url = "/resources/events";
      if (this.currentPage > 1) {
        url += "/p" + this.currentPage;
      }
      this.setUrl("events", document.title, url);
    }
  },
  computed: {
    jsonLd() {
      let array = [],
        position = 0,
        self = this;
      if (!self.localItems) {
        return null;
      }
      Object.keys(self.localItems).forEach(key => {
        const localItem = self.localItems[key];
        position++;
        let item = {
          "@type": "ListItem",
          position: position,
          item: {
            "@type": "Event",
            name: localItem.title,
            url: localItem.moreInfoUrl,
            startDate: localItem.jsonLdStartDate,
            endDate: localItem.jsonLdEndDate,
            image: localItem.thumbnailSrcRetina,
            location: {
              "@type": "Place",
              name: localItem.location,
              address: localItem.location
            }
          }
        };
        array.push(item);
      });
      return {
        t: "application/ld+json",
        i: '{ "@context": "http://schema.org", "@type": "ItemList", "itemListElement": ' + JSON.stringify(array) + "}",
        id: "eventsItemList"
      };
    }
  },
  head: {
    script: function() {
      if (this.jsonLd) {
        return [this.jsonLd];
      }
    }
  },
  mounted: function() {
    this.currentPage = parseInt(this.current_page);
    this.localItems = this.items;
    // if there are no results and the page is higher than 1, try resetting to page 1 and refresh
    if (this.localItems.length === 0 && this.currentPage > 1) {
      this.currentPage = 1;
      this.updateEntries();
    } else {
      this.$emit("updateHead");
    }
  }
};
</script>
