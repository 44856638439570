var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "block comparison-chart white-block" }, [
    _c(
      "div",
      { staticClass: "container px-5 mx-auto pt-8 lg:pt-12 pb-12 lg:pb-16" },
      [
        _c(
          "div",
          {
            class: [
              "chart mx-auto leading-normal",
              _vm.data.products.length === 3
                ? "max-w-3xl text-sm sm:text-base lg:text-lg xl:text-xl"
                : "max-w-2xl text-lg lg:text-xl xl:text-2xl",
              "num-products-" + _vm.data.products.length
            ]
          },
          [
            _c(
              "div",
              { staticClass: "top-row flex flex-wrap" },
              [
                _c("div", { staticClass: "c1 border-r border-brown" }, [
                  _vm._v(" ")
                ]),
                _vm._v(" "),
                _vm._l(_vm.data.products, function(product, p) {
                  return product
                    ? _c(
                        "div",
                        {
                          key: "product-" + p,
                          class: [
                            "c2 sm:border-t border-brown",
                            "bg-brown-lighter tr-bg hover:bg-brown-light",
                            p < _vm.data.products.length - 1
                              ? "border-r"
                              : "sm:border-r"
                          ]
                        },
                        [
                          _c(
                            "a",
                            {
                              class: [
                                "block p-4 bg-white",
                                _vm.data.products.length === 3
                                  ? "px-2 lg:px-4"
                                  : "px-4 lg:px-6"
                              ],
                              attrs: { href: product.url }
                            },
                            [
                              _c("base-image", {
                                attrs: {
                                  src: product.src,
                                  retina_src: product.retinaSrc,
                                  srcset_webp: product.webpSrcset,
                                  alt: product.name
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "a",
                            {
                              staticClass:
                                "block text-white w-full font-bold pad",
                              attrs: { href: product.url }
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(product.name) +
                                  "\n          "
                              )
                            ]
                          )
                        ]
                      )
                    : _vm._e()
                })
              ],
              2
            ),
            _vm._v(" "),
            _vm.hasAudio
              ? _c(
                  "div",
                  { staticClass: "flex flex-wrap" },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "c1 text-center sm:text-left font-bold py-2 lg:py-3 px-5 sm:pl-0 sm:pr-5 sm:lg:pr-7 border-b sm:border-r border-brown"
                      },
                      [_vm._v("\n          AUDIO EXAMPLE\n        ")]
                    ),
                    _vm._v(" "),
                    _vm._l(_vm.data.products, function(product, p) {
                      return _c(
                        "div",
                        {
                          class: [
                            "c2 border-b border-brown pad",
                            p < _vm.data.products.length - 1
                              ? "border-r"
                              : "sm:border-r"
                          ]
                        },
                        [
                          product.audio
                            ? _c(
                                "audio",
                                {
                                  staticClass: "max-w-full",
                                  attrs: { controls: "" }
                                },
                                [
                                  _c("source", {
                                    attrs: {
                                      src: product.audio,
                                      type: "audio/mpeg"
                                    }
                                  }),
                                  _vm._v(
                                    "\n            Your browser does not support the audio tag.\n          "
                                  )
                                ]
                              )
                            : _vm._e()
                        ]
                      )
                    })
                  ],
                  2
                )
              : _vm._e(),
            _vm._v(" "),
            _vm._l(_vm.data.rows, function(row, r) {
              return _c(
                "div",
                { key: "row-" + r, staticClass: "flex flex-wrap" },
                [
                  _c("div", {
                    staticClass:
                      "c1 text-center sm:text-left font-bold py-2 lg:py-3 px-5 sm:pl-0 sm:pr-5 sm:lg:pr-7 border-b sm:border-r border-brown",
                    domProps: { innerHTML: _vm._s(row[0]) }
                  }),
                  _vm._v(" "),
                  _vm.data.products[0]
                    ? _c("div", {
                        staticClass:
                          "c2 rich-text border-b border-r border-brown pad",
                        domProps: { innerHTML: _vm._s(row[1]) }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.data.products[1]
                    ? _c("div", {
                        class: [
                          "c2 rich-text border-b border-brown pad",
                          _vm.data.products.length === 3
                            ? "border-r"
                            : "sm:border-r"
                        ],
                        domProps: { innerHTML: _vm._s(row[2]) }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.data.products[2]
                    ? _c("div", {
                        staticClass:
                          "c2 rich-text border-b sm:border-r border-brown pad",
                        domProps: { innerHTML: _vm._s(row[3]) }
                      })
                    : _vm._e()
                ]
              )
            }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "buttons-row flex flex-wrap mt-10" },
              [
                _c("div", { staticClass: "c1" }, [_vm._v(" ")]),
                _vm._v(" "),
                _vm._l(_vm.data.products, function(product, p) {
                  return _c(
                    "div",
                    { key: p, staticClass: "c2 px-5 lg:px-7 text-center" },
                    [
                      _c("base-button", {
                        attrs: {
                          url: product.url,
                          color: "red",
                          label: "More Information"
                        }
                      })
                    ],
                    1
                  )
                })
              ],
              2
            )
          ],
          2
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }