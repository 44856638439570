var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a",
    {
      class: [
        "thumbnail-image-with-overlay inline-block relative",
        _vm.class_names
      ],
      attrs: { href: _vm.url }
    },
    [
      _c("base-image", {
        attrs: {
          src: _vm.src,
          retina_src: _vm.retina_src,
          alt: _vm.alt,
          width: _vm.width,
          height: _vm.height,
          class_names: "z-10"
        }
      }),
      _vm._v(" "),
      _c(
        "span",
        {
          staticClass:
            "block overlay z-20 absolute left-0 top-0 w-full h-full opacity-0 tr-opacity"
        },
        [
          _c(
            "span",
            {
              staticClass:
                "block px-10 w-full centered-y text-white text-center text-3xl uppercase font-bold"
            },
            [_vm._v(_vm._s(_vm.text))]
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }