<template>
  <section class="product-images block white-block carousel-rounded-nav">
    <div class="container-full py-8 lg:py-10">
      <carousel
        :perPage="1"
        :navigationEnabled="true"
        navigationNextLabel=""
        navigationPrevLabel=""
        :perPageCustom="[[768, 2], [1280, 3]]"
        :paginationEnabled="false"
        class="flex"
        :spacePadding="100"
      >
        <slide v-for="(item, i) in images" :key="i">
          <div class="mx-2 flex items-start flex-wrap h-full">
            <div class="w-full">
              <zoomable-image :alt="item.alt" :src="item.src" :srcZoom="item.zoom" :srcRetina="item.retina" :srcWebp="item.webpSrcset"></zoomable-image>
            </div>
            <div class="w-full text-brown text-base mt-3" v-show="item.caption" v-html="item.caption"></div>
          </div>
        </slide>
      </carousel>
    </div>
  </section>
</template>

<script>
import { Carousel, Slide } from "vue-carousel";

export default {
  props: ["images"],
  components: {
    BaseImage: () => import(/* webpackChunkName: "base-image" */ "../components/BaseImage.vue"),
    ZoomableImage: () => import(/* webpackChunkName: "zoomable-image" */ "../_new/ZoomableImage.vue"),
    Carousel,
    Slide
  }
};
</script>

<style>
.carousel-rounded-nav {
  .VueCarousel-navigation {
    button.VueCarousel-navigation-prev,
    button.VueCarousel-navigation-next {
      border: 0;
      margin: 0;
      padding: 0;
      width: 42px;
      height: 83px;
      display: block;
      position: absolute;
      top: 50%;
      margin-top: -41px;
      transform: none !important;
      z-index: 9999;

      &.VueCarousel-navigation--disabled {
        opacity: 0;
      }
    }

    button.VueCarousel-navigation-prev {
      background: url("/img/carousel-prev-btn.png") no-repeat;
      background-size: 42px 83px;
      left: 0;
    }

    button.VueCarousel-navigation-next {
      background: url("/img/carousel-next-btn.png") no-repeat;
      background-size: 42px 83px;
      right: 0;
    }
  }
}
@media (max-width: 639px) {
  .VueCarousel-inner {
    padding-left: 1% !important;
    padding-right: 1% !important;
    /*flex-basis: 90% !important;*/
  }
}
</style>
