<template>
  <div class="user-guides-page container px-5-safe mx-auto pt-8 pb-12">
    <nav class="border-b border-brown">
      <ul class="text-center md:text-left">
        <li v-for="(language, l) in languages" :key="l" :class="['inline-block pt-4', l > 0 ? 'pl-2 lg:pl-4 xl:pl-5' : 'pl-2']">
          <button
            type="button"
            @click="setLanguage(language.slug)"
            :class="[
              'inline-block text-center px-2 text-base xl:text-lg uppercase font-bold text-green',
              selectedLanguage === language.slug ? 'active border-green border-b-5' : ''
            ]"
          >
            <img
              :src="language.flagSrc"
              :srcset="[`${language.flagSrc} 1x, ${language.flagRetinaSrc} 2x`]"
              :alt="language.title"
              class="mx-auto"
            />
            <span class="block label pt-2 xl:pt-4 pb-2">
              {{ language.local }}
            </span>
          </button>
        </li>
      </ul>
    </nav>
    <div class="mt-10">
      <div class="md:flex md:flex-wrap md:justify-between text-links text-lg xl:text-xl leading-normal items">
        <div
          v-for="(category, c) in showCategories"
          :key="c"
          :class="[
            'md:w-1/2 px-10 mb-8',
            (c + 1) % 2 !== 0 && c + 1 !== showCategories.length ? 'md:border-r md:border-brown' : 'md:border-0'
          ]"
        >
          <base-heading size="h2" color="brown">{{ category.title }}</base-heading>
          <div class="mt-2">
            <div v-for="(download, d) in category.downloads" :key="d" class="item mb-4 flex flex-col">
              <div>
                <a :href="download.url" target="_blank" class="block relative pl-11 leading-loose">
                  <base-icon icon-name="checkmark" width="30" height="30" viewbox="0 0 31 31" class="absolute left-0 top-0">
                    <icon-download></icon-download>
                  </base-icon>
                  {{ download.title }}
                </a>
              </div>
              <div v-if="download.htmlUrl && download.htmlUrl.length > 0" class="mt-4">
                <a :href="download.htmlUrl" target="_blank" class="block relative pl-11 leading-loose">
                  <base-icon icon-name="checkmark" width="30" height="30" viewbox="0 0 24 24" class="absolute text-red left-0 top-0">
                    <icon-info></icon-info>
                  </base-icon>
                  {{ download.htmlTitle ? download.htmlTitle : `${download.title} (HTML version)` }}
                </a>
              </div>
            </div>
          </div>
        </div>
        <div v-if="showCategories.length % 2 === 0" class="extra-col w-1/3 px-10">&nbsp;</div>
      </div>
    </div>
  </div>
</template>

<script>
import HelpersMixin from "../mixins/Helpers";

export default {
  data: function() {
    return {
      selectedLanguage: this.default_language
    };
  },
  props: ["languages", "downloads", "categories", "default_language"],
  components: {
    BaseHeading: () => import(/* webpackChunkName: "base-heading" */ "../components/BaseHeading.vue"),
    BaseImage: () => import(/* webpackChunkName: "base-image" */ "../components/BaseImage.vue"),
    BaseIcon: () => import(/* webpackChunkName: "base-icon" */ "../components/BaseIcon.vue"),
    IconDownload: () => import(/* webpackChunkName: "icon-download" */ "../icons/IconDownload.vue"),
    IconInfo: () => import(/* webpackChunkName: "icon-info" */ "../icons/IconInfo.vue")
  },
  mixins: [HelpersMixin],
  methods: {
    setLanguage(lang) {
      this.selectedLanguage = lang;
      this.setUrl("user-guides", document.title, "/resources/user-guides/" + lang);
    }
  },
  computed: {
    showCategories() {
      // return categories with downloads in selected language
      let self = this,
        data = [];
      self.categories.forEach(function(category) {
        // check if this category has any downloads for this language
        let downloads = [];
        self.downloads.forEach(function(download) {
          // if category slug matches
          if (download.categorySlug === category.slug) {
            // loop through downloads
            download.downloads.forEach(function(downloadFile) {
              // if language matches
              if (downloadFile.lang === self.selectedLanguage) {
                // add download to our array
                downloads.push({
                  url: downloadFile.url,
                  title: downloadFile.title || download.title,
                  htmlUrl: downloadFile.htmlUrl,
                  htmlTitle: downloadFile.htmlTitle,
                });
              }
            });
          }
        });
        if (downloads.length > 0) {
          data.push({
            title: category.title,
            slug: category.slug,
            downloads: downloads
          });
        }
      });
      return data;
    }
  }
};
</script>

<style scoped>
button:not(.active) {
  .label {
    transition: color 0.2s ease;
  }
  &:hover {
    .label {
      color: #e1251b;
    }
  }
}
.items {
  margin-left: -40px;
  margin-right: -40px;
}
nav {
  img {
    height: 30px;
  }
}
@media (max-width: 991px) {
  .extra-col {
    display: none;
  }
}
@media (min-width: 1280px) {
  nav {
    img {
      height: 38px;
    }
  }
}
</style>
