var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: ["reseller-item red-text-links", _vm.class_names] },
    [
      _vm.item.showCountry
        ? _c(
            "base-heading",
            {
              attrs: {
                size: "h4",
                uppercase: "true",
                color: "green",
                class_names: "mb-3"
              }
            },
            [_vm._v(_vm._s(_vm.item.country))]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "base-heading",
        { attrs: { size: "h3", color: "brown", class_names: "mt-1" } },
        [_vm._v(_vm._s(_vm.item.name))]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "text-xl xl:text-2xl leading-normal mt-1 text-links" },
        [
          _c("div", { domProps: { innerHTML: _vm._s(_vm.item.description) } }),
          _vm._v(" "),
          _vm.item.details.address !== undefined
            ? _c("div", {
                domProps: { innerHTML: _vm._s(_vm.item.details.address) }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.item.details.contactPerson !== undefined
            ? _c("div", [
                _vm._v("Contact: " + _vm._s(_vm.item.details.contactPerson))
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.item.details.phoneNumber !== undefined
            ? _c("div", [
                _c("span", { staticClass: "inline-block w-6" }, [_vm._v("p:")]),
                _vm._v(" " + _vm._s(_vm.item.details.phoneNumber))
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.item.details.faxNumber !== undefined
            ? _c("div", [
                _c("span", { staticClass: "inline-block w-6" }, [_vm._v("f:")]),
                _vm._v(" " + _vm._s(_vm.item.details.faxNumber))
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.item.details.mobileNumber !== undefined
            ? _c("div", [
                _c("span", { staticClass: "inline-block w-6" }, [_vm._v("m:")]),
                _vm._v(" " + _vm._s(_vm.item.details.mobileNumber) + "\n    ")
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.item.details.emailAddress !== undefined
            ? _c("div", [
                _vm.item.details.emailAddress.indexOf("@") !== -1
                  ? _c(
                      "a",
                      {
                        attrs: {
                          href: ["mailto:" + _vm.item.details.emailAddress]
                        }
                      },
                      [_vm._v(_vm._s(_vm.item.details.emailAddress))]
                    )
                  : _c("span", [_vm._v(_vm._s(_vm.item.details.emailAddress))])
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.item.details.websiteUrl !== undefined &&
          _vm.item.details.websiteUrl !== null
            ? _c("div", [
                _c(
                  "a",
                  {
                    attrs: {
                      href: _vm.formatUrl(_vm.item.details.websiteUrl),
                      target: "_blank"
                    }
                  },
                  [_vm._v(_vm._s(_vm.item.details.websiteUrl))]
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.item.details.other !== undefined && _vm.item.details.other
            ? _c(
                "div",
                _vm._l(_vm.item.details.other, function(other) {
                  return _c("div", [
                    _vm._v(
                      "\n        " +
                        _vm._s(other.label ? other.label + ": " : "") +
                        "\n        "
                    ),
                    other.type === "email"
                      ? _c("span", [
                          _c(
                            "a",
                            { attrs: { href: "mailto:" + other.value } },
                            [_vm._v(_vm._s(other.value))]
                          )
                        ])
                      : other.type === "url"
                      ? _c("span", [
                          _c(
                            "a",
                            {
                              attrs: {
                                href: _vm.formatUrl(other.value),
                                target: "_blank"
                              }
                            },
                            [_vm._v(_vm._s(other.value))]
                          )
                        ])
                      : _c("span", {
                          domProps: { innerHTML: _vm._s(other.value) }
                        })
                  ])
                }),
                0
              )
            : _vm._e()
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }