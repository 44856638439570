<template>
  <article class="news-item w-full md:w-p48 lg:w-p31 mb-8">
    <div :class="['mb-6 bg-gradient-1', !item.src ? 'shadow' : '']">
      <base-image
        :src="item.src ? item.src : '/img/news-thumbnail-placeholder.png'"
        :retina_src="item.retinaSrc ? item.retinaSrc : '/img/news-thumbnail-placeholder@2x.png'"
        :alt="item.thumbnailAlt"
        class_names="block m-0"
        :url="item.url"
      ></base-image>
    </div>
    <div class="text-xl uppercase font-bold text-brown-lighter">
      <span v-for="(category, c) in item.categories" :key="'category_' + c">
        {{ c > 0 ? (item.categories.length === c + 1 ? " & " : ", ") : "" }}
        <a :href="category.url" class="text-brown-lighter tr-color hover:text-brown-light">{{ category.name }}</a>
      </span>
    </div>
    <div class="text-sm italic text-brown mt-1">
      {{ item.date }}
      <span v-if="item.author">/ By {{ item.author }}</span>
    </div>
    <div class="mt-3">
      <base-heading size="h2" color="brown" hover_color="red" :url="item.url">{{ item.title }}</base-heading>
    </div>
    <div class="mt-2" v-if="item.summary">
      <base-rich-text v-html="widowProtect(item.summary)"></base-rich-text>
    </div>
  </article>
</template>

<script>
import HelpersMixin from "../mixins/Helpers";

export default {
  props: ["item"],
  mixins: [HelpersMixin],
  components: {
    BaseHeading: () => import(/* webpackChunkName: "base-heading" */ "../components/BaseHeading.vue"),
    BaseImage: () => import(/* webpackChunkName: "base-image" */ "../components/BaseImage.vue"),
    BaseRichText: () => import(/* webpackChunkName: "base-rich-text" */ "../components/BaseRichText.vue")
  }
};
</script>
