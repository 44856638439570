<template>
  <div class="blog-category-heading" v-if="name !== ''">
    <a :href="url" class="font-bold text-xl text-brown-lighter uppercase tr-color hover:text-brown-light hover:underline">
      {{ name }}
    </a>
  </div>
</template>

<script>
// @group Blog
export default {
  props: ["name", "url"]
};
</script>
