var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "base-heading",
        {
          attrs: {
            size: "h2",
            color: "brown-lighter",
            class_names: "w-full mt-16"
          }
        },
        [
          _vm._v(
            "\n    " +
              _vm._s(
                _vm.product_versions_heading &&
                  _vm.product_versions_heading.length
                  ? _vm.product_versions_heading
                  : "Other Versions"
              ) +
              "\n  "
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "pt-5 pb-8 lg:pb-0 flex flex-wrap flex-col lg:flex-row gap-4"
        },
        _vm._l(_vm.product_versions, function(version) {
          return _c(
            "div",
            {
              key: "" + version.id,
              staticClass: "cursor-pointer",
              attrs: { tabindex: "0" }
            },
            [
              version.product_version_image && version.product_version_image.src
                ? _c(
                    "div",
                    {
                      staticClass:
                        "block lg:inline-block lg:relative popover-wrapper"
                    },
                    [
                      _c("div", { staticClass: "hidden lg:block" }, [
                        _c(
                          "a",
                          { attrs: { href: version.url } },
                          [
                            _c("base-image", {
                              attrs: {
                                src: version.product_version_image.src,
                                retina_src:
                                  version.product_version_image.retina_src,
                                srcset_webp:
                                  version.product_version_image.webp_src_set,
                                alt: version.product_version_image.alt,
                                class_names: "bg-white rounded-sm w-24 h-24",
                                wrapper_class_names: "flex justify-start"
                              }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "popover-content flex items-start text-left opacity-0 invisible absolute overflow-visible"
                          },
                          [
                            _c(
                              "span",
                              {
                                staticClass:
                                  "text-3xl px-3 py-1 bg-white text-green rounded-md shadow-md bg-white border border-white"
                              },
                              [_vm._v(_vm._s(version.product_version_name))]
                            )
                          ]
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "a",
                        {
                          staticClass:
                            "block flex flex-row justify-start items-end gap-4 lg:hidden ml-1",
                          attrs: { href: version.url }
                        },
                        [
                          _c("base-image", {
                            attrs: {
                              src: version.product_version_image.src,
                              retina_src:
                                version.product_version_image.retina_src,
                              srcset_webp:
                                version.product_version_image.webp_src_set,
                              alt: version.product_version_image.alt,
                              class_names:
                                "bg-white rounded-sm w-8 h-8 md:w-12 md:h-12",
                              wrapper_class_names: "flex justify-start"
                            }
                          }),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              staticClass:
                                "flex-1 hover:underline hover:text-blue"
                            },
                            [_vm._v(_vm._s(version.product_version_name))]
                          )
                        ],
                        1
                      )
                    ]
                  )
                : _c(
                    "a",
                    {
                      staticClass:
                        "block ml-3 text-2xl hover:underline hover:text-blue",
                      attrs: { href: version.url }
                    },
                    [_vm._v(_vm._s(version.product_version_name))]
                  )
            ]
          )
        }),
        0
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }