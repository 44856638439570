var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("g", [
    _c("path", {
      attrs: {
        fill: "currentColor",
        d:
          "M23.7,21.3c-0.6,0-1.2,0.2-1.7,0.7s-0.7,1-0.7,1.7s0.2,1.2,0.7,1.7s1,0.7,1.7,0.7c0.6,0,1.2-0.2,1.7-0.7s0.7-1,0.7-1.7\n      s-0.2-1.2-0.7-1.7C24.9,21.5,24.3,21.3,23.7,21.3z"
      }
    }),
    _vm._v(" "),
    _c("path", {
      attrs: {
        fill: "currentColor",
        d:
          "M27.7,4.4c-0.2-0.2-0.5-0.3-0.8-0.3H6.7c0-0.1,0-0.2-0.1-0.4c0-0.2-0.1-0.4-0.1-0.5S6.4,2.9,6.4,2.8c0-0.3-0.1-0.4-0.2-0.5\n      C6.1,2.2,6,2.1,5.9,2C5.7,2,5.6,1.9,5.4,1.9H1.1C0.8,1.9,0.5,2,0.3,2.2C0.1,2.5,0,2.7,0,3s0.1,0.5,0.3,0.8C0.5,4,0.8,4.1,1.1,4.1\n      h3.4l3,13.7c0,0-0.1,0.3-0.3,0.6c-0.2,0.4-0.4,0.7-0.5,1s-0.2,0.5-0.2,0.7c0,0.3,0.1,0.5,0.3,0.8C7,21,7.2,21.1,7.5,21.1h17.2\n      c0.3,0,0.5-0.1,0.8-0.3c0.2-0.2,0.3-0.5,0.3-0.8s-0.1-0.5-0.3-0.8c-0.2-0.2-0.5-0.3-0.8-0.3H9.3c0.3-0.5,0.4-0.9,0.4-1.1\n      c0-0.1,0-0.2,0-0.4c0-0.1-0.1-0.3-0.1-0.4c0-0.2-0.1-0.3-0.1-0.4l17.6-2c0.3,0,0.5-0.2,0.7-0.4s0.3-0.4,0.3-0.7V5.1\n      C28,4.8,27.9,4.6,27.7,4.4z"
      }
    }),
    _vm._v(" "),
    _c("path", {
      attrs: {
        fill: "currentColor",
        d:
          "M8.2,21.3c-0.6,0-1.2,0.2-1.7,0.7s-0.7,1-0.7,1.7s0.2,1.2,0.7,1.7s1,0.7,1.7,0.7s1.2-0.2,1.7-0.7s0.7-1,0.7-1.7\n      s-0.2-1.2-0.7-1.7S8.8,21.3,8.2,21.3z"
      }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }