<template>
  <section class="block boxed-large-image-with-buttons-and-text white-block">
    <div :class="[
            include_container ? 'container mx-auto px-5-safe' : 'full-container',
            'py-10 lg:py-14 xl:py-16'
         ]">
      <div class="border-3 border-brown px-6 relative md:px-8 lg:px-10 xl:px-12 py-6 lg:pt-16 xl:pt-20 xl:pb-10 xl:mt-2">
        <div class="large-heading lg:whitespace-no-wrap lg:absolute lg:bg-white lg:px-8">
          <base-heading size="h1" color="brown">
            {{ large_heading }}
          </base-heading>
        </div>
        <div v-if="image_src" class="mt-6 lg:mt-0">
          <base-image
            :src="image_src"
            :retina_src="image_retina_src"
            :srcset_webp="image_srcset_webp"
            class_names="mx-auto"
            :alt="alt"
          ></base-image>
        </div>
        <div class="md:flex mt-12">
          <div class="empty-col mb-8 md:mb-0 md:w-1/3">
            &nbsp;
          </div>
          <div class="md:w-2/3 md:pl-12">
            <base-heading size="h2" color="brown">
              {{ heading }}
            </base-heading>
            <base-heading size="h4" color="green" :uppercase="true" class_names="mt-3">
              {{ subheading }}
            </base-heading>
          </div>
        </div>
        <div class="md:flex mt-5">
          <div class="mb-4 md:mb-0 pt-1 md:w-1/3">
            <slot name="buttons"></slot>
          </div>
          <div class="md:w-2/3 md:pl-12">
            <base-rich-text v-show="has_text" class_names="heading ? 'mt-3':''">
              <template>
                <slot name="text"></slot>
              </template>
            </base-rich-text>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { Carousel, Slide } from "vue-carousel";
// Large image within box, allows for buttons and text
// @group Blocks
export default {
  props: {
    large_heading: {
      type: String,
      required: true
    },
    heading: {
      type: String,
      required: true
    },
    subheading: {
      type: String,
      required: true
    },
    image_src: String,
    image_retina_src: String,
    image_srcset_webp: String,
    alt: String,
    has_text: Boolean,
    include_container: Boolean
  },
  components: {
    BaseButton: () => import(/* webpackChunkName: "base-button" */ "../components/BaseButton.vue"),
    BaseHeading: () => import(/* webpackChunkName: "base-heading" */ "../components/BaseHeading.vue"),
    BaseImage: () => import(/* webpackChunkName: "base-image" */ "../components/BaseImage.vue"),
    BaseRichText: () => import(/* webpackChunkName: "base-rich-text" */ "../components/BaseRichText.vue")
  }
};
</script>

<style scoped>
@media (max-width: 991px) {
  .empty-col {
    display: none;
  }
}
@media (min-width: 992px) {
  .large-heading {
    top: -26px;
    left: 50%;
    transform: translate(-50%, 0);
  }
}
</style>
