<template>
  <div v-click-outside="hideOverlay" class="absolute xl:w-2/5 lg:w-3/5 w-full transform-center left-center bg-grey-light">
    <header class="block relative py-4 px-8 border-b border-brown">
      <div class="uppercase font-bold text-2xl text-left text-brown">
        Added to your cart
      </div>
      <button @click="hideOverlay" class="text-brown absolute right-0 centered-y pr-5">
        <base-icon icon-name="x" width="16" height="16" viewbox="0 0 23 23">
          <icon-x></icon-x>
        </base-icon>
      </button>
    </header>
    <div class="px-4 pt-4">
      <div class="flex">
        <div class="py-4 flex items-center w-full border-b border-brown">
          <base-image :src="product.src" :retina_src="product.retina_src" :height="50" :width="150">
          </base-image>
          <div class="text-left">
            <div class="font-bold text-xl text-brown" v-html="product.title"></div>
            <div class="text-sm mt-1">Product Code: {{ product.code }}</div>
          </div>
        </div>
      </div>
      <div class="flex flex-wrap justify-center sm:p-6 px-4 pt-4">
        <span class="pr-4 sm:pb-0 pb-4">
          <button @click="hideOverlay"
          class="button inline-block uppercase tr-all rounded font-bold leading-tighter text-center cursor-pointer text-lg xl:text-xl border-3 border-brown hover:border-brown-hover text-brown bg-white hover:bg-grey-light px-8 py-2 xl:py-3">Keep
          shopping</button>
        </span>
        <span class="pr-4 sm:pb-0 pb-4">
          <base-button url="/shop/cart" label="View Cart" color="brown" :outline="false" size="extrawide">
        </base-button>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import ClickOutside from "vue-click-outside";

export default {
  props: ["product"],
  components: {
    BaseButton: () => import(/* webpackChunkName: "base-button" */ "../components/BaseButton.vue"),
    BaseImage: () => import(/* webpackChunkName: "base-image" */ "../components/BaseImage.vue"),
    BaseIcon: () => import(/* webpackChunkName: "base-icon" */ "../components/BaseIcon.vue"),
    IconX: () => import(/* webpackChunkName: "icon-x" */ "../../vue/icons/IconX.vue")
  },
  methods: {
    hideOverlay() {
      this.$parent.showOverlay = false;
      this.$parent.accessory.qty = 0;
    }
  },
  directives: {
    ClickOutside
  }
};
</script>

<style scoped>
.overlay {
  top: 100px;
  min-height: 160px;
  z-index: 9901;
  left: -10px;
  width: calc(100% + 20px);

  @media (min-width: 506px) and (max-width: 567px),
  (min-width: 1013px) and (max-width: 1099px),
  (min-width: 1335px) and (max-width: 1399px) {
    left: -30px;
    width: calc(100% + 60px);
  }

  @media (min-width: 568px) and (max-width: 991px),
  (min-width: 1100px) and (max-width: 1279px),
  (min-width: 1400px) {
    left: -60px;
    width: calc(100% + 120px);
  }
}
</style>
