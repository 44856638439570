var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "aside",
    { staticClass: "publications-filter side-filter text-brown" },
    [
      _c(
        "div",
        { staticClass: "h-12" },
        [
          _c("base-heading", { attrs: { size: "h2", color: "brown" } }, [
            _vm._v("Filter by:")
          ])
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "ul",
        { staticClass: "border-b border-brown" },
        _vm._l(_vm.filters, function(filter, f) {
          return filter.categories
            ? _c(
                "li",
                { key: f },
                [
                  _c("sidebar-filter-button", {
                    attrs: {
                      label: filter.label,
                      has_submenu: true,
                      active: filter.active
                    },
                    on: {
                      onclick: function($event) {
                        filter.active = !filter.active
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c(
                    "slide-up-down",
                    { attrs: { active: filter.active, duration: 350 } },
                    [
                      _c(
                        "ul",
                        {
                          staticClass:
                            "sub border-t border-brown py-1 pl-4 pr-4 xl:pl-12 xl:pr-10 text-lg xl:text-xl"
                        },
                        _vm._l(filter.categories, function(category, c) {
                          return _c(
                            "li",
                            { key: c, staticClass: "pt-1 pb-2" },
                            [
                              _c("form-checkbox", {
                                attrs: {
                                  name: category.slug,
                                  checked: category.active,
                                  label: category.name,
                                  class_names: "text-lg xl:text-xl"
                                },
                                model: {
                                  value: category.active,
                                  callback: function($$v) {
                                    _vm.$set(category, "active", $$v)
                                  },
                                  expression: "category.active"
                                }
                              })
                            ],
                            1
                          )
                        }),
                        0
                      )
                    ]
                  )
                ],
                1
              )
            : _vm._e()
        }),
        0
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }