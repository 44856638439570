var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.categories.length > 0
    ? _c(
        "div",
        { staticClass: "expandable-categories" },
        [
          _c(
            "div",
            {
              class: [
                _vm.page === "store" ? "h-10" : "",
                _vm.page === "cart" ? "flex items-center mb-5" : ""
              ]
            },
            [
              _vm.page === "cart"
                ? _c(
                    "div",
                    { class: [_vm.page === "cart" ? "w-1/2" : ""] },
                    [
                      _c(
                        "base-heading",
                        { attrs: { size: "h1", color: "brown" } },
                        [_vm._v("Optional Add-Ons")]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                { class: [_vm.page === "cart" ? "w-1/2 text-right" : ""] },
                [
                  _c(
                    "button",
                    {
                      staticClass:
                        "underline tr-color text-brown hover:text-red",
                      attrs: { type: "button" },
                      on: {
                        click: function($event) {
                          return _vm.toggleAllSections(true)
                        }
                      }
                    },
                    [_vm._v("\n        Expand All\n      ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass:
                        "underline tr-color text-brown hover:text-red ml-4",
                      attrs: { type: "button" },
                      on: {
                        click: function($event) {
                          return _vm.toggleAllSections(false)
                        }
                      }
                    },
                    [_vm._v("\n        Collapse All\n      ")]
                  )
                ]
              )
            ]
          ),
          _vm._v(" "),
          _vm._l(_vm.categories, function(category, c) {
            return category.products.length > 0
              ? _c(
                  "div",
                  {
                    key: c,
                    staticClass: "mb-5",
                    attrs: {
                      "data-category-id": c,
                      id: category.categoryName
                        .replace(/\s+/g, "-")
                        .toLowerCase()
                    }
                  },
                  [
                    _c(
                      "button",
                      {
                        class: [
                          "relative text-2xl xl:text-3xl font-bold tr-bg tr-color block border-2 border-brown w-full text-left pl-6 xl:pl-7 pr-11 py-2",
                          category.active
                            ? "active bg-grey-lighter text-brown uppercase shadow-sm"
                            : "bg-brown text-white"
                        ],
                        attrs: { type: "button" },
                        on: {
                          click: function($event) {
                            category.active = !category.active
                          }
                        }
                      },
                      [
                        _c(
                          "span",
                          { staticClass: "inline-flex items-center" },
                          [
                            _c("span", [_vm._v(_vm._s(category.categoryName))]),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                staticClass:
                                  "font-regular text-2xl opacity-50 ml-3"
                              },
                              [
                                _vm._v(
                                  "(" + _vm._s(category.products.length) + ")"
                                )
                              ]
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c("span", {
                          staticClass: "arrow absolute centered-y right-0 mr-6"
                        })
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "slide-up-down",
                      {
                        staticClass: "px-2",
                        attrs: { active: category.active, duration: 350 }
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "bg-grey-lighter border-l-2 border-b-2 border-r-2 border-brown p-4 xl:px-6 xl:py-5"
                          },
                          _vm._l(category.products, function(product, p) {
                            return _c(
                              "expandable-category-product",
                              {
                                key: p,
                                attrs: {
                                  product: product,
                                  is_disabled: _vm.is_disabled,
                                  is_first: p === 0
                                },
                                on: { "zoom-image": _vm.openImageModal }
                              },
                              [
                                _c(
                                  "template",
                                  { slot: "bulk_prices_text" },
                                  [_vm._t("bulk_prices_text")],
                                  2
                                )
                              ],
                              2
                            )
                          }),
                          1
                        )
                      ]
                    )
                  ],
                  1
                )
              : _vm._e()
          }),
          _vm._v(" "),
          _c("zoomable-image-modal", {
            attrs: {
              show: _vm.modalImage.show,
              src: _vm.modalImage.src,
              srcZoom: _vm.modalImage.srcZoom
            },
            on: { close: _vm.closeImageModal }
          })
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }