var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    {
      class: [
        "sidebar-audio sidebar-block max-w-sm mx-auto",
        !_vm.is_first ? "mt-4" : ""
      ]
    },
    [
      _vm.block.showAudioPlayer
        ? _c("audio", { staticClass: "max-w-full", attrs: { controls: "" } }, [
            _c("source", {
              attrs: { src: _vm.block.file, type: "audio/mpeg" }
            }),
            _vm._v("\n    Your browser does not support the audio tag.\n  ")
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.block.showDownloadLink
        ? _c("div", { class: _vm.block.showAudioPlayer ? "mt-4" : "" }, [
            _c(
              "a",
              {
                staticClass:
                  "download-link block relative pl-10 text-lg text-brown tr-color hover:text-red",
                attrs: { href: _vm.block.file, target: "_blank" }
              },
              [
                _c(
                  "base-icon",
                  {
                    staticClass: "absolute left-0 top-0",
                    attrs: {
                      "icon-name": "checkmark",
                      width: "24",
                      height: "24",
                      viewbox: "0 0 31 31"
                    }
                  },
                  [_c("icon-download")],
                  1
                ),
                _vm._v(
                  "\n      " + _vm._s(_vm.block.downloadLinkText) + "\n    "
                )
              ],
              1
            )
          ])
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }