<template>
    <div>
        <div v-for="(session, s) in sessions" :key="'session_' + s" :class="['py-6', s > 0 ? 'border-t border-brown' : '']">
            <div class="md:flex md:items-start">
                <div v-if="session.thumbnailSrc" class="col-1 mb-6 md:mb-0">
                    <base-image
                      :src="session.thumbnailSrc"
                      :retina_src="session.thumbnailSrcRetina"
                      :alt="session.thumbnailAlt"
                      class_names="block m-0"
                      :url="session.buttonUrl"
                    ></base-image>
                </div>
                <div :class="['lg:flex lg:pr-8', session.thumbnailSrc ? 'col-2' : 'w-full']">
                    <div class="col-2-1 pl-8 lg:pr-8">
                        <base-heading size="h4" color="brown-lighter" :uppercase="true" class_names="mb-2">
                            {{ session.subheading }}
                        </base-heading>
                        <base-heading size="h2" color="brown">{{ session.heading }}</base-heading>
                        <base-heading size="h3" color="brown" class_names="my-2">{{ session.date }}</base-heading>
                        <div v-if="session.time" class="text-lg mt-4">
                             <div class="sm:flex">
                                 <div class="sm:flex-shrink font-bold pr-5 mb-1 sm:mb-0">
                                    Time:
                                 </div>
                                 <div class="sm:flex-grow" v-html="session.time"></div>
                             </div>
                        </div>
                        <div v-if="session.description !== ''" class="mt-4">
                            <base-rich-text v-html="session.description"></base-rich-text>
                        </div>
                    </div>
                    <div class="col-2-2 pl-8 mt-6 lg:pl-0 lg:mt-0">

                        <!-- Registration button -->
                        <div v-if="session.buttonText && session.buttonUrl"
                             class="mb-4">
                            <base-button
                                :url="session.buttonUrl"
                                :label="session.buttonText"
                                :target="session.buttonTarget"
                                class_names="button"
                                size="smaller"
                                style="width: 170px;"
                                color="red"
                            ></base-button>
                        </div>
                        <div v-if="session.buttonText && !session.buttonUrl"
                             class="mb-4">
                            <base-button
                                :url="null"
                                :label="session.buttonText"
                                class_names="button"
                                size="smaller"
                                style="width: 170px;"
                                color="grey"
                            ></base-button>
                        </div>

                        <!-- Additional buttons -->
                        <div v-for="(button, b) in session.additionalButtons"
                             class="mb-4"
                             :key="`button-${b}`">
                            <base-button
                                v-if="button.url"
                                :url="button.url"
                                :label="button.text"
                                :target="button.target"
                                class_names="button"
                                size="smaller"
                                style="width: 170px;"
                                color="red"
                            ></base-button>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
  export default {
    props: ["sessions"],
    components: {
      BaseButton: () => import(/* webpackChunkName: "base-button" */ "../components/BaseButton.vue"),
      BaseHeading: () => import(/* webpackChunkName: "base-heading" */ "../components/BaseHeading.vue"),
      BaseImage: () => import(/* webpackChunkName: "base-image" */ "../components/BaseImage.vue"),
      BaseRichText: () => import(/* webpackChunkName: "base-rich-text" */ "../components/BaseRichText.vue")
    }
  };
</script>

<style scoped>
@media (min-width: 768px) {
    .col-1 {
        width: 33%;
    }
    .col-2 {
        width: 67%;
    }
}
@media (min-width: 992px) {
    .col-2-1 {
        width: calc(100% - 170px);
    }
    .col-2-2 {
        width: 170px;
    }
}
</style>
