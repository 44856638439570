var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "training-course-item" },
    [
      _c("base-image", {
        attrs: {
          src:
            _vm.item.thumbnailSrc !== ""
              ? _vm.item.thumbnailSrc
              : "/img/training-course-thumbnail-placeholder.png",
          retina_src:
            _vm.item.thumbnailSrcRetina !== ""
              ? _vm.item.thumbnailSrcRetina
              : "/img/training-course-thumbnail-placeholder@2x.png",
          alt: _vm.item.thumbnailAlt,
          class_names: [
            "block mx-0 mb-5",
            _vm.item.addImageBorder || _vm.item.thumbnailSrc === ""
              ? "border border-brown"
              : ""
          ],
          url: _vm.item.url
        }
      }),
      _vm._v(" "),
      _c(
        "base-heading",
        { attrs: { size: "h4", color: "brown-lighter", uppercase: true } },
        [_vm._v(_vm._s(_vm.item.location))]
      ),
      _vm._v(" "),
      _c(
        "base-heading",
        {
          attrs: {
            size: "h2",
            color: "brown",
            hover_color: "red",
            class_names: "mt-1",
            url: _vm.item.url
          }
        },
        [_vm._v(_vm._s(_vm.item.title))]
      ),
      _vm._v(" "),
      _c(
        "base-rich-text",
        {
          attrs: { size: "small", class_names: "mt-3" },
          domProps: { innerHTML: _vm._s(_vm.item.summary) }
        },
        [[_vm._t("text")]],
        2
      ),
      _vm._v(" "),
      _vm.item.url !== "" && _vm.item.trainingCourseTileButton
        ? _c(
            "div",
            { staticClass: "mt-5" },
            [
              _c("base-button", {
                attrs: {
                  url: _vm.item.url,
                  label: _vm.item.trainingCourseTileButton,
                  color: "red",
                  size: "smaller wider"
                }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }