<template>
  <div class="sidebar-order-summary" v-if="$parent.cart !== undefined && $parent.cart">
    <div class="bg-red-hover text-white text-xl uppercase font-bold py-3 px-5 text-center">
      Order Summary
    </div>

    <!-- line items -->
    <div class="bg-grey-lighter text-lg leading-normal px-5">
      <div
        v-for="(lineItem, productCode, index) in $parent.cart.items"
        :key="'checkout_sidebar_lineitem_' + productCode"
        :class="['flex py-4', index > 0 ? 'border-t border-grey' : '']"
      >
        <div class="w-3/4 leading-compact pr-4">
          {{ productName(productCode) }}
          <div v-if="productName(productCode) !== productCode" class="text-xs mt-1">Product Code: {{ productCode }}</div>
        </div>
        <div class="w-1/4 text-right">
          {{ $parent.formattedPrice(lineItem.unit) }}<br />
          Qty: {{ lineItem.qty }}
        </div>
      </div>
    </div>

    <!-- negative tax adjustment -->
    <div class="pt-4 px-5" style="background-color: #dcdcdc;" v-if="$parent.cart.tax < 0">
      <div class="flex text-lg">
        <div class="w-1/2">
          Tax:<br/><span class="text-xs italic">* {{ $parent.cart.taxcode }}</span>
        </div>
        <div class="w-1/2 text-right">
          {{ $parent.formattedPrice($parent.cart.tax) }}
        </div>
      </div>
    </div>

    <!-- subtotal -->
    <div class="pt-4 pb-1 px-5" style="background-color: #dcdcdc;">
      <div class="flex text-lg">
        <div class="w-1/2">
          Subtotal:
        </div>
        <div class="w-1/2 text-right">
          {{ $parent.formattedPrice($parent.cart.subtotal) }}
        </div>
      </div>
    </div>

    <!-- tax -->
    <div class="py-1 px-5" style="background-color: #dcdcdc;" v-if="$parent.cart.tax > 0">
      <div class="flex text-lg">
        <div class="w-1/2">
          Tax:<br/><span class="text-xs italic">* {{ $parent.cart.taxcode }}</span>
        </div>
        <div class="w-1/2 text-right">
          {{ $parent.formattedPrice($parent.cart.tax) }}
        </div>
      </div>
    </div>

    <!-- shipping -->
    <div class="py-1 px-5" style="background-color: #dcdcdc;" v-if="$parent.cart.oz > 0">
      <div class="flex text-lg">
        <div class="w-1/2">
          Estimated Shipping:
        </div>
        <div class="w-1/2 text-right">
          <span v-if="$parent.cart.shipoverride !== undefined">
            {{ $parent.formattedPrice($parent.cart.shipoverriderate) }}
          </span>
          <span v-else-if="$parent.cart.shipmethod">
            {{ $parent.formattedPrice($parent.cart.shiprate) }}
          </span>
          <span v-else>
            TBD
          </span>
        </div>
      </div>
    </div>

    <!-- total -->
    <div class="pt-1 pb-4 px-5" style="background-color: #dcdcdc;">
      <div class="flex text-lg font-bold">
        <div class="w-1/2">
          Total:
        </div>
        <div class="w-1/2 text-right">
          <span v-if="$parent.cart.shipoverride !== undefined || $parent.cart.shipmethod">
            {{ $parent.formattedPrice($parent.cart.total) }}
          </span>
          <span v-else>
            TBD
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HelpersMixin from "../mixins/Helpers";

export default {
  methods: {
    productName(productCode) {
      return window.cartProductNames[productCode] || productCode;
    }
  }
};
</script>
