var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "video-tutorials-sidebar-nav" },
    [
      _c("video-tutorials-languages-nav", {
        attrs: {
          languages: _vm.$parent.languages,
          clicked_language: _vm.$parent.selectedLanguage
        },
        on: { onselect: _vm.selectLanguage }
      }),
      _vm._v(" "),
      _c(
        "aside",
        { staticClass: "side-filter text-brown", attrs: { id: "nav-videos" } },
        [
          _c(
            "ul",
            { staticClass: "border-b border-brown" },
            _vm._l(_vm.$parent.categories, function(category, c) {
              return _vm.categoryHasVideos(category)
                ? _c(
                    "li",
                    { key: "cat-" + c },
                    [
                      _c("sidebar-filter-button", {
                        attrs: {
                          label: _vm.translatedCategoryName(category),
                          has_submenu:
                            category.children && category.children.length > 0,
                          active: category.active
                        },
                        on: {
                          onclick: function($event) {
                            category.active = !category.active
                          }
                        }
                      }),
                      _vm._v(" "),
                      category.children && category.children.length > 0
                        ? _c(
                            "slide-up-down",
                            {
                              attrs: { active: category.active, duration: 350 }
                            },
                            [
                              _c(
                                "ul",
                                {
                                  staticClass:
                                    "sub border-t border-brown py-1 pl-4 pr-4 xl:pl-12 xl:pr-10 text-lg xl:text-xl"
                                },
                                [
                                  _vm._l(category.children, function(
                                    subCategory,
                                    s
                                  ) {
                                    return _vm.videosInCategory(subCategory.id)
                                      .length > 0
                                      ? _c(
                                          "li",
                                          {
                                            key: "subcat" + s,
                                            staticClass: "pt-1 pb-2"
                                          },
                                          [
                                            _c("strong", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.translatedCategoryName(
                                                    subCategory
                                                  )
                                                )
                                              )
                                            ]),
                                            _vm._v(" "),
                                            _c(
                                              "ul",
                                              {
                                                staticClass: "mt-2 ml-7 text-lg"
                                              },
                                              _vm._l(
                                                _vm.videosInCategory(
                                                  subCategory.id
                                                ),
                                                function(video, v) {
                                                  return _c(
                                                    "li",
                                                    {
                                                      key: "video" + v,
                                                      class: [
                                                        "py-2",
                                                        _vm.selectedVideoUid ===
                                                        video.uid
                                                          ? "text-red"
                                                          : ""
                                                      ]
                                                    },
                                                    [
                                                      _c(
                                                        "button",
                                                        {
                                                          staticClass:
                                                            "video-nav-link hover:underline",
                                                          attrs: {
                                                            type: "button",
                                                            "data-uid":
                                                              video.uid
                                                          },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              $event.preventDefault()
                                                              return _vm.selectVideo(
                                                                video
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                    " +
                                                              _vm._s(
                                                                video.shortTitle &&
                                                                  video.shortTitle !==
                                                                    ""
                                                                  ? video.shortTitle
                                                                  : video.title
                                                              ) +
                                                              "\n                  "
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  )
                                                }
                                              ),
                                              0
                                            )
                                          ]
                                        )
                                      : _vm._e()
                                  }),
                                  _vm._v(" "),
                                  _vm._l(
                                    _vm.videosInCategory(category.id),
                                    function(video, v) {
                                      return _c(
                                        "li",
                                        {
                                          key: "video" + v,
                                          class: [
                                            "py-2",
                                            _vm.selectedVideoUid === video.uid
                                              ? "text-red"
                                              : ""
                                          ]
                                        },
                                        [
                                          _c(
                                            "button",
                                            {
                                              staticClass:
                                                "video-nav-link hover:underline",
                                              attrs: {
                                                type: "button",
                                                "data-uid": video.uid
                                              },
                                              on: {
                                                click: function($event) {
                                                  $event.preventDefault()
                                                  return _vm.selectVideo(video)
                                                }
                                              }
                                            },
                                            [
                                              _c("strong", [
                                                _vm._v(
                                                  _vm._s(
                                                    video.shortTitle &&
                                                      video.shortTitle !== ""
                                                      ? video.shortTitle
                                                      : video.title
                                                  )
                                                )
                                              ])
                                            ]
                                          )
                                        ]
                                      )
                                    }
                                  )
                                ],
                                2
                              )
                            ]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e()
            }),
            0
          ),
          _vm._v(" "),
          _vm.videosWithoutCategory.length > 0
            ? _c("div", { staticStyle: { "background-color": "#f4ede3" } }, [
                _c(
                  "ul",
                  {
                    staticClass:
                      "border-b border-brown py-2 pl-4 pr-4 xl:pl-6 xl:pr-6 text-lg xl:text-xl"
                  },
                  _vm._l(_vm.videosWithoutCategory, function(video, v) {
                    return _c(
                      "li",
                      {
                        key: "video" + v,
                        class: [
                          "py-2",
                          _vm.selectedVideoUid === video.uid ? "text-red" : ""
                        ]
                      },
                      [
                        _c(
                          "button",
                          {
                            staticClass: "video-nav-link hover:underline",
                            attrs: { type: "button", "data-uid": video.uid },
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.selectVideo(video)
                              }
                            }
                          },
                          [
                            _c("strong", [
                              _vm._v(
                                _vm._s(
                                  video.shortTitle && video.shortTitle !== ""
                                    ? video.shortTitle
                                    : video.videoTitle
                                )
                              )
                            ])
                          ]
                        )
                      ]
                    )
                  }),
                  0
                )
              ])
            : _vm._e()
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }