var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("polygon", {
    attrs: {
      fill: "currentColor",
      points: "13.4,1 13.4,1 6.1,9.2 2.6,5.3 0,8.3 6,15 16,3.9"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }