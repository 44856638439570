var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "blog-index container mx-auto px-5-safe py-12",
      attrs: { id: "blog-entries" }
    },
    [
      _c(
        "div",
        { staticClass: "mb-12" },
        [
          _c("search-bar", {
            attrs: { keyword: _vm.keyword, placeholder: "Search Blog" },
            on: { updateResults: _vm.keywordChanged }
          })
        ],
        1
      ),
      _vm._v(" "),
      _vm.ajaxError
        ? _c("div", {
            staticClass: "mb-10 text-red text-lg",
            domProps: { innerHTML: _vm._s(_vm.ajaxError) }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.localItems && _vm.localItems.length > 0
        ? _c(
            "div",
            {
              class: [
                "flex justify-between flex-wrap",
                _vm.isLoading ? "opacity-25" : ""
              ]
            },
            [
              _vm._l(_vm.localItems, function(item, i) {
                return _c("blog-entry-item", { key: i, attrs: { item: item } })
              }),
              _vm._v(" "),
              _vm.localItems.length % 2 === 0
                ? _c("div", { staticClass: "sm:w-p47 lg:w-p30 pb-10" }, [
                    _vm._v(" ")
                  ])
                : _vm._e()
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _c("no-results", {
        attrs: { results: _vm.localItems, keyword: _vm.keyword },
        on: { resetKeyword: _vm.resetKeyword }
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "mt-10" },
        [
          _c("base-pagination", {
            attrs: {
              max_visible_buttons:
                _vm.numPages >= 3 ? 3 : parseInt(_vm.numPages),
              per_page: parseInt(_vm.limit),
              num_pages: parseInt(_vm.numPages),
              current_page: _vm.currentPage,
              total: parseInt(_vm.totalCount),
              show_prev_next_btns: true,
              pagination_disabled: _vm.isLoading
            },
            on: { pagechanged: _vm.onPageChange }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }