var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("path", {
    attrs: {
      fill: "currentColor",
      d:
        "M31.3,13.7v0.8c0,7.9-6,16.9-17,16.9c-3.4,0-6.5-1-9.2-2.7c0.5,0.1,0.9,0.1,1.4,0.1c2.8,0,5.4-1,7.4-2.5\n\t\tc-2.6,0-4.8-1.8-5.6-4.1c0.4,0.1,0.7,0.1,1.1,0.1c0.5,0,1.1-0.1,1.6-0.2c-2.7-0.5-4.8-3-4.8-5.8v-0.1c0.8,0.4,1.7,0.7,2.7,0.7\n\t\tc-1.6-1.1-2.7-2.9-2.7-5c0-1.1,0.3-2.1,0.8-3c2.9,3.6,7.3,6,12.3,6.2c-0.1-0.4-0.2-0.9-0.2-1.4c0-3.3,2.7-6,6-6\n\t\tc1.7,0,3.3,0.7,4.4,1.9c1.5-0.1,2.8-0.6,4-1.3c-0.4,1.4-1.4,2.6-2.6,3.3c1.2-0.1,2.4-0.5,3.4-0.9C33.5,11.9,32.5,12.9,31.3,13.7\n\t\t M34.2,0H5.3C2.4,0,0,2.4,0,5.3v28.8c0,2.9,2.4,5.3,5.3,5.3h28.9c2.9,0,5.3-2.4,5.3-5.3V5.3C39.5,2.4,37.1,0,34.2,0"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }