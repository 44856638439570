<template>
  <div class="container px-5-safe mx-auto py-12">
    <accessories-filter v-if="showFilters"></accessories-filter>
    <accessories-results @updateUrl="updateUrl" @setPageNumber="setPageNumber" :initial_items="data.data" :num_pages="data.metadata.pages"></accessories-results>
  </div>
</template>

<script>
import HelpersMixin from "../mixins/Helpers";

export default {
  data: function () {
    return {
      currentPage: 1,
      selectedAssociatedProduct: "",
      selectedAccessoryCategory: "",
    };
  },
  props: ["limit", "page_from_url", "data", "associated_products", "accessory_categories", "associated_product_from_url", "accessory_category_from_url"],
  mixins: [HelpersMixin],
  components: {
    AccessoriesFilter: () => import(/* webpackChunkName: "accessories-filter" */ "./AccessoriesFilter.vue"),
    AccessoriesResults: () => import(/* webpackChunkName: "accessories-results" */ "./AccessoriesResults.vue"),
  },
  methods: {
    setPageNumber(pageNumber) {
      this.currentPage = pageNumber;
    },
    updateUrl() {
      let url = "/accessories",
        andChar = "?";
      if (this.currentPage > 1) {
        url += "/p" + this.currentPage;
      }
      if (this.selectedAssociatedProduct !== "") {
        url += andChar + "ap=" + encodeURIComponent(this.selectedAssociatedProduct);
        andChar = "&";
      }
      if (this.selectedAccessoryCategory !== "") {
        url += andChar + "ac=" + encodeURIComponent(this.selectedAccessoryCategory);
        andChar = "&";
      }
      this.setUrl("accessories", document.title, url);
    },
  },
  computed: {
    showFilters() {
      return (
        Object.keys(this.associated_products).length > 0 ||
        Object.keys(this.accessory_categories).length > 0
      );
    }
  },
  mounted: function () {
    if (this.page_from_url !== "") {
      this.currentPage = parseInt(this.page_from_url);
    }
    if (this.associated_product_from_url !== "") {
      this.selectedAssociatedProduct = this.associated_product_from_url;
    }
    if (this.accessory_category_from_url !== "") {
      this.selectedAccessoryCategory = this.accessory_category_from_url;
    }
  }
}
</script>
