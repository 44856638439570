var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "account-bar text-white py-2 text-right print-hidden" },
    [
      _c("div", { staticClass: "container mx-auto px-5-safe" }, [
        _c("div", { staticClass: "profile-link flex items-center" }, [
          _c("div", { staticClass: "flex-1" }, [_vm._v(" ")]),
          _vm._v(" "),
          _c("div", { staticClass: "flex-shrink-0" }, [
            _c(
              "svg",
              {
                staticStyle: {
                  "enable-background": "new 0 0 409.165 409.164",
                  width: "18px",
                  height: "18px"
                },
                attrs: {
                  version: "1.1",
                  xmlns: "http://www.w3.org/2000/svg",
                  "xmlns:xlink": "http://www.w3.org/1999/xlink",
                  x: "0px",
                  y: "0px",
                  viewBox: "0 0 409.165 409.164",
                  "xml:space": "preserve"
                }
              },
              [
                _c("path", {
                  attrs: {
                    fill: "currentColor",
                    d:
                      "M204.583,216.671c50.664,0,91.74-48.075,91.74-107.378c0-82.237-41.074-107.377-91.74-107.377\n              c-50.668,0-91.74,25.14-91.74,107.377C112.844,168.596,153.916,216.671,204.583,216.671z"
                  }
                }),
                _vm._v(" "),
                _c("path", {
                  attrs: {
                    fill: "currentColor",
                    d:
                      "M407.164,374.717L360.88,270.454c-2.117-4.771-5.836-8.728-10.465-11.138l-71.83-37.392\n              c-1.584-0.823-3.502-0.663-4.926,0.415c-20.316,15.366-44.203,23.488-69.076,23.488c-24.877,0-48.762-8.122-69.078-23.488\n              c-1.428-1.078-3.346-1.238-4.93-0.415L58.75,259.316c-4.631,2.41-8.346,6.365-10.465,11.138L2.001,374.717\n              c-3.191,7.188-2.537,15.412,1.75,22.005c4.285,6.592,11.537,10.526,19.4,10.526h362.861c7.863,0,15.117-3.936,19.402-10.527\n              C409.699,390.129,410.355,381.902,407.164,374.717z"
                  }
                })
              ]
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "flex-shrink-0 text pl-2 leading-normal" }, [
            _vm._v("\n        Logged in as: "),
            _c(
              "a",
              {
                staticClass: "font-bold hover:underline",
                attrs: { href: "/account/profile" }
              },
              [_vm._v(_vm._s(_vm.name))]
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "flex-shrink-0 pl-4" }, [
            _c(
              "form",
              {
                attrs: { action: "/", method: "POST" },
                on: { submit: _vm.logout }
              },
              [
                _c("input", {
                  attrs: {
                    type: "hidden",
                    name: "action",
                    value: "/wildlife/account/logoff"
                  }
                }),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.$root.csrfTokenValue,
                      expression: "$root.csrfTokenValue"
                    }
                  ],
                  attrs: { type: "hidden", name: _vm.$root.csrfTokenName },
                  domProps: { value: _vm.$root.csrfTokenValue },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.$root, "csrfTokenValue", $event.target.value)
                    }
                  }
                }),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    class: [
                      "relative bg-white py-1 pr-3 rounded font-bold uppercase align-top",
                      _vm.isLoading
                        ? "pl-8 text-grey"
                        : "pl-3 tr-bg hover:bg-grey-lighter text-green"
                    ],
                    attrs: { type: "submit" }
                  },
                  [
                    _c(
                      "base-icon",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.isLoading,
                            expression: "isLoading"
                          }
                        ],
                        staticClass:
                          "loading absolute ml-2 left-0 centered-y text-grey",
                        attrs: {
                          "icon-name": "loading",
                          width: "18",
                          height: "18",
                          viewbox: "0 0 32 32"
                        }
                      },
                      [_c("icon-loading")],
                      1
                    ),
                    _vm._v("\n            Log Out\n          ")
                  ],
                  1
                )
              ]
            )
          ])
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }