var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.block.text
    ? _c(
        "section",
        {
          class: [
            "sidebar-rich-text sidebar-block max-w-sm mx-auto",
            !_vm.is_first ? "mt-4" : ""
          ]
        },
        [
          _c("base-rich-text", {
            attrs: { size: _vm.size, link_style: "red" },
            domProps: { innerHTML: _vm._s(_vm.block.text) }
          })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }