var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: ["cart-buttons", _vm.class_names] },
    [
      _c("base-button", {
        attrs: {
          url: "/shop",
          label: "Keep Shopping",
          color: "red",
          outline: true,
          class_names: "mb-3"
        }
      }),
      _vm._v(" "),
      _c(
        "button",
        {
          class: [
            "button inline-block uppercase tr-all rounded text-lg xl:text-xl font-bold leading-tighter text-center border-3 cursor-pointer px-4 py-2 xl:px-5 xl:py-3 ml-7 mb-3",
            _vm.is_disabled
              ? "text-grey bg-white"
              : "bg-red border-red text-white hover:bg-red-hover hover:border-red-hover"
          ],
          attrs: { type: "button", disabled: _vm.is_disabled },
          on: {
            click: function($event) {
              $event.preventDefault()
              return _vm.proceedToCheckout($event)
            }
          }
        },
        [_vm._v("\n    Proceed to Checkout\n  ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }