<template>
  <section :class="['grant-program-horizontal-line', !block.isFirst ? 'mt-10' : '']">
    <hr class="bg-brown border-0 w-full" />
  </section>
</template>

<script>
export default {
  props: ["block"]
};
</script>

<style scoped>
hr {
  height: 1px;
}
</style>
