<template>
  <div class="account-bar text-white py-2 text-right print-hidden">
    <div class="container mx-auto px-5-safe">
      <div class="profile-link flex items-center">
        <div class="flex-1">&nbsp;</div>
        <div class="flex-shrink-0">
          <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 409.165 409.164"
            style="enable-background:new 0 0 409.165 409.164; width: 18px; height: 18px;"
            xml:space="preserve"
          >
            <path
              fill="currentColor"
              d="M204.583,216.671c50.664,0,91.74-48.075,91.74-107.378c0-82.237-41.074-107.377-91.74-107.377
                c-50.668,0-91.74,25.14-91.74,107.377C112.844,168.596,153.916,216.671,204.583,216.671z"
            ></path>
            <path
              fill="currentColor"
              d="M407.164,374.717L360.88,270.454c-2.117-4.771-5.836-8.728-10.465-11.138l-71.83-37.392
                c-1.584-0.823-3.502-0.663-4.926,0.415c-20.316,15.366-44.203,23.488-69.076,23.488c-24.877,0-48.762-8.122-69.078-23.488
                c-1.428-1.078-3.346-1.238-4.93-0.415L58.75,259.316c-4.631,2.41-8.346,6.365-10.465,11.138L2.001,374.717
                c-3.191,7.188-2.537,15.412,1.75,22.005c4.285,6.592,11.537,10.526,19.4,10.526h362.861c7.863,0,15.117-3.936,19.402-10.527
                C409.699,390.129,410.355,381.902,407.164,374.717z"
            ></path>
          </svg>
        </div>
        <div class="flex-shrink-0 text pl-2 leading-normal">
          Logged in as: <a href="/account/profile" class="font-bold hover:underline">{{ name }}</a>
        </div>
        <div class="flex-shrink-0 pl-4">
          <form action="/" method="POST" @submit="logout">
            <input type="hidden" name="action" value="/wildlife/account/logoff" />
            <input type="hidden" :name="$root.csrfTokenName" v-model="$root.csrfTokenValue" />
            <button
              type="submit"
              :class="[
                'relative bg-white py-1 pr-3 rounded font-bold uppercase align-top',
                isLoading ? 'pl-8 text-grey' : 'pl-3 tr-bg hover:bg-grey-lighter text-green'
              ]"
            >
              <base-icon
                icon-name="loading"
                width="18"
                height="18"
                viewbox="0 0 32 32"
                class="loading absolute ml-2 left-0 centered-y text-grey"
                v-show="isLoading"
              >
                <icon-loading></icon-loading>
              </base-icon>
              Log Out
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueAxios from "../../../js/modules/vue-axios";

export default {
  data: function() {
    return {
      isLoading: false
    };
  },
  props: ["name"],
  components: {
    BaseIcon: () => import(/* webpackChunkName: "base-icon" */ "../../components/BaseIcon.vue"),
    IconLoading: () => import(/* webpackChunkName: "icon-loading" */ "../../icons/IconLoading.vue")
  },
  methods: {
    logout(e) {
      this.isLoading = true;
    }
  }
};
</script>

<style scoped>
.account-bar {
  background: #4b3d2a;
  background: -moz-linear-gradient(left, #4b3d2a 42%, #4e5b31 100%);
  background: -webkit-linear-gradient(left, #4b3d2a 42%, #4e5b31 100%);
  background: linear-gradient(to right, #4b3d2a 42%, #4e5b31 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4b3d2a', endColorstr='#4e5b31',GradientType=1 );
}
</style>
