var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.src && _vm.src.length > 0
    ? _c("inner-image-zoom", {
        attrs: {
          alt: _vm.alt,
          src: _vm.src,
          zoomSrc: _vm.srcZoom,
          zoomType: "hover",
          sources: _vm.sources,
          zoomPreload: true
        }
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }