<template>
  <div class="flex flex-col h-full">
    <div class="border-grey border h-80 flex items-center justify-center">
      <base-image :src="accessory.src !== '' ? accessory.src : '/img/add-on-small-thumbnail-placeholder.png'"
        :retina_src="accessory.retinaSrc !== '' ? accessory.retinaSrc : '/img/add-on-small-thumbnail-placeholder@2x.png'"
        :height="300" :width="250" class_names="max-h-48 object-contain"></base-image>
    </div>
    <div class="py-4">
      <base-heading size="h2" color="brown" class_names="my-2">
        {{ accessory.title }}
      </base-heading>
      <span class=" mt-1 sm:mt-2 text-sm text-brown">Product code:{{ accessory.code }}</span>
      <base-rich-text v-if="accessory.tooltip !== ''" size="small" class_names="mt-3">
        <span v-html="accessory.tooltip"></span>
      </base-rich-text>
    </div>
    <div class="border-t border-b border-grey flex flex-wrap justify-between py-4 mt-auto">
      <div>
        <div class="text-2xl font-bold">
          <span class="text-brown" v-html="calculatedProductPrice(accessory)"></span>
          <span class="text-brown mt-1">({{ $root.formattedCurrency() }})</span>
        </div>
        <div class="text-sm uppercase text-brown mt-1 product-stock" v-show="!hasPriceOverride"
          v-html="accessory.stock"></div>
        <div v-if="!hasPriceOverride && accessory.price_tiers_display.length > 1" class="relative mt-4">
          <button @click="showQuantityDiscountBox = !showQuantityDiscountBox" type="button"
            class="italic text-red text-sm underline">
            Quantity Discount
          </button>
          <slide-up-down :active="showQuantityDiscountBox" :duration="0" class="price-tiers-box absolute right-0 z-10">
            <div class="py-2 px-3 bg-brown text-white">
              <table class="mx-auto">
                <tr v-for="(tier, t) in accessory.price_tiers_display" :key="t">
                  <td class="text-right">{{ tier.label }}:</td>
                  <td class="pl-3">{{ formattedPrice(tier.price) }} ({{ $root.formattedCurrency() }})</td>
                </tr>
              </table>

              <div class="text-left mt-2">
                <slot name="bulk_prices_text"></slot>
              </div>
            </div>
          </slide-up-down>
        </div>
      </div>
      <div class="flex items-center">
        <span class="mr-2 text-lg text-brown">Qty:</span>
        <input-product-quantity v-model="quantity" :value="quantity" bg_color="border-grey-lightest"
          border_color="undefined" :is_loading="isLoading" :is_disabled="is_disabled"
          class_names="mx-auto border-brown"></input-product-quantity>
      </div>
      <div class="w-full pt-4">
        <button type="button" :disabled="isLoading || quantity < 1" @click="addToCart()" :class="[
          'w-full text-white rounded uppercase py-3 px-16 font-bold text-lg xl:text-xl leading-tighter text-center tr-bg',
          isLoading || quantity < 1 ? 'bg-grey' : 'bg-red hover:bg-red-hover'
        ]">
          Add to cart
        </button>
      </div>
    </div>
    <cart-overlay-single v-show="showOverlay" :product="accessory">
    </cart-overlay-single>
  </div>

</template>

<script>
import Vue from "vue";
import VueAxios from "../../js/modules/vue-axios";
import { gaAddToCart } from "../../js/modules/wa-analytics";
import ClickOutside from "vue-click-outside";
import CartMixin from "../mixins/Cart";
import VueScrollTo from "vue-scrollto";

Vue.use(VueScrollTo);

export default {
  data: function () {
    return {
      isLoading: false,
      page: this.$parent.$parent.page,
      showQuantityDiscountBox: false,
      ajaxError: "",
      quantity: 1,
      showOverlay: false,
    };
  },
  props: ["accessory", "is_disabled"],
  mixins: [CartMixin],
  components: {
    BaseButton: () => import(/* webpackChunkName: "base-button" */ "../components/BaseButton.vue"),
    BaseHeading: () => import(/* webpackChunkName: "base-heading" */ "../components/BaseHeading.vue"),
    BaseIcon: () => import(/* webpackChunkName: "base-icon" */ "../components/BaseIcon.vue"),
    BaseImage: () => import(/* webpackChunkName: "base-image" */ "../components/BaseImage.vue"),
    BaseRichText: () => import(/* webpackChunkName: "base-rich-text" */ "../components/BaseRichText.vue"),
    InputProductQuantity: () => import(/* webpackChunkName: "input-product-quantity" */ "../components/InputProductQuantity.vue"),
    // CartOverlay: () => import(/* webpackChunkName: "cart-overlay" */ "./CartOverlay.vue"),
    CartOverlaySingle: () => import(/* webpackChunkName: "cart-overlay-single" */ "./CartOverlaySingle.vue"),
  },
  computed: {
    hasPriceOverride() {
      return this.accessory.price_override && this.accessory.price_override.length > 0;
    },
  },
  methods: {
    addToCart() {
      let self = this;
      self.isLoading = true;
      let formData = new FormData();
      const productCode = self.accessory.code.replace(/\\/g, '\\\\');

      formData.append(self.$root.csrfTokenName, self.$root.csrfTokenValue);
      formData.append("action", "wildlife/shop/update");
      formData.append("quantity_" + productCode, self.quantity);
      formData.append("add_" + productCode, "");
      let options = {
        method: "POST",
        data: formData,
        url: "/"
      };
      self.ajaxError = "";
      VueAxios(options)
        .then(response => {
          let data = response.data || null;
          self.isLoading = false;
          if (data.success) {
            // update $root.cart
            let data = response.data || null,
              cart = data.cart || null;
            if (data.success && cart) {
              self.$root.cart = cart;
              self.showOverlay = true; // Show overlay
              // Send to Google Analytics
              gaAddToCart(
                [
                  {
                    name: self.accessory.title,
                    id: self.accessory.code,
                    price: self.accessory.price,
                    brand: self.$root.getSiteFullName(),
                    quantity: self.quantity
                  }
                ],
                {
                  list: "Cart Add-Ons"
                },
                self.quantity,
                self.$root.currencyCode()
              );
            }
            // set qty box back to 1
            self.quantity = 1;
            // scroll to line item
            self.$nextTick(function () {
              VueScrollTo.scrollTo("accessories-resul" + self.accessory.code, 350, {
                offset: -10
              });
            });
          } else {
            self.ajaxError = "An error occurred. Please try again.";
          }
        })
        .catch(error => {
          self.isLoading = false;
          self.ajaxError = "An error occurred. Please try again.";
        });
    },
    calculatedProductPrice(accessory) {
      return this.hasPriceOverride
        ? this.accessory.price_override
        : this.formattedPrice(this.getProductPriceByQuantity(accessory, this.quantity));
    },
    hideQuantityDiscountBox() {
      this.showQuantityDiscountBox = false;
    },
  }
}
</script>

<style scoped>
.price-tiers-box {
  top: 25px;
}

.product-stock>>>a {
  @apply underline text-red;
}
</style>
