var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "block custom-table white-block" }, [
    _c(
      "div",
      {
        class: [
          _vm.include_container
            ? "container mx-auto px-5-safe"
            : "full-container",
          "py-8 lg:py-10 xl:py-12"
        ]
      },
      [
        _c("div", { staticClass: "max-w-2xl mx-auto" }, [
          _c(
            "div",
            { staticClass: "mb-3 sm:hidden" },
            [_c("swipe-table-indicator")],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "table-wrapper min-sm" }, [
            _c(
              "table",
              {
                staticClass:
                  "w-full text-base md:text-lg lg:text-xl leading-normal"
              },
              [
                _c("thead", [
                  _c(
                    "tr",
                    _vm._l(_vm.data.columns, function(column, i) {
                      return _c("td", {
                        key: i,
                        class: [
                          "font-bold uppercase text-brown-lighter border-b border-brown py-3",
                          i > 0 ? "pl-3" : "",
                          i < _vm.data.columns.length - 1 ? "pr-3" : ""
                        ],
                        attrs: {
                          width: column.width,
                          align: column.align,
                          valign: "bottom"
                        },
                        domProps: { innerHTML: _vm._s(column.heading) }
                      })
                    }),
                    0
                  )
                ]),
                _vm._v(" "),
                _c(
                  "tbody",
                  _vm._l(_vm.data.rows, function(row, j) {
                    return _c(
                      "tr",
                      { key: j },
                      _vm._l(row, function(cell, k) {
                        return _c(
                          "td",
                          {
                            key: k,
                            class: [
                              "py-3 border-b border-brown",
                              _vm.data.firstColumnBold && k === 0
                                ? "font-bold"
                                : "",
                              k > 0 ? "pl-3" : "",
                              k < row.length - 1 ? "pr-3" : ""
                            ],
                            attrs: {
                              width: _vm.data.columns[k].width,
                              align: _vm.data.columns[k].align,
                              valign: "top"
                            },
                            domProps: { innerHTML: _vm._s(cell) }
                          },
                          [
                            _vm._v(
                              "\n                " +
                                _vm._s(k) +
                                "\n              "
                            )
                          ]
                        )
                      }),
                      0
                    )
                  }),
                  0
                )
              ]
            )
          ])
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }