<template>
  <div class="grant-program">
    <div class="container px-5-safe mx-auto py-12">
      <div class="lg:flex">
        <div class="col-1 mb-10 lg:mb-0 lg:pr-10 xl:pr-16 lg:border-r lg:border-brown">
          <grant-program-content></grant-program-content>
        </div>
        <div class="col-2 lg:pl-10 xl:pl-16">
          <sidebar-blocks :blocks="sidebar_blocks"></sidebar-blocks>
        </div>
      </div>
    </div>
    <grant-program-guidelines></grant-program-guidelines>
  </div>
</template>

<script>
export default {
  props: ["content_blocks", "sidebar_blocks", "guidelines_data"],
  components: {
    GrantProgramContent: () => import(/* webpackChunkName: "grant-program-content" */ "./GrantProgramContent.vue"),
    GrantProgramGuidelines: () => import(/* webpackChunkName: "grant-program-guidelines" */ "./GrantProgramGuidelines.vue"),
    SidebarBlocks: () => import(/* webpackChunkName: "sidebar-blocks" */ "../sidebar/SidebarBlocks.vue")
  }
};
</script>

<style scoped>
@media (min-width: 992px) {
  .col-1 {
    width: calc(100% - 300px);
  }

  .col-2 {
    width: 300px;
  }
}
@media (min-width: 1280px) {
  .col-1 {
    width: calc(100% - 357px);
  }

  .col-2 {
    width: 357px;
  }
}
</style>
