var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.data
    ? _c("section", { staticClass: "news-featured-story mb-8 p-6 lg:p-8" }, [
        _c("div", { staticClass: "md:flex" }, [
          _c(
            "div",
            { staticClass: "md:w-1/3" },
            [
              _vm.data.src
                ? _c("base-image", {
                    attrs: {
                      src: _vm.data.src,
                      retina_src: _vm.data.retinaSrc,
                      alt: _vm.data.alt
                    }
                  })
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "mt-6 md:mt-0 md:w-2/3 md:pl-10" },
            [
              _c(
                "base-heading",
                { attrs: { size: "h4", color: "red", uppercase: true } },
                [_vm._v("Feature Story")]
              ),
              _vm._v(" "),
              _c(
                "base-heading",
                {
                  attrs: {
                    size: "h2",
                    color: "brown",
                    url: _vm.data.url,
                    class_names: "mt-3"
                  }
                },
                [_vm._v(_vm._s(_vm.data.title))]
              ),
              _vm._v(" "),
              _vm.data.summary
                ? _c("base-rich-text", {
                    attrs: {
                      size: "small",
                      link_style: "red",
                      class_names: "mt-3"
                    },
                    domProps: { innerHTML: _vm._s(_vm.data.summary) }
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "mt-4" }, [
                _c(
                  "a",
                  {
                    staticClass:
                      "italic text-brown text-base tr-color hover:text-brown-hover hover:underline ",
                    attrs: { href: _vm.data.url }
                  },
                  [_vm._v("Read more")]
                )
              ])
            ],
            1
          )
        ])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }