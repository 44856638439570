<template>
  <div>
    <header :class="['site-header print-hidden', `${header_style}-style`]">
      <div :class="['site-header__top hidden  lg:block', header_style === 'light' ? 'bg-white' : 'bg-brown', text_color]">
        <utility-nav></utility-nav>
      </div>

      <div class="site-header__main">
        <div :class="['relative pt-5-safe pb-5', header_style === 'dark' ? 'lg:pt-5-safe' : 'lg:pt-1-safe']">
          <div class="bg-white">
            <div class="relative z-40">
              <div class="relative container mx-auto px-5-safe flex justify-between items-center lg:items-end">
                <!-- the logo -->
                <div>
                  <a :href="logo_url">
                    <img
                      :src="header_style === 'light' ? logo_dark_src : logo_light_src"
                      :alt="logo_alt"
                      :width="logo_width"
                      class="site-logo m-0 block h-12 lg:h-14"
                    />
                  </a>
                </div>

                <!-- open mobile menu -->
                <div class="-mr-2 -my-2 lg:hidden">
                  <button
                    type="button"
                    class="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100"
                    @click.stop.prevent="toggleMobileMenu()"
                  >
                    <span class="sr-only">Open menu</span>
                    <svg
                      class="h-12 w-12"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
                    </svg>
                  </button>
                </div>

                <!-- The main menu -->
                <div class="hidden lg:flex md:items-center md:justify-between">
                  <nav class="flex font-bold text-xl xl:text-hxl text-brown">
                    <div v-for="item_l1 in main_nav" class="ml-7">
                      <template v-if="item_l1.depth === 1">
                        <a :href="item_l1.url" :target="item_l1.target" class="top-link">
                          <span class="text relative">{{ item_l1.text }}</span>
                        </a>
                      </template>

                      <template v-else>
                        <button type="button" class="top-link font-bold" @click.stop.prevent="toggleDropdown(item_l1.text)">
                          <span class="text relative">
                            {{ item_l1.text }}
                          </span>

                          <base-icon
                            icon-name="angle-down"
                            width="14"
                            height="7"
                            viewbox="0 0 17 9"
                            v-show="item_l1.children.length > 0"
                            :class="['angle inline', item_l1.text === activeMenu ? 'rotated' : '']"
                          >
                            <icon-angle-down></icon-angle-down>
                          </base-icon>
                        </button>

                        <div :class="[
                          'absolute hidden z-10 text-white font-regular font-base mt-4 items-center justify-center ',
                          item_l1.depth !== 2 && item_l1.depth ? 'w-full transform-center left-center' : '',
                          item_l1.ctas.left || item_l1.ctas.right ? 'inset-x-0' : '',
                          item_l1.text === activeMenu ? 'md:flex' : ''
                        ]" v-on-clickaway="closeDropdown">
                          <div class="bg-red flex flex-col w-full px-6 pt-10">
                            <!-- left CTA -->
                            <div v-if="item_l1.ctas.left" class="w-p24 flex-none px-5-safe py-10 flex justify-end">
                              <header-cta :cta="item_l1.ctas.left"></header-cta>
                            </div>

                            <!-- links -->
                            <template v-if="item_l1.depth <= 3">
                              <a :href="item_l1.url" :target="item_l1.target" class="font-bold text-3xl hover:text-brown">
                              {{ item_l1.text }}

                              <base-icon icon-name="angle-right" width="10" height="12" viewbox="0 0 9 17" class="angle inline">
                                <icon-angle-right></icon-angle-right>
                              </base-icon>
                            </a>
                            </template>

                            <div :class="['flex', item_l1.depth >= 4 ? 'flex-col pb-8' : '']">
                              <template v-if="item_l1.depth >= 4">
                                <div v-for="item_l2 in item_l1.children" class="flex flex-wrap">
                                  <a :href="item_l2.url" :target="item_l2.target" class="w-full px-5-safe font-bold text-3xl hover:text-brown" :class="item_l2.text == 'Accessories' ? 'pt-2 pb-4' : ''">
                                    {{ item_l2.text }}

                                    <base-icon icon-name="angle-right" width="10" height="12" viewbox="0 0 9 17" class="angle inline">
                                      <icon-angle-right></icon-angle-right>
                                    </base-icon>
                                  </a>
                                  <ul class="menu-list flex-grow px-5-safe py-4" v-for="item_l3 in item_l2.children" :class="item_l3.text == 'Reconyx Professional Cameras' || item_l3.text == 'Reconyx Economy Cameras'? 'reconyx-level' : ''">
                                    <template>
                                        <li class="font-bold py-2">
                                          {{ item_l3.text }}
                                        </li>
                                        <div>
                                          <template v-for="item_l4 in item_l3.children">
                                            <li class="hover:bg-red-hover recon-l4">
                                              <a :href="item_l4.url" :target="item_l4.target" class="flex w-full py-2">
                                                {{ item_l4.text }}
                                              </a>
                                            </li>
                                            <template v-for="item_l5 in item_l4.children">
                                              <li class="hover:bg-red-hover">
                                                <a :href="item_l5.url" :target="item_l5.target" class="flex w-full pl-8 py-2">
                                                  {{ item_l5.text }}
                                                </a>
                                              </li>
                                            </template>
                                          </template>
                                        </div>
                                      </template>
                                    </ul>
                                </div>
                              </template>

                              <template v-if="item_l1.depth === 3">
                                <div class="flex flex-wrap">
                                  <ul class="menu-list px-5-safe py-4" v-for="item_l2 in item_l1.children">
                                    <li class="font-bold py-2">
                                      {{ item_l2.text }}
                                    </li>

                                    <template v-for="item_l3 in item_l2.children">
                                      <li class="hover:bg-red-hover">
                                        <a :href="item_l3.url" :target="item_l3.target" class="flex w-full py-2">
                                          {{ item_l3.text }}
                                        </a>
                                      </li>

                                      <li v-for="item_l4 in item_l3.children" class="hover:bg-red-hover">
                                        <a :href="item_l4.url" :target="item_l4.target" class="flex w-full pl-8 py-2">
                                          {{ item_l4.text }}
                                        </a>
                                      </li>
                                    </template>
                                  </ul>
                                </div>
                              </template>

                              <template v-if="item_l1.depth === 2">
                                <div class="flex w-full flex-wrap">
                                  <ul class="menu-list w-full py-4">
                                    <li v-for="item_l2 in item_l1.children" class="hover:bg-red-hover">
                                      <a :href="item_l2.url" :target="item_l2.target" class="flex w-full py-2">{{ item_l2.text }}</a>
                                    </li>
                                  </ul>
                                </div>
                              </template>
                            </div>

                            <!-- right CTA -->
                            <div v-if="item_l1.ctas.right" class="hidden xl:block w-p24 flex-none px-10-safe py-10 bg-brown">
                              <header-cta :cta="item_l1.ctas.right"></header-cta>
                            </div>
                          </div>
                        </div>
                      </template>
                    </div>
                  </nav>
                </div>
              </div>
            </div>

            <!-- mobile menu -->
            <div
              :class="[
                'absolute z-40 top-0 inset-x-0 p-2 transition transform origin-top-right lg:hidden',
                mobileMenuActive ? '' : 'hidden'
              ]"
              v-on-clickaway="closeMobileMenu"
            >
              <div class="shadow bg-white">
                <div>
                  <!-- close button -->
                  <div class="flex items-center justify-end pt-5 pr-5">
                    <div class="-mr-2">
                      <button
                        type="button"
                        class="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100"
                        @click.stop.prevent="toggleMobileMenu()"
                      >
                        <span class="sr-only">Close menu</span>
                        <!-- Heroicon name: outline/x -->
                        <svg
                          class="h-12 w-12"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          aria-hidden="true"
                        >
                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                      </button>
                    </div>
                  </div>

                  <div>
                    <nav>
                      <ul class="mt-2">
                        <template v-for="item in main_nav">
                          <template v-if="item.depth === 1">
                            <li>
                              <a :href="item.url" class="block leading-loosest py-2 px-8 text-lg font-bold">{{ item.text }}</a>
                            </li>
                          </template>

                          <template v-else="item.depth === 2">
                            <li>
                              <button
                                type="button"
                                class="block leading-loosest py-2 px-8 text-lg font-bold w-full text-left"
                                @click.stop.prevent="toggleMobileDropdown(item.text)"
                              >
                                {{ item.text }}

                                <base-icon
                                  icon-name="angle-down"
                                  width="14"
                                  height="7"
                                  viewbox="0 0 17 9"
                                  v-show="item.children.length > 0"
                                  :class="['angle inline', item.text === activeMobileMenu ? 'rotated' : '']"
                                >
                                  <icon-angle-down></icon-angle-down>
                                </base-icon>
                              </button>

                              <ul v-show="item.text === activeMobileMenu">
                                <template v-if="item.depth === 2">
                                  <li>
                                    <a :href="item.url" class="block leading-loosest py-2 px-8 text-lg">
                                      {{ item.text }}
                                      <base-icon icon-name="angle-right" width="10" height="12" viewbox="0 0 9 17" class="angle inline">
                                        <icon-angle-right></icon-angle-right>
                                      </base-icon>
                                    </a>
                                  </li>
                                </template>
                                <template v-for="item_l2 in item.children">
                                  <li>
                                    <a :href="item_l2.url" class="block leading-loosest py-2 px-8 text-lg">{{ item_l2.text }}
                                      <base-icon icon-name="angle-right" width="10" height="12" viewbox="0 0 9 17" class="angle inline" v-if="item.depth >=3">
                                        <icon-angle-right></icon-angle-right>
                                      </base-icon>
                                    </a>
                                  </li>
                                  <template v-for="item_l3 in item_l2.children">
                                    <li>
                                      <a :href="item_l3.url" class="block leading-loosest py-2 px-8 text-lg">{{ item_l3.text }}</a>
                                    </li>
                                    <template v-for="item_l4 in item_l3.children">
                                      <li>
                                        <a :href="item_l4.url" class="block leading-loosest py-2 px-16 text-lg">{{ item_l4.text }}</a>
                                      </li>
                                    </template>
                                  </template>
                                </template>
                              </ul>
                            </li>
                          </template>
                        </template>

                        <template v-for="item in utility_nav">
                          <li v-show="item.display.value !== 'desktopOnly'">
                            <a
                              :href="item.url"
                              :class="['block leading-loosest py-2 px-8 text-lg', item.navHighlight ? 'bg-red text-white' : '']"
                            >
                              {{ item.mobileText !== "" && item.mobileText !== null ? item.mobileText : item.text }}
                            </a>
                          </li>
                        </template>
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  </div>
</template>

<script>
import { mixin as clickaway } from "vue-clickaway";

// @group Headers
export default {
  props: ["header_style", "text_color", "logo_url", "logo_dark_src", "logo_light_src", "main_nav", "utility_nav", "logo_alt", "logo_width"],

  data: function() {
    return {
      activeMenu: null,
      mobileMenuActive: false,
      activeMobileMenu: null
    };
  },

  components: {
    HeaderCta: () => import(/* webpackChunkName: "header-cta" */ "./HeaderCta.vue"),
    UtilityNav: () => import(/* webpackChunkName: "utility-nav" */ "../nav/UtilityNav.vue"),
    BaseIcon: () => import(/* webpackChunkName: "base-icon" */ "../BaseIcon.vue"),
    IconAngleDown: () => import(/* webpackChunkName: "icon-angle-down" */ "../../icons/IconAngleDown.vue"),
    IconAngleRight: () => import(/* webpackChunkName: "icon-angle-right" */ "../../icons/IconAngleRight.vue")
  },

  methods: {
    toggleDropdown(text) {
      if (this.activeMenu === text) {
        this.activeMenu = null;
      } else {
        this.activeMenu = text;
      }
    },

    closeDropdown() {
      this.activeMenu = null;
    },

    toggleMobileDropdown(text) {
      if (this.activeMobileMenu === text) {
        this.activeMobileMenu = null;
      } else {
        this.activeMobileMenu = text;
      }
    },

    toggleMobileMenu() {
      // close all drop-downs
      this.activeMenu = null;
      this.activeMobileMenu = null;

      this.mobileMenuActive = !this.mobileMenuActive;
    },

    closeMobileMenu() {
      this.mobileMenuActive = false;
    }
  },

  mixins: [clickaway]
};
</script>

<style>
.menu-list li {
  border-bottom: 1px solid white;
}

.menu-list li:last-child {
  border-bottom: none;
}

.angle.rotated {
  transform: rotate(180deg);
}

.top-link {
  .text:after {
    content: "";
    height: 3px;
    background-color: #e1251b;
    position: absolute;
    bottom: -5px;
    left: 0;
    right: 100%;
    opacity: 0;
    transition-delay: 0.15s;
    transition-property: left right;
    transition-duration: 0.25s;
    transition-timing-function: ease-out;
  }

  &:hover {
    .text:after {
      right: 0;
      opacity: 1;
    }
  }
}
</style>
