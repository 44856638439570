<template>
  <div class="site-search">
    <div class="bg-brown">
      <div class="container mx-auto px-5-safe py-6 lg:py-8 xl:py-10 text-white">
        <h1 class="font-bold text-4xl lg:text-5xl xl:text-6xl uppercase">{{ pageTitle }}</h1>
      </div>
    </div>
    <div class="container px-5-safe mx-auto py-10" id="search-top">
      <search-bar :keyword="keyword" @updateResults="setKeyword" placeholder="Search our site"></search-bar>
      <div v-if="ajaxError" class="mt-10 text-red text-lg" v-html="ajaxError"></div>
      <div :class="[isLoading ? 'opacity-25' : '']">
        <results-info :total="total" :current_page="currentPage" :limit="limit" :keyword="keyword" class_names="mt-10"></results-info>
        <div class="mt-8 text-lg text-links">
          <search-item
            v-for="(item, i) in items"
            :key="i"
            :item="item"
            :class_names="[i > 0 ? 'border-t border-brown mt-5 pt-5' : '']"
          >
          </search-item>
        </div>
        <div class="mt-6">
          <base-pagination
            :max_visible_buttons="numPages >= 3 ? 3 : parseInt(numPages)"
            :per_page="parseInt(limit)"
            :num_pages="parseInt(numPages)"
            :current_page="currentPage"
            :total="parseInt(total)"
            :show_prev_next_btns="true"
            :pagination_disabled="isLoading"
            @pagechanged="onPageChange"
          ></base-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import VueAxios from "../../js/modules/vue-axios";
import VueScrollTo from "vue-scrollto";
import HelpersMixin from "../mixins/Helpers";

Vue.use(VueScrollTo);

export default {
  data: function() {
    return {
      isLoading: false,
      pageTitle: "Search",
      items: null,
      numPages: null,
      currentPage: 1,
      total: null,
      ajaxError: "",
      keyword: ""
    };
  },
  props: ["keyword_from_url", "current_page", "limit"],
  components: {
    SearchBar: () => import(/* webpackChunkName: "search-bar" */ "../components/SearchBar.vue"),
    SearchItem: () => import(/* webpackChunkName: "search-item" */ "./SearchItem.vue"),
    BasePagination: () => import(/* webpackChunkName: "base-pagination" */ "../components/BasePagination.vue"),
    ResultsInfo: () => import(/* webpackChunkName: "results-info" */ "../components/ResultsInfo.vue")
  },
  mixins: [HelpersMixin],
  methods: {
    setKeyword(keyword) {
      if (keyword !== undefined) {
        this.keyword = keyword;
      }
      this.currentPage = 1;
      this.updateResults();
    },
    updateResults() {
      let self = this,
        selectedCategories = [],
        ajaxUrl = "/actions/site-module/site/search?limit=" + this.limit + "&page=" + this.currentPage;
      if (self.keyword !== "") {
        ajaxUrl += "&kw=" + encodeURIComponent(this.keyword);
      }
      self.isLoading = true;
      VueScrollTo.scrollTo("#search-top", 350, {
        offset: 5
      });
      self.ajaxError = "";
      VueAxios.get(ajaxUrl)
        .then(response => {
          self.isLoading = false;
          self.updateUrl();
          var data = response.data || null;
          if (data !== undefined) {
            self.items = data.items || null;
            self.numPages = data.numPages || null;
            self.total = data.total || null;
          } else {
            self.ajaxError = "An error occurred. Please try again.";
          }
        })
        .catch(error => {
          self.ajaxError = "An error occurred. Please try again.";
          self.isLoading = false;
        });
    },
    onPageChange(new_current_page) {
      this.currentPage = new_current_page;
      this.updateResults();
    },
    updateUrl() {
      let url = "/search";
      if (this.currentPage > 1) {
        url += "/p" + this.currentPage;
      }
      url += "?keyword=" + encodeURIComponent(this.keyword).replace(/%20/g, "+");
      this.setUrl("events", document.title, url);
    }
  },
  mounted() {
    if (this.keyword_from_url !== "") {
      this.keyword = this.keyword_from_url;
    }
    if (this.current_page !== "") {
      this.currentPage = parseInt(this.current_page);
    }
    this.updateResults();
  }
};
</script>
