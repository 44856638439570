<template>
  <div class='border-b border-brown'>
    <header
      @click='toggle'
      class='cursor-pointer font-bold pl-2 pr-12 py-2 relative select-none text-green text-xl xl:text-2xl uppercase w-full uppercase'
      :class="{ open: opened }"
    >{{ heading }}</header>

    <div class='px-2' v-if='opened'>
      <slot name='details'></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: ['heading'],

  data: function() {
    return {
      opened: false
    };
  },

  methods: {
    toggle: function() {
      this.opened = !this.opened;
    },
  }
};
</script>

<style scoped>
header:after {
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  position: absolute;
  right: 0.5rem;
  top: 50%;
  margin-top: -4px;
}

header:not(.open):after {
  border-width: 8px 8px 0;
  border-color: #4E5B31 transparent transparent;
}

header.open:after {
  border-width: 0 8px 8px;
  border-color: transparent transparent #4E5B31;
}
</style>
