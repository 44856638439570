<template>
  <div
    class="product-important-notice rich-text text-xl text-green text-center bg-white border border-brown py-4 px-5 leading-normal"
    v-html="block.text"
  ></div>
</template>

<script>
export default {
  props: ["block"]
};
</script>
