<template>
  <div :class="['customer-story-item', class_names]">
    <div class="text-center">
      <thumbnail-image-with-overlay
        :src="item.src ? item.src : '/img/customer-story-thumbnail-placeholder.png'"
        :retina_src="item.retinaSrc ? item.retinaSrc : '/img/customer-story-thumbnail-placeholder@2x.png'"
        :alt="item.alt"
        :url="item.url"
        text="Read Customer Story"
        :class_names="['mb-3 mx-auto text-center', !item.src ? 'shadow' : '']"
      ></thumbnail-image-with-overlay>
    </div>
    <base-heading size="h4" color="brown-lighter" :uppercase="true">{{ item.location }}</base-heading>
    <div class="italic mt-1 text-sm text-brown">{{ item.date }}</div>
    <base-heading size="h2" color="brown" hover_color="red" class_names="mt-1" :url="item.url">{{ item.title }}</base-heading>
  </div>
</template>

<script>
export default {
  props: ["item", "class_names"],
  components: {
    CustomerStoryItem: () => import(/* webpackChunkName: "customer-story-item" */ "./CustomerStoryItem.vue"),
    BaseHeading: () => import(/* webpackChunkName: "base-heading" */ "../components/BaseHeading.vue"),
    ThumbnailImageWithOverlay: () =>
      import(/* webpackChunkName: "thumbnail-image-with-overlay" */ "../components/ThumbnailImageWithOverlay.vue")
  }
};
</script>
