<template>
  <tr v-if="isOpen" class="bg-gray-100">
    <td colspan="4" class="bg-brown-lightest py-4 px-6">
      <table class="w-full">
        <thead>
        <tr class="text-left text-green">
          <th>Microphone</th>
          <th>Last Status</th>
          <th>WAV Files</th>
          <th>ZC Files</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(d, subIndex) in item.status.dev" :key="subIndex">
          <td>{{ d.entry.prefix }}</td>
          <td>{{ d.entry.status }}</td>
          <td>{{ d.entry.nwav }}</td>
          <td>{{ d.entry.nzc }}</td>
        </tr>
        </tbody>
      </table>
    </td>
  </tr>
</template>

<script>
export default {
  name: 'smart-system-device',
  props: {
    item: Object,
    isOpen: Boolean,
  },
};
</script>
