<template>
  <section :class="['sidebar-audio sidebar-block max-w-sm mx-auto', !is_first ? 'mt-4' : '']">
    <audio controls v-if="block.showAudioPlayer" class="max-w-full">
      <source :src="block.file" type="audio/mpeg" />
      Your browser does not support the audio tag.
    </audio>
    <div v-if="block.showDownloadLink" :class="block.showAudioPlayer ? 'mt-4' : ''">
      <a :href="block.file" target="_blank" class="download-link block relative pl-10 text-lg text-brown tr-color hover:text-red">
        <base-icon icon-name="checkmark" width="24" height="24" viewbox="0 0 31 31" class="absolute left-0 top-0">
          <icon-download></icon-download>
        </base-icon>
        {{ block.downloadLinkText }}
      </a>
    </div>
  </section>
</template>

<script>
export default {
  props: ["block", "is_first"],
  components: {
    BaseIcon: () => import(/* webpackChunkName: "base-icon" */ "../components/BaseIcon.vue"),
    IconDownload: () => import(/* webpackChunkName: "icon-download" */ "../icons/IconDownload.vue")
  }
};
</script>

<style scoped>
.download-link {
  display: block;
  min-height: 24px;
  line-height: 24px;
}
</style>
