<template>
  <div class="expandable-categories" v-if="categories.length > 0">
    <div :class="[page === 'store' ? 'h-10' : '', page === 'cart' ? 'flex items-center mb-5' : '']">
      <div v-if="page === 'cart'" :class="[page === 'cart' ? 'w-1/2' : '']">
        <base-heading size="h1" color="brown">Optional Add-Ons</base-heading>
      </div>
      <div :class="[page === 'cart' ? 'w-1/2 text-right' : '']">
        <button type="button" class="underline tr-color text-brown hover:text-red" @click="toggleAllSections(true)">
          Expand All
        </button>
        <button type="button" class="underline tr-color text-brown hover:text-red ml-4" @click="toggleAllSections(false)">
          Collapse All
        </button>
      </div>
    </div>
    <div
      v-for="(category, c) in categories"
      :key="c"
      :data-category-id="c"
      :id="category.categoryName.replace(/\s+/g, '-').toLowerCase()"
      class="mb-5"
      v-if="category.products.length > 0"
    >
      <button
        type="button"
        @click="category.active = !category.active"
        :class="[
          'relative text-2xl xl:text-3xl font-bold tr-bg tr-color block border-2 border-brown w-full text-left pl-6 xl:pl-7 pr-11 py-2',
          category.active ? 'active bg-grey-lighter text-brown uppercase shadow-sm' : 'bg-brown text-white'
        ]"
      >
        <span class="inline-flex items-center">
          <span>{{ category.categoryName }}</span>
          <span class="font-regular text-2xl opacity-50 ml-3">({{ category.products.length }})</span>
        </span>
        <span class="arrow absolute centered-y right-0 mr-6"></span>
      </button>
      <slide-up-down :active="category.active" :duration="350" class="px-2">
        <div class="bg-grey-lighter border-l-2 border-b-2 border-r-2 border-brown p-4 xl:px-6 xl:py-5">
          <expandable-category-product
            v-for="(product, p) in category.products"
            :key="p"
            :product="product"
            :is_disabled="is_disabled"
            :is_first="p === 0"
            v-on:zoom-image="openImageModal"
          >
            <template slot="bulk_prices_text">
              <slot name="bulk_prices_text"></slot>
            </template>
          </expandable-category-product>
        </div>
      </slide-up-down>
    </div>
    <zoomable-image-modal
      v-on:close="closeImageModal"
      :show="modalImage.show"
      :src="modalImage.src"
      :srcZoom="modalImage.srcZoom"
    ></zoomable-image-modal>
  </div>
</template>

<script>
export default {
  props: ["page", "is_disabled"],
  data: function() {
    return {
      categories: window.productCategories,
      modalImage: {
        show: false,
        src: null,
        srcZoom: null
      }
    };
  },
  components: {
    BaseHeading: () => import(/* webpackChunkName: "base-heading" */ "../components/BaseHeading.vue"),
    ExpandableCategoryProduct: () => import(/* webpackChunkName: "expandable-category-product" */ "./ExpandableCategoryProduct.vue"),
    ZoomableImageModal: () => import(/* webpackChunkName: "zoomable-image-modal" */ "../_new/ZoomableImageModal.vue")
  },
  methods: {
    toggleAllSections(active) {
      this.categories.forEach(function(category) {
        category.active = active;
      });
    },
    openImageModal(data) {
      const { src, srcZoom } = data;

      if (src && src.length > 0 && srcZoom && srcZoom.length > 0) {
        this.modalImage = {
          show: true,
          src,
          srcZoom
        };
      }
    },
    closeImageModal() {
      this.modalImage = {
        show: false,
        src: null,
        srcZoom: null
      };
    }
  },
  mounted() {
    let self = this;

    // Open detail section based on URL hash
    let hash = window.location.hash;
    hash = hash.substring(1);
    const el = document.getElementById(hash);

    if (!el) return;

    self.categories.forEach(category => {
      category.active = false;
    });

    self.categories[el.dataset.categoryId].active = true;
  }
};
</script>

<style scoped>
button {
  .arrow {
    width: 0;
    height: 0;
    border-style: solid;
  }
  &.active {
    .arrow {
      border-width: 0 9px 8px 9px;
      border-color: transparent transparent #4b3d2a transparent;
    }
  }
  &:not(.active) {
    .arrow {
      border-width: 8px 9px 0 9px;
      border-color: #fff transparent transparent transparent;
    }
  }
}
</style>
