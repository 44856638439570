<template>
  <div class="site-footer-news-events">
    <base-heading size="h3" :uppercase="true" color="white">
      {{ $parent.middle_column_heading }}
    </base-heading>
    <div class="footer-news mt-6">
      <site-footer-news-events-item
        v-for="(item, i) in items"
        :key="i"
        :item="item"
        :itemType="$parent.middle_column_type === 'latestBlogEntries' ? 'news' : 'event'"
      ></site-footer-news-events-item>
    </div>
  </div>
</template>

<script>
export default {
  data: function() {
    return {
      items: this.$parent.middle_column_items
    };
  },
  components: {
    BaseHeading: () => import(/* webpackChunkName: "base-heading" */ "../BaseHeading.vue"),
    SiteFooterNewsEventsItem: () => import(/* webpackChunkName: "site-footer-news-events-item" */ "./SiteFooterNewsEventsItem.vue")
  }
};
</script>
