var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: ["input-product-quantity w-12", _vm.class_names] },
    [
      _c(
        "div",
        { staticClass: "relative" },
        [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.localQty,
                expression: "localQty"
              }
            ],
            class: [
              "w-12 rounded-t text-center bg-white leading-tight",
              _vm.is_loading
                ? "text-white"
                : _vm.is_disabled
                ? "text-grey"
                : "text-black",
              _vm.border_color !== undefined
                ? "border-l border-t border-r " +
                  (_vm.is_disabled || _vm.is_loading
                    ? "border-grey"
                    : "border-" + _vm.border_color)
                : ""
            ],
            attrs: {
              type: "number",
              "aria-label": "Quantity",
              pattern: "[0-9]",
              onkeypress: "return event.charCode >= 48 && event.charCode <= 57",
              step: "1",
              min: "0"
            },
            domProps: { value: _vm.localQty },
            on: {
              keyup: function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.keyEnter()
              },
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.localQty = $event.target.value
              }
            }
          }),
          _vm._v(" "),
          _c(
            "base-icon",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.is_loading,
                  expression: "is_loading"
                }
              ],
              staticClass: "centered text-grey",
              attrs: {
                "icon-name": "loading",
                width: "24",
                height: "24",
                viewbox: "0 0 32 32"
              }
            },
            [_c("icon-loading")],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "flex" }, [
        _c("div", { staticClass: "w-1/2" }, [
          _c(
            "button",
            {
              class: [
                "inline-block w-full cursor-pointer text-xl tr-bg text-white text-sm rounded-bl p-0 font-bold uppercase leading-tight",
                _vm.is_disabled || _vm.is_loading
                  ? "bg-grey"
                  : "bg-brown hover:bg-red"
              ],
              attrs: { type: "button", disabled: _vm.is_disabled },
              on: {
                click: function($event) {
                  _vm.localQty++
                }
              }
            },
            [_vm._v("\n        +\n      ")]
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "w-1/2" }, [
          _c(
            "button",
            {
              class: [
                "inline-block w-full border-l cursor-pointer text-xl tr-bg text-white text-sm rounded-br p-0 font-bold uppercase leading-tight",
                _vm.is_disabled || _vm.is_loading || _vm.localQty === 0
                  ? "bg-grey"
                  : "bg-brown hover:bg-red",
                "border-" + _vm.bg_color
              ],
              attrs: {
                type: "button",
                disabled: _vm.is_disabled || _vm.localQty === 0
              },
              on: {
                click: function($event) {
                  _vm.localQty > 0 ? _vm.localQty-- : _vm.localQty
                }
              }
            },
            [_vm._v("\n        –\n      ")]
          )
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }