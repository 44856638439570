<template>
  <section :class="['sidebar-buttons sidebar-block max-w-sm mx-auto', !is_first ? 'mt-4' : '']" v-if="block.buttons">
    <div v-show="block.buttons.length > 0" :class="[block.buttons.length > 1 ? 'mt-5' : '']">
      <div v-for="(button, b) in block.buttons" :key="b" :class="b > 0 ? 'mt-3' : ''">
        <base-button
          :url="button.url"
          :label="button.text"
          :target="button.target"
          :color="button.color"
          :outline="button.outlined"
          py="2"
          class_names="w-full"
        ></base-button>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: ["block", "is_first"],
  components: {
    BaseButton: () => import(/* webpackChunkName: "base-button" */ "../components/BaseButton.vue")
  }
};
</script>
