<template>
  <div v-if="show" class="fixed top-0 left-0 w-full h-full wrapper">

    <!-- Dark overlay -->
    <div @click="close"
         class="overlay bg-black absolute top-0 left-0 w-full h-full opacity-50"></div>

    <div class="z-20 bg-white shadow-sm w-full centered address-verification-modal">

      <!-- Heading -->
      <div class="bg-green text-white px-6 py-4 text-lg font-bold relative">
        Help us verify your address

        <!-- Close button -->
        <button @click="close"
                class="text-white tr-opacity hover:opacity-50 float-right">
            <base-icon icon-name="close" width="18" height="18" viewbox="0 0 23 23">
                <icon-x></icon-x>
            </base-icon>
        </button>

      </div>

      <!-- Content -->
      <div class="px-6 pt-4 pb-6">
        <div class="rich-text mb-4">
          <p>We noticed that the address you provided might not be accurate</p>
          <p class="font-bold">Please select an option to proceed</p>
        </div>

        <div class="mt-5 flex flex-col md:flex-row md:justify-between">
          <div class="p-2 flex-1 border-r-4 border-white flex flex-col bg-grey-lightest">
            <div class="text-sm font-bold text-center pt-2">Original Address</div>
            <div v-if="original" class="flex flex-col pt-4">
              <div class="flex flex-row">
                <div class="py-2 w-1/2">
                  <span class="p-1">Address 1:</span>
                </div>
                <div class="py-2 w-1/2">
                  <span class="p-1">{{ original.addr1 ? original.addr1 : '' }}</span>
                </div>
              </div>
              <div class="flex flex-row">
                <div class="py-2 w-1/2">
                  <span class="p-1">Address 2:</span>
                </div>
                <div class="py-2 w-1/2">
                  <span class="p-1">{{ original.addr2 ? original.addr2 : '' }}</span>
                </div>
              </div>
              <div class="flex flex-row">
                <div class="py-2 w-1/2">
                  <span class="p-1">City:</span>
                </div>
                <div class="py-2 w-1/2">
                  <span class="p-1">{{ original.city ? original.city : '' }}</span>
                </div>
              </div>
              <div class="flex flex-row">
                <div class="py-2 w-1/2">
                  <span class="p-1">State/Province:</span>
                </div>
                <div class="py-2 w-1/2">
                  <span class="p-1">{{ original.state ? original.state : '' }}</span>
                </div>
              </div>
              <div class="flex flex-row">
                <div class="py-2 w-1/2">
                  <span class="p-1">Zip/Postal Code:</span>
                </div>
                <div class="py-2 w-1/2">
                  <span class="p-1">{{ original.zip ? original.zip : '' }}</span>
                </div>
              </div>
              <div class="flex flex-row">
                <div class="py-2 w-1/2">
                  <span class="p-1">Country</span>
                </div>
                <div class="py-2 w-1/2">
                  <span class="p-1">{{ original.country ? original.country : '' }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="p-2 flex-1 border-r-4 border-white flex flex-col bg-grey-lightest">
            <div class="text-sm font-bold text-center pt-2">Suggested Address</div>
            <div v-if="original" class="flex flex-col pt-4">
              <div class="flex flex-row">
                <div class="py-2 w-1/2">
                  <span class="p-1">Address 1:</span>
                </div>
                <div class="py-2 w-1/2">
                  <span :class="`p-1 ${original.addr1 !== suggested.addr1 ? 'text-red' : ''}`">{{ suggested.addr1 ? suggested.addr1 : '' }}</span>
                </div>
              </div>
              <div class="flex flex-row">
                <div class="py-2 w-1/2">
                  <span class="p-1">Address 2:</span>
                </div>
                <div class="py-2 w-1/2">
                  <span :class="`p-1 ${original.addr2 !== suggested.addr2 ? 'text-red' : ''}`">{{ suggested.addr2 ? suggested.addr2 : '' }}</span>
                </div>
              </div>
              <div class="flex flex-row">
                <div class="py-2 w-1/2">
                  <span class="p-1">City:</span>
                </div>
                <div class="py-2 w-1/2">
                  <span :class="`p-1 ${original.city !== suggested.city ? 'text-red' : ''}`">{{ suggested.city ? suggested.city : '' }}</span>
                </div>
              </div>
              <div class="flex flex-row">
                <div class="py-2 w-1/2">
                  <span class="p-1">State/Province:</span>
                </div>
                <div class="py-2 w-1/2">
                  <span :class="`p-1 ${original.state !== suggested.state ? 'text-red' : ''}`">{{ suggested.state ? suggested.state : '' }}</span>
                </div>
              </div>
              <div class="flex flex-row">
                <div class="py-2 w-1/2">
                  <span class="p-1">Zip/Postal Code:</span>
                </div>
                <div class="py-2 w-1/2">
                  <span :class="`p-1 ${original.zip !== suggested.zip ? 'text-red' : ''}`">{{ suggested.zip ? suggested.zip : '' }}</span>
                </div>
              </div>
              <div class="flex flex-row">
                <div class="py-2 w-1/2">
                  <span class="p-1">Country</span>
                </div>
                <div class="py-2 w-1/2">
                  <span :class="`p-1 ${original.country !== suggested.country ? 'text-red' : ''}`">{{ suggested.country ? suggested.country : '' }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div class="flex-1 flex flex-row">
            <div class="p-2 w-1/2">
              <button @click="e => selectAddressHandler(e, 'original')" class="relative loading-btn bg-brown-lighter text-base inline-block font-bold rounded-lg text-center h-12 w-full uppercase text-white tr-color tr-bg hover:text-white hover:bg-green">
                  <span class="inline">Use original address</span>
              </button>
            </div>
            <div class="p-2 w-1/2">
              <button @click="e => selectAddressHandler(e, 'suggested')" class="relative loading-btn bg-brown-lighter text-base inline-block font-bold rounded-lg text-center h-12 w-full uppercase text-white tr-color tr-bg hover:text-white hover:bg-green">
                  <span class="inline">Use suggested address</span>
              </button>
            </div>
          </div>
        </div>
        <div class="flex pt-4">
          <button @click="close" class="relative loading-btn bg-white text-sm inline-block font-bold rounded-lg text-center h-12 w-full uppercase border border-brown text-brown tr-color tr-bg hover:text-white hover:bg-green">
                <span class="inline">Go back to shipping form</span>
            </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: function () {
    return {
      show: false,
      original: false,
      suggested: false,
      form: false,
      addressType: false,
      onUpdateAddress: false,
      onClose: false
    };
  },
  components: {
    BaseIcon: () => import(/* webpackChunkName: "base-icon" */ "../components/BaseIcon.vue"),
    IconX: () => import(/* webpackChunkName: "icon-x" */ "../icons/IconX.vue"),
  },
  methods: {
    close() {
      if (this.onClose) {
        this.onClose();
      }

      this.show = false;
      document.documentElement.classList.toggle('overflow-hidden', false);
    },
    selectAddressHandler(e, choice) {
      e.preventDefault();

      if (! this.onUpdateAddress) {
        this.close();
        return;
      }

      this.onUpdateAddress(choice === 'suggested' ? this.suggested : false);
      this.close();
    }
  },
  mounted() {
    let self = this;
    self.$root.$on('open-address-verification-modal', function(data) {
      self.original = data.original ?? false;
      self.suggested = data.suggested ?? false;
      self.onUpdateAddress = data.onUpdateAddress ?? false,
      self.onClose = data.onClose ?? false,
      document.documentElement.classList.toggle('overflow-hidden', true);
      self.show = true;
    });
  }
}
</script>

<style scoped>
.wrapper {
  z-index: 104;
}
.overlay {
  z-index: 105;
}
.address-verification-modal {
  z-index: 106;
}
@media (min-width: 640px) {
  .address-verification-modal {
    max-width: 600px;
  }
}
@media (max-width: 639px) {
  .address-verification-modal {
    width: calc(100% - 40px);
  }
}
</style>
