<template>
  <div class="video-tutorials-index container px-5-safe mx-auto pt-10 pb-16">
    <video-tutorials-products-nav @onselectproduct="selectProduct" @updateurl="updateUrl"></video-tutorials-products-nav>
    <div :class="['lg:flex mt-10', isLoading ? 'opacity-25' : '']">
      <div class="lg:w-1/3 lg:pr-12">
        <video-tutorials-sidebar-nav @onselectvideo="selectVideo" @updateurl="updateUrl"></video-tutorials-sidebar-nav>
      </div>
      <div class="pt-10 lg:pt-0 lg:w-2/3">
        <video-tutorial></video-tutorial>
      </div>
    </div>
  </div>
</template>

<script>
import HelpersMixin from "../mixins/Helpers";

export default {
  data: function() {
    return {
      isLoading: false,
      categories: this.data.categories,
      products: this.data.products,
      videos: this.data.videos,
      languages: this.data.languages,
      selectedProduct: this.default_product,
      selectedLanguage: this.default_language,
      selectedVideoUid: this.default_video,
      clickedLanguage: this.default_language !== "en" ? this.default_language : null,
      showVideo: null
    };
  },
  props: ["default_product", "default_language", "default_video", "data"],
  mixins: [HelpersMixin],
  components: {
    VideoTutorialsProductsNav: () => import(/* webpackChunkName: "video-tutorials-products-nav" */ "./VideoTutorialsProductsNav.vue"),
    VideoTutorialsSidebarNav: () => import(/* webpackChunkName: "video-tutorials-sidebar-nav" */ "./VideoTutorialsSidebarNav.vue"),
    VideoTutorial: () => import(/* webpackChunkName: "video-tutorial" */ "./VideoTutorial.vue")
  },
  methods: {
    selectVideo(video) {
      this.showVideo = video;
    },
    selectProduct(productSlug) {
      this.selectedProduct = productSlug;
    },
    updateUrl() {
      this.setUrl(
        "video-tutorials",
        document.title,
        "/resources/video-tutorials/" +
          this.selectedProduct +
          "/" +
          this.selectedLanguage +
          (this.showVideo !== undefined && this.showVideo ? "/" + this.showVideo.slug : "")
      );
    },
    selectFirstVideo() {
      // get first video in sidebar nav
      let firstVideo = document.getElementsByClassName("video-nav-link")[0];
      if (firstVideo !== undefined) {
        // get video uid from data attribute
        let videoUid = firstVideo.getAttribute("data-uid");
        if (videoUid) {
          // select the video
          this.selectVideoByUid(videoUid);
          this.updateUrl();
        }
      }
    },
    selectVideoByUid(uid) {
      let self = this;
      // loop through videos to find video by uid
      self.videos.forEach(function(video) {
        if (video.uid === uid) {
          // show the video
          self.showVideo = video;
        }
      });
    }
  },
  mounted: function() {
    let self = this;
    // find selected video
    if (self.selectedVideoUid) {
      self.selectVideoByUid(self.selectedVideoUid);
    }
    // update url with selected video
    self.updateUrl();
    if (typeof self.categories !== undefined) {
      // if video is selected open category with that video
      if (self.showVideo) {
        // loop through categories to find category of video
        let categoryIdOfSelectedVideo = self.showVideo.categoryId;
        self.categories.forEach(function(category) {
          if (category.id === categoryIdOfSelectedVideo) {
            if (!category.active) {
              category.active = true;
            }
          } else if (category.children.length > 0) {
            // loop through sub categories
            category.children.forEach(function(subCategory) {
              if (subCategory.id === categoryIdOfSelectedVideo) {
                // set parent category to active
                if (!category.active) {
                  category.active = true;
                }
              }
            });
          }
        });
        // if no video is selected open first category
      } else {
        if (self.categories[0] !== undefined) {
          self.categories[0].active = true;
        }
      }
    }
  }
};
</script>
