<template>
    <div>
        <div v-for="(item, i) in items" :key="`download_${$parent.selectedCategory}_${i}`"
             :id="'row-' + item.uid"
             :class="i > 0 ? 'pt-4 border-t border-grey mt-4' : ''">

            <!-- software row -->
            <div class="software-row pr-14">

                <div class="flex flex-wrap md:flex-no-wrap">

                    <!-- download icon -->
                    <div class="c-1 flex-shrink-0 flex-grow-0">
                        <base-icon icon-name="checkmark" width="30" height="30" viewbox="0 0 31 31">
                            <icon-download></icon-download>
                        </base-icon>
                    </div>
                    <div class="c-2 md:flex-grow pl-4 pr-8">

                        <!-- title -->
                        <div class="font-bold text-2xl hover:underline cursor-pointer" @click="click(item)">
                            {{ item.title }}
                        </div>

                        <!-- available for platforms -->
                        <div class="text-base mt-1 text-grey-dark" v-if="item.availableFor">
                            Available for {{ item.availableFor }}
                        </div>

                        <!-- summary -->
                        <div class="block text-lg mt-1 leading-normal" v-if="item.summary" v-html="item.summary"></div>
                    </div>

                    <!-- button -->
                    <div class="c-3 md:flex-shrink-0 md:flex-grow-0">
                        <button v-if="$parent.showPlatforms.indexOf(item.uid) === -1" @click="click(item)" class="button block md:w-full uppercase tr-all rounded text-lg xl:text-xl font-bold leading-tighter text-center cursor-pointer text-white bg-red hover:bg-red-hover px-4 py-2 xl:px-5 xl:py-3">
                            {{ item.pickPlatform ? 'Pick OS' : 'View' }}
                        </button>
                    </div>
                </div>
            </div>

            <!-- platforms -->
            <div v-if="item.pickPlatform" class="pick-platform">
                <slide-up-down :active="$parent.showPlatforms.indexOf(item.uid) >= 0" :duration="350">
                    <platforms-list
                        :items="item.downloads"
                        :uid="item.uid"
                        @click="viewDownload"
                        @close="hidePlatforms">
                    </platforms-list>
                </slide-up-down>
            </div>
        </div>
    </div>
</template>

<script>
import Vue from "vue";
import VueScrollTo from "vue-scrollto";
Vue.use(VueScrollTo);

export default {
    props: ['items'],
    components: {
      BaseIcon: () => import(/* webpackChunkName: "base-icon" */ "../components/BaseIcon.vue"),
      BaseImage: () => import(/* webpackChunkName: "base-image" */ "../components/BaseImage.vue"),
      IconDownload: () => import(/* webpackChunkName: "icon-download" */ "../icons/IconDownload.vue"),
      PlatformsList: () => import(/* webpackChunkName: "platforms-list" */ "./PlatformsList.vue")
    },
    methods: {
      click(item) {

        let self = this;

        // if software has platforms to pick
        if (item.pickPlatform) {

          // see if platforms are already shown
          if (self.$parent.showPlatforms.indexOf(item.uid) >= 0) {

            // hide platforms box
            self.hidePlatforms(item.uid);

          } else {

            // show platforms box
            self.$parent.showPlatforms.push(item.uid);
          }

          // scroll to item row
          self.$nextTick(function() {
            VueScrollTo.scrollTo("#row-" + item.uid, 350, {
              offset: -10
            });
          });

        } else {

          // no platforms? show download page
          this.viewDownload(item.downloads[0]);

        }
      },
      viewDownload(item) {
          this.$emit("selectDownload", item);
      },
      hidePlatforms(uid) {
        for (var i = 0; i < this.$parent.showPlatforms.length; i++) {
          if (this.$parent.showPlatforms[i] === uid) {
            this.$parent.showPlatforms.splice(i, 1);
          }
        }
      }
    }
}
</script>

<style scoped>
.software-row {
    min-height: 30px;
}
.c-1 {
    width: 30px;
}
@media (max-width: 767px) {
    .c-2 {
        width: calc(100% - 30px);
    }
    .c-3 {
        padding-left: 44px;
        margin-top: 12px;
        width: 100%;
        .button {
            width: 130px;
        }
    }
}
@media (min-width: 768px) {
    .c-3 {
        width: 130px;
    }
    .pick-platform {
        padding-left: 44px;
    }
}

</style>