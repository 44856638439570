var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isOpen
    ? _c("tr", { staticClass: "bg-gray-100" }, [
        _c(
          "td",
          {
            staticClass: "bg-brown-lightest py-4 px-6",
            attrs: { colspan: "4" }
          },
          [
            _c("table", { staticClass: "w-full" }, [
              _vm._m(0),
              _vm._v(" "),
              _c(
                "tbody",
                _vm._l(_vm.item.status.dev, function(d, subIndex) {
                  return _c("tr", { key: subIndex }, [
                    _c("td", [_vm._v(_vm._s(d.entry.prefix))]),
                    _vm._v(" "),
                    _c("td", [_vm._v(_vm._s(d.entry.status))]),
                    _vm._v(" "),
                    _c("td", [_vm._v(_vm._s(d.entry.nwav))]),
                    _vm._v(" "),
                    _c("td", [_vm._v(_vm._s(d.entry.nzc))])
                  ])
                }),
                0
              )
            ])
          ]
        )
      ])
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", { staticClass: "text-left text-green" }, [
        _c("th", [_vm._v("Microphone")]),
        _vm._v(" "),
        _c("th", [_vm._v("Last Status")]),
        _vm._v(" "),
        _c("th", [_vm._v("WAV Files")]),
        _vm._v(" "),
        _c("th", [_vm._v("ZC Files")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }