<template>
  <div class="store-selected-products">
    <div class="bg-brown text-white text-xl font-bold uppercase text-center px-5 py-2">
      Selected Products
    </div>
    <div class="bg-grey-lighter py-2">
      <div v-if="selectedProducts.length === 0" class="py-5 px-5 text-center italic">
        No products selected
      </div>
      <div v-for="(product, p) in selectedProducts" :key="p" :class="['px-5 py-2', p > 0 ? 'border-t border-brown' : '']">
        <div class="flex">
          <div class="flex-auto text-base leading-tight pr-4">
            {{ product.title }}
          </div>
          <div class="flex-auto text-right text-sm leading-normal">
            {{ calculatedProductPrice(product) }}<br />
            Qty: {{ product.qty }}
          </div>
        </div>
      </div>
      <div v-if="ajaxError" class="py-3 px-5 text-red text-lg" v-html="ajaxError"></div>
      <div class="py-2 px-5">
        <button
          type="submit"
          @click.prevent="addToCart()"
          :disabled="isLoading || selectedProducts.length === 0"
          :class="[
            'button relative inline-block uppercase tr-bg rounded text-xl font-bold leading-tight text-center cursor-pointer w-full py-2 px-5',
            isLoading || selectedProducts.length === 0 ? 'text-white bg-grey' : 'bg-red text-white hover:bg-red-hover',
            isLoading ? 'pl-8' : ''
          ]"
        >
          <base-icon
            icon-name="loading"
            width="28"
            height="28"
            viewbox="0 0 32 32"
            class="loading absolute ml-3 left-0 centered-y"
            v-show="isLoading"
          >
            <icon-loading></icon-loading>
          </base-icon>
          <span class="inline">Add to Cart</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import VueAxios from "../../js/modules/vue-axios";
import CartMixin from "../mixins/Cart";

export default {
  data: function() {
    return {
      isLoading: false,
      categories: window.productCategories,
      ajaxError: ""
    };
  },
  components: {
    BaseButton: () => import(/* webpackChunkName: "base-button" */ "../components/BaseButton.vue"),
    BaseIcon: () => import(/* webpackChunkName: "base-icon" */ "../components/BaseIcon.vue"),
    IconLoading: () => import(/* webpackChunkName: "icon-loading" */ "../icons/IconLoading.vue")
  },
  mixins: [CartMixin],
  methods: {
    calculatedProductPrice(product) {
      return this.formattedPrice(this.getProductPriceByQuantity(product, product.qty));
    },
    addToCart() {
      let self = this;
      self.isLoading = true;
      let formData = new FormData();
      formData.append(this.$root.csrfTokenName, this.$root.csrfTokenValue);
      self.selectedProducts.forEach(function(product) {
        if (product.qty !== "" && product.qty > 0) {
          const code = product.code.replace(/\\/g, '\\\\');
          formData.append("quantity_" + code, product.qty);
          formData.append("add_" + code, "");
        }
      });
      formData.append("action", "wildlife/shop/update");
      let options = {
        method: "POST",
        data: formData,
        url: "/"
      };
      self.ajaxError = "";
      VueAxios(options)
        .then(response => {
          let data = response.data || null,
            cart = data.cart || null;
          if (data.success && cart) {
            this.$root.cart = cart;
            window.location.href = "/shop/cart";
          } else {
            self.isLoading = false;
            self.ajaxError = "An error occurred. Please try again.";
          }
        })
        .catch(error => {
          self.isLoading = false;
          self.ajaxError = "An error occurred. Please try again.";
        });
    }
  },
  computed: {
    selectedProducts() {
      let products = [];
      this.categories.forEach(function(category) {
        if (category.products !== undefined) {
          category.products.forEach(function(product) {
            if (product.qty > 0) {
              products.push(product);
            }
          });
        }
      });
      return products;
    }
  }
};
</script>
