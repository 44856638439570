<template>
  <div>
    <table class="text-xl">
      <thead>
      <tr class="pb-1">
        <td></td>
        <td>SMART System</td>
        <td>Internal Storage</td>
        <td>Voltage</td>
        <td>Status Received <br/>(America/New York)</td>
        <td>Control Panel URL</td>
        <td>SSH Port</td>
        <td>Authorized IP</td>
        <td></td>
      </tr>
      </thead>
      <tbody>
      <template v-for="(item, index) in items">
        <smart-system
            :item="item"
            :index="index"
            :openAccordion="openAccordion"
            :userIp="userIp"
            @toggle-accordion="toggleAccordion"
            @open-modal="openModal"
        />

        <smart-system-device :item="item" :isOpen="isAccordionOpen(index)" />

        <device-modal
            v-if="isModalOpen && currentModalIndex === index"
            :isModalOpen="isModalOpen !== null"
            :userIp="userIp"
            :item="items[currentModalIndex]"
            @close-modal="closeModal"
        />
      </template>
      </tbody>
    </table>
  </div>
</template>

<script>
import SmartSystem from './SmartSystem.vue';
import SmartSystemDevice from './SmartSystemDevice.vue';
import DeviceModal from './DeviceModal.vue';

export default {
  name: 'smart-portal',
  components: {
    SmartSystem,
    SmartSystemDevice,
    DeviceModal,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
    userIp: String,
  },
  data() {
    return {
      openAccordion: null,
      isModalOpen: false,
      currentModalIndex: null,
    };
  },
  methods: {
    toggleAccordion(index) {
      this.openAccordion = this.openAccordion === index ? null : index;
    },
    isAccordionOpen(index) {
      return this.openAccordion === index;
    },
    openModal(index) {
      this.isModalOpen = true;
      this.currentModalIndex = index;
    },
    closeModal() {
      this.isModalOpen = false;
      this.currentModalIndex = null;
    },
  },
};
</script>

<style scoped>
  table thead td {
    text-transform: capitalize;
  }
</style>
