<template>
  <section class="block heading-block white-block">
    <div :class="[
            include_container ? 'container px-5-safe mx-auto' : 'full-container',
            include_container && centered ? 'max-w-2xl' : '',
            'py-8 lg:py-10 xl:py-12'
         ]">
      <base-heading :size="heading_size" :centered="centered" :color="text_color" :cursive="cursive">
        <slot></slot>
      </base-heading>
    </div>
  </section>
</template>

<script>
// @group Blocks
export default {
  props: {
    heading_size: String,
    text_color: String,
    centered: Boolean,
    cursive: Boolean,
    include_container: Boolean
  },
  components: {
    BaseHeading: () => import(/* webpackChunkName: "base-heading" */ "../components/BaseHeading.vue")
  }
};
</script>
