<template>
    <div class="container bg-grey-lighter p-6 mt-6 text-left relative">

        <!-- close button -->
        <button @click="close" class="text-grey-alt tr-color hover:text-grey-dark absolute top-0 right-0 mr-3 mt-3">
            <base-icon icon-name="close" width="18" height="18" viewbox="0 0 23 23">
                <icon-x></icon-x>
            </base-icon>
        </button>

        <!-- platform row -->
        <div v-for="(item, i) in items"
             :key="`platform_${i}`"
             v-if="item.platform.slug !== 'firmware'"
             :class="['platform-row pr-8', i > 0 ? 'pt-4 border-t border-grey mt-4' : '']">
            <div class="flex flex-wrap md:flex-no-wrap">
                <div class="d-1 flex-shrink-0 flex-grow-0">

                    <!-- platform logo -->
                    <div @click="click(item)" class="cursor-pointer">
                        <base-image
                            :src="item.platform.src"
                            :retina_src="item.platform.retina"
                            :alt="item.platform.alt"
                            class_names="block m-0"
                        ></base-image>
                    </div>
                </div>
                <div class="d-2 md:flex-grow pl-4 pr-8">

                    <!-- title -->
                    <div class="font-bold text-2xl cursor-pointer hover:underline" @click="click(item)">
                        {{ item.platform.title }}
                    </div>

                    <div class="block text-base mt-1">
                        Latest version: <strong>{{ item.version }}</strong>
                        {{ item.hasOldVersions
                            ? " (" + item.oldVersions.length + " older version" + (item.oldVersions.length > 1 ? "s" : "") + " available)"
                            : ""
                            }}
                    </div>
                    <div class="block text-lg mt-1 leading-normal">
                        {{ item.note }}
                    </div>

                </div>

                <!-- button -->
                <div class="d-3 md:flex-shrink-0 md:flex-grow-0">
                    <button @click="click(item)" class="button block w-full uppercase tr-all rounded text-lg xl:text-xl font-bold leading-tighter text-center cursor-pointer text-white bg-red hover:bg-red-hover px-4 py-2 xl:px-5 xl:py-3">
                        View
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.container:before {
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 11px 12px 11px;
    border-color: transparent transparent #eee transparent;
    position: absolute;
    top: -12px;
}
.d-1 {
    width: 50px;
}
@media (max-width: 767px) {
    .d-2 {
        width: calc(100% - 50px);
    }
    .d-3 {
        padding-left: 64px;
        margin-top: 12px;
        width: 100%;
        .button {
            width: 130px;
        }
    }
}
@media (min-width: 768px) {
    .d-3 {
        width: 130px;
    }
}
</style>

<script>
  export default {
    props: ['uid', 'items'],
    components: {
      BaseIcon: () => import(/* webpackChunkName: "base-icon" */ "../components/BaseIcon.vue"),
      BaseImage: () => import(/* webpackChunkName: "base-image" */ "../components/BaseImage.vue"),
      IconX: () => import(/* webpackChunkName: "icon-x" */ "../icons/IconX.vue"),
    },
    methods: {
      click(item) {
        this.$emit("click", item);
      },
      close() {
        this.$emit("close", this.uid);
      }
    }
  }
</script>

