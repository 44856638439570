<template>
  <div class="contact-details-page">
    <base-heading v-if="heading !== ''" size="h1" color="brown">{{ heading }}</base-heading>
    <div class="lg:flex mt-6 text-brown">
      <div class="lg:w-1/3">
        <div>
          <base-heading size="h2" color="brown">{{ office_location_heading }}</base-heading>
          <div class="text-xl xl:text-2xl leading-normal mt-2">
            <slot name="office_location"></slot>
          </div>
        </div>
        <div class="mt-8">
          <base-heading size="h2" color="brown">{{ hours_heading }}</base-heading>
          <div class="text-xl xl:text-2xl leading-normal mt-2">
            <slot name="hours"></slot>
          </div>
        </div>
        <div class="mt-8" id="contact-buttons" v-if="has_buttons">
          <slot name="buttons"></slot>
        </div>
      </div>
      <div class="mt-8 lg:mt-0 lg:w-2/3 lg:pl-12 red-text-links">
        <div v-for="(section, s) in support_sections" :key="s" :class="[s > 0 ? 'mt-8' : '']">
          <base-heading size="h2" color="brown">{{ section.heading }}</base-heading>
          <div v-if="section.text !== ''" class="mt-4 mb-8 rich-text" v-html="section.text"></div>
          <div v-if="section.email !== ''" class="mt-4">
            <base-heading size="h4" color="green" uppercase="true">Email</base-heading>
            <div class="text-xl xl:text-2xl leading-normal text-links">
              <a :href="'mailto:' + section.email">{{ section.email }}</a>
            </div>
          </div>
          <div :class="['sm:flex sm:flex-wrap sm:justify-between', section.email !== '' ? 'mt-6' : '']">
            <div v-for="(number, n) in section.phoneNumbers" :key="n" :class="['sm:w-p48', n > 0 ? 'mt-4 sm:mt-0' : '']">
              <base-heading size="h4" color="green" uppercase="true">{{ number.heading }}</base-heading>
              <div class="text-xl xl:text-2xl leading-normal mt-1">{{ number.number }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["heading", "office_location_heading", "hours_heading", "support_sections", "has_buttons"],
  components: {
    BaseHeading: () => import(/* webpackChunkName: "base-heading" */ "../components/BaseHeading.vue")
  }
};
</script>
