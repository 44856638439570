import { render, staticRenderFns } from "./IconX.vue?vue&type=template&id=4adf3550&"
var script = {}


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/ian/wildlife-website.master/craftcms/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('4adf3550')) {
      api.createRecord('4adf3550', component.options)
    } else {
      api.reload('4adf3550', component.options)
    }
    module.hot.accept("./IconX.vue?vue&type=template&id=4adf3550&", function () {
      api.rerender('4adf3550', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/vue/icons/IconX.vue"
export default component.exports