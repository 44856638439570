var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: [
        "input-select-wrapper relative",
        _vm.disabled ? "disabled" : "",
        _vm.is_multiple ? "multiple-select" : ""
      ]
    },
    [
      _c(
        "select",
        {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.localValue,
              expression: "localValue"
            }
          ],
          class: ["input-select", _vm.class_names],
          attrs: {
            name: _vm.name,
            title: _vm.title,
            "aria-label": _vm.title,
            required: _vm.required,
            multiple: _vm.is_multiple,
            size: _vm.rows
          },
          on: {
            change: function($event) {
              var $$selectedVal = Array.prototype.filter
                .call($event.target.options, function(o) {
                  return o.selected
                })
                .map(function(o) {
                  var val = "_value" in o ? o._value : o.value
                  return val
                })
              _vm.localValue = $event.target.multiple
                ? $$selectedVal
                : $$selectedVal[0]
            }
          }
        },
        _vm._l(_vm.options, function(o, v) {
          return _c("option", { key: v, domProps: { value: v } }, [
            _vm._v(_vm._s(o))
          ])
        }),
        0
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }