<template>
    <div v-if="url">

        <!-- block with heading text or sidebar -->
        <div v-if="text || sidebar">

            <!-- heading + intro text -->
            <base-heading v-if="text && text.heading" size="h1" color="brown">
                {{ text.heading}}
            </base-heading>
            <base-rich-text v-if="text && text.introText" class_names="mt-2" v-html="text.introText"></base-rich-text>

            <!-- columns if sidebar -->
            <div :class="[
                'mt-8',
                sidebar ? 'cols lg:flex' : ''
            ]">

                <!-- video -->
                <div class="bg-grey-light">
                    <div class="video-wrapper">
                        <iframe :src="url" width="640" height="360" frameborder="0" allow="autoplay; fullscreen"
                                allowfullscreen></iframe>
                    </div>
                </div>

                <!-- sidebar -->
                <aside v-if="sidebar"
                       class="c1 bg-brown-lighter text-white p-6 text-center lg:text-left">

                    <!-- sidebar image -->
                    <div v-if="sidebar.image">
                        <base-image
                            :src="sidebar.image.src"
                            :retina_src="sidebar.image.retina"
                            :srcset_webp="sidebar.image.webpSrcset"
                            class_names="mx-auto"
                            :alt="alt"
                        ></base-image>
                    </div>

                    <!-- sidebar heading -->
                    <div v-if="sidebar.heading">
                        <base-heading size="h2" color="white">
                            {{ sidebar.heading}}
                        </base-heading>
                    </div>

                    <!-- sidebar blurb -->
                    <div v-if="sidebar.blurb">
                        <base-rich-text size="smaller" v-html="sidebar.blurb"></base-rich-text>
                    </div>

                    <!-- sidebar button -->
                    <div v-if="sidebar.button">
                        <base-button
                            :url="sidebar.button.url"
                            :label="sidebar.button.text"
                            color="brown"
                            :outline="true"
                            class_names="w-full text-center"
                            :target="sidebar.button.target"
                        ></base-button>
                    </div>

                    <!-- footnote -->
                    <div v-if="sidebar.footnote">
                        <base-rich-text size="tiny" v-html="sidebar.footnote"></base-rich-text>
                    </div>

                </aside>
            </div>
        </div>

        <!-- simple video -->
        <div v-else>
            <div class="video-wrapper">
                <iframe :src="url" width="640" height="360" frameborder="0" allow="autoplay; fullscreen"
                        allowfullscreen></iframe>
            </div>
        </div>

    </div>
</template>

<script>
  export default {
    props: ["platform", "video_id", "text", "sidebar"],
    components: {
      BaseButton: () => import(/* webpackChunkName: "base-button" */ "../components/BaseButton.vue"),
      BaseHeading: () => import(/* webpackChunkName: "base-heading" */ "../components/BaseHeading.vue"),
      BaseImage: () => import(/* webpackChunkName: "base-image" */ "../components/BaseImage.vue"),
      BaseRichText: () => import(/* webpackChunkName: "base-rich-text" */ "../components/BaseRichText.vue")
    },
    computed: {
      url() {
        if (this.video_id === "") {
          return null;
        }
        if (this.platform === "youtube") {
          return "https://www.youtube-nocookie.com/embed/" + this.video_id;
        } else if (this.platform === "vimeo") {
          return "https://player.vimeo.com/video/" + this.video_id + "?dnt=1";
        } else {
          return null;
        }
      }
    }
  };
</script>

<style scoped>
    @media (min-width: 992px) {
        .cols {
            > div {
                width: 70%;
            }
            > aside {
                width: 30%;
            }
        }
    }
    aside div:not(:first-child) {
        margin-top: 20px;
    }
    .video-wrapper {
        position: relative;
        padding-bottom: 56.25%;
        height: 0;
        overflow: hidden;

        iframe,
        object,
        embed {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
</style>
