<template>
  <div class="publications-results">
    <search-bar :keyword="keyword" @updateResults="updateResults" placeholder="Search Library"></search-bar>
    <div v-if="ajaxError" class="mt-10 text-red text-lg" v-html="ajaxError"></div>
    <div :class="['mt-10', isLoading ? 'opacity-25' : '']">
      <div v-if="items && items.length > 0" class="text-brown text-links">
        <article v-for="(item, i) in items" :key="i" :class="[i > 0 ? 'mt-8' : '']">
          <base-heading size="h4" color="brown-lighter" :uppercase="true">{{ item.publicationType }}</base-heading>
          <a :href="item.url" :target="item.target" :class="['block', item.publicationType !== '' ? 'mt-2' : '']">
            <span class="block text-2xl" v-html="item.overrideTitle ? item.overrideTitle : item.title"></span>
            <span class="block text-lg mt-2" v-if="item.authors !== ''">
              {{ item.authors }}
            </span>
          </a>
        </article>
      </div>
      <div class="mt-10" v-if="numPages > 1">
        <base-pagination
          :max_visible_buttons="numPages >= 3 ? 3 : parseInt(numPages)"
          :per_page="parseInt(limit)"
          :num_pages="parseInt(numPages)"
          :current_page="currentPage"
          :total="parseInt(total)"
          :show_prev_next_btns="true"
          :pagination_disabled="isLoading"
          @pagechanged="onPageChange"
        ></base-pagination>
      </div>
      <div v-if="!items || items.length === 0">
        <div class="w-full rounded-lg bg-grey-lighter text-center py-20">
          <div class="text-3xl uppercase text-grey-dark">
            No publications found
          </div>
          <div v-if="keyword !== ''">
            <div class="text-xl uppercase text-grey-dark mt-2 leading-normal">
              with keyword
              <span class="italic">`{{ keyword }}`</span>
            </div>
            <div class="mt-12">
              <button
                @click="keyword = ''"
                class="button inline-block uppercase tr-all rounded text-lg xl:text-xl font-bold leading-tighter text-center cursor-pointer text-white bg-red hover:bg-red-hover px-4 py-2"
              >
                Reset Search
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import VueAxios from "../../js/modules/vue-axios";
import VueScrollTo from "vue-scrollto";
import HelpersMixin from "../mixins/Helpers";

Vue.use(VueScrollTo);

export default {
  data: function() {
    return {
      isLoading: false,
      keyword: "",
      items: null,
      numPages: null,
      limit: this.$parent.limit,
      total: null,
      currentPage: 1,
      filterProducts: this.$parent.products,
      filterSpecies: this.$parent.species,
      publicationTypes: this.$parent.types,
      ajaxError: ""
    };
  },
  components: {
    BaseHeading: () => import(/* webpackChunkName: "base-heading" */ "../components/BaseHeading.vue"),
    BaseRichText: () => import(/* webpackChunkName: "base-rich-text" */ "../components/BaseRichText.vue"),
    SearchBar: () => import(/* webpackChunkName: "search-bar" */ "../components/SearchBar.vue"),
    BasePagination: () => import(/* webpackChunkName: "base-pagination" */ "../components/BasePagination.vue")
  },
  mixins: [HelpersMixin],
  methods: {
    updateResults(keyword) {
      let self = this,
        selectedProducts = [],
        selectedSpecies = [],
        selectedTypes = [],
        queryStrings = [],
        ajaxUrl = "/actions/site-module/publications/get-entries?limit=" + self.limit + "&page=" + self.currentPage;
      if (keyword !== undefined) {
        self.keyword = keyword;
      }
      if (self.filterProducts) {
        const filterProducts = Object.values(self.filterProducts);
        filterProducts.forEach(function(category) {
          if (category.active) {
            selectedProducts.push(category.slug);
          }
        });
        if (selectedProducts.length > 0) {
          queryStrings.push("cp=" + selectedProducts.join(","));
        }
      }
      if (self.filterSpecies) {
        const filterSpecies = Object.values(self.filterSpecies);
        filterSpecies.forEach(function(category) {
          if (category.active) {
            selectedSpecies.push(category.slug);
          }
        });
        if (selectedSpecies.length > 0) {
          queryStrings.push("cs=" + selectedSpecies.join(","));
        }
      }
      if (self.publicationTypes) {
        const publicationTypes = Object.values(self.publicationTypes);
        publicationTypes.forEach(function(category) {
          if (category.active) {
            selectedTypes.push(category.slug);
          }
        });
        if (selectedTypes.length > 0) {
          queryStrings.push("ct=" + selectedTypes.join(","));
        }
      }
      if (self.keyword !== "") {
        queryStrings.push("kw=" + encodeURIComponent(this.keyword));
      }
      if (queryStrings.length > 0) {
        ajaxUrl += "&" + queryStrings.join("&");
      }
      self.updateUrl(selectedProducts, selectedSpecies, selectedTypes);
      self.isLoading = true;
      self.ajaxError = "";
      VueAxios.get(ajaxUrl)
        .then(response => {
          self.isLoading = false;
          var data = response.data || null;
          if (data !== undefined) {
            self.items = data.items.items || null;
            self.numPages = data.items.numPages || null;
            self.total = data.items.total || null;
            if (self.currentPage > 1) {
              self.scrollToTop();
            }
          } else {
            self.ajaxError = "An error occurred. Please try again.";
            self.scrollToTop();
          }
        })
        .catch(error => {
          self.ajaxError = "An error occurred. Please try again.";
          self.isLoading = false;
        });
    },
    updateUrl(selectedProducts, selectedSpecies, selectedTypes) {
      let queryParams = [];
      if (this.keyword) {
        queryParams.push("kw=" + this.keyword);
      }
      if (selectedProducts.length > 0) {
        queryParams.push("cp=" + selectedProducts.join(","));
      }
      if (selectedSpecies.length > 0) {
        queryParams.push("cs=" + selectedSpecies.join(","));
      }
      if (selectedTypes.length > 0) {
        queryParams.push("ct=" + selectedTypes.join(","));
      }
      let url = "/resources/publications" + (queryParams.length > 0 ? "?" : "") + queryParams.join("&");
      this.setUrl("publications", document.title, url);
    },
    scrollToTop() {
      VueScrollTo.scrollTo("#publications-top", 350, {
        offset: 20
      });
    },
    onPageChange(newPageNum) {
      this.currentPage = newPageNum;
      this.updateResults();
    }
  },
  watch: {
    filterProducts: {
      deep: true,
      handler() {
        // Update results
        this.updateResults();
      }
    },
    filterSpecies: {
      deep: true,
      handler() {
        // Update results
        this.updateResults();
      }
    },
    publicationTypes: {
      deep: true,
      handler() {
        // Update results
        this.updateResults();
      }
    }
  },
  mounted() {
    if (this.$parent.keyword) {
      this.keyword = this.$parent.keyword;
    }
    this.updateResults();
  }
};
</script>
