var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "path",
    {
      attrs: {
        d: "M16,0C7.2,0,0,7.2,0,16h3.5C3.5,9.1,9.1,3.5,16,3.5V0z",
        fill: "currentColor"
      }
    },
    [
      _c("animateTransform", {
        attrs: {
          accumulate: "none",
          additive: "replace",
          attributeName: "transform",
          attributeType: "xml",
          calcMode: "linear",
          dur: "1.2s",
          fill: "remove",
          from: "0 16 16",
          repeatCount: "indefinite",
          restart: "always",
          to: "360 16 16",
          type: "rotate"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }