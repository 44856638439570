<template>
  <div class="video-tutorials-sidebar-nav">

    <!-- Languages -->
    <video-tutorials-languages-nav
        :languages="$parent.languages"
        :clicked_language="$parent.selectedLanguage"
        @onselect="selectLanguage"
    ></video-tutorials-languages-nav>

    <aside class="side-filter text-brown" id="nav-videos">
      <ul class="border-b border-brown">
        <!-- Categories -->
        <li v-for="(category, c) in $parent.categories" :key="'cat-' + c" v-if="categoryHasVideos(category)">
          <sidebar-filter-button
            :label="translatedCategoryName(category)"
            :has_submenu="category.children && category.children.length > 0"
            :active="category.active"
            @onclick="category.active = !category.active"
          >
          </sidebar-filter-button>

          <!-- Sub-categories -->
          <slide-up-down :active="category.active" :duration="350" v-if="category.children && category.children.length > 0">
            <ul class="sub border-t border-brown py-1 pl-4 pr-4 xl:pl-12 xl:pr-10 text-lg xl:text-xl">
              <li
                v-for="(subCategory, s) in category.children"
                :key="'subcat' + s"
                class="pt-1 pb-2"
                v-if="videosInCategory(subCategory.id).length > 0"
              >
                <strong>{{ translatedCategoryName(subCategory) }}</strong>

                <!-- Videos in sub-category -->
                <ul class="mt-2 ml-7 text-lg">
                  <li
                    v-for="(video, v) in videosInCategory(subCategory.id)"
                    :key="'video' + v"
                    :class="['py-2', selectedVideoUid === video.uid ? 'text-red' : '']"
                  >
                    <button
                      type="button"
                      @click.prevent="selectVideo(video)"
                      class="video-nav-link hover:underline"
                      :data-uid="video.uid"
                    >
                      {{ video.shortTitle && video.shortTitle !== "" ? video.shortTitle : video.title }}
                    </button>
                  </li>
                </ul>
              </li>
              <!-- Videos in category -->
              <li
                v-for="(video, v) in videosInCategory(category.id)"
                :key="'video' + v"
                :class="['py-2', selectedVideoUid === video.uid ? 'text-red' : '']"
              >
                <button type="button" @click.prevent="selectVideo(video)" class="video-nav-link hover:underline" :data-uid="video.uid">
                  <strong>{{ video.shortTitle && video.shortTitle !== "" ? video.shortTitle : video.title }}</strong>
                </button>
              </li>
            </ul>
          </slide-up-down>
        </li>
      </ul>

      <!-- Videos without a category -->
      <div v-if="videosWithoutCategory.length > 0" style="background-color: #f4ede3;">
        <ul class="border-b border-brown py-2 pl-4 pr-4 xl:pl-6 xl:pr-6 text-lg xl:text-xl">
          <li
            v-for="(video, v) in videosWithoutCategory"
            :key="'video' + v"
            :class="['py-2', selectedVideoUid === video.uid ? 'text-red' : '']"
          >
            <button type="button" @click.prevent="selectVideo(video)" class="video-nav-link hover:underline" :data-uid="video.uid">
              <strong>{{ video.shortTitle && video.shortTitle !== "" ? video.shortTitle : video.videoTitle }}</strong>
            </button>
          </li>
        </ul>
      </div>
    </aside>

  </div>
</template>

<script>
export default {
  components: {
    SidebarFilterButton: () => import(/* webpackChunkName: "sidebar-filter-button" */ "../components/SidebarFilterButton.vue"),
    VideoTutorialsLanguagesNav: () =>
      import(/* webpackChunkName: "video-tutorials-languages-nav" */ "./VideoTutorialsLanguagesNav.vue")
  },
  methods: {
    selectVideo(video) {
      this.$emit("onselectvideo", video);
      this.$emit("updateurl");
    },
    selectLanguage(language) {
      let self = this;
      self.$parent.selectedLanguage = language.slug;
      self.$parent.clickedLanguage = language.slug;
      self.$emit("updateurl");
      self.$nextTick(function() {
        self.$parent.selectFirstVideo();
      });
    },
    videosInCategory(categoryId) {
      let self = this,
        videos = [];
      self.$parent.videos.forEach(function(video) {
        if (video.categoryId === categoryId && video.languages.includes(self.$parent.selectedLanguage)) {
          videos.push(video);
        }
      });
      return videos;
    },
    translatedCategoryName(category) {
      let name = category.name;
      if (this.$parent.selectedLanguage !== "en") {
        if (category.translations[this.$parent.selectedLanguage] !== undefined) {
          name = category.translations[this.$parent.selectedLanguage];
        }
      }
      return name;
    },
    categoryHasVideos(category) {
      let self = this,
        value = false;
      if (self.videosInCategory(category.id).length > 0) {
        value = true;
      }
      if (category.children && category.children.length > 0) {
        category.children.forEach(function(subCategory) {
          if (self.videosInCategory(subCategory.id).length > 0) {
            value = true;
          }
        });
      }
      return value;
    }
  },
  computed: {
    videosWithoutCategory() {
      let self = this,
        videos = [];
      self.$parent.videos.forEach(function(video) {
        if (!video.categoryId && video.languages.includes(self.$parent.selectedLanguage)) {
          videos.push(video);
        }
      });
      return videos;
    },
    selectedVideoUid() {
      return this.$parent.showVideo && this.$parent.showVideo.uid !== undefined ? this.$parent.showVideo.uid : null;
    }
  },
  mounted: function() {
    // if no video uid in URL
    if (!this.$parent.selectedVideoUid) {
      // select first video in sidebar nav
      this.$parent.selectFirstVideo();
    }
  }
};
</script>

<style scoped>
button {
  background-color: transparent !important;
  text-align: left;
}
.side-filter {
  button {
    background-color: #f4ede3;
  }
}
</style>
