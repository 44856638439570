var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("g", [
    _c("polygon", {
      attrs: {
        fill: "currentColor",
        points:
          "13.3,20.8 6.7,20.8 6.7,22.3 8.8,22.3 8.8,32 10.9,32 10.9,22.3 13.3,22.3 \t"
      }
    }),
    _vm._v(" "),
    _c("path", {
      attrs: {
        fill: "currentColor",
        d:
          "M16.1,28.9h0.2c0,0.4,0,0.7,0,0.9s0,0.3-0.1,0.4c-0.1,0.2-0.2,0.4-0.5,0.5c-0.3,0.1-0.5,0-0.7-0.4c0-0.1,0-0.2,0-0.4\n        c0-0.2,0-0.5,0-0.8v-5.2h-2.1V29c0,0.4,0,0.7,0,1s0,0.5,0,0.6c0,0.2,0.1,0.5,0.1,0.7c0,0.3,0.2,0.5,0.4,0.6\n        c0.2,0.1,0.5,0.2,0.8,0.2s0.6-0.1,0.9-0.1c0.3-0.1,0.6-0.2,0.8-0.4c0.3-0.2,0.5-0.4,0.6-0.6v1h1.7v-8.2h-2.1\n        C16.1,23.8,16.1,28.9,16.1,28.9z"
      }
    }),
    _vm._v(" "),
    _c("path", {
      attrs: {
        fill: "currentColor",
        d:
          "M19.2,14.6c0.2,0,0.5-0.1,0.6-0.2c0.2-0.2,0.3-0.4,0.3-0.6V9.2c0-0.2-0.1-0.5-0.3-0.6c-0.2-0.2-0.4-0.3-0.6-0.3\n        s-0.5,0.1-0.6,0.3c-0.2,0.2-0.3,0.4-0.3,0.6v4.5c0,0.2,0.1,0.4,0.3,0.6C18.7,14.5,19,14.6,19.2,14.6z"
      }
    }),
    _vm._v(" "),
    _c("path", {
      attrs: {
        fill: "currentColor",
        d:
          "M24,23.6c-0.2-0.1-0.5-0.2-0.7-0.2c-0.5,0-1.1,0.2-1.7,0.6v-3.2h-2.1v11h1.7l0.1-0.7c0.6,0.4,1,0.7,1.5,0.8\n        c0.4,0.1,0.8,0.1,1.1-0.1c0.3-0.2,0.5-0.5,0.7-0.9s0.3-0.9,0.3-1.4v-4.3C25,24.5,24.7,23.9,24,23.6z M23.4,30\n        c0,0.2-0.1,0.3-0.3,0.4c-0.2,0.1-0.4,0.2-0.6,0.2s-0.5-0.1-0.6-0.2c-0.2-0.1-0.3-0.3-0.3-0.4v-4.8c0-0.2,0.1-0.3,0.3-0.4\n        c0.2-0.1,0.4-0.2,0.6-0.2s0.4,0.1,0.6,0.2s0.3,0.3,0.3,0.4V30z"
      }
    }),
    _vm._v(" "),
    _c("path", {
      attrs: {
        fill: "currentColor",
        d:
          "M33.9,0H5.3C2.4,0,0,2.3,0,5.2v28.4c0,2.9,2.4,5.2,5.3,5.2h28.6c2.9,0,5.3-2.3,5.3-5.2V5.2C39.1,2.3,36.8,0,33.9,0z\n         M23.1,13.8V13h0.1V7.2h1.9v6.6c0,0.2,0.1,0.3,0.2,0.4c0.1,0.1,0.3,0.2,0.5,0.2s0.4-0.1,0.5-0.2c0.2-0.1,0.2-0.3,0.2-0.4V7.2h1.8\n        v8.5H26V15c-0.2,0.3-0.4,0.5-0.6,0.6s-0.5,0.2-0.8,0.2s-0.6-0.1-0.8-0.2c-0.2-0.1-0.4-0.3-0.5-0.6c-0.1-0.1-0.1-0.2-0.1-0.3\n        s0-0.2-0.1-0.3C23.1,14.3,23.1,14.1,23.1,13.8z M17.6,7.3C18,7,18.5,6.9,19.1,6.9c0.5,0,1,0.1,1.4,0.3c0.4,0.1,0.7,0.4,0.9,0.7\n        c0.2,0.3,0.3,0.5,0.4,0.9c0.1,0.3,0.1,0.8,0.1,1.4v2.2c0,0.8,0,1.4-0.1,1.7c0,0.3-0.2,0.7-0.5,1.1c-0.3,0.3-0.6,0.6-0.9,0.7\n        c-0.4,0.2-0.8,0.2-1.3,0.2s-0.9-0.1-1.3-0.2c-0.4-0.1-0.7-0.3-0.8-0.6c-0.2-0.2-0.3-0.5-0.5-0.9c-0.1-0.4-0.1-0.9-0.1-1.7v-2.3\n        c0-0.8,0.1-1.4,0.2-1.9C16.9,7.9,17.2,7.5,17.6,7.3z M11.5,3.9l1.4,4.2l1.4-4.2h2.4l-2.6,5.6V16h-2.2V9.5L9.1,3.9H11.5z M34.6,30.9\n        L34.6,30.9c-0.1,0.6-0.2,1.1-0.5,1.6s-0.6,0.9-1.1,1.3c-0.4,0.4-1,0.6-1.5,0.9c-0.6,0.2-1.2,0.3-1.9,0.3h-20\n        c-0.7,0-1.3-0.1-1.9-0.3s-1.1-0.5-1.6-0.9C5.7,33.4,5.3,33,5,32.5s-0.4-1-0.4-1.6v-9.2c0-0.5,0.1-1.1,0.4-1.6s0.6-0.9,1.1-1.3\n        c0.4-0.4,1-0.6,1.6-0.9c0.6-0.2,1.2-0.3,1.9-0.3h20c0.7,0,1.3,0.1,1.9,0.3c0.6,0.2,1.1,0.5,1.6,0.9c0.4,0.4,0.8,0.8,1.1,1.3\n        s0.4,1,0.4,1.6V30.9z"
      }
    }),
    _vm._v(" "),
    _c("path", {
      attrs: {
        fill: "currentColor",
        d:
          "M29.9,29.2L29.9,29.2V30c0,0.2-0.1,0.4-0.3,0.5c-0.2,0.1-0.4,0.2-0.6,0.2h-0.3c-0.2,0-0.4-0.1-0.6-0.2s-0.2-0.3-0.2-0.5\n        v-0.1v-0.8V28h3.5v-1.1c0-0.4,0-0.8,0-1.2c0-0.4,0-0.7-0.1-0.9c-0.1-0.4-0.2-0.7-0.5-0.9c-0.3-0.2-0.7-0.4-1.1-0.5s-0.8-0.1-1.3,0\n        c-0.4,0.1-0.8,0.2-1.1,0.4c-0.4,0.3-0.7,0.6-0.9,1c-0.2,0.4-0.3,1-0.3,1.8v2.6c0,1.1,0.3,1.9,0.9,2.3s1.2,0.6,1.8,0.6H29\n        c0.7,0,1.3-0.3,1.9-0.8c0.4-0.4,0.6-0.9,0.6-1.5c0-0.2,0-0.4-0.1-0.6C31.4,29.2,29.9,29.2,29.9,29.2z M27.9,25.6\n        c0-0.2,0.1-0.4,0.3-0.6c0.2-0.1,0.4-0.2,0.6-0.2h0.1c0.3,0,0.5,0.1,0.7,0.2s0.3,0.3,0.3,0.6v1h-2V25.6z"
      }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }