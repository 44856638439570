var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "share-icons" },
    _vm._l(_vm.items, function(item, i) {
      return _c(
        "div",
        { key: "item_" + i, class: ["inline-block", i > 0 ? "pl-2" : ""] },
        [
          _c(
            "a",
            {
              staticClass: "text-brown-lighter tr-color hover:text-brown-light",
              attrs: { href: item.url },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.openWindow(item.url)
                }
              }
            },
            [
              _c("social-icon", {
                attrs: { type: item.site, icon_size: _vm.icon_size }
              })
            ],
            1
          )
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }