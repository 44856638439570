var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("path", {
    attrs: {
      fill: "currentColor",
      d:
        "M11.8,2.9c4.6,0,8.3,3.7,8.3,8.4c0,4.6-3.7,8.4-8.3,8.4S3.5,16,3.5,11.3C3.4,6.7,7.2,2.9,11.8,2.9 M27.1,25.5l-6.7-6.9\n\tc1.7-2,2.6-4.6,2.6-7.3C23.1,5.1,18,0,11.8,0S0.5,5.1,0.5,11.3s5.1,11.3,11.3,11.3c2.3,0,4.6-0.7,6.5-2l6.7,7\n\tc0.3,0.3,0.7,0.5,1.1,0.5s0.7-0.1,1-0.4C27.6,27,27.7,26.1,27.1,25.5"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }