var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.block.src !== "",
          expression: "block.src !== ''"
        }
      ],
      class: ["text-center", !_vm.block.isFirst ? "mt-6" : ""]
    },
    [
      _c("base-image", {
        attrs: {
          src: _vm.block.src,
          retina_src: _vm.block.retinaSrc,
          alt: _vm.block.alt,
          class_names: "mx-auto"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }