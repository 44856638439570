var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: [
        _vm.include_container
          ? "container mx-auto px-5-safe"
          : "full-container",
        "block white-block image-block"
      ]
    },
    [
      _c(
        "span",
        {
          class: [
            "table w-auto mb-10",
            "max-width-" + _vm.data.maximumWidth,
            "position-" + _vm.data.position
          ]
        },
        [
          _c("base-image", {
            attrs: {
              src: _vm.data.src,
              retina_src: _vm.data.retina,
              alt: _vm.data.alt,
              url: _vm.data.url,
              target: _vm.data.target ? "_blank" : null
            }
          }),
          _vm._v(" "),
          _vm.data.hasCaption
            ? _c(
                "span",
                { staticClass: "caption text-base mt-2 leading-normal" },
                [_vm._t("default")],
                2
              )
            : _vm._e()
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }