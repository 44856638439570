<template>
  <input
    :type="input_type"
    :placeholder="placeholder"
    :name="name"
    :readonly="disabled"
    :class="['input-text', disabled ? 'disabled' : '']"
    :required="required"
    v-model="localValue"
  />
</template>

<script>
// @group Form

export default {
  props: ["input_type", "placeholder", "name", "value", "disabled", "required"],
  computed: {
    localValue: {
      get() {
        return this.value;
      },
      set(localValue) {
        this.$emit("input", localValue);
      }
    }
  }
};
</script>
