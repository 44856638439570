<template>
  <div :class="['input-select-wrapper relative', disabled ? 'disabled' : '', is_multiple ? 'multiple-select' : '']">
    <select
      v-model="localValue"
      :name="name"
      :title="title"
      :aria-label="title"
      :required="required"
      :multiple="is_multiple"
      :size="rows"
      :class="['input-select', class_names]"
    >
      <option v-for="(o, v) in options" :key="v" :value="v">{{ o }}</option>
    </select>
  </div>
</template>

<script>
// @group Form

export default {
  props: {
    name: String,
    title: String,
    options: Object,
    value: String,
    class_names: Array,
    disabled: Boolean,
    required: Boolean,
    multiple: Boolean,
    rows: Number,
  },
  computed: {
    is_multiple: function() {
      return this.multiple === "true";
    },

    localValue: {
      get() {
        return this.value;
      },
      set(localValue) {
        this.$emit("input", localValue);
        this.$emit("selectchanged", localValue);
      }
    }
  }
};
</script>
