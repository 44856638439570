<template>
  <div class="add-to-cart-essential-products">
    <div class="products text-base leading-normal">
      <div v-for="(group, g) in groupedEssentialProducts" :key="g">
        <div v-for="(item, i) in group" class="py-2 px-5 flex border-b border-brown" :key="i">
          <div class="c1">
            <div>{{ item.title }}</div>
            <div class="text-xs mt-1">Product Code: {{ item.code }}</div>
          </div>
          <div class="c2 text-right">
            <div>
              <div class="font-bold">{{ $root.formattedPrice(item.price) }}</div>
              <div class="mt-1 text-xs italic">{{ $root.formattedCurrency() }}</div>
              <div class="text-xs uppercase mt-1 product-stock" v-show="item.stock !== ''" v-html="item.stock"></div>
            </div>
          </div>
          <div class="c3 text-right">
            <input-product-quantity
              v-model="groupedEssentialProducts[g][i].qty"
              :value="groupedEssentialProducts[g][i].qty"
              border_color="brown"
              bg_color="grey-light"
              :is_disabled="isLoading"
              class_names="float-right ml-4 sm:float-none sm:mx-auto"
            ></input-product-quantity>
          </div>
        </div>
      </div>
    </div>
    <div class="px-5 py-6">
      <div v-if="ajaxError" class="mb-6 text-red text-lg" v-html="ajaxError"></div>
      <div class="flex justify-between">
        <div class="w-p48">
          <base-button
            url="/shop/cart"
            label="View Cart"
            color="brown"
            outline="true"
            size="smaller"
            class_names="w-full"
          ></base-button>
        </div>
        <div class="w-p48">
          <button
            type="submit"
            @click.prevent="addToCart()"
            :disabled="isLoading || totalQuantity < 1"
            :class="[
              'button relative inline-block uppercase tr-all rounded text-xl font-bold leading-tight text-center border-3 cursor-pointer w-full py-2 px-5',
              isLoading || totalQuantity < 1
                ? 'text-grey bg-white'
                : 'bg-brown border-brown text-white hover:bg-brown-hover hover:border-brown-hover',
              isLoading ? 'pl-8' : ''
            ]"
          >
            <base-icon
              icon-name="loading"
              width="28"
              height="28"
              viewbox="0 0 32 32"
              class="loading absolute ml-3 left-0 centered-y"
              v-show="isLoading"
            >
              <icon-loading></icon-loading>
            </base-icon>
            <span class="inline">Add to Order</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueAxios from "../../../js/modules/vue-axios";
import { gaAddToCart } from "../../../js/modules/wa-analytics";

export default {
  data: function() {
    return {
      isLoading: false,
      products: this.$parent.$parent.$parent.essential_products,
      ajaxError: ""
    };
  },
  components: {
    BaseButton: () => import(/* webpackChunkName: "base-button" */ "../../components/BaseButton.vue"),
    AddToCartBoxEssentialProducts: () =>
      import(/* webpackChunkName: "add-to-cart-box-essential-products" */ "./AddToCartBoxEssentialProducts.vue"),
    InputProductQuantity: () => import(/* webpackChunkName: "input-product-quantity" */ "../../components/InputProductQuantity.vue"),
    BaseIcon: () => import(/* webpackChunkName: "base-icon" */ "../../components/BaseIcon.vue"),
    IconLoading: () => import(/* webpackChunkName: "icon-loading" */ "../../icons/IconLoading.vue")
  },
  methods: {
    addToCart() {
      let self = this;
      self.isLoading = true;
      self.ajaxError = "";
      // loop through products
      let formData = new FormData(),
        numProductsToAdd = 0;
      formData.append(self.$root.csrfTokenName, self.$root.csrfTokenValue);
      formData.append("action", "wildlife/shop/update");
      self.products.forEach(function(product) {
        if (product.qty > 0) {
          const productCode = product.code.replace(/\\/g, '\\\\');
          numProductsToAdd++;
          formData.append("quantity_" + productCode, product.qty);
          formData.append("add_" + productCode, "");
        }
      });
      if (numProductsToAdd > 0) {
        let options = {
          method: "POST",
          data: formData,
          url: "/"
        };
        VueAxios(options)
          .then(response => {
            let data = response.data || null;
            if (data.success) {
              window.location.href = "/shop/cart";

              // Send to Google Analytics
              let productsData = [];
              self.products.forEach(function(product) {
                if (product.qty > 0) {
                  productsData.push({
                    name: product.title,
                    id: product.code,
                    price: product.price,
                    brand: self.$root.getSiteFullName(),
                    quantity: product.qty
                  });
                }
              });
              const productQtyTotal = productsData.reduce((partialSum, p) => {
                return partialSum + p;
              });
              gaAddToCart(
                productsData,
                {
                  list: "Recommended Products"
                },
                productQtyTotal,
                self.$root.currencyCode()
              );
            } else {
              self.isLoading = false;
              self.ajaxError = "An error occurred. Please try again.";
            }
          })
          .catch(error => {
            self.ajaxError = "An error occurred. Please try again.";
            self.isLoading = false;
          });
      }
    },
    chunkArray(array, size) {
      const chunked_arr = [];
      for (let i = 0; i < array.length; i++) {
        const last = chunked_arr[chunked_arr.length - 1];
        if (!last || last.length === size) {
          chunked_arr.push([array[i]]);
        } else {
          last.push(array[i]);
        }
      }
      return chunked_arr;
    }
  },
  computed: {
    totalQuantity() {
      let total = 0;
      this.products.forEach(function(product) {
        if (product.qty > 0) {
          total = total + parseInt(product.qty);
        }
      });
      return total;
    },
    groupedEssentialProducts() {
      return this.chunkArray(this.products, 3);
    }
  }
};
</script>

<style scoped>
.products {
  .c1 {
    width: 55%;
  }
  .c2 {
    width: 27%;
  }
  .c3 {
    width: 18%;
  }
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}
.product-stock >>> a {
  @apply underline text-red;
}
</style>
