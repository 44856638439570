var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.url
    ? _c("div", [
        _vm.text || _vm.sidebar
          ? _c(
              "div",
              [
                _vm.text && _vm.text.heading
                  ? _c(
                      "base-heading",
                      { attrs: { size: "h1", color: "brown" } },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.text.heading) +
                            "\n        "
                        )
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.text && _vm.text.introText
                  ? _c("base-rich-text", {
                      attrs: { class_names: "mt-2" },
                      domProps: { innerHTML: _vm._s(_vm.text.introText) }
                    })
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  { class: ["mt-8", _vm.sidebar ? "cols lg:flex" : ""] },
                  [
                    _c("div", { staticClass: "bg-grey-light" }, [
                      _c("div", { staticClass: "video-wrapper" }, [
                        _c("iframe", {
                          attrs: {
                            src: _vm.url,
                            width: "640",
                            height: "360",
                            frameborder: "0",
                            allow: "autoplay; fullscreen",
                            allowfullscreen: ""
                          }
                        })
                      ])
                    ]),
                    _vm._v(" "),
                    _vm.sidebar
                      ? _c(
                          "aside",
                          {
                            staticClass:
                              "c1 bg-brown-lighter text-white p-6 text-center lg:text-left"
                          },
                          [
                            _vm.sidebar.image
                              ? _c(
                                  "div",
                                  [
                                    _c("base-image", {
                                      attrs: {
                                        src: _vm.sidebar.image.src,
                                        retina_src: _vm.sidebar.image.retina,
                                        srcset_webp:
                                          _vm.sidebar.image.webpSrcset,
                                        class_names: "mx-auto",
                                        alt: _vm.alt
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.sidebar.heading
                              ? _c(
                                  "div",
                                  [
                                    _c(
                                      "base-heading",
                                      { attrs: { size: "h2", color: "white" } },
                                      [
                                        _vm._v(
                                          "\n                        " +
                                            _vm._s(_vm.sidebar.heading) +
                                            "\n                    "
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.sidebar.blurb
                              ? _c(
                                  "div",
                                  [
                                    _c("base-rich-text", {
                                      attrs: { size: "smaller" },
                                      domProps: {
                                        innerHTML: _vm._s(_vm.sidebar.blurb)
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.sidebar.button
                              ? _c(
                                  "div",
                                  [
                                    _c("base-button", {
                                      attrs: {
                                        url: _vm.sidebar.button.url,
                                        label: _vm.sidebar.button.text,
                                        color: "brown",
                                        outline: true,
                                        class_names: "w-full text-center",
                                        target: _vm.sidebar.button.target
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.sidebar.footnote
                              ? _c(
                                  "div",
                                  [
                                    _c("base-rich-text", {
                                      attrs: { size: "tiny" },
                                      domProps: {
                                        innerHTML: _vm._s(_vm.sidebar.footnote)
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e()
                          ]
                        )
                      : _vm._e()
                  ]
                )
              ],
              1
            )
          : _c("div", [
              _c("div", { staticClass: "video-wrapper" }, [
                _c("iframe", {
                  attrs: {
                    src: _vm.url,
                    width: "640",
                    height: "360",
                    frameborder: "0",
                    allow: "autoplay; fullscreen",
                    allowfullscreen: ""
                  }
                })
              ])
            ])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }