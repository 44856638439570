<template>
  <inner-image-zoom v-if="src && src.length > 0" :alt="alt" :src="src" :zoomSrc="srcZoom" zoomType="hover" :sources="sources" :zoomPreload="true"></inner-image-zoom>
</template>

<script>
import InnerImageZoom from "vue-inner-image-zoom";

export default {
  props: ["src", "srcZoom", "srcRetina", "srcWebp", "alt"],
  components: {
    "inner-image-zoom": InnerImageZoom
  },
  computed: {
    imageExtension() {
      if (this.src === undefined) {
        return null;
      }
      return this.src.split(".").pop();
    },
    sources() {
      let sources = [];

      if (this.imageExtension !== "svg" && this.srcRetina && this.srcRetina.length > 0) {
        sources.push({
          srcSet: this.src + " 1x, " + this.srcRetina + " 2x"
        });
      }

      if (this.srcWebp && this.srcWebp.length > 0) {
        sources.push({
          srcSet: this.srcWebp,
          type: "image/webp"
        });
      }

      return sources;
    }
  }
};
</script>
